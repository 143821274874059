import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import MIRRoutes from '../../../lib/routes'
import BookNowModal from '../../CommonElements/BookNowModal'
import Footer from '../../Footer/Footer';
import { Helmet } from 'react-helmet';

export default function CRMBundles() {

    const [showRegionPopup, setShowRegionPopup] = useState(false);

    return (
        <main>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Dynamics 365 CRM Sales Bundles | MIR Digital Solutions</title>
                <meta name="description" content="Elevate your business performance with Microsoft Dynamics 365 Customer Engagement - the comprehensive solution tailored for small and medium-sized enterprises." />
                <link rel="canonical" href={`${window.location.origin}${MIRRoutes.CRMBundles}`} />
            </Helmet>

            <section className='tw-pt-[72px] tw-bg-no-repeat tw-bg-cover position-relative mir-bg-primary-100'>
                <img src="/bc-bundles/bg.png" className='tw-absolute tw-object-center tw-top-0 tw-left-0 tw-right-0 tw-h-[85%] tw-w-full tw-object-cover tw-z-0' alt="bc-bundles-bg" />
                <div className="container mx-auto tw-pb-[72px] text-center tw-relative tw-z-10">
                    <h1 className='mir-display-semibold-lg text-white mx-auto tw-max-w-full md:tw-max-w-[695px] tw-mb-[24px]'>Customer Relationship Management bundles</h1>
                    <h3 className='mir-p-md-regular mir-text-neutral-100 mx-auto tw-max-w-full md:tw-max-w-[695px]'> Specifically tailored for small and medium-sized enterprises, Microsoft Dynamics 365 Customer Engagement is a comprehensive solution to your sales, marketing and customer service operations.</h3>
                </div>
                <div className="container mx-auto tw-relative tw-z-10">
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[32px] tw-max-w-full md:tw-max-w-[1008px] mx-auto  tw-h-full">
                        <div className='tw-group tw-flex tw-flex-col tw-h-full tw-items-start tw-justify-between tw-p-[32px] tw-bg-white tw-rounded-[8px]' style={{ boxShadow: '0px 4px 9px -1px rgba(16, 24, 40, 0.04), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)' }}>
                            <div>
                                <svg width="36" height="36" className='tw-mb-[24px]' viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_7381_13466)">
                                        <path d="M8.25073 26.251C10.736 26.251 12.7507 24.2363 12.7507 21.751C12.7507 19.2657 10.736 17.251 8.25073 17.251C5.76545 17.251 3.75073 19.2657 3.75073 21.751C3.75073 24.2363 5.76545 26.251 8.25073 26.251Z" fill="#D6DBE0" className='group-hover:tw-fill-[#1D68F0]' />
                                        <path d="M12.375 28.501H4.12425C1.8495 28.501 0 30.3505 0 32.6252V34.8752C0 35.4962 0.504 36.0002 1.125 36.0002H15.3743C15.9953 36.0002 16.4993 35.4962 16.4993 34.8752V32.6252C16.4993 30.3505 14.6497 28.501 12.375 28.501Z" fill="#D6DBE0" className='group-hover:tw-fill-[#1D68F0]' />
                                        <path d="M33.3743 0H10.125C8.68502 0 7.49927 1.18575 7.49927 2.62575V15.0458C7.74002 15.0165 7.99427 15.0007 8.24852 15.0007C11.7135 15.0007 14.5778 17.6265 14.9535 21.0015H18V25.875C18 26.37 18.315 26.8042 18.7943 26.955C18.9 26.9842 19.0193 27 19.125 27C19.485 27 19.845 26.82 20.0543 26.505L23.8343 20.9993H33.3743C34.8143 20.9993 36 19.8135 36 18.3735V2.6235C36 1.18575 34.8143 0 33.3743 0ZM31.2458 6.345L25.6208 13.095C25.2158 13.5742 24.5115 13.635 24.03 13.2458L20.295 10.125L15.39 14.6993C15.1808 14.895 14.895 14.9985 14.625 14.9985C14.3258 14.9985 14.0243 14.8792 13.7993 14.6385C13.3785 14.1885 13.41 13.4685 13.86 13.0477L19.485 7.7985C19.89 7.40925 20.5358 7.3935 20.97 7.7535L24.5993 10.7843L29.5043 4.905C29.9093 4.42575 30.6135 4.365 31.095 4.75425C31.5743 5.15925 31.635 5.86575 31.2458 6.345Z" fill="#EEEEF2" className='group-hover:tw-fill-[#1D68F0]' />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_7381_13466">
                                            <rect width="36" height="36" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <h3 className='mir-h5-semibold tw-mb-[12px] mir-text-neutral-800'>Core Sales</h3>
                                <p className='mir-p-rg-regular mir-text-neutral-600'>For every profit and non-profit organization that has a core sales process to sell products or services.</p>
                            </div>
                            <a href={`${MIRRoutes.CRMBundles}#crm-core-sales`} className='group-hover:tw-bg-[#1D68F0] mir-btn-lg mir-btn-lg-tertiary tw-w-full'>
                                <span className=''>See details</span>
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.1505 10.942L12.5255 16.567C12.4082 16.6843 12.2492 16.7502 12.0833 16.7502C11.9175 16.7502 11.7584 16.6843 11.6411 16.567C11.5239 16.4498 11.458 16.2907 11.458 16.1249C11.458 15.959 11.5239 15.7999 11.6411 15.6827L16.1997 11.1249H3.95831C3.79255 11.1249 3.63358 11.059 3.51637 10.9418C3.39916 10.8246 3.33331 10.6656 3.33331 10.4999C3.33331 10.3341 3.39916 10.1751 3.51637 10.0579C3.63358 9.94071 3.79255 9.87486 3.95831 9.87486H16.1997L11.6411 5.31705C11.5239 5.19977 11.458 5.04071 11.458 4.87486C11.458 4.70901 11.5239 4.54995 11.6411 4.43267C11.7584 4.3154 11.9175 4.24951 12.0833 4.24951C12.2492 4.24951 12.4082 4.3154 12.5255 4.43267L18.1505 10.0577C18.2086 10.1157 18.2547 10.1846 18.2862 10.2605C18.3176 10.3364 18.3338 10.4177 18.3338 10.4999C18.3338 10.582 18.3176 10.6633 18.2862 10.7392C18.2547 10.8151 18.2086 10.884 18.1505 10.942Z" fill="white" />
                                </svg>
                            </a>
                        </div>
                        <div className='tw-group tw-flex tw-flex-col tw-h-full tw-items-start tw-justify-between tw-p-[32px] tw-bg-white tw-rounded-[8px]' style={{ boxShadow: '0px 4px 9px -1px rgba(16, 24, 40, 0.04), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)' }}>
                            <div>
                                <svg width="37" height="36" className='tw-mb-[24px]' viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_7381_13454)">
                                        <path d="M32.6221 9.33252H31.8861V16.3328C31.8861 19.7876 29.0767 22.5948 25.6241 22.5948H8.31018V25.9103C8.31018 28.145 10.1271 29.962 12.3618 29.962H14.203V33.2775C14.203 33.7329 14.4816 34.1418 14.9082 34.3076C15.0386 34.3584 15.1734 34.3827 15.3082 34.3827C15.6111 34.3827 15.9095 34.2567 16.1217 34.0246L19.8461 29.962H32.6221C34.8568 29.962 36.6737 28.145 36.6737 25.9103V13.3841C36.6737 11.1517 34.8568 9.33252 32.6221 9.33252Z" fill="#EEEEF2" className='group-hover:tw-fill-[#1D68F0]' />
                                        <path d="M25.6219 0.491211H4.25636C2.42838 0.491211 0.940796 1.97879 0.940796 3.80678V16.333C0.940796 18.161 2.42838 19.6485 4.25636 19.6485H25.6241C27.4521 19.6485 28.9396 18.161 28.9396 16.333V3.80678C28.9374 1.97879 27.4498 0.491211 25.6219 0.491211ZM25.991 7.03393L16.0598 11.8525C15.7061 12.0294 15.3237 12.1178 14.9391 12.1178C14.5545 12.1178 14.1721 12.0294 13.8185 11.8525L3.88723 7.03393V4.58925L14.7778 9.86542C14.8816 9.9251 14.9988 9.9251 15.1027 9.86542L25.9932 4.58925L25.991 7.03393Z" fill="#D6DBE0" className='group-hover:tw-fill-[#1D68F0]' />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_7381_13454">
                                            <rect width="36" height="36" fill="white" transform="translate(0.666626)" />
                                        </clipPath>
                                    </defs>
                                </svg>

                                <h3 className='mir-h5-semibold tw-mb-[12px] mir-text-neutral-800'>Core Sales & Marketing </h3>
                                <p className='mir-p-rg-regular mir-text-neutral-600'>For organizations to reach new customers and to have constant communications with their existing customers.</p>
                            </div>
                            <a href={`${MIRRoutes.CRMBundles}#crm-core-sales-marketing`} className='group-hover:tw-bg-[#1D68F0] mir-btn-lg mir-btn-lg-tertiary tw-w-full'>
                                <span className=''>See details</span>
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.1505 10.942L12.5255 16.567C12.4082 16.6843 12.2492 16.7502 12.0833 16.7502C11.9175 16.7502 11.7584 16.6843 11.6411 16.567C11.5239 16.4498 11.458 16.2907 11.458 16.1249C11.458 15.959 11.5239 15.7999 11.6411 15.6827L16.1997 11.1249H3.95831C3.79255 11.1249 3.63358 11.059 3.51637 10.9418C3.39916 10.8246 3.33331 10.6656 3.33331 10.4999C3.33331 10.3341 3.39916 10.1751 3.51637 10.0579C3.63358 9.94071 3.79255 9.87486 3.95831 9.87486H16.1997L11.6411 5.31705C11.5239 5.19977 11.458 5.04071 11.458 4.87486C11.458 4.70901 11.5239 4.54995 11.6411 4.43267C11.7584 4.3154 11.9175 4.24951 12.0833 4.24951C12.2492 4.24951 12.4082 4.3154 12.5255 4.43267L18.1505 10.0577C18.2086 10.1157 18.2547 10.1846 18.2862 10.2605C18.3176 10.3364 18.3338 10.4177 18.3338 10.4999C18.3338 10.582 18.3176 10.6633 18.2862 10.7392C18.2547 10.8151 18.2086 10.884 18.1505 10.942Z" fill="white" />
                                </svg>
                            </a>
                        </div>
                        <div className='tw-group tw-flex tw-flex-col tw-h-full tw-items-start tw-justify-between tw-p-[32px] tw-bg-white tw-rounded-[8px]' style={{ boxShadow: '0px 4px 9px -1px rgba(16, 24, 40, 0.04), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)' }}>
                            <div>
                                <svg width="37" height="36" className='tw-mb-[24px]' viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_7381_13437)">
                                        <path d="M30.4177 4.29075C28.8922 1.665 25.9852 0 22.8375 0C18.8077 0 15.3112 2.72475 14.382 6.45975C13.3335 6.69825 12.591 6.948 11.907 7.29675C12.8565 8.064 13.5675 9.12375 13.8802 10.3725L14.9602 14.661C15.2437 15.786 15.1695 16.9313 14.8027 17.9753C14.9512 17.9843 15.102 18 15.2482 18H29.34C33.255 18 36.4387 14.9062 36.4387 11.106C36.4387 7.668 33.8422 4.788 30.4177 4.29075Z" fill="#EEEEF2" className='group-hover:tw-fill-[#1D68F0]' />
                                        <path d="M24.4889 36C24.4147 36 24.3404 35.9978 24.2662 35.991C11.5379 35.0055 1.24417 24.7185 0.326167 12.0623C0.269917 11.2793 0.539916 10.5008 1.06642 9.92925C1.59292 9.36 2.30842 9.04275 3.08467 9.03825L8.25742 9C9.54892 9 10.6604 9.864 10.9709 11.1015L12.0509 15.3922C12.3254 16.4812 11.9182 17.6197 11.0159 18.2925L9.15067 19.683C10.8742 22.7452 13.4504 25.3373 16.5352 27.1058L17.9842 25.1887C18.6569 24.2977 19.7932 23.8928 20.8867 24.1628L25.2067 25.236C26.4509 25.5442 27.3194 26.6535 27.3194 27.9293V33.2257C27.3194 34.002 27.0067 34.7198 26.4352 35.2463C25.9087 35.73 25.2089 36 24.4889 36Z" fill="#D6DBE0" className='group-hover:tw-fill-[#1D68F0]' />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_7381_13437">
                                            <rect width="36" height="36" fill="white" transform="translate(0.333374)" />
                                        </clipPath>
                                    </defs>
                                </svg>

                                <h3 className='mir-h5-semibold tw-mb-[12px] mir-text-neutral-800'>Core Customer Service</h3>
                                <p className='mir-p-rg-regular mir-text-neutral-600'>For organizations looking for a ticket management system for their omni-channel customer service teams.</p>
                            </div>
                            <a href={`${MIRRoutes.CRMBundles}#crm-core-customer-service`} className='group-hover:tw-bg-[#1D68F0] mir-btn-lg mir-btn-lg-tertiary tw-w-full'>
                                <span className=''>See details</span>
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.1505 10.942L12.5255 16.567C12.4082 16.6843 12.2492 16.7502 12.0833 16.7502C11.9175 16.7502 11.7584 16.6843 11.6411 16.567C11.5239 16.4498 11.458 16.2907 11.458 16.1249C11.458 15.959 11.5239 15.7999 11.6411 15.6827L16.1997 11.1249H3.95831C3.79255 11.1249 3.63358 11.059 3.51637 10.9418C3.39916 10.8246 3.33331 10.6656 3.33331 10.4999C3.33331 10.3341 3.39916 10.1751 3.51637 10.0579C3.63358 9.94071 3.79255 9.87486 3.95831 9.87486H16.1997L11.6411 5.31705C11.5239 5.19977 11.458 5.04071 11.458 4.87486C11.458 4.70901 11.5239 4.54995 11.6411 4.43267C11.7584 4.3154 11.9175 4.24951 12.0833 4.24951C12.2492 4.24951 12.4082 4.3154 12.5255 4.43267L18.1505 10.0577C18.2086 10.1157 18.2547 10.1846 18.2862 10.2605C18.3176 10.3364 18.3338 10.4177 18.3338 10.4999C18.3338 10.582 18.3176 10.6633 18.2862 10.7392C18.2547 10.8151 18.2086 10.884 18.1505 10.942Z" fill="white" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className='tw-pt-[96px] tw-pb-[52px] mir-bg-primary-100'>
                <div className="container mx-auto text-center">
                    <h2 className='mir-display-semibold-sm mir-text-neutral-800 tw-mb-[24px]'>Our packages</h2>
                    <p className='mir-text-neutral-700 mir-p-md-regular mx-auto tw-max-w-full md:tw-max-w-[656px]'>To simplify the selection process, we have developed three Microsoft Dynamics 365 CRM packages.</p>
                </div>
            </section>

            <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />

            <section className='tw-pb-[32px] mir-bg-primary-100' id="crm-core-sales">
                <div className="container mx-auto">
                    <div className='tw-bg-white tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-[104px] tw-px-[24px] tw-py-[32px] md:tw-px-[72px] md:tw-py-[64px]' style={{ borderTop: '6px solid var(--ui-primary-500, #1D68F0)' }}>
                        <div>
                            <svg className='tw-mb-[24px]' width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_7847_3561)">
                                    <path d="M8.25073 26.251C10.736 26.251 12.7507 24.2363 12.7507 21.751C12.7507 19.2657 10.736 17.251 8.25073 17.251C5.76545 17.251 3.75073 19.2657 3.75073 21.751C3.75073 24.2363 5.76545 26.251 8.25073 26.251Z" fill="#1D68F0" />
                                    <path d="M12.375 28.501H4.12425C1.8495 28.501 0 30.3505 0 32.6252V34.8752C0 35.4962 0.504 36.0002 1.125 36.0002H15.3743C15.9953 36.0002 16.4993 35.4962 16.4993 34.8752V32.6252C16.4993 30.3505 14.6497 28.501 12.375 28.501Z" fill="#1D68F0" />
                                    <path d="M33.3743 0H10.125C8.68502 0 7.49927 1.18575 7.49927 2.62575V15.0458C7.74002 15.0165 7.99427 15.0007 8.24852 15.0007C11.7135 15.0007 14.5778 17.6265 14.9535 21.0015H18V25.875C18 26.37 18.315 26.8042 18.7943 26.955C18.9 26.9842 19.0193 27 19.125 27C19.485 27 19.845 26.82 20.0543 26.505L23.8343 20.9993H33.3743C34.8143 20.9993 36 19.8135 36 18.3735V2.6235C36 1.18575 34.8143 0 33.3743 0ZM31.2458 6.345L25.6208 13.095C25.2158 13.5742 24.5115 13.635 24.03 13.2458L20.295 10.125L15.39 14.6993C15.1808 14.895 14.895 14.9985 14.625 14.9985C14.3258 14.9985 14.0243 14.8792 13.7993 14.6385C13.3785 14.1885 13.41 13.4685 13.86 13.0477L19.485 7.7985C19.89 7.40925 20.5358 7.3935 20.97 7.7535L24.5993 10.7843L29.5043 4.905C29.9093 4.42575 30.6135 4.365 31.095 4.75425C31.5743 5.15925 31.635 5.86575 31.2458 6.345Z" fill="#8EB8F7" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_7847_3561">
                                        <rect width="36" height="36" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <h3 className='mir-h3-semibold mir-neutral-800 tw-mb-[24px]'>Core Sales</h3>
                            <p className='tw-mb-[36px] mir-p-rg-regular mir-text-neutral-600'>
                                CRM/CE Core Sales and Marketing package requires a Microsoft Dynamics 365 Business Central Essentials license. MIR is a Microsoft Gold Partner, and our licensing specialists will ensure that your license investment is efficient and maximized. <br /><br />
                                Our project teams consist of functional and technical consultants, quality analysts, and project management consultants. Guided by leadership, our unique consulting approach focuses on integrating and adopting CRM holistically. <br /><br />
                                In addition to the warranty hours, 20 hours of additional support is also offered to address any questions or issues that may arise.
                            </p>
                            <button onClick={() => { setShowRegionPopup(true) }} className='tw-w-full md:tw-w-fit mir-btn-lg mir-btn-lg-primary tw-mb-[16px]'>Schedule a Meeting</button>
                            <h4 className='mir-p-rg-regular'>or <Link to={MIRRoutes.Contact} className='mir-text-primary-500 !tw-underline'>Let us reach out to you</Link></h4>
                        </div>
                        <div>
                            <h5 className='mir-p-sm-semibold tw-mb-[32px]'>Included the following features:</h5>
                            <div>
                                {
                                    [
                                        'Leads, Contacts, Accounts, Contacts, Opportunities, Quotes, Orders and Invoice',
                                        'Products and Services along with pricing tiers',
                                        '2 business process workflows integrating Opportunities, Quotes, Orders and Invoices',
                                        'Outlook email integration',
                                        <>Customization limited to 15 hours of development of 2 entities and 30 fields. <span className='mir-text-neutral-500'>For example, compliance and governance rules and changes to standard workflows.</span></>,
                                        'Power Automate automations limited to 5 business rules',
                                        'Integration with two 3rd party apps and an email marketing app',
                                        'Data Migration from internal databases or other CRM tools',
                                        'Copilot capabilities limited to the Microsoft licenses purchased',
                                        <>Out-of-the-box reports. <span className='mir-text-neutral-500'>For example, sales funnels, forecasts etc.</span></>,
                                        'User training sessions',
                                    ].map((item, index) => {
                                        return <div className='tw-flex tw-items-center tw-justify-start tw-pt-[16px] first:tw-pt-0 tw-pb-[16px] last:tw-pb-0 tw-border-b-1 last:tw-border-none tw-border-[#EEEEF2]' key={`bc-core-item-${index}`} style={{ borderBottom: index != 14 ? '1px solid #EEEEF2' : 'none' }}>
                                            <svg className='tw-mr-[16px] tw-mt-[5px] tw-min-w-[16px]' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.00001 11.4393L3.03055 8.46928L3.03053 8.46926C2.88983 8.32856 2.69899 8.24951 2.5 8.24951C2.30102 8.24951 2.11018 8.32856 1.96948 8.46926C1.82877 8.60997 1.74973 8.8008 1.74973 8.99979C1.74973 9.19878 1.82877 9.38961 1.96948 9.53032L5.46938 13.0302C5.53903 13.0999 5.62175 13.1553 5.7128 13.193C5.80385 13.2308 5.90144 13.2502 6 13.2502C6.09856 13.2502 6.19616 13.2308 6.28721 13.193C6.37822 13.1553 6.4609 13.1 6.53053 13.0303C6.53056 13.0303 6.5306 13.0303 6.53063 13.0302L14.5305 5.03032C14.6712 4.88961 14.7503 4.69878 14.7503 4.49979C14.7503 4.3008 14.6712 4.10997 14.5305 3.96926C14.3898 3.82856 14.199 3.74951 14 3.74951C13.801 3.74951 13.6102 3.82856 13.4695 3.96926L13.4695 3.96927L6.00001 11.4393Z" fill="#1D68F0" stroke="#1D68F0" stroke-width="0.5" />
                                            </svg>
                                            <p className='m-0 mir-p-rg-regular mir-text-neutral-800'>{item}</p>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='tw-pb-[32px] tw-pt-[32px] mir-bg-primary-100' id="crm-core-sales-marketing">
                <div className="container mx-auto">
                    <div className='tw-bg-white tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-[104px] tw-px-[24px] tw-py-[32px] md:tw-px-[72px] md:tw-py-[64px]' style={{ borderTop: '6px solid var(--ui-primary-500, #1D68F0)' }}>
                        <div>
                            <svg className='tw-mb-[24px]' width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_7847_3556)">
                                    <path d="M31.9555 9.33252H31.2194V16.3328C31.2194 19.7876 28.41 22.5948 24.9574 22.5948H7.64355V25.9103C7.64355 28.145 9.46048 29.962 11.6952 29.962H13.5364V33.2775C13.5364 33.7329 13.8149 34.1418 14.2415 34.3076C14.3719 34.3584 14.5068 34.3827 14.6416 34.3827C14.9444 34.3827 15.2428 34.2567 15.455 34.0246L19.1795 29.962H31.9555C34.1902 29.962 36.0071 28.145 36.0071 25.9103V13.3841C36.0071 11.1517 34.1902 9.33252 31.9555 9.33252Z" fill="#8EB8F7" />
                                    <path d="M24.9552 0.491211H3.58974C1.76175 0.491211 0.27417 1.97879 0.27417 3.80678V16.333C0.27417 18.161 1.76175 19.6485 3.58974 19.6485H24.9574C26.7854 19.6485 28.273 18.161 28.273 16.333V3.80678C28.2708 1.97879 26.7832 0.491211 24.9552 0.491211ZM25.3244 7.03393L15.3931 11.8525C15.0395 12.0294 14.6571 12.1178 14.2725 12.1178C13.8879 12.1178 13.5055 12.0294 13.1518 11.8525L3.2206 7.03393V4.58925L14.1111 9.86542C14.215 9.9251 14.3322 9.9251 14.4361 9.86542L25.3266 4.58925L25.3244 7.03393Z" fill="#1D68F0" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_7847_3556">
                                        <rect width="36" height="36" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <h3 className='mir-h3-semibold mir-neutral-800 tw-mb-[24px]'>Core Sales & Marketing</h3>
                            <p className='tw-mb-[36px] mir-p-rg-regular mir-text-neutral-600'>
                                CRM/CE Core Sales and Marketing package requires a Microsoft Dynamics 365 Business Central Essentials license. MIR is a Microsoft Gold Partner, and our licensing specialists will ensure that your license investment is efficient and maximized. <br /><br />
                                Our project teams consist of functional and technical consultants, quality analysts, and project management consultants. Guided by leadership, our unique consulting approach focuses on integrating and adopting CRM holistically. <br /><br />
                                In addition to the warranty hours, 20 hours of additional support is also offered to address any questions or issues that may arise.
                            </p>
                            <button onClick={() => { setShowRegionPopup(true) }} className='tw-w-full md:tw-w-fit mir-btn-lg mir-btn-lg-primary tw-mb-[16px]'>Schedule a Meeting</button>
                            <h4 className='mir-p-rg-regular'>or <Link to={MIRRoutes.Contact} className='mir-text-primary-500 !tw-underline'>Let us reach out to you</Link></h4>
                        </div>
                        <div>
                            <h5 className='mir-p-sm-semibold tw-mb-[32px]'>Included the following features:</h5>
                            <div>
                                {
                                    [
                                        'Email Marketing and Campaign Management ',
                                        '3 Customer Segments and 3 Customer Journeys ',
                                        'A Lead scoring model inline with your business needs ',
                                        '3 Marketing forms, 2 Marketing pages and 1 Website with branding guidelines ',
                                        <>Customization limited to 15 hours of development of 2 entities and 30 fields. <span className='mir-text-neutral-500'>For example, compliance and governance rules and changes to standard workflows.</span></>,
                                        'Integration with Facebook, Instagram, Twitter and LinkedIn social media handles ',
                                        'Integration with an email marketing app (if required) ',
                                        <>Out-of-the-box reports. <span className='mir-text-neutral-500'>For example, customer acquisitions, conversions, channel efficiencies etc.</span></>,
                                        'User training sessions',
                                    ].map((item, index) => {
                                        return <div className='tw-flex tw-items-center tw-justify-start tw-pt-[16px] first:tw-pt-0 tw-pb-[16px] last:tw-pb-0 tw-border-b-1 last:tw-border-none tw-border-[#EEEEF2]' key={`bc-core-item-${index}`} style={{ borderBottom: index != 12 ? '1px solid #EEEEF2' : 'none' }}>
                                            <svg className='tw-mr-[16px] tw-mt-[5px] tw-min-w-[16px]' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.00001 11.4393L3.03055 8.46928L3.03053 8.46926C2.88983 8.32856 2.69899 8.24951 2.5 8.24951C2.30102 8.24951 2.11018 8.32856 1.96948 8.46926C1.82877 8.60997 1.74973 8.8008 1.74973 8.99979C1.74973 9.19878 1.82877 9.38961 1.96948 9.53032L5.46938 13.0302C5.53903 13.0999 5.62175 13.1553 5.7128 13.193C5.80385 13.2308 5.90144 13.2502 6 13.2502C6.09856 13.2502 6.19616 13.2308 6.28721 13.193C6.37822 13.1553 6.4609 13.1 6.53053 13.0303C6.53056 13.0303 6.5306 13.0303 6.53063 13.0302L14.5305 5.03032C14.6712 4.88961 14.7503 4.69878 14.7503 4.49979C14.7503 4.3008 14.6712 4.10997 14.5305 3.96926C14.3898 3.82856 14.199 3.74951 14 3.74951C13.801 3.74951 13.6102 3.82856 13.4695 3.96926L13.4695 3.96927L6.00001 11.4393Z" fill="#1D68F0" stroke="#1D68F0" stroke-width="0.5" />
                                            </svg>
                                            <p className='m-0 mir-p-rg-regular mir-text-neutral-800'>{item}</p>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='tw-pb-[96px] tw-pt-[32px] mir-bg-primary-100' id="crm-core-customer-service">
                <div className="container mx-auto">
                    <div className='tw-bg-white tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-[104px] tw-px-[24px] tw-py-[32px] md:tw-px-[72px] md:tw-py-[64px]' style={{ borderTop: '6px solid var(--ui-primary-500, #1D68F0)' }}>
                        <div>
                            <svg className='tw-mb-[24px]' width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_7847_3552)">
                                    <path d="M30.0844 4.29075C28.5589 1.665 25.6519 0 22.5041 0C18.4744 0 14.9779 2.72475 14.0486 6.45975C13.0001 6.69825 12.2576 6.948 11.5736 7.29675C12.5231 8.064 13.2341 9.12375 13.5469 10.3725L14.6269 14.661C14.9104 15.786 14.8361 16.9313 14.4694 17.9753C14.6179 17.9843 14.7686 18 14.9149 18H29.0066C32.9216 18 36.1054 14.9062 36.1054 11.106C36.1054 7.668 33.5089 4.788 30.0844 4.29075Z" fill="#8EB8F7" />
                                    <path d="M24.1555 36C24.0813 36 24.007 35.9978 23.9328 35.991C11.2045 35.0055 0.910793 24.7185 -0.00720732 12.0623C-0.0634573 11.2793 0.206542 10.5008 0.733042 9.92925C1.25954 9.36 1.97504 9.04275 2.75129 9.03825L7.92404 9C9.21554 9 10.327 9.864 10.6375 11.1015L11.7175 15.3922C11.992 16.4812 11.5848 17.6197 10.6825 18.2925L8.81729 19.683C10.5408 22.7452 13.117 25.3373 16.2018 27.1058L17.6508 25.1887C18.3235 24.2977 19.4598 23.8928 20.5533 24.1628L24.8733 25.236C26.1175 25.5442 26.986 26.6535 26.986 27.9293V33.2257C26.986 34.002 26.6733 34.7198 26.1018 35.2463C25.5753 35.73 24.8755 36 24.1555 36Z" fill="#1D68F0" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_7847_3552">
                                        <rect width="36" height="36" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <h3 className='mir-h3-semibold mir-neutral-800 tw-mb-[24px]'>Core Customer Service</h3>
                            <p className='tw-mb-[36px] mir-p-rg-regular mir-text-neutral-600'>
                                CRM/CE Core Sales and Marketing package requires a Microsoft Dynamics 365 Business Central Essentials license. MIR is a Microsoft Gold Partner, and our licensing specialists will ensure that your license investment is efficient and maximized. <br /><br />
                                Our project teams consist of functional and technical consultants, quality analysts, and project management consultants. Guided by leadership, our unique consulting approach focuses on integrating and adopting CRM holistically. <br /><br />
                                In addition to the warranty hours, 20 hours of additional support is also offered to address any questions or issues that may arise.
                            </p>
                            <button onClick={() => { setShowRegionPopup(true) }} className='tw-w-full md:tw-w-fit mir-btn-lg mir-btn-lg-primary tw-mb-[16px]'>Schedule a Meeting</button>
                            <h4 className='mir-p-rg-regular'>or <Link to={MIRRoutes.Contact} className='mir-text-primary-500 !tw-underline'>Let us reach out to you</Link></h4>
                        </div>
                        <div>
                            <h5 className='mir-p-sm-semibold tw-mb-[32px]'>Included the following features:</h5>
                            <div>
                                {
                                    [
                                        'Service ticket management platform ',
                                        'Integration with 4 channels email, chat, web and mobile apps ',
                                        'Service management queues with rules to prioritize tickets ',
                                        'Routing rules to direct tickets to specific customer service groups ',
                                        'SLAs (service level agreements) by service buckets ',
                                        'Customer portal ',
                                        'Knowledge management portal to house articles, FAQs and customer communications ',
                                        'Customizations limited to 15 hours ',
                                        'Power Automate automations limited to 5 business rules ',
                                        'Branding incorporation ',
                                        'Surveys to collect customer feedback using Customer voice ',
                                        'Core reports ',
                                        'User training ',
                                    ].map((item, index) => {
                                        return <div className='tw-flex tw-items-start tw-justify-start tw-pt-[16px] first:tw-pt-0 tw-pb-[16px] last:tw-pb-0 tw-border-b-1 last:tw-border-none tw-border-[#EEEEF2]' key={`bc-core-item-${index}`} style={{ borderBottom: index != 15 ? '1px solid #EEEEF2' : 'none' }}>
                                            <svg className='tw-mr-[16px] tw-mt-[5px] tw-min-w-[16px]' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.00001 11.4393L3.03055 8.46928L3.03053 8.46926C2.88983 8.32856 2.69899 8.24951 2.5 8.24951C2.30102 8.24951 2.11018 8.32856 1.96948 8.46926C1.82877 8.60997 1.74973 8.8008 1.74973 8.99979C1.74973 9.19878 1.82877 9.38961 1.96948 9.53032L5.46938 13.0302C5.53903 13.0999 5.62175 13.1553 5.7128 13.193C5.80385 13.2308 5.90144 13.2502 6 13.2502C6.09856 13.2502 6.19616 13.2308 6.28721 13.193C6.37822 13.1553 6.4609 13.1 6.53053 13.0303C6.53056 13.0303 6.5306 13.0303 6.53063 13.0302L14.5305 5.03032C14.6712 4.88961 14.7503 4.69878 14.7503 4.49979C14.7503 4.3008 14.6712 4.10997 14.5305 3.96926C14.3898 3.82856 14.199 3.74951 14 3.74951C13.801 3.74951 13.6102 3.82856 13.4695 3.96926L13.4695 3.96927L6.00001 11.4393Z" fill="#1D68F0" stroke="#1D68F0" stroke-width="0.5" />
                                            </svg>
                                            <p className='m-0 mir-p-rg-regular mir-text-neutral-800'>{item}</p>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='tw-py-[96px] mir-bg-primary-700 tw-bg-cover tw-bg-no-repeat tw-bg-bottom' style={{ backgroundImage: 'url(/bc-bundles/cta-bg.jpeg)' }}>
                <div className="container mx-auto text-center">
                    <h2 className='mx-auto mir-text-neutral-100 mir-h1-semibold tw-mb-[16px]'>Schedule a call with our team now!</h2>
                    <p className='mx-auto mir-text-neutral-100 mir-p-rg-medium tw-max-w-full md:tw-max-w-[608px] tw-mb-[52px]'>Our Business Central specialists are available for a free 1-hour consultation to understand your needs and to equip you with the knowledge and power to identify the right business tools for you. </p>
                    <div className='mx-auto tw-flex tw-items-center tw-justify-center flex-wrap'>
                        <a className='mir-btn-lg mir-btn-lg-primary tw-w-full md:tw-w-fit tw-mb-[12px] md:tw-mr-[24px]' href='tel:+18664373812'><span className='tw-px-[32px]'>Call us now</span></a>
                        <button onClick={() => { setShowRegionPopup(true) }} className='tw-w-full md:tw-w-fit tw-mb-[12px] mir-btn-lg mir-btn-lg-secondary'>Schedule a Meeting</button>
                    </div>
                </div>
            </section>

            <Footer />

        </main>
    )
}
