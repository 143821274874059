import React from "react";
import {ReactComponent as MapSVG} from '../svg/map.svg';
import {ReactComponent as MapTopClipArt} from '../svg/MapSectionClipArtTop.svg';
import {ReactComponent as MapBottomClipArt} from '../svg/MapSectionClipArtBottom.svg';
import {Link} from "react-router-dom";


const MapSection = () => {

    return (
        <section className='map-section mir-bg-neutral-800'>
            <div className='container-fluid'>
                <div className='row justify-content-center p-0'>
                    <div className='map-title col-12 col-md-8 text-center mir-h4-medium mir-text-neutral-100'>
                        And other global workers that are driving positive changes in our company, one of whom can be
                        you.
                    </div>
                </div>
                <div class="row">
                    <div class="map-container">
                        <div className='map-svg'>
                            <MapSVG/>
                        </div>
                        <div class="overlay-container">

                            <div class="content-box">
                                <div className="top-clip-art">
                                    <MapTopClipArt />
                                </div>
                                <div className='content'>
                                    <h2 className="title mir-h1-semibold mir-text-white">Looking for a refreshing
                                        start?</h2>
                                    <div className="description mir-p-rg-regular mir-text-neutral-300">Join our
                                        fast-growing team today. We’re always
                                        looking for talented individuals who are passionate about working with others to
                                        solve digitally complex problems and thrive in your own way.
                                    </div>
                                </div>
                                <div className="button-container">
                                    <Link to="/contact-us" className="reachout-btn mir-btn-lg-outlined">
                                        <span>Reach out to us now</span>
                                    </Link>
                                </div>
                                <div className="bottom-clip-art">
                                    <MapBottomClipArt />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )

}

export default MapSection;