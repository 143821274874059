import React, { useEffect, useState } from 'react'
import StickyBanner from '../StickyBanner/StickyBanner'
import Footer from '../Footer/Footer'
import TeamSlider from '../CloudSolutions/Elements/TeamSlider/TeamSlider'
import BookNowModal from '../CommonElements/BookNowModal';
import { Helmet } from 'react-helmet';

export default function MIRTeam() {

    const [selected, setSelected] = useState(null);
    const [index, setIndex] = useState(null);

    const team = [
        { linkedin: "https://www.linkedin.com/in/mohammed-ali-a3ab079/", image: "/mir-team/team/1.png", desc: "At the heart of our organization is Mohammed Ali, who leads MIR Digital Solutions, driving technology growth and transformation on a global scale. With extensive experience as a President, CEO, and COO across diverse industries, Mohammed brings expertise in technology, finance, operations, manufacturing, and supply chain. His track record includes successful program management, change management, and strategy execution. As a visionary entrepreneur, he has founded and nurtured companies, showcasing adaptability and innovation. Mohammed's guidance enables us to assist SMEs and multinational corporations in North America, the Middle East, and Africa with IT infrastructure knowledge transfer and efficient project management. With his forward-thinking approach, you can trust us for digital transformation and sustainable success.", alt: "MIR Digital Solutions Chief Executive Officer and  Co-founder, Mohammed Ali", name: "Mohammed Ali", designation: "Chief Executive Officer" },
        { linkedin: "https://www.linkedin.com/in/snehalthebest/", image: "/mir-team/team/2.png", desc: "Leading our team is Snehal Patel, a renowned Chief Revenue Officer (CRO) known for driving revenue growth and delivering exceptional results. Snehal's diverse career spans sectors such as government, financial services, energy, and startups, granting him valuable insights and expertise at the intersection of technology and business strategy. As an entrepreneurial CRO, Snehal pioneers technology consulting solutions, focusing on transformative changes for our clients. He possesses deep involvement in emerging technologies like AI and crypto, along with a keen understanding of the investment landscape. With skills in technology consulting leadership, sales strategy, revenue growth,  and strategic market positioning, Snehal provides invaluable insights and effective solutions to our clients in the ever-evolving digital landscape. His extroverted nature and strong presence enable him to excel in building lasting relationships, prioritizing long-term partnerships that drive sustainable growth for our clients. Beyond his professional prowess, Snehal maintains a balanced, social, and fun-loving lifestyle that brings a unique perspective and positive energy to elevate our team and clients.", alt: "MIR Digital Solutions Chief executive officer, Snehal Patel", name: "Snehal Patel", designation: "Chief Revenue Officer" },
        { linkedin: "https://www.linkedin.com/in/namitgarg/", image: "/mir-team/team/3.png", desc: "As the Chief Technology Officer at MIR Digital Solutions, Namit Garg brings a wealth of experience and expertise to the table. With a background in B2B2C product/technology evangelism and global business acumen, Namit has successfully built cross-functional teams and led projects across various sectors such as healthcare, telecom, IoT, and more. His notable achievements include driving digital transformations, data monetization, enhancing customer experience, and driving growth. Additionally, Namit has also ventured into entrepreneurship and actively participates in professional organizations and memberships.  He's also an entrepreneur and mentor, bringing diverse insights to the table. With professional memberships and a P. Eng designation, Namit adds depth to our team. His data-driven decision-making, executive presence, and ability to deliver results make him an invaluable asset as both a leader and a business partner.", alt: "MIR Digital Solutions Chieft product and technology officer, Namit Garg", name: "Namit Garg", designation: "Chief Product & Technology Officer" },
    ]

    const viewMore = (index) => {
        setIndex(index);
        setSelected(team[index]);
    }

    useEffect(() => {
        setSelected(team[index])
    }, [index])

    const onPrev = () => {
        if (index == 0) {
            setIndex(team.length - 1);
        }
        else {
            setIndex(index - 1)
        }
    }

    const onNext = () => {
        if (index == (team.length - 1)) {
            setIndex(0);
        }
        else {
            setIndex(index + 1)
        }
    }

    const getPrevious = () => {
        if (index == 0) {
            return team.length - 1;
        }
        else {
            return index - 1;
        }
    }
    const getNext = () => {
        if (index == (team.length - 1)) {
            return 0;
        }
        else {
            return index + 1;
        }
    }

    const [showRegionPopup, setShowRegionPopup] = useState(false);

    return (
        <main>

            <Helmet>
                <meta charSet="utf-8" />
                <title>About MIR Digital Solutions team | Microsoft Certified Solutions Partner</title>
                <meta name="description" content="Our experts of 15+ years of experience are dedicated to empowering businesses to achieve goals with innovative Microsoft solutions. Partner with us for success" />
                <link rel="canonical" href={`${window.location.origin}/about-us/mir-team`} />
            </Helmet>

            <StickyBanner />
            <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />

            {
                selected && <div className="tw-fixed tw-inset-0 tw-w-screen tw-h-screen tw-overflow-y-auto tw-bg-black/50 tw-z-[9999999999999] tw-p-[24px] md:tw-p-0 tw-flex tw-items-start md:tw-items-center tw-justify-center">
                    <div className="container mx-auto tw-rounded-[12px] tw-bg-white tw-relative">
                        <div className="tw-absolute tw-top-[20px] tw-right-[20px] tw-cursor-pointer" onClick={() => { setSelected(null); setIndex(null) }}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.0675 15.1832C16.1256 15.2412 16.1717 15.3102 16.2031 15.386C16.2345 15.4619 16.2507 15.5432 16.2507 15.6253C16.2507 15.7075 16.2345 15.7888 16.2031 15.8647C16.1717 15.9405 16.1256 16.0095 16.0675 16.0675C16.0095 16.1256 15.9405 16.1717 15.8647 16.2031C15.7888 16.2345 15.7075 16.2507 15.6253 16.2507C15.5432 16.2507 15.4619 16.2345 15.386 16.2031C15.3102 16.1717 15.2412 16.1256 15.1832 16.0675L10.0003 10.8839L4.81753 16.0675C4.70026 16.1848 4.5412 16.2507 4.37535 16.2507C4.2095 16.2507 4.05044 16.1848 3.93316 16.0675C3.81588 15.9503 3.75 15.7912 3.75 15.6253C3.75 15.4595 3.81588 15.3004 3.93316 15.1832L9.11675 10.0003L3.93316 4.81753C3.81588 4.70026 3.75 4.5412 3.75 4.37535C3.75 4.2095 3.81588 4.05044 3.93316 3.93316C4.05044 3.81588 4.2095 3.75 4.37535 3.75C4.5412 3.75 4.70026 3.81588 4.81753 3.93316L10.0003 9.11675L15.1832 3.93316C15.3004 3.81588 15.4595 3.75 15.6253 3.75C15.7912 3.75 15.9503 3.81588 16.0675 3.93316C16.1848 4.05044 16.2507 4.2095 16.2507 4.37535C16.2507 4.5412 16.1848 4.70026 16.0675 4.81753L10.8839 10.0003L16.0675 15.1832Z" fill="#111827"/>
                            </svg>
                        </div>
                        <div className='tw-p-[24px] md:tw-p-[64px]'>
                            <div className="tw-flex tw-items-center tw-justify-start tw-flex-wrap tw-mb-[48px]">
                                <img src={selected.image} className='tw-w-[199px] tw-h-[227px] tw-rounded-[8px]' alt={selected.alt} />
                                <div className='tw-pl-[0px] md:tw-pl-[52px] tw-mt-[24px] md:tw-mt-[0px]'>
                                    <h2 className='mir-h3-semibold mir-neutral-800 tw-mb-[8px]'>{selected.name}</h2>
                                    <h3 className='mir-p-lg-medium mir-text-primary-500 tw-mb-[27px]'>{selected.designation}</h3>
                                    <a href={selected.linkedin} target='_blank' className='mir-text-neutral-800 mir-p-rg-regular !tw-underline'>Linkedin</a>
                                </div>
                            </div>
                            <p className='mir-p-rg-regular mir-text-neutral-700 tw-mb-[64px]'>{selected.desc}</p>
                            <div className='tw-flex tw-items-center tw-justify-between tw-flex-wrap'>
                                <div className='tw-flex tw-items-center tw-justify-start tw-cursor-pointer' onClick={onPrev}>
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20.9996 12.5004C20.9996 12.6993 20.9206 12.8901 20.7799 13.0307C20.6393 13.1714 20.4485 13.2504 20.2496 13.2504H5.55993L11.0302 18.7198C11.0999 18.7895 11.1552 18.8722 11.1929 18.9632C11.2306 19.0543 11.25 19.1519 11.25 19.2504C11.25 19.349 11.2306 19.4465 11.1929 19.5376C11.1552 19.6286 11.0999 19.7114 11.0302 19.781C10.9606 19.8507 10.8778 19.906 10.7868 19.9437C10.6957 19.9814 10.5982 20.0008 10.4996 20.0008C10.4011 20.0008 10.3035 19.9814 10.2124 19.9437C10.1214 19.906 10.0387 19.8507 9.96899 19.781L3.21899 13.031C3.14926 12.9614 3.09394 12.8787 3.05619 12.7876C3.01845 12.6966 2.99902 12.599 2.99902 12.5004C2.99902 12.4019 3.01845 12.3043 3.05619 12.2132C3.09394 12.1222 3.14926 12.0394 3.21899 11.9698L9.96899 5.21979C10.1097 5.07906 10.3006 5 10.4996 5C10.6986 5 10.8895 5.07906 11.0302 5.21979C11.171 5.36052 11.25 5.55139 11.25 5.75042C11.25 5.94944 11.171 6.14031 11.0302 6.28104L5.55993 11.7504H20.2496C20.4485 11.7504 20.6393 11.8294 20.7799 11.9701C20.9206 12.1107 20.9996 12.3015 20.9996 12.5004Z" fill="#111827" />
                                    </svg>
                                    <div className='tw-pl-[16px]'>
                                        <p className='mir-p-sm-regular mir-text-neutral-500 m-0'>Previous</p>
                                        <p className='mir-p-rg-semibold mir-text-neutral-700 m-0'>{team[getPrevious()].name}</p>
                                    </div>
                                </div>
                                <div className='tw-flex tw-items-center tw-justify-start tw-cursor-pointer' onClick={onNext}>
                                    <div className='tw-pr-[16px]'>
                                        <p className='mir-p-sm-regular mir-text-neutral-500 m-0'>Next</p>
                                        <p className='mir-p-rg-semibold mir-text-neutral-700 m-0'>{team[getNext()].name}</p>
                                    </div>
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20.7806 13.031L14.0306 19.781C13.8899 19.9218 13.699 20.0008 13.5 20.0008C13.301 20.0008 13.1101 19.9218 12.9694 19.781C12.8286 19.6403 12.7496 19.4494 12.7496 19.2504C12.7496 19.0514 12.8286 18.8605 12.9694 18.7198L18.4397 13.2504H3.75C3.55109 13.2504 3.36032 13.1714 3.21967 13.0307C3.07902 12.8901 3 12.6993 3 12.5004C3 12.3015 3.07902 12.1107 3.21967 11.9701C3.36032 11.8294 3.55109 11.7504 3.75 11.7504H18.4397L12.9694 6.28104C12.8286 6.14031 12.7496 5.94944 12.7496 5.75042C12.7496 5.55139 12.8286 5.36052 12.9694 5.21979C13.1101 5.07906 13.301 5 13.5 5C13.699 5 13.8899 5.07906 14.0306 5.21979L20.7806 11.9698C20.8504 12.0394 20.9057 12.1222 20.9434 12.2132C20.9812 12.3043 21.0006 12.4019 21.0006 12.5004C21.0006 12.599 20.9812 12.6966 20.9434 12.7876C20.9057 12.8787 20.8504 12.9614 20.7806 13.031Z" fill="#111827" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <section className='tw-py-[200px] mir-bg-primary-100 tw-bg-cover tw-bg-no-repeat bg-center tw-pb-[250px] md:tw-pb-[200px]' style={{ background: "url('/mir-team/hero-bg.png')", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="container mx-auto">
                    <h4 className='mir-text-primary-500 mir-p-overline-lg tw-mb-[24px] tw-uppercase'>about MIR</h4>
                    <h1 className='mir-display-semibold-sm tw-mb-[24px] mir-text-neutral-800 tw-max-w-full md:tw-max-w-[736px]'>Elevate Your Business with Our Values and Expertise</h1>
                    <h3 className='mir-p-lg-regular tw-mb-[72px] mir-text-neutral-700 tw-max-w-full md:tw-max-w-[545px]'>Partner with us to achieve your goals through innovation, excellence, and integrity. Your success is our focus.</h3>
                    <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-lg mir-btn-lg-primary'>
                        <span className='mir-p-rg-medium mir-text-white tw-mr-[12px]'>Get in touch today</span>
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.3172 10.942L11.6922 16.567C11.5749 16.6843 11.4159 16.7502 11.25 16.7502C11.0841 16.7502 10.9251 16.6843 10.8078 16.567C10.6905 16.4498 10.6247 16.2907 10.6247 16.1249C10.6247 15.959 10.6905 15.7999 10.8078 15.6827L15.3664 11.1249H3.125C2.95924 11.1249 2.80027 11.059 2.68306 10.9418C2.56585 10.8246 2.5 10.6656 2.5 10.4999C2.5 10.3341 2.56585 10.1751 2.68306 10.0579C2.80027 9.94071 2.95924 9.87486 3.125 9.87486H15.3664L10.8078 5.31705C10.6905 5.19977 10.6247 5.04071 10.6247 4.87486C10.6247 4.70901 10.6905 4.54995 10.8078 4.43267C10.9251 4.3154 11.0841 4.24951 11.25 4.24951C11.4159 4.24951 11.5749 4.3154 11.6922 4.43267L17.3172 10.0577C17.3753 10.1157 17.4214 10.1846 17.4529 10.2605C17.4843 10.3364 17.5005 10.4177 17.5005 10.4999C17.5005 10.582 17.4843 10.6633 17.4529 10.7392C17.4214 10.8151 17.3753 10.884 17.3172 10.942Z" fill="white" />
                        </svg>
                    </button>
                </div>
            </section>

            <section className='tw-pt-[120px] tw-pb-[64px]'>
                <div className="container mx-auto">
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-[128px]">
                        <div>
                            <h2 className='mir-h4-semibold mir-text-neutral-800 tw-mb-[102px] tw-max-w-full md:tw-max-w-[544px]'><span className='mir-text-primary-500'>Our mission</span> is to empower businesses to achieve their goals by simplifying and optimizing their digital processes through innovative and custom Microsoft solutions. </h2>
                            <img src="/mir-team/team-1.png" className='tw-max-w-full md:tw-max-w-[544px]' alt="MIR Digital Solutions team conversation, collaboration and personal growth at MIR headquarter in Canada" />
                        </div>
                        <div>
                            <img src="/mir-team/team-2.png" className='tw-max-w-full md:tw-max-w-[448px]' alt="MIR Digital Solutions women empowerment at workplace workshop at Canada headquarters" />
                            <h2 className='mir-h4-semibold mir-text-neutral-800 md:tw-mb-[102px] tw-max-w-full md:tw-max-w-[448px]'><span className='mir-text-primary-500'>Our vision</span> is to pioneer digital transformation with Microsoft expertise, propelling businesses forward in the evolving tech landscape.</h2>
                        </div>
                    </div>
                </div>
            </section>

            <section className='md:tw-pt-[96px] tw-pb-[120px]'>
                <div className="container mx-auto">
                    <h4 className='mir-p-overline-lg mir-text-primary-500 tw-mb-[24px] tw-uppercase'>our vision</h4>
                    <h2 className='mir-text-neutral-900 mir-h1-semibold tw-mb-[72px]'>Driven by five core values</h2>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[32px]">
                        <div className='md:tw-col-span-2 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 mir-bg-neutral-100 tw-rounded-[8px] tw-p-[32px]'>
                            <div className='tw-flex tw-items-end tw-justify-start'>
                                <div className='tw-max-w-full md:tw-max-w-[277px]'>
                                    <h3 className='mir-h4-semibold mir-text-neutral-800 tw-mb-[24px]'>Innovation</h3>
                                    <p className='mir-text-neutral-600 mir-p-rg-regular'>We are dedicated to innovative thinking and approaches to delivering digital solutions that drive progress and results for our clients. </p>
                                </div>
                            </div>
                            <div>
                                <img className='tw-w-full' src="/mir-team/core-bg.png" alt="core-bg" />
                            </div>
                        </div>
                        <div className='mir-bg-neutral-100 tw-rounded-[8px] tw-p-[32px]'>
                            <div className='tw-flex tw-flex-col tw-items-start tw-justify-start tw-h-full'>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.967506 18.7535C0.517358 18.7634 0.15625 19.1311 0.15625 19.5832V34.8977C0.15625 35.1262 0.34175 35.3125 0.569298 35.3125H4.47965C4.84818 35.3125 5.17219 35.0666 5.27359 34.7113L8.92177 21.7965C9.15674 20.9619 8.71154 20.085 7.90028 19.7894C6.40638 19.2404 4.1136 18.7013 0.967506 18.7535Z" fill="#1D68F0" />
                                    <path d="M36.5431 27.8677C33.24 27.8677 26.6314 32.0453 23.3283 32.0453C20.0252 32.0453 16.3058 29.516 16.3058 29.516C16.3058 29.516 20.7562 30.3401 23.3258 30.3401C25.8954 30.3401 26.6289 29.464 26.6289 28.2785C26.6289 25.3904 21.7176 24.9424 19.2348 24.5662C16.7965 23.21 15.5302 22.1384 11.4912 21.7969C11.4268 22.4428 8.22281 33.5549 8.04688 34.1811C11.8059 35.8887 18.2758 38.5938 21.673 38.5938C26.6289 38.5938 39.8437 31.9933 39.8437 30.345C39.8437 28.6968 38.1934 27.8677 36.5431 27.8677Z" fill="#1D68F0" />
                                    <path d="M36.9377 5.53958H34.8728V4.30012C34.8728 2.7037 33.5763 1.40723 31.9799 1.40723H27.8475C26.2536 1.40723 24.9571 2.7037 24.9571 4.30012V5.53958H22.8922C22.0791 5.53958 21.3478 5.87919 20.8223 6.4196L29.9149 11.1642L39.0101 6.4196C38.4845 5.87919 37.7508 5.53958 36.9377 5.53958ZM27.436 5.53958V4.30012C27.436 4.07206 27.6219 3.88614 27.85 3.88614H31.9823C32.2079 3.88614 32.3938 4.07206 32.3938 4.30012V5.53958H27.436Z" fill="#8EB8F7" />
                                    <path d="M30.4873 13.6607C30.1279 13.8491 29.699 13.8491 29.3421 13.6607L19.999 8.78711V15.8669C19.999 17.4608 21.2955 18.7598 22.8919 18.7598H36.9399C38.5339 18.7598 39.8328 17.4633 39.8328 15.8669V8.78711L30.4873 13.6607Z" fill="#8EB8F7" />
                                </svg>
                                <div className='tw-max-w-full tw-pt-[52px]'>
                                    <h3 className='mir-h4-semibold mir-text-neutral-800 tw-mb-[24px]'>Partnership</h3>
                                    <p className='m-0 mir-text-neutral-600 mir-p-rg-regular'>Our focus is on building long-lasting partnerships with our clients to help achieve their goals. We believe that collaboration leads to innovation and success. </p>
                                </div>
                            </div>
                        </div>
                        <div className='mir-bg-neutral-100 tw-rounded-[8px] tw-p-[32px]'>
                            <div className='tw-flex tw-flex-col tw-items-start tw-justify-start tw-h-full'>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_5205_54318)">
                                        <path d="M17.0464 27.7316L12.4199 27.0248L10.3388 22.5933C9.88813 21.6356 8.31773 21.6356 7.86709 22.5933L5.78598 27.0248L1.15945 27.7316C0.654187 27.808 0.233593 28.1627 0.0697256 28.6457C-0.0914109 29.1314 0.0314914 29.6662 0.389269 30.0319L3.77587 33.5001L2.97291 38.4146C2.88825 38.9303 3.10674 39.4488 3.5328 39.749C3.96158 40.0491 4.52419 40.0819 4.98029 39.8281L9.10156 37.555L13.2228 39.8308C13.4304 39.9454 13.6571 40 13.8838 40C14.1596 40 14.4355 39.9154 14.6703 39.7517C15.0964 39.4515 15.3149 38.9331 15.2302 38.4173L14.4273 33.5029L17.8139 30.0346C18.1716 29.669 18.2945 29.1341 18.1334 28.6484C17.9723 28.1627 17.5517 27.808 17.0464 27.7316Z" fill="#8EB8F7" />
                                        <path d="M38.8433 27.7316L34.2168 27.0248L32.1356 22.5933C31.685 21.6356 30.1146 21.6356 29.664 22.5933L27.5828 27.0248L22.9563 27.7316C22.4511 27.808 22.0305 28.1627 21.8666 28.6457C21.7055 29.1314 21.8284 29.6662 22.1861 30.0319L25.5727 33.5001L24.7698 38.4146C24.6851 38.9303 24.9036 39.4488 25.3297 39.749C25.7585 40.0491 26.3183 40.0819 26.7772 39.8281L30.8984 37.555L35.0197 39.8308C35.2273 39.9454 35.454 40 35.6806 40C35.9565 40 36.2323 39.9154 36.4672 39.7517C36.8933 39.4515 37.1118 38.9331 37.0271 38.4173L36.2241 33.5029L39.6107 30.0346C39.9685 29.669 40.0914 29.1341 39.9303 28.6484C39.7691 28.1627 39.3485 27.808 38.8433 27.7316Z" fill="#1D68F0" />
                                        <path d="M17.0466 5.93469L12.4207 5.22795L10.3372 0.796467C9.88664 -0.161322 8.31648 -0.161322 7.86591 0.796467L5.78511 5.22795L1.15927 5.93469C0.654087 6.0111 0.233559 6.36584 0.0697157 6.84882C-0.0913967 7.33454 0.0314846 7.86937 0.389209 8.23502L3.7753 11.7033L2.97247 16.6177C2.88782 17.1335 3.10627 17.6519 3.53226 17.9521C3.96099 18.2522 4.52351 18.285 4.97954 18.0312L9.10293 15.7582L13.2236 18.0339C13.4311 18.1486 13.6578 18.2031 13.8844 18.2031C14.1602 18.2031 14.436 18.1185 14.6709 17.9548C15.0969 17.6546 15.3153 17.1362 15.2307 16.6205L14.4278 11.706L17.8139 8.23775C18.1716 7.8721 18.2945 7.33727 18.1334 6.85155C17.9723 6.36583 17.5518 6.0111 17.0466 5.93469Z" fill="#8EB8F7" />
                                        <path d="M25.5722 11.7053L24.7693 16.6195C24.6847 17.1352 24.9031 17.6536 25.3291 17.9537C25.7579 18.2539 26.3177 18.2866 26.7764 18.0329L30.8998 15.7572L35.0205 18.0329C35.228 18.1475 35.4546 18.202 35.6813 18.202C35.9571 18.202 36.2329 18.1174 36.4677 17.9537C36.8937 17.6536 37.1122 17.1352 37.0275 16.6195L36.2247 11.7053L39.6108 8.23726C39.9685 7.87163 40.0914 7.33683 39.9303 6.85114C39.7664 6.36545 39.3459 6.01347 38.8407 5.93707L34.2149 5.23037L32.1341 0.796424C31.6835 -0.161308 30.1134 -0.161308 29.6628 0.796424L27.582 5.22764L22.9561 5.93434C22.451 6.01074 22.0304 6.36546 21.8666 6.84841C21.7055 7.3341 21.8284 7.8689 22.1861 8.23453L25.5722 11.7053Z" fill="#1D68F0" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5205_54318">
                                            <rect width="40" height="40" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <div className='tw-max-w-full tw-pt-[52px]'>
                                    <h3 className='mir-h4-semibold mir-text-neutral-800 tw-mb-[24px]'>Excellence</h3>
                                    <p className='m-0 mir-text-neutral-600 mir-p-rg-regular'>We are committed to delivering the highest quality of service and workmanship to our clients to ensure they receive maximum value from our solutions.</p>
                                </div>
                            </div>
                        </div>
                        <div className='mir-bg-neutral-100 tw-rounded-[8px] tw-p-[32px]'>
                            <div className='tw-flex tw-flex-col tw-items-start tw-justify-start tw-h-full'>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_5205_54328)">
                                        <path d="M33.75 0H6.25C5.5675 0 5 0.5675 5 1.25V15.4175C5 17.95 7.05 20 9.5825 20H13.765C15.115 17.9825 17.415 16.6675 19.9975 16.6675C22.58 16.6675 24.88 17.985 26.23 20H30.4125C32.95 20 35 17.95 35 15.4175V1.25C35 0.5675 34.4325 0 33.75 0ZM27.5 10.4175C27.5 10.9225 27.195 11.3775 26.7275 11.5725C26.5725 11.6375 26.41 11.6675 26.25 11.6675C25.925 11.6675 25.605 11.54 25.365 11.3025L24.055 9.9925L21.68 12.17C21.28 12.54 20.6875 12.6075 20.2125 12.3375L18.125 11.145L14.6325 14.635C14.39 14.8775 14.07 15 13.75 15C13.43 15 13.11 14.8775 12.865 14.635C12.3775 14.1475 12.3775 13.355 12.865 12.8675L17.0325 8.7C17.43 8.3 18.0475 8.2175 18.5375 8.4975L20.66 9.71L22.2875 8.22L21.2025 7.135C20.845 6.7775 20.7375 6.24 20.9325 5.7725C21.1275 5.305 21.5775 5 22.0825 5H26.25C26.94 5 27.5 5.56 27.5 6.25V10.4175Z" fill="#8EB8F7" />
                                        <path d="M6.66746 28.333C8.50795 28.333 9.99996 26.841 9.99996 25.0005C9.99996 23.16 8.50795 21.668 6.66746 21.668C4.82697 21.668 3.33496 23.16 3.33496 25.0005C3.33496 26.841 4.82697 28.333 6.66746 28.333Z" fill="#1D68F0" />
                                        <path d="M11.1175 30.6675C9.435 31.935 8.335 33.9675 8.335 36.25V36.6675H1.25C0.5675 36.6675 0 36.1 0 35.4175V34.585C0 32.05 2.05 30 4.5825 30H8.75C9.6175 30 10.4325 30.25 11.1175 30.6675Z" fill="#1D68F0" />
                                        <path d="M33.3325 28.333C35.173 28.333 36.665 26.841 36.665 25.0005C36.665 23.16 35.173 21.668 33.3325 21.668C31.492 21.668 30 23.16 30 25.0005C30 26.841 31.492 28.333 33.3325 28.333Z" fill="#1D68F0" />
                                        <path d="M39.9998 34.5825V35.415C39.9998 36.0975 39.4323 36.665 38.7498 36.665H31.6673V36.25C31.6673 33.9675 30.5673 31.9325 28.8848 30.6675C29.5673 30.25 30.3823 30 31.2498 30H35.4173C37.9498 30 39.9998 32.05 39.9998 34.5825Z" fill="#1D68F0" />
                                        <path d="M20 29.168C22.7614 29.168 25 26.9294 25 24.168C25 21.4065 22.7614 19.168 20 19.168C17.2386 19.168 15 21.4065 15 24.168C15 26.9294 17.2386 29.168 20 29.168Z" fill="#1D68F0" />
                                        <path d="M24.582 31.668H15.4145C12.887 31.668 10.832 33.723 10.832 36.2505V38.7505C10.832 39.4405 11.392 40.0005 12.082 40.0005H27.9145C28.6045 40.0005 29.1645 39.4405 29.1645 38.7505V36.2505C29.167 33.723 27.1095 31.668 24.582 31.668Z" fill="#1D68F0" />
                                        <path d="M37.1051 2.5H2.89237C2.20141 2.5 1.64062 1.94 1.64062 1.25C1.64062 0.56 2.20141 0 2.89237 0H37.1076C37.7986 0 38.3594 0.56 38.3594 1.25C38.3594 1.94 37.7961 2.5 37.1051 2.5Z" fill="#8EB8F7" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5205_54328">
                                            <rect width="40" height="40" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <div className='tw-max-w-full tw-pt-[52px]'>
                                    <h3 className='mir-h4-semibold mir-text-neutral-800 tw-mb-[24px]'>Integrity</h3>
                                    <p className='m-0 mir-text-neutral-600 mir-p-rg-regular'>We operate with honesty, transparency, and ethical standards in all our interactions, both with our clients and within our team.</p>
                                </div>
                            </div>
                        </div>
                        <div className='mir-bg-neutral-100 tw-rounded-[8px] tw-p-[32px]'>
                            <div className='tw-flex tw-flex-col tw-items-start tw-justify-start tw-h-full'>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_5205_54343)">
                                        <path d="M5.40008 33.7825C5.33258 33.815 5.26758 33.865 5.20008 33.915C3.31755 35.2651 2.00003 37.2151 1.50002 39.4326L1.36752 40.0001H0V31.25C2.16753 31.25 4.11756 32.2325 5.40008 33.7825Z" fill="#8EB8F7" />
                                        <path d="M40.001 31.2505V40.0007H38.601L38.4685 39.4332C37.951 37.2156 36.651 35.2506 34.8184 33.9656C34.7359 33.9156 34.6684 33.8481 34.5859 33.7981C35.8685 32.2481 37.8035 31.2655 39.9685 31.248C39.9835 31.2505 39.9835 31.2505 40.001 31.2505Z" fill="#8EB8F7" />
                                        <path d="M27.5 33.3326C26.81 33.3326 26.25 32.7726 26.25 32.0826V28.75C26.25 28.06 26.81 27.5 27.5 27.5C28.19 27.5 28.75 28.06 28.75 28.75V32.0826C28.75 32.7726 28.19 33.3326 27.5 33.3326Z" fill="#8EB8F7" />
                                        <path d="M12.5 33.3326C11.81 33.3326 11.25 32.7726 11.25 32.0826V28.75C11.25 28.06 11.81 27.5 12.5 27.5C13.19 27.5 13.75 28.06 13.75 28.75V32.0826C13.75 32.7726 13.19 33.3326 12.5 33.3326Z" fill="#8EB8F7" />
                                        <path d="M30.3036 23.9672L26.6541 20.3071V10.2667C26.6541 8.4541 26.0255 6.67903 24.8855 5.27147L20.9691 0.438768C20.4952 -0.146256 19.5073 -0.146256 19.0334 0.438768L15.117 5.27397C13.977 6.68153 13.3484 8.4566 13.3484 10.2692V20.3096L9.69892 23.9697C8.83583 24.8373 8.35938 25.9873 8.35938 27.2149V28.7499C8.35938 29.44 8.91814 30 9.60663 30H30.3934C31.0818 30 31.6406 29.44 31.6406 28.7499V27.2124C31.6431 25.9873 31.1667 24.8348 30.3036 23.9672ZM22.9622 11.3142C22.7178 11.5592 22.3985 11.6817 22.0792 11.6817C21.7599 11.6817 21.4406 11.5592 21.1961 11.3142C20.5376 10.6517 19.4624 10.6517 18.8014 11.3142C18.315 11.8017 17.5242 11.8017 17.0378 11.3142C16.5513 10.8267 16.5513 10.0342 17.0378 9.54415C18.6692 7.90658 21.3258 7.90658 22.9597 9.54415C23.4487 10.0342 23.4487 10.8267 22.9622 11.3142Z" fill="#1D68F0" />
                                        <path d="M36.0156 40H28.9126L28.1333 38.7059C27.4188 37.5287 26.4403 36.5158 25.3125 35.7866C26.4254 35.0399 27.7524 34.6094 29.179 34.6094C30.74 34.6094 32.1666 35.1245 33.3293 35.9857C34.6563 36.9314 35.6347 38.3574 36.0156 40Z" fill="#8EB8F7" />
                                        <path d="M14.6875 35.8034C13.536 36.5671 12.5673 37.5622 11.8664 38.7064L11.0829 40H3.90625C4.28924 38.3408 5.2755 36.9328 6.62722 35.9701C7.79621 35.1069 9.23054 34.6094 10.7825 34.6094C12.2344 34.6094 13.5686 35.0422 14.6875 35.8034Z" fill="#8EB8F7" />
                                        <path d="M26.0156 40H13.9844C14.6052 38.9743 15.4924 38.1351 16.548 37.5479C17.5709 36.9758 18.7421 36.6406 19.9987 36.6406C21.2378 36.6406 22.4115 36.9607 23.4344 37.5479C24.4926 38.1174 25.3973 38.9743 26.0156 40Z" fill="#8EB8F7" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5205_54343">
                                            <rect width="40" height="40" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <div className='tw-max-w-full tw-pt-[52px]'>
                                    <h3 className='mir-h4-semibold mir-text-neutral-800 tw-mb-[24px]'>Growth</h3>
                                    <p className='m-0 mir-text-neutral-600 mir-p-rg-regular'>We foster a culture of continuous growth and development for our team and clients. We believe in taking on new challenges and learning from every experience to drive progress and innovation.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='tw-pt-[120px] tw-pb-[64px] tw-bg-[#030712]'>
                <div className="container mx-auto text-center">
                    <h2 className='mir-h1-semibold mir-text-neutral-100 tw-mb-[24px]'>MIR Team</h2>
                    <h3 className='mir-text-neutral-200 mir-p-lg-regular tw-max-w-full md:tw-max-w-[609px] mx-auto'>Our team brings over 15 years of experience, starting in Product Management, Pre-sales, Product/Project Management and Sales and Account Management. </h3>
                </div>
            </section>
            <img src="/mir-team/mir-team.png" className='tw-w-full' alt="MIR Digital Solutions team outdoor corporate event in Canada" />

            <section className='tw-py-[120px]'>
                <div className="container mx-auto">
                    <h2 className='mir-h2-semibold tw-mb-[27px] mir-text-neutral-800 tw-max-w-full md:tw-max-w-[544px]'>Areas of Expertise and Core Competencies</h2>
                    <h2 className='mir-p-md-regular tw-mb-[72px] mir-text-neutral-700 tw-max-w-full md:tw-max-w-[639px]'>Our team possesses expertise in a wide range of areas, including:</h2>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-x-[32px] tw-gap-y-[60px]">
                        {
                            [
                                'Agile Project Planning and Management',
                                'Business Consulting',
                                'Cross Functional Leaderships',
                                'Customer Relationship Management',
                                'Digital Transformation Strategy',
                                'New Product Development',
                                'Process Optimization E-Commerce',
                                'Product/Program Management',
                                'Technology Consulting',
                                'System Integration',
                                'Web and Mobile apps',
                            ].map((item, index) => {
                                return <div className='tw-flex tw-items-center tw-justify-start'>
                                    <h5 className='mir-p-md-medium tw-pl-[12px]' style={{ borderLeft: '2px solid var(--ui-primary-500, #1D68F0)' }}>{item}</h5>
                                </div>
                            })
                        }
                    </div>
                </div>
            </section>

            <section className='mir-bg-neutral-800 tw-py-[120px] tw-relative tw-overflow-hidden'>
                <img src="/mir-team/globe.png" className='tw-absolute tw-max-w-[400px] md:tw-max-w-fit tw-z-0 tw-right-0' alt="globe-vector" />
                <div className="container mx-auto tw-relative tw-z-10">
                    <h2 className='mir-text-neutral-100 mir-h2-semibold tw-mb-[27px]'>Notable Projects Led</h2>
                    <p className='mir-text-neutral-100 mir-p-md-regular tw-mb-[72px] tw-max-w-full md:tw-max-w-[639px]'>Together, the team members have collectively led a diverse set of projects, showcasing their expertise and leadership across various domains. These projects include: </p>
                </div>
                <section className='banner-sec' style={{ "--speed": `30000ms` }}>
                    <div className='tw-flex tw-items-center tw-justify-center tw-mb-[15px] tw-relative tw-z-10'>
                        {
                            [
                                'Woodgreen CRM closure',
                                'Blakes Recruitment app and Power BI reports',
                                'SAS Loan approval solutions',
                                'Rogers solution strategy',
                                'Enterprise Search',
                                'Woodgreen CRM closure',
                                'Blakes Recruitment app and Power BI reports',
                                'SAS Loan approval solutions',
                                'Rogers solution strategy',
                                'Enterprise Search',
                                'Woodgreen CRM closure',
                                'Blakes Recruitment app and Power BI reports',
                                'SAS Loan approval solutions',
                                'Rogers solution strategy',
                                'Enterprise Search',
                            ].map((item, index) => {
                                return <h5 className='tw-cursor-pointer hover:tw-bg-[#4B5563] tw-transition tw-whitespace-nowrap tw-p-[24px] mir-p-md-medium  mir-bg-neutral-700 mir-text-neutral-100 tw-rounded-[8px] tw-w-fit tw-mb-0 tw-mr-4'>{item}</h5>
                            })
                        }
                    </div>
                </section>
                <section className='banner-sec-front' style={{ "--speed": `30000ms` }}>
                    <div className='tw-flex tw-items-center tw-justify-center tw-mb-[15px] tw-relative tw-z-10'>
                        {
                            [
                                'Woodgreen Tax Clinic Portal Development',
                                'WASH Microsoft CRM Marketing Module Implementation',
                                'Woodgreen CRM Modernization',
                                'DMC D365 F&O Procurement Module',
                                'DCM D365 Inventory and Warehouse Management Module',
                                'Woodgreen Tax Clinic Portal Development',
                                'WASH Microsoft CRM Marketing Module Implementation',
                                'Woodgreen CRM Modernization',
                                'DMC D365 F&O Procurement Module',
                                'DCM D365 Inventory and Warehouse Management Module',
                            ].map((item, index) => {
                                return <h5 className='tw-cursor-pointer hover:tw-bg-[#4B5563] tw-transition tw-whitespace-nowrap tw-p-[24px] mir-p-md-medium  mir-bg-neutral-700 mir-text-neutral-100 tw-rounded-[8px] tw-w-fit tw-mb-0 tw-mr-4'>{item}</h5>
                            })
                        }
                    </div>
                </section>
                <section className='banner-sec' style={{ "--speed": `25000ms` }}>
                    <div className='tw-flex tw-items-center tw-justify-center tw-mb-[15px] tw-relative tw-z-10'>
                        {
                            [
                                'Launch and development of a pioneering C2C and B2C e-commerce marketplace in Asia ',
                                'Scotiabank Customer care Business Process Management Tool Pega PRPC platform migration',
                                'DCM Self Serve Portal',
                                'Launch and development of a pioneering C2C and B2C e-commerce marketplace in Asia ',
                                'Scotiabank Customer care Business Process Management Tool Pega PRPC platform migration',
                                'DCM Self Serve Portal',
                            ].map((item, index) => {
                                return <h5 className='tw-cursor-pointer hover:tw-bg-[#4B5563] tw-transition tw-whitespace-nowrap tw-p-[24px] mir-p-md-medium  mir-bg-neutral-700 mir-text-neutral-100 tw-rounded-[8px] tw-w-fit tw-mb-0 tw-mr-4'>{item}</h5>
                            })
                        }
                    </div>
                </section>
            </section>

            <div className='tw-pt-[120px] tw-pb-[32px]'>
                <TeamSlider />
            </div>

            <section className='md:tw-pt-[120px] tw-pb-[96px] '>
                <div className="container mx-auto">
                    <h4 className='mir-p-overline-lg mir-text-primary-500 tw-mb-[24px] tw-uppercase'>our leadership</h4>
                    <h2 className='tw-mb-[72px] mir-h1-semibold mir-text-neutral-900'>Meet our Board of Directors</h2>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[32px]">
                        {
                            team.map((item, index) => {
                                return <div>
                                    <div className='tw-relative tw-w-fit tw-h-fit'>
                                        <img className='tw-w-[352px] tw-h-[402px] tw-mb-[32px] tw-object-cover' src={item.image} alt={item.alt} />
                                        <div onClick={() => { viewMore(index) }} className='tw-transition hover:tw-opacity-75 tw-cursor-pointer tw-absolute tw-right-[20px] tw-bottom-[50px] mir-bg-neutral-800 tw-h-[56px] tw-w-[56px] tw-rounded-full tw-flex tw-items-center tw-justify-center'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 21C11.8011 21 11.6103 20.921 11.4697 20.7803C11.329 20.6397 11.25 20.4489 11.25 20.25L11.25 12.75L3.75 12.75C3.55109 12.75 3.36032 12.671 3.21967 12.5303C3.07902 12.3897 3 12.1989 3 12C3 11.8011 3.07902 11.6103 3.21967 11.4697C3.36032 11.329 3.55109 11.25 3.75 11.25L11.25 11.25L11.25 3.75C11.25 3.55109 11.329 3.36032 11.4697 3.21967C11.6103 3.07902 11.8011 3 12 3C12.1989 3 12.3897 3.07902 12.5303 3.21967C12.671 3.36032 12.75 3.55109 12.75 3.75L12.75 11.25L20.25 11.25C20.4489 11.25 20.6397 11.329 20.7803 11.4697C20.921 11.6103 21 11.8011 21 12C21 12.1989 20.921 12.3897 20.7803 12.5303C20.6397 12.671 20.4489 12.75 20.25 12.75L12.75 12.75L12.75 20.25C12.75 20.4489 12.671 20.6397 12.5303 20.7803C12.3897 20.921 12.1989 21 12 21Z" fill="white" />
                                            </svg>
                                        </div>
                                    </div>
                                    <h3 className='mir-h5-semibold m-0 tw-mb-[8px]'>{item.name}</h3>
                                    <h4 className='mir-text-neutral-600 mir-p-rg-regular tw-mb-[24px] mir-text-neutral-600'>{item.designation}</h4>
                                    <a href={item.linkedin} target='_blank' className='mir-text-neutral-800 mir-p-rg-regular !tw-underline'>Linkedin</a>
                                </div>
                            })
                        }
                    </div>
                </div>
            </section>

            <Footer />

        </main>
    )
}
