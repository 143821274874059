import Modal from 'react-bootstrap/Modal';
import React from 'react'
import './index.scss'

export default function BookNowModal({ showRegionPopup, setShowRegionPopup }) {

    return (
        <div className='book-now-modal-wrapper tw-relative tw-z-[1000000000]'>
            <Modal show={showRegionPopup} onHide={() => { setShowRegionPopup(false) }}>
                <Modal.Header closeButton>
                    {/* <Modal.Title className=''>

                    </Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='modal-header-custom col-lg-12'>
                            Choose your region
                        </div>
                        <div className='col-lg-12 region-group'>
                            {/* <a target="_blank" href='https://outlook.office365.com/owa/calendar/PrasannaJeyakumarBooking@mir-services.com/bookings/' className='row'> */}
                            <a target="_blank" href='https://outlook.office365.com/owa/calendar/MIRDigitalSolutionsNA@mirdigitalsolutions.com/bookings/' className='row'>
                                <div className='col-lg-8'>
                                    <div>North America</div>
                                </div>
                                <div className='col-lg-4 text-right'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                    <path d="M17.3172 10.9425L11.6922 16.5675C11.5749 16.6848 11.4159 16.7507 11.25 16.7507C11.0841 16.7507 10.9251 16.6848 10.8078 16.5675C10.6905 16.4503 10.6247 16.2912 10.6247 16.1253C10.6247 15.9595 10.6905 15.8004 10.8078 15.6832L15.3664 11.1253H3.125C2.95924 11.1253 2.80027 11.0595 2.68306 10.9423C2.56585 10.8251 2.5 10.6661 2.5 10.5003C2.5 10.3346 2.56585 10.1756 2.68306 10.0584C2.80027 9.9412 2.95924 9.87535 3.125 9.87535H15.3664L10.8078 5.31753C10.6905 5.20026 10.6247 5.0412 10.6247 4.87535C10.6247 4.7095 10.6905 4.55044 10.8078 4.43316C10.9251 4.31588 11.0841 4.25 11.25 4.25C11.4159 4.25 11.5749 4.31588 11.6922 4.43316L17.3172 10.0582C17.3753 10.1162 17.4214 10.1851 17.4529 10.261C17.4843 10.3369 17.5005 10.4182 17.5005 10.5003C17.5005 10.5825 17.4843 10.6638 17.4529 10.7397C17.4214 10.8156 17.3753 10.8845 17.3172 10.9425Z" fill="#1D68F0" />
                                    </svg>
                                </div>
                            </a>
                        </div>
                        <div className='col-lg-12 region-group'>
                            <a target="_blank" href='https://outlook.office365.com/owa/calendar/MIRDigitalSolutionsUAEAppointmentBooking@mirdigitalsolutions.com/bookings/' className='row'>
                                <div className='col-lg-8'>
                                    <div>Middle East</div>
                                </div>
                                <div className='col-lg-4 text-right'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                    <path d="M17.3172 10.9425L11.6922 16.5675C11.5749 16.6848 11.4159 16.7507 11.25 16.7507C11.0841 16.7507 10.9251 16.6848 10.8078 16.5675C10.6905 16.4503 10.6247 16.2912 10.6247 16.1253C10.6247 15.9595 10.6905 15.8004 10.8078 15.6832L15.3664 11.1253H3.125C2.95924 11.1253 2.80027 11.0595 2.68306 10.9423C2.56585 10.8251 2.5 10.6661 2.5 10.5003C2.5 10.3346 2.56585 10.1756 2.68306 10.0584C2.80027 9.9412 2.95924 9.87535 3.125 9.87535H15.3664L10.8078 5.31753C10.6905 5.20026 10.6247 5.0412 10.6247 4.87535C10.6247 4.7095 10.6905 4.55044 10.8078 4.43316C10.9251 4.31588 11.0841 4.25 11.25 4.25C11.4159 4.25 11.5749 4.31588 11.6922 4.43316L17.3172 10.0582C17.3753 10.1162 17.4214 10.1851 17.4529 10.261C17.4843 10.3369 17.5005 10.4182 17.5005 10.5003C17.5005 10.5825 17.4843 10.6638 17.4529 10.7397C17.4214 10.8156 17.3753 10.8845 17.3172 10.9425Z" fill="#1D68F0" />
                                    </svg>
                                </div>
                            </a>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
