import React, { useState } from 'react'
import StickyBanner from '../StickyBanner/StickyBanner'
import Footer from '../Footer/Footer'
import { Helmet } from 'react-helmet';
import MIRRoutes from '../../lib/routes';
import SectionTabs from '../CloudSolutions/Elements/Sections/SectionTabs/SectionTabs';
import BookNowModal from '../CommonElements/BookNowModal';
import Dynamics from '../CloudSolutions/Elements/Sections/Dynamics';
import BlueBanner from '../CloudSolutions/Elements/Sections/BlueBanner';
import StepsSlider from '../CloudSolutions/Elements/StepProcess/StepProcess';
import HowHelpSection from '../CloudSolutions/Elements/Sections/HowHelpSection';
import ContactSection from '../CloudSolutions/Elements/Sections/ContactSection';
import { Link } from 'react-router-dom';
import TransformationSection from '../CloudSolutions/Elements/Sections/TransformationSection';
import SuccessStory from '../CloudSolutions/Elements/Sections/SuccessStory';
import CaseStudyUrls from '../../lib/singleCaseStudyUrls';
import SuccessStoriesDynamic from '../CommonElements/SuccessStoriesDynamic';

export default function Microsoft365() {

    const [tabs, setTabs] = useState([
        { name: 'Overview', id: 'section-1' },
        { name: 'Introduction', id: 'section-2' },
        { name: 'Benefits', id: 'section-3' },
        { name: 'How we can help', id: 'section-4' },
        { name: 'Case studies', id: 'section-5' },
        { name: 'Contact Us', id: 'section-6' },
    ]);

    const successStoriesContent = [
        
        {
            image: "/success-stories-custom/6.png",
            timeToRead: "10",
            desc: "6Harmonics Drives Agility at Workplace with Cloud and Office 365 Migration",
            linkToRead: "/about-us/case-studies/6-harmonics-agility-microsoft-365"
        },
        {
            image: "/success-stories-custom/13.png",
            timeToRead: "5",
            desc: "MIR Successfully Improves AGMD Group Inc\'s Productivity and Security with Microsoft 365 License Migration",
            linkToRead: "/about-us/case-studies/agmd"
        },
        {
            image: "/success-stories-custom/3.png",
            timeToRead: "3",
            desc: "Make A Wish Upscales Organization Operations & Reduce Ongoing Costs with Azure Public Migration",
            linkToRead: "/about-us/case-studies/make-a-wish-canada-azure-migration"
        }
    ];

    const [showRegionPopup, setShowRegionPopup] = useState(false);

    return (
        <main>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Microsoft 365 Office - Business Services | MIR Digital Solutions</title>
                <meta name="description" content="Discover the power of productivity with Microsoft 365. MIR bundles provide seamless migration, expert management, and reliable support for your needs." />
                <link rel="canonical" href={`${window.location.origin}${MIRRoutes.Microsoft365}`} />
            </Helmet>
            <StickyBanner />
            <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />

            <section className='tw-pt-[120px] tw-pb-[160px] tw-bg-cover tw-bg-center tw-bg-no-repeat' style={{ backgroundImage: 'url(/supply-chain/bg.svg)' }}>
                <div className="container mx-auto text-center">
                    <img src="/365/hero-icon.png" className='tw-mb-[42px]' alt="MIR Digital Solutions is a Microsoft Solutions Partner for digital, app innovation, and Azure services." />
                    <h4 className='tw-mb-[24px] mir-h6-medium mir-text-primary-500'>Productivity, security, device control <br /> All-in-one for the future of your business</h4>
                    <h2 className='mir-display-semibold-sm mir-text-neutral-800 m-0'>Microsoft 365 for Business</h2>
                </div>
            </section>

            {/* section tabs */}
            <SectionTabs tabs={tabs} />

            <section className='tw-py-[96px] tw-relative'>
                <span data-section id="section-1" className='tw-absolute tw-top-[-100px]'></span>
                <div className="container mx-auto">
                    <div className="text-center">
                        <h4 className='tw-uppercase mir-text-primary-500 mir-p-overline-lg tw-mb-[16px]'>Overview</h4>
                        <h2 className='mir-h1-semibold tw-mb-[24px] tw-max-w-full mx-auto md:tw-max-w-[590px] mir-text-neutral-800'>Streamline your business operations with Microsoft 365</h2>
                        <p className='mir-p-md-regular mir-text-neutral-800 tw-max-w-full md:tw-max-w-[862px] mx-auto tw-mb-[64px]'>Small and medium-sized businesses (SMBs) encounter many collaboration challenges like larger enterprises. <a href="https://query.prod.cms.rt.microsoft.com/cms/api/am/binary/RE4MbPc" target='_blank' className='mir-text-primary-500 mir-p-md-medium'>Recent Forrester survey data showed that 70% of SMB employees collaborate daily with colleagues in different locations, with over half relying on technology for support. </a>
                            <br /><br /> Before emergence of Microsoft 365 and similar cloud-based suites, business faced several operational, communication, and productivity challenges. These challenges included:
                        </p>
                        <div className='tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-x-[32px] tw-gap-y-[48px]'>
                            {
                                [
                                    { heading: "Limited Access", desc: "Businesses had difficulty accessing critical data and applications remotely. " },
                                    { heading: "Version Control Issues", desc: "Managing document versions caused confusion and errors." },
                                    { heading: "Email Hassles", desc: "On-premises email systems led to problems like downtime and storage limitations. " },
                                    { heading: "Costly Hardware Maintenance", desc: "Maintaining in-house servers and hardware was expensive. " },
                                    { heading: "Security Concerns", desc: "Security risks were prevalent as businesses had to handle their security measures. " },
                                    { heading: "Collaboration Gaps", desc: "Collaborative work was hindered due to the lack of integrated tools. " },
                                    { heading: "Limited Mobility", desc: "Mobile workforces had restricted access to corporate resources and data." },
                                    { heading: "Data Backup Complexity", desc: "Backup and disaster recovery plans were complicated and unreliable." },
                                    { heading: "Software Management", desc: "Updating and managing software licenses was resource-intensive." },
                                ].map((item, index) => {
                                    return <div key={`challenge-item-${index}`} className='text-start'>
                                        <img src="/365/sad.svg" alt="sad-icon" className='tw-mb-[16px]' />
                                        <h5 className='mir-p-md-semibold mir-text-neutral-800'>{item.heading}</h5>
                                        <p className='tw-max-w-full md:tw-max-w-[384px] mir-p-rg-regular mir-text-neutral-700'>{item.desc}</p>
                                    </div>
                                })
                            }
                        </div>
                    </div>

                </div>
            </section>

            {/* dynamics */}
            <div className='tw-relative'>
                <span className='tw-absolute tw-top-[-100px]' data-section id={'section-2'}></span>
                <Dynamics
                    icon={'/365/icon.png'}
                    overline={'Introduction'}
                    heading={<>What is Microsoft 365 for Business? </>}
                    desc={<>Microsoft 365 is a cloud-based collection of software solutions crafted to amplify your productivity. It comprises top-notch communication, design, and data management applications, along with cutting-edge cloud services and robust security measures. You can effortlessly reach your data from any location, seamlessly collaborate with colleagues in real-time, engage in virtual meetings, and harness a multitude of ready-made templates for more effective customer communication and business administration. </>}
                    videoPlaceholder={"/supply-chain/video-placeholder.png"}
                    iframe={<iframe width="100%" height="541px" src="https://www.youtube.com/embed/CbLMS2Fhcsk?si=itEwId4DPZ_FZZAM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>}
                />
            </div>

            <div className='mir-bg-primary-500 tw-relative tw-overflow-hidden'>
                <img src="/supply-chain/light-blue-banner-bg.png" alt="blue-banner-bg" className='tw-w-full tw-h-full tw-absolute tw-z-0 tw-inset-0' />
                <div className="container mx-auto">
                    <div className={`tw-px-[36px] tw-py-[36px] md:tw-px-[64px] md:tw-py-[72px]`}>
                        <div className='tw-relative tw-z-10 tw-flex tw-justify-between tw-items-center tw-flex-wrap'>
                            <h2 className='tw-max-w-full tw-mb-[36px] md:tw-mb-[0px] md:tw-max-w-[672px] mir-h5-semibold text-white'>Discover how Microsoft 365 can benefit your business</h2>
                            <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-secondary mir-btn-md mir-text-primary-500 mir-bg-white'>
                                <svg className='tw-mr-[12px]' width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.3727 12.8798L13.6922 11.2306L13.682 11.2259C13.491 11.1441 13.2825 11.1114 13.0756 11.1304C12.8687 11.1495 12.6698 11.2199 12.4969 11.3352C12.4765 11.3487 12.457 11.3633 12.4383 11.379L10.5367 13.0001C9.33204 12.4149 8.08829 11.1806 7.50313 9.99149L9.12657 8.06102C9.14219 8.04149 9.15704 8.02196 9.1711 8.00087C9.28394 7.82845 9.35239 7.63081 9.37039 7.42553C9.38838 7.22026 9.35534 7.01372 9.27423 6.8243V6.81493L7.62032 3.12821C7.51309 2.88076 7.3287 2.67463 7.09468 2.54058C6.86067 2.40654 6.58958 2.35177 6.32188 2.38446C5.26326 2.52376 4.29155 3.04366 3.58824 3.84704C2.88492 4.65042 2.49809 5.68234 2.50001 6.75009C2.50001 12.9532 7.54688 18.0001 13.75 18.0001C14.8177 18.002 15.8497 17.6152 16.6531 16.9119C17.4564 16.2085 17.9763 15.2368 18.1156 14.1782C18.1484 13.9106 18.0937 13.6396 17.9598 13.4056C17.8259 13.1716 17.62 12.9871 17.3727 12.8798ZM13.75 16.7501C11.0987 16.7472 8.55687 15.6927 6.68214 13.818C4.8074 11.9432 3.7529 9.40136 3.75001 6.75009C3.74707 5.98719 4.02192 5.2493 4.52324 4.67423C5.02456 4.09916 5.71806 3.72623 6.47423 3.62509C6.47392 3.6282 6.47392 3.63134 6.47423 3.63446L8.11485 7.30634L6.50001 9.23915C6.48362 9.25801 6.46873 9.27812 6.45548 9.2993C6.33791 9.47971 6.26894 9.68743 6.25525 9.90232C6.24157 10.1172 6.28362 10.332 6.37735 10.5259C7.08516 11.9735 8.54376 13.4212 10.007 14.1282C10.2023 14.2211 10.4184 14.2617 10.634 14.2461C10.8497 14.2304 11.0576 14.1592 11.2375 14.0391C11.2576 14.0256 11.2769 14.011 11.2953 13.9954L13.1945 12.3751L16.8664 14.0196C16.8664 14.0196 16.8727 14.0196 16.875 14.0196C16.7751 14.7769 16.4027 15.4718 15.8275 15.9743C15.2524 16.4769 14.5138 16.7527 13.75 16.7501Z" fill="#1D68F0" />
                                </svg>
                                <span className='tw-mr-[12px] mir-p-rg-medium'>Contact our expert</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <section className='tw-relative mir-bg-neutral-800 tw-bg-bottom tw-bg-cover tw-bg-no-repeat' style={{ backgroundImage: 'url(/business/service-bundles-bg.png)' }}>
                <span data-section id="section-3" className='tw-absolute tw-top-[-100px]'></span>
                <div className='container tw-mx-auto tw-text-center tw-pt-[120px] tw-pb-[120px]'>
                    <h4 className='mir-p-overline-lg text-primary-500 tw-mb-[24px]'>FEATURES</h4>
                    <h2 className='mir-h1-semibold mir-text-neutral-100 tw-mb-[36px] tw-max-w-[100%] md:tw-max-w-[534px] tw-mx-auto'>Key features of Microsoft 365 for Business</h2>
                    <h3 className='mir-text-neutral-100 mir-p-md-regular tw-max-w-[100%] md:tw-max-w-[734px] tw-mx-auto'>Microsoft 365 for Business offers a comprehensive suite of tools and services designed to enhance productivity and streamline business operations. Some key features of Microsoft 365 for Business include: </h3>
                </div>
            </section>
            <section className='tw-py-[96px] mir-bg-neutral-100'>
                <div className="container mx-auto">
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-[52px]">
                        {
                            [
                                { heading: 'Office Applications', desc: 'Microsoft 365 for Business provides access to a suite of professional applications like Word, Excel, PowerPoint, and Outlook. You can use these tools online or install them on your devices.' },
                                { heading: 'Email and Calendar', desc: 'The service includes a professional email solution with a custom domain. It also offers shared calendars and the flexibility to access your emails from anywhere.' },
                                { heading: 'Cloud Storage', desc: 'Users benefit from secure cloud storage, which allows for file sharing and collaborative work. This feature ensures that documents are accessible from various devices.' },
                                { heading: 'Teams Collaboration', desc: 'Microsoft Teams is a platform that facilitates communication and teamwork. It offers features such as chat, video conferencing, and document sharing, promoting efficient collaboration, especially for remote teams. ' },
                                { heading: 'SharePoint Online', desc: "This platform allows you to create and manage team sites and intranet sites for content management and document storage. It's a valuable tool for organizing and sharing information" },
                                { heading: 'Security and Compliance', desc: 'Microsoft 365 for Business includes robust security measures like data loss prevention (DLP), threat protection, and encryption to safeguard your data and communications. ' },
                                { heading: 'Device Management', desc: 'It enables centralized control of devices and applications, enhancing security and access control for company resources.' },
                                { heading: 'Business Apps', desc: 'Access a wide array of business applications and add-ons through the Microsoft AppSource, tailored to enhance productivity and streamline operations. ' },
                                { heading: 'Mobile Apps', desc: 'Microsoft 365 offers mobile versions of Office applications, allowing you to work on smartphones and tablets while on the move.' },
                                { heading: 'Task and Project Management', desc: 'Utilize tools such as Microsoft Planner and To-Do for organizing tasks and projects efficiently. Additionally, Yammer offers an internal social networking platform. ' },
                                { heading: 'Online Meetings and Webinars', desc: 'Microsoft Teams facilitates online meetings, webinars, and screen sharing, making virtual collaboration seamless and effective.' },
                                { heading: 'AI-Powered Features', desc: 'Take advantage of intelligent services like Microsoft Search and MyAnalytics, which provide valuable insights to boost productivity. ' },
                                { heading: 'Professional Email with Custom Domain', desc: 'Create professional email addresses using your own domain, projecting a polished image for your business. ' },
                                { heading: 'Regular Updates and Upgrades', desc: 'Microsoft 365 for Business ensures that you receive consistent updates and new features, guaranteeing access to the latest tools and improvements. ' },
                                { heading: 'Support and Training', desc: 'Microsoft provides access to support resources and extensive online training materials for both users and administrators. ' },
                                { heading: 'Scalability', desc: 'The solution is designed to grow with your business, making it suitable for businesses of varying sizes. ' },
                            ].map((item, index) => {
                                return <div key={`benefit-feature-index-${index}`}>
                                    <h4 className='mir-text-primary-500 mir-h6-semibold tw-mb-[12px]'>{item.heading}</h4>
                                    <h5 className='mir-text-neutral-600 mir-p-rg-regular'>{item.desc}</h5>
                                </div>
                            })
                        }
                    </div>
                </div>
            </section>

            <section className='tw-pb-[64px] mir-bg-neutral-100'>
                <div className="container mx-auto tw-bg-[#001d6c] tw-relative md:tw-rounded-[12px]">
                    <img src="/supply-chain/blue-banner-bg.png" className='tw-absolute tw-z-0 tw-inset-0 tw-w-full tw-h-full tw-rounded-[12px]'
                        alt="Streamline data analysis and reporting with Microsoft Power BI" />
                    <div className="tw-py-[52px] tw-px-[30px] md:tw-py-[52px] md:tw-px-[64px] tw-rounded-[12px] tw-flex tw-items-center tw-flex-wrap tw-justify-between tw-relative tw-z-10">
                        <h3 className='tw-mb-[24px] md:tw-mb-0 tw-max-w-full md:tw-max-w-[527px] mir-h5-semibold mir-text-neutral-100'>Microsoft 365 is the go-to choice for productivity, security, and scalability in today's digital business landscape</h3>
                        <button className='mir-btn-md mir-btn-primary mir-p-rg-medium' onClick={() => { setShowRegionPopup(true) }}>
                            <span className='tw-mr-[12px]'>Make it yours today</span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.3172 10.4422L11.6922 16.0672C11.5749 16.1845 11.4159 16.2504 11.25 16.2504C11.0841 16.2504 10.9251 16.1845 10.8078 16.0672C10.6905 15.9499 10.6247 15.7909 10.6247 15.625C10.6247 15.4592 10.6905 15.3001 10.8078 15.1828L15.3664 10.625H3.125C2.95924 10.625 2.80027 10.5592 2.68306 10.442C2.56585 10.3247 2.5 10.1658 2.5 10C2.5 9.83425 2.56585 9.67528 2.68306 9.55807C2.80027 9.44086 2.95924 9.37501 3.125 9.37501H15.3664L10.8078 4.8172C10.6905 4.69992 10.6247 4.54086 10.6247 4.37501C10.6247 4.20916 10.6905 4.0501 10.8078 3.93282C10.9251 3.81555 11.0841 3.74966 11.25 3.74966C11.4159 3.74966 11.5749 3.81555 11.6922 3.93282L17.3172 9.55782C17.3753 9.61587 17.4214 9.6848 17.4529 9.76067C17.4843 9.83655 17.5005 9.91788 17.5005 10C17.5005 10.0821 17.4843 10.1635 17.4529 10.2393C17.4214 10.3152 17.3753 10.3842 17.3172 10.4422Z" fill="white" />
                            </svg>
                        </button>
                    </div>
                </div>
            </section>

            <section className='tw-py-[96px] mir-bg-neutral-100' data-section id='section-3'>
                <div className="container mx-auto">
                    <div className='text-center'>
                        <h4 className='mx-auto mir-p-overline-lg mir-text-primary-500 tw-text-uppercase tw-mb-[16px]'>BENEFITS</h4>
                        <h2 className='mx-auto mir-h1-semibold mir-text-neutral-800 tw-max-w-full md:tw-max-w-[448px] tw-mb-[80px]'>Why should a business choose Microsoft 365?</h2>
                    </div>
                    <div className='tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-x-[32px] tw-gap-y-[48px]'>
                        {
                            [
                                { icon: "/365/benefits/1.svg", heading: "Productivity Tools", desc: "It offers familiar tools like Word, Excel, and Outlook for streamlined collaboration." },
                                { icon: "/365/benefits/2.svg", heading: "Cloud Convenience", desc: "Work from anywhere with internet access, ideal for remote or mobile teams. " },
                                { icon: "/365/benefits/3.svg", heading: "Strong Security", desc: "Robust security features, including data protection and threat prevention." },
                                { icon: "/365/benefits/4.svg", heading: "Team Collaboration", desc: "Microsoft Teams facilitates video meetings, chat, and file sharing. " },
                                { icon: "/365/benefits/5.svg", heading: "Scalability", desc: "Suited for businesses of all sizes, with flexible subscription plans." },
                                { icon: "/365/benefits/6.svg", heading: "Integration", desc: "Seamlessly works with other Microsoft services for unified management." },
                                { icon: "/365/benefits/7.svg", heading: "Regular Updates", desc: "Continual software enhancements for staying current. " },
                                { icon: "/365/benefits/8.svg", heading: "Compliance Support", desc: "Helps meet industry regulations, critical for sectors like healthcare and finance. " },
                                { icon: "/365/benefits/9.svg", heading: "Support and Training", desc: "Extensive resources for easy onboarding and issue resolution. " },
                                { icon: "/365/benefits/10.svg", heading: "Cost-Efficiency", desc: "Subscription model can reduce costs and eases maintenance. " },
                                { icon: "/365/benefits/11.svg", heading: "Mobile-Friendly", desc: "Mobile apps enhance productivity for employees on the go." },
                                { icon: "/365/benefits/12.svg", heading: "Data Analytics", desc: "Tools like Power BI assist in data analysis for informed decisions. " },
                            ].map((item, index) => {
                                return <div key={`365-benefits-item-${index}`}>
                                    <img src={item.icon} className='tw-mb-[24px]' alt="benefit-icon" />
                                    <h5 className='mir-h6-semibold mir-text-neutral-800 tw-mb-[12px]'>{item.heading}</h5>
                                    <p className='mir-text-neutral-600 mir-p-rg-regular'>{item.desc}</p>
                                </div>
                            })
                        }
                    </div>
                </div>
            </section>


            <div className='tw-relative' style={{ marginTop: '-24px' }}>
                <span data-section id="section-4" className='tw-absolute tw-top-[-100px]'></span>
                <HowHelpSection
                    overline={'HOW WE HELP YOU'}
                    heading={<>Why should you choose <span style={{ color: '#1D68F0' }}>MIR Digital Solutions</span> to fuel your business growth through Microsoft 365?</>}
                    desc={<>MIR Digital Solutions has a proven track record of successful implementations and a strong commitment to Microsoft technologies, resulting in unrivaled product knowledge and process improvement expertise. Our experience has enabled us to develop efficient processes and approaches for cloud solutions, enabling innovation in a way other competitors simply cannot.</>}
                    image={'url(/365/help-bg.png) no-repeat'}
                />
            </div>

            <div className="delivering-wrapper py-5 mb-5">
                <div className="container">
                    <h3 className='mir-h5-semibold mb-4'>As a Microsoft Partner, we deliver</h3>
                    <div className="row">
                        {
                            [
                                'An end-to-end digital transformation platform that drives project-based operations from prospect to payment to profit',
                                'An extensive partner ecosystem and go-to-market channels, including AppSource Marketplace, partner-to partner network, and Microsoft sellers',
                                'Dynamics 365 extensibility to unite Finance, Human Resources, Sales, Supply Chain Management, Customer Service, Field Service, and more',
                                'Complete cloud vision that encompasses Azure, Office 365, and Dynamics 365',
                                'Deep expertise and a rich history with CRM, ERP, HCM, collaboration, productivity, and project management applications',
                                'Accelerated application development and flexibility with the Power Platform',
                            ].map((item) => {
                                return <div className="col-12 col-md-4">
                                    <div className="deliver-item d-flex align-items-start justify-content-start">
                                        <img src="/project-operations/blue-check.svg" className='mt-3 me-3' alt="delivering-icon" />
                                        <h3 className='mir-p-rg-regular mt-3' style={{ maxWidth: '450px' }}>{item}</h3>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>

            <StepsSlider
                subtitle={'Our specialist team offers custom-tailored implementation of Microsoft Power Apps for crafting personalized applications, bypassing intricate coding.'}
            />

            <div className='tw-relative'>
                <span data-section id="section-5" className='tw-absolute tw-top-[-100px]'></span>
                <SuccessStoriesDynamic
                    successStoriesContent={successStoriesContent}
                />
            </div>

            <TransformationSection
                heading={<>From anywhere to everywhere: Expand your business reach with Microsoft 365</>}
                desc={<>With our seasoned expert team, MIR can help you tailor and adapt any functionality to your business requirements.</>}
                items={[
                    {
                        icon: '/transformation-icons/power-apps.svg',
                        heading: <>Microsoft <br /> Power Apps</>,
                        link: <Link to={MIRRoutes.PowerApps}><span className='mir-p-sm-medium me-2'>Learn More</span>
                            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.3172 7.44254L9.69219 13.0675C9.57491 13.1848 9.41585 13.2507 9.25 13.2507C9.08415 13.2507 8.92509 13.1848 8.80781 13.0675C8.69054 12.9503 8.62465 12.7912 8.62465 12.6253C8.62465 12.4595 8.69054 12.3004 8.80781 12.1832L13.3664 7.62535H1.125C0.95924 7.62535 0.800269 7.5595 0.683058 7.44229C0.565848 7.32508 0.5 7.16611 0.5 7.00035C0.5 6.83459 0.565848 6.67562 0.683058 6.55841C0.800269 6.4412 0.95924 6.37535 1.125 6.37535H13.3664L8.80781 1.81753C8.69054 1.70026 8.62465 1.5412 8.62465 1.37535C8.62465 1.2095 8.69054 1.05044 8.80781 0.93316C8.92509 0.815885 9.08415 0.75 9.25 0.75C9.41585 0.75 9.57491 0.815885 9.69219 0.93316L15.3172 6.55816C15.3753 6.61621 15.4214 6.68514 15.4529 6.76101C15.4843 6.83688 15.5005 6.91821 15.5005 7.00035C15.5005 7.08248 15.4843 7.16381 15.4529 7.23969C15.4214 7.31556 15.3753 7.38449 15.3172 7.44254Z" fill="#1D68F0" />
                            </svg></Link>
                    },
                    {
                        icon: '/transformation-icons/sharepoint.png',
                        heading: <>Microsoft <br /> SharePoint</>,
                        link: <Link to={MIRRoutes.SharePoint}><span className='mir-p-sm-medium me-2'>Learn More</span>
                            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.3172 7.44254L9.69219 13.0675C9.57491 13.1848 9.41585 13.2507 9.25 13.2507C9.08415 13.2507 8.92509 13.1848 8.80781 13.0675C8.69054 12.9503 8.62465 12.7912 8.62465 12.6253C8.62465 12.4595 8.69054 12.3004 8.80781 12.1832L13.3664 7.62535H1.125C0.95924 7.62535 0.800269 7.5595 0.683058 7.44229C0.565848 7.32508 0.5 7.16611 0.5 7.00035C0.5 6.83459 0.565848 6.67562 0.683058 6.55841C0.800269 6.4412 0.95924 6.37535 1.125 6.37535H13.3664L8.80781 1.81753C8.69054 1.70026 8.62465 1.5412 8.62465 1.37535C8.62465 1.2095 8.69054 1.05044 8.80781 0.93316C8.92509 0.815885 9.08415 0.75 9.25 0.75C9.41585 0.75 9.57491 0.815885 9.69219 0.93316L15.3172 6.55816C15.3753 6.61621 15.4214 6.68514 15.4529 6.76101C15.4843 6.83688 15.5005 6.91821 15.5005 7.00035C15.5005 7.08248 15.4843 7.16381 15.4529 7.23969C15.4214 7.31556 15.3753 7.38449 15.3172 7.44254Z" fill="#1D68F0" />
                            </svg></Link>
                    },
                    {
                        icon: '/transformation-icons/power-bi.png',
                        heading: <>Microsoft <br /> Power BI</>,
                        link: <Link to={MIRRoutes.PowerBI}><span className='mir-p-sm-medium me-2'>Learn More</span>
                            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.3172 7.44254L9.69219 13.0675C9.57491 13.1848 9.41585 13.2507 9.25 13.2507C9.08415 13.2507 8.92509 13.1848 8.80781 13.0675C8.69054 12.9503 8.62465 12.7912 8.62465 12.6253C8.62465 12.4595 8.69054 12.3004 8.80781 12.1832L13.3664 7.62535H1.125C0.95924 7.62535 0.800269 7.5595 0.683058 7.44229C0.565848 7.32508 0.5 7.16611 0.5 7.00035C0.5 6.83459 0.565848 6.67562 0.683058 6.55841C0.800269 6.4412 0.95924 6.37535 1.125 6.37535H13.3664L8.80781 1.81753C8.69054 1.70026 8.62465 1.5412 8.62465 1.37535C8.62465 1.2095 8.69054 1.05044 8.80781 0.93316C8.92509 0.815885 9.08415 0.75 9.25 0.75C9.41585 0.75 9.57491 0.815885 9.69219 0.93316L15.3172 6.55816C15.3753 6.61621 15.4214 6.68514 15.4529 6.76101C15.4843 6.83688 15.5005 6.91821 15.5005 7.00035C15.5005 7.08248 15.4843 7.16381 15.4529 7.23969C15.4214 7.31556 15.3753 7.38449 15.3172 7.44254Z" fill="#1D68F0" />
                            </svg></Link>
                    },
                ]}
            />

            <div className='tw-relative'>
                <span data-section id="section-6" className='tw-absolute tw-top-[-100px]'></span>
                <ContactSection />
            </div>


            <Footer />

        </main>
    )
}
