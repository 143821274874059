import React from "react";
import {ReactComponent as TopClipArt} from "./../svg/top_clip_art.svg";
import {ReactComponent as BottomClipArt} from "./../svg/bottom-clip-art.svg";

const SuccessSection = () => {
    return (
        <section className='success-section'>
            <div className='top-clip-path'>
                <TopClipArt/>
            </div>
            <div className='container-fluid'>
                <div className="row justify-content-center align-items-center">
                    <div
                        className="col-12 col-md-10 success-container d-flex flex-column align-items-start justify-content-center text-white">
                        <div className='row success-content'>
                            <div className='d-inline-block p-0 z-2 mir-h1-medium success-title'>
                                At MIR, our success is <span className='mir-text-primary-400'>rooted in a distinctive and robust culture</span>
                            </div>
                            <div className='d-inline-block p-0 z-2 mir-p-md-medium'>
                                We hold every facet of our company in high regard, encompassing not only how we serve our
                                customers but also how we engage with one another.
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='bottom-clip-path'>
                <BottomClipArt/>
            </div>
        </section>
    );
}

export default SuccessSection;
