import MIRRoutes from "./routes"

const cloudSolutionLinks = [
    {
        title: 'Dynamics 365',
        items: [
            { img: '/header/nav-options/dynamics-1.svg', heading: 'Supply Chain Management', subtitle: 'Streamline your supply chain processes and drive growth', href: MIRRoutes.SupplyChain },
            { img: '/header/nav-options/dynamics-2.svg', heading: 'Sales', subtitle: 'Optimize sales, enhance customer engagement, and drive growth', href: MIRRoutes.Sales },
            { img: '/header/nav-options/dynamics-3.svg', heading: 'Project Operations', subtitle: 'Maximize productivity and deliver projects on time', href: MIRRoutes.ProjectOperations },
            { img: '/header/nav-options/dynamics-4.svg', heading: 'Business Central', subtitle: 'Streamline your financials and boost efficiency', href: MIRRoutes.BusinessCentral },
        ]
    },
    {
        title: 'Productivity',
        items: [
            { img: '/header/nav-options/productivity-1.svg', heading: 'Power Apps', subtitle: 'Simplify and expedite the creation of custom applications', href: MIRRoutes.PowerApps },
            { img: '/header/nav-options/productivity-2.svg', heading: 'Microsoft 365', subtitle: 'Productivity, security, device control - All-in-one for the future of your business', href: MIRRoutes.Microsoft365 },
            { img: '/header/nav-options/productivity-3.svg', heading: 'Power BI', subtitle: 'Your all-in-one business intelligence platform', href: MIRRoutes.PowerBI },
            { img: '/header/nav-options/productivity-4.svg', heading: 'Microsoft Sharepoint', subtitle: 'Streamline workflows, secure collaboration, and efficient document management', href: MIRRoutes.SharePoint },
        ]
    },
    {
        title: 'MIR Products',
        items: [
            { img: '/header/nav-options/product-1.svg', heading: 'TimeIt', subtitle: 'One unified time management software', href: '/business-solutions/timeit'},
            { img: '/header/nav-options/product-2.svg', heading: 'RecruIt Application', subtitle: 'Streamline candidate-to-hire process with the smart RecruIt Application', href: MIRRoutes.InterviewAssesment },
        ]
    }
]

const businessSolutionLinks = [
    {
        title: 'Business Solutions',
        items: [
            { img: '/header/business-options/solution-1.svg', heading: 'Enterprise Resource Planning (ERP)', subtitle: 'Streamline, scale, succeed with Microsoft ERP Solutions', href: MIRRoutes.ERP },
            { img: '/header/business-options/solution-2.svg', heading: 'Customer Relationship Management', subtitle: 'Microsoft CRM: Fueling business growth through stronger customer relationships', href: MIRRoutes.CRM },
            { img: '/header/business-options/solution-3.svg', heading: 'Low Code Development', subtitle: 'Accelerate innovation with low code development', href: MIRRoutes.LowCode },
            { img: '/header/business-options/solution-4.svg', heading: 'Cloud Services', subtitle: 'Your cloud, our expertise: Migrate, optimize, modernize with Microsoft Azure', href: MIRRoutes.CloudServices },
            { img: '/header/business-options/solution-5.svg', heading: 'Business Intelligence & Data Analytics', subtitle: 'Boost efficiency and drive data-driven decisions with Microsoft Power BI', href: MIRRoutes.BusinessIntelligence },
            { img: '/header/business-options/solution-6.svg', heading: 'Project Rescue', subtitle: `Regain control with MIR's Microsoft Dynamics 365 project rescue solutions`, href: MIRRoutes.ProjectRescue },
            { img: '/header/business-options/solution-7.svg', heading: 'Staffing Solutions', subtitle: 'Unlock specialized talent across North America, Middle East, and Africa', href: MIRRoutes.StaffingSolutions },
            { img: '/header/business-options/solution-8.png', heading: 'Data Science & AI', subtitle: 'Leverage AI/ML to make data-driven decisions ', href: MIRRoutes.BusinessScience },
        ]
    },
]

export default {
    cloudSolutionLinks, businessSolutionLinks
}