import React from "react";
import {ReactComponent as CommitteeSvg} from "../svg/committe.svg";
import {ReactComponent as BadgeSvg} from "../svg/badge.svg";
import {ReactComponent as FlagSvg} from "../svg/flag.svg";

const WomenEmpowerSection = () => {
    return (
        <section className='mir-bg-neutral-100 position-relative women-empowerment-section mt-4'>
            <div className='container empowerment-container'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-md-10'>
                        <div className='text-center women-empowerment-content'>
                            <h2 className='mir-h1-semibold mir-text-neutral-800'>MIR empowers females to grow and succeed at the
                                workplace</h2>
                            <p className='mir-p-md-regular mir-text-neutral-700'>At MIR, we're committed to closing the gender gap. Our
                                inclusive environment fosters empowerment for women, breaking down barriers and
                                unlocking the full potential of our female employees. Join us in creating a workplace
                                where autonomy and control are equal opportunities for all.</p>
                        </div>
                        <div className='women-empowerment-badges'>
                            <div className="badge-item">
                                <CommitteeSvg/>
                                <div className='mir-p-md-semibold'> Women committee to inspire and nurture</div>
                            </div>
                            <div className="badge-item">
                                <BadgeSvg/>
                                <div className='mir-p-md-semibold'>Diversity trainings</div>
                            </div>
                            <div className="badge-item">
                                <FlagSvg/>
                                <div className='mir-p-md-semibold'> Leadership buy-in</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='f-section-image-container'>
                <img src='/mir-culture/female_empower.svg' alt='female empower'/>
            </div>
        </section>
    );
}

export default WomenEmpowerSection;