import React, { useEffect, useRef, useState } from 'react'
import './index.scss'
import emailjs from '@emailjs/browser';
import { Formik, Field, Form } from 'formik';
import validator from 'validator';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MIRRoutes, { ContactFormIdLabel } from '../../../../../lib/routes';
import CaseStudiesContent from '../../../../../lib/caseStudiesContent';
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

export default function ContactSection() {
    return (
        <section className='sec-12 cloud-solutions light-contact-form' style={{ backgroundColor: '#FAFAFC' }}>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12'>
                        <div className='sec-12-sub-heading'>
                            contact us
                        </div>
                        <div className='sec-12-heading mir-heading-l8-l2 text-shades-100'>
                            Let the work <br /> begin!
                        </div>
                        <div className='sec-12-desc text-shades-100'>
                            Start Your Journey to Streamlined Operations. Complete The Form To Get Started.
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 offset-lg-2 offset-md-2 offset-sm-2 offset-xs-2'>
                        <div className='sec-11-contact-card'>
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export const ContactForm = ({ downloadFile, gridLayout }) => {

    const form = useRef();
    const recaptcha = useRef();

    const routeKeyFromMIRRoutes = Object.keys(MIRRoutes).find(key => MIRRoutes[key] === window.location.pathname)

    // get the formId 
    const [formId, setFormId] = useState('pushform_contact')

    useEffect(() => {
        getTheFormId()
    }, [])

    const getTheFormId = () => {
        // ContactFormIdLabel[routeKeyFromMIRRoutes]?.formId ? ContactFormIdLabel[routeKeyFromMIRRoutes]?.formId : 'pushform_contact'
        if (window.location.pathname.includes('/about-us/case-studies/')) {
            let slug = window.location.pathname.replace('/about-us/case-studies/', '');
            let caseStudy = CaseStudiesContent.find(x => x.metaSlug == slug);
            setFormId(caseStudy?.formId ? caseStudy.formId : 'pushform_contact')
        }
        else {
            if (ContactFormIdLabel[routeKeyFromMIRRoutes]?.formId) {
                setFormId(ContactFormIdLabel[routeKeyFromMIRRoutes]?.formId)
            }
        }
    }

    useEffect(() => {
        // console.log(MIRRoutes[routeKeyFromMIRRoutes], routeKeyFromMIRRoutes, ContactFormIdLabel[routeKeyFromMIRRoutes]?.formId)
    }, [formId])

    return <Formik
        initialValues={{
            firstName: "",
            lastName: "",
            businessEmail: "",
            company: "",
            phoneNumber: "",
            hearAbout: "",
            message: "",
            location: "",
            isCaseStudy: "",
            webUrl: window.location.href,
            environment: window.location.origin == 'https://mirdigitalsolutions.com' ? 'PROD' : 'DEV',
        }}
        validate={values => {
            const errors = {};
            if (values.firstName.length <= 0) { errors.firstName = 'Enter your first name' }
            if (values.lastName.length <= 0) { errors.lastName = 'Enter your last name' }
            if (!validator.isEmail(values.businessEmail)) { errors.businessEmail = 'Enter a valid email address' }
            if (!values.company) { errors.company = 'Enter your company name' }
            if (!validator.isMobilePhone(values.phoneNumber)) { errors.phoneNumber = 'Enter a valid phone number' }
            // if(values.message.length <= 0){ errors.message = 'Enter your message' }
            // if(values.hearAbout == "selected"){ errors.message = 'Select this option' }
            return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm, setFieldValue }) => {
            const captchaValue = recaptcha.current.getValue();
            setSubmitting(true)
            // get ip location from ipapijson
            let location = '';
            let locationRes = await fetch('https://ipapi.co/json/');
            if (locationRes && locationRes.status == 200) {
                locationRes = await locationRes.json();
                location = `${locationRes.city}, ${locationRes.region}, ${locationRes.country_name}`
            }
            // udpate the form field location with the location value
            // if (location) {
            //     setFieldValue('location', location)
            // }
            setFieldValue('isCaseStudy', 'No');
            if (window.location.href.includes('/about-us/case-studies/')) {
                setFieldValue('isCaseStudy', 'Yes');
            }
            await new Promise(resolve => setTimeout(resolve, 500))
            // send email 
            console.log(locationRes);
            console.log(location);
            axios
                .post("https://mirdigitalsolutions.com/api-backend/verify", {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    businessEmail: values.businessEmail,
                    company: values.company,
                    phoneNumber: values.phoneNumber,
                    hearAbout: values.hearAbout,
                    message: values.message,
                    city: locationRes.city,
                    state: locationRes.region,
                    country: locationRes.country_name,
                    isCaseStudy: values.isCaseStudy,
                    captchaValue: captchaValue
                })
                .then((result) => {
                    if (!result.data.success) {
                        toast.success('Unable to submit. ' + result.data.reason, {
                            className: 'form-submission-text'
                        });
                        setSubmitting(false);
                        return;
                    }
                    resetForm();
                    window.grecaptcha.reset();
                    setSubmitting(false)
                    toast.success('Thank you for your interest! We will get back to you soon.', {
                        className: 'form-submission-text'
                    });
                    window.dataLayer.push({
                        'event': 'gtm.formSubmit'
                    })
                    // download the file if there is any downloadFile available
                    if (downloadFile && result) {
                        fetch(window.location.origin + downloadFile, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/pdf',
                            },
                        }).then((response) => response.blob())
                            .then((blob) => {
                                // Create blob link to download
                                const url = window.URL.createObjectURL(
                                    new Blob([blob]),
                                );
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute(
                                    'download',
                                    `file.pdf`,
                                );


                                // Append to html link element page
                                document.body.appendChild(link);

                                // Start download
                                link.click();

                                // Clean up and remove the link
                                link.parentNode.removeChild(link);
                            });
                    }
                }, (error) => {
                    if (error.response && error.response.data && error.response.data.reason) {
                        toast.error('Unable to submit. ' + error.response.data.reason, {
                            className: 'form-submission-text'
                        });
                    } else {
                        alert('Some error occurred while submitting the form!')
                    }
                    setSubmitting(false)
                });
        }}
    >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            handleReset,
            setFieldValue,
            /* and other goodies */
        }) => (
            <form ref={form} onSubmit={handleSubmit} id={formId}>
                <ToastContainer />
                <div className="row">
                    <div className={`${gridLayout ? 'col-12 col-lg-6' : 'col-12'}`}>
                        <label className='mir-label'>First Name</label>
                        <input type="text" name='firstName' required className='mir-input form-control !tw-text-slate-900' onChange={handleChange} onBlur={handleBlur} value={values.firstName} placeholder='Required'></input>
                        {(errors.firstName && touched.firstName && errors.firstName) && <p className='tw-text-red-500 tw-text-xs tw-font-medium tw-mb-4 -tw-mt-4'>{errors.firstName}</p>}

                        <label className='mir-label'>Last Name</label>
                        <input type="text" name='lastName' required className='mir-input form-control !tw-text-slate-900' onChange={handleChange} onBlur={handleBlur} value={values.lastName} placeholder='Required'></input>
                        {(errors.lastName && touched.lastName && errors.lastName) && <p className='tw-text-red-500 tw-text-xs tw-font-medium tw-mb-4 -tw-mt-4'>{errors.lastName}</p>}

                        <label className='mir-label'>Your Business Email</label>
                        <input type="text" name='businessEmail' required className='mir-input form-control !tw-text-slate-900' onChange={handleChange} onBlur={handleBlur} value={values.businessEmail} placeholder='Required'></input>
                        {(errors.businessEmail && touched.businessEmail && errors.businessEmail) && <p className='tw-text-red-500 tw-text-xs tw-font-medium tw-mb-4 -tw-mt-4'>{errors.businessEmail}</p>}
                    </div>

                    <div className={`${gridLayout ? 'col-12 col-lg-6' : 'col-12'}`}>
                        <label className='mir-label'>Company</label>
                        <input type="text" name='company' required className='mir-input form-control !tw-text-slate-900' onChange={handleChange} onBlur={handleBlur} value={values.company} placeholder='Required'></input>
                        {(errors.company && touched.company && errors.company) && <p className='tw-text-red-500 tw-text-xs tw-font-medium tw-mb-4 -tw-mt-4'>{errors.company}</p>}

                        <label className='mir-label'>Phone number</label>
                        <input type="text" name='phoneNumber' required className='mir-input form-control !tw-text-slate-900' onChange={handleChange} onBlur={handleBlur} value={values.phoneNumber} placeholder='Required'></input>
                        {(errors.phoneNumber && touched.phoneNumber && errors.phoneNumber) && <p className='tw-text-red-500 tw-text-xs tw-font-medium tw-mb-4 -tw-mt-4'>{errors.phoneNumber}</p>}

                        {/* location field which is hidden */}
                        <div className='tw-hidden'>
                            <label className='mir-label'>Location</label>
                            <input type="text" name='location' className='mir-input form-control !tw-text-slate-900' onChange={handleChange} onBlur={handleBlur} value={values.location}></input>
                        </div>
                        {/* url field which is hidden */}
                        <div className='tw-hidden'>
                            <label className='mir-label'>URL</label>
                            <input type="text" name='webUrl' className='mir-input form-control !tw-text-slate-900' onChange={handleChange} onBlur={handleBlur} value={values.webUrl}></input>
                        </div>
                        {/* env field which is hidden */}
                        <div className='tw-hidden'>
                            <label className='mir-label'>ENV</label>
                            <input type="text" name='environment' className='mir-input form-control !tw-text-slate-900' onChange={handleChange} onBlur={handleBlur} value={values.environment}></input>
                        </div>
                        {/* isCaseStudy field which is hidden */}
                        <div className='tw-hidden'>
                            <label className='mir-label'>isCaseStudy</label>
                            <input type="text" name='isCaseStudy' className='mir-input form-control !tw-text-slate-900' onChange={handleChange} onBlur={handleBlur} value={values.isCaseStudy}></input>
                        </div>

                        <label className='mir-label'>How did you hear about us?</label>
                        <select name='hearAbout' aria-label="hearAbout" placeholder='Please select' onChange={handleChange} onBlur={handleBlur} value={values.hearAbout ? values.hearAbout : ''} className='form-control !tw-text-slate-900 mir-input'>
                            <option disabled value={''}>Please select</option>
                            <option>Google</option>
                            <option>Social Media</option>
                        </select>
                        {(errors.hearAbout && touched.hearAbout && errors.hearAbout) && <p className='tw-text-red-500 tw-text-xs tw-font-medium tw-mb-4 -tw-mt-4'>{errors.hearAbout}</p>}
                    </div>

                    <div className="col-12">
                        <label className='mir-label'>Message</label>
                        <textarea name='message' style={{ height: "171px" }} onChange={handleChange} onBlur={handleBlur} value={values.message} placeholder='Explain your challenges briefly...' className='form-control !tw-text-slate-900 mir-input'>
                            {(errors.message && touched.message && errors.message) && <p className='tw-text-red-500 tw-text-xs tw-font-medium tw-mb-4 -tw-mt-4'>{errors.message}</p>}
                        </textarea>

                        <p className='sec-12-form-note'>
                            We're committed to your privacy. MIR uses the information you provide to us to contact you about our relevant content, products, and services. You may unsubscribe from these communications at any time. For more information, check out our Privacy Policy.
                        </p>
                        <div className='col-12' style={{ transform: "scale(0.85)", transformOrigin: "0 0" }}>
                            <ReCAPTCHA ref={recaptcha} sitekey="6LdASGwpAAAAAE0lL4dev1fq3g_q080J7R9F4jsU" />
                        </div>
                        <button type='submit' disabled={isSubmitting} className='mir-btn-md-tertiary form-submit' style={{ width: "100%", display: "inline-block" }}>{isSubmitting ? 'Sending Message..' : 'Submit'}</button>
                    </div>
                </div>
            </form>
        )}
    </Formik>
}