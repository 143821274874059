import React, { useEffect, useState } from 'react'
import StickyBanner from '../StickyBanner/StickyBanner'
import Footer from '../Footer/Footer';
import {useParams} from "react-router-dom";
import CaseStudiesContent from '../../lib/caseStudiesContent';
import { ContactForm } from '../CloudSolutions/Elements/Sections/ContactSection';
import BookNowModal from '../CommonElements/BookNowModal';
import { Helmet } from 'react-helmet';
import { FacebookShareButton, LinkedinShareButton } from "react-share";
import { toast } from 'react-toastify';

export default function CaseStudyDetails() {

    const {slug} = useParams();
    const [details, setDetails] = useState(null);
    const [showRegionPopup, setShowRegionPopup] = useState(false);

    useEffect(() => {
        if(slug){
            setDetails(CaseStudiesContent.find((x) => x.metaSlug == slug))
        }
    }, [slug])
  
    return (
        <main>

            <Helmet>
                <meta charSet="utf-8" />
                <title>{details?.metaTitle}</title>
                <meta name="description" content={details?.metaDesc} />
                <link rel="canonical" href={`${window.location.origin}/about-us/case-studies/${slug}`} />
            </Helmet>

            <StickyBanner />
            <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />
            {
                (!details || !details?.id) ? <div>
                    <section className='tw-h-[500px] tw-flex tw-text-center tw-items-center tw-justify-center'>
                        {/* <h3>The Case Study is not Found</h3> */}
                    </section>
                </div> : <section className='tw-py-[120px]'>
                    <div className="container mx-auto">
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-[136px]">
                            <div className='tw-w-full'>
                                <h4 className='mir-p-overline-lg mir-text-primary-500 tw-mb-[32px]'>CASE STUDY</h4>
                                <h1 className='mir-h1-semibold mir-text-neutral-800 tw-mb-[16px]'>{details?.title}</h1>
                                <p className='!tw-uppercase mir-p-rg-medium mir-text-neutral-400 tw-mb-[48px]'>{details?.date}</p>
                                <div>{details?.body}</div>
                                <div className='tw-h-[1px] mir-bg-neutral-300 tw-w-full tw-my-[32px]'></div>
                                <div>
                                    <h2 className='mir-h5-semibold tw-mb-[12px]'>Not a fan of reading? <br /> Book a meeting to learn more about the case study.</h2>
                                    <h3 className='mir-p-md-medium mir-text-primary-500'><a onClick={() => { setShowRegionPopup(true) }} className='tw-cursor-pointer'>{`I’d like to connect with a Microsoft expert ->`}</a></h3>
                                </div>
                                <div className='tw-h-[1px] mir-bg-neutral-300 tw-w-full tw-my-[32px]'></div>
                                <div className='tw-mb-[64px]'>
                                    <h4 className='mir-p-sm-medium mir-text-neutral-400 tw-mb-[16px]'>Share this post</h4>
                                    <div className='tw-flex tw-items-center tw-justify-start'>
                                        <a onClick={() => {
                                            navigator.clipboard.writeText(window.location.href)
                                            toast.success('Copied URL to clipboard.')
                                        }} className='tw-mr-[8px] tw-transition hover:tw-opacity-60 tw-cursor-pointer'>
                                            <img src="/case-studies/share-icon.png" alt="share-icon-btn" />
                                        </a>
                                        <LinkedinShareButton url={window.location.href}>
                                            <a className='tw-mr-[8px] tw-transition hover:tw-opacity-60 tw-cursor-pointer'>
                                                <img src="/case-studies/linkedin-icon.png" alt="linkedin-icon-btn" />
                                            </a>
                                        </LinkedinShareButton>
                                        <FacebookShareButton url={window.location.href}>
                                            <a className='tw-mr-[8px] tw-transition hover:tw-opacity-60 tw-cursor-pointer'>
                                                <img src="/case-studies/facebook-icon.png" alt="facebook-icon-btn" />
                                            </a>
                                        </FacebookShareButton>
                                    </div>
                                </div>
                                {/* schedule booking */}
                                <div className='tw-hidden md:tw-block tw-w-full tw-rounded-[8px] tw-p-[36px] mir-bg-primary-100 tw-relative tw-overflow-hidden'>
                                    <h2 className='mir-h6-bold'>Schedule a Meeting</h2>
                                    <h3 className='mir-p-md-regular mir-text-neutral-600 tw-mb-[36px]'>with a Microsoft expert to streamline your business operations</h3>
                                    <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-lg mir-btn-primary mir-p-rg-medium'>Schedule now</button>
                                    {/* image */}
                                    <img src="/case-studies/send-email-image.svg" className='tw-absolute tw-right-0 tw-bottom-0' alt="schedule-meeting-image" />
                                </div>
                            </div>
                            <div className='tw-w-full'>
                                <div className='tw-w-full' style={{ boxShadow: '0px 5px 26px -5px rgba(0, 18, 56, 0.06), 0px 8px 10px -6px rgba(16, 24, 40, 0.02)' }}>
                                    <img src={details?.image} alt="schedule-case-study-image" className='tw-w-full tw-mb-[52px]' />
                                    <div className='tw-pb-[36px] tw-px-[32px]'>
                                        <h2 className='mir-h5-semibold tw-mb-[32px]'>Get the case study</h2>
                                        <ContactForm downloadFile={details?.downloadLink} />
                                    </div>
                                </div>
                                {/* schedule booking */}
                                <div className='tw-block md:tw-hidden tw-w-full tw-rounded-[8px] tw-pt-[36px] tw-px-[36px] mir-bg-primary-100 tw-relative tw-overflow-hidden'>
                                    <h2 className='mir-h6-bold'>Schedule a Meeting</h2>
                                    <h3 className='mir-p-md-regular mir-text-neutral-600 tw-mb-[36px]'>with a Microsoft expert to streamline your business operations</h3>
                                    <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-lg mir-btn-primary mir-p-rg-medium'>Schedule now</button>
                                    {/* image */}
                                    <div className='tw-flex tw-justify-end'>
                                        <img src="/case-studies/send-email-image.svg" className='tw-mt-[52px]' alt="schedule-meeting-image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }

            <Footer />

        </main>
    )
}
