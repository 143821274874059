import React, { useEffect, useState } from "react";
import sanityClient from "../../../client";
import imageUrlBuilder from "@sanity/image-url";
import './index.scss'
import BookNowModal from "../../CommonElements/BookNowModal";
import Footer from "../../Footer/Footer";
import StickyBanner from "../../StickyBanner/StickyBanner";
import { useNavigate } from "react-router-dom";


const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source);
}

Date.prototype.toShortFormat = function () {

    const monthNames = ["Jan", "Feb", "Mar", "Apr",
        "May", "Jun", "Jul", "Aug",
        "Sep", "Oct", "Nov", "Dec"];

    const day = this.getDate();

    const monthIndex = this.getMonth();
    const monthName = monthNames[monthIndex];

    const year = this.getFullYear();

    return `${day} ${monthName} ${year}`;
}

export default function BlogList() {
    const navigate = useNavigate();
    const [allPostsData, setAllPosts] = useState(null);
    const [showRegionPopup, setShowRegionPopup] = useState(false);

    var blogCount = 6;

    function loadMore() {
        blogCount += 6;
        loadBlogs();
    }

    function handleReadBlog(post) {
        navigate("/blogs/" + post.date.replaceAll("-", "") + "/" + post.url);
    }

    function loadBlogs() {
        sanityClient
            .fetch(
                `*[_type == "mir-blogs"]| order(_createdAt desc)  [0...${blogCount}]{
            _id,
            title,
            shortDescription,
            cardImage,
            cardImageAlternateText,
            url,
            content,
            date,
            timeToRead,
            writtenBy,
            _createdAt
          }`
            )
            .then((data) => setAllPosts(data))
            .catch(console.error);
    }

    useEffect(() => {
        loadBlogs();
    }, []);

    return (
        <div>
            <StickyBanner />
            <div className="blog-header-div">
                <div className="text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 offset-lg-4">
                                <h1 className="text-shades-100 mir-heading-l7-l2">
                                    Blogs
                                </h1>
                                <h3 className="blog-header-div-para mir-paragraph-md-l4-l7 text-secondary-400">
                                    Breaking down the "whats" and "whys" of Microsoft Solutions
                                </h3>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="container">
                <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />
                <div className="blogs-preview-section">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="row">
                                {allPostsData &&
                                    allPostsData.map((post, index) => (
                                        <div className="col-lg-6" onClick={() => handleReadBlog(post)}>
                                            <div className="preview-card">
                                                <div className="img-container">
                                                    <img className="preview-image" src={urlFor(post.cardImage).url()} alt={post.cardImageAlternateText} />
                                                </div>

                                                <p className="posted-date mir-paragraph-sm-l2-l7 text-secondary-400">{new Date(post.date).toShortFormat()}</p>
                                                <h2 className="card-header text-secondary-800 mir-heading-l1-l2">{post.title}</h2>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                            <div className="row">
                                <div className="col-lg-12 text-center">
                                    <button className="mir-btn-md-tertiary" style={{ marginTop: "72px" }} onClick={loadMore}>Load more</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 text-center">
                            <div className="meeting-section">
                                <h4 className="mir-heading-l3-l1 text-secondary-800">Schedule a meeting today</h4>
                                <p className="mir-paragraph-rg-l3-l7 text-secondary-800 meeting-text">with a Microsoft expert to streamline your business operations</p>
                                <div className="svg-container">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="194" height="109" viewBox="0 0 194 109" fill="none">
                                        <path d="M0 42.4602L170.938 67.0621L193.876 90.2168L0 42.4602Z" fill="#1D68F0" />
                                        <path opacity="0.6" d="M0 42.4602L170.938 67.0621L193.876 90.2168L0 42.4602Z" fill="white" />
                                        <path d="M0 42.4602L170.938 67.0621L163.005 100.542L0 42.4602Z" fill="#1D68F0" />
                                        <path opacity="0.2" d="M0 42.4602L170.938 67.0621L163.005 100.542L0 42.4602Z" fill="black" />
                                        <path d="M159.497 3.70162L169.462 50.236C169.716 51.4506 169.479 52.7163 168.803 53.7571C168.128 54.798 167.068 55.5295 165.855 55.7922L77.278 74.6654C76.0632 74.9212 74.7965 74.6853 73.7551 74.0093C72.7138 73.3334 71.9827 72.2724 71.7218 71.0587L61.8015 24.5693C61.6717 23.9681 61.6619 23.3471 61.7727 22.7421C61.8834 22.1371 62.1126 21.5599 62.447 21.0436C62.7814 20.5274 63.2145 20.0823 63.7214 19.7338C64.2282 19.3854 64.7989 19.1404 65.4007 19.0131L153.94 0.102445C155.154 -0.155612 156.421 0.0784004 157.463 0.753143C158.504 1.42789 159.236 2.48825 159.497 3.70162Z" fill="#1D68F0" />
                                        <path opacity="0.4" d="M159.497 3.70162L169.462 50.236C169.716 51.4506 169.479 52.7163 168.803 53.7571C168.128 54.798 167.068 55.5295 165.855 55.7922L77.278 74.6654C76.0632 74.9212 74.7965 74.6853 73.7551 74.0093C72.7138 73.3334 71.9827 72.2724 71.7218 71.0587L61.8015 24.5693C61.6717 23.9681 61.6619 23.3471 61.7727 22.7421C61.8834 22.1371 62.1126 21.5599 62.447 21.0436C62.7814 20.5274 63.2145 20.0823 63.7214 19.7338C64.2282 19.3854 64.7989 19.1404 65.4007 19.0131L153.94 0.102445C155.154 -0.155612 156.421 0.0784004 157.463 0.753143C158.504 1.42789 159.236 2.48825 159.497 3.70162Z" fill="black" />
                                        <path d="M157.321 0.664799L127.733 36.979C124.993 40.3496 121.183 42.6834 116.936 43.5943C112.689 44.5051 108.257 43.9384 104.376 41.9878L62.543 20.8877C63.2238 19.921 64.2438 19.2463 65.3998 18.9981L153.94 0.102428C155.097 -0.143177 156.305 0.057689 157.321 0.664799Z" fill="#1D68F0" />
                                        <path d="M0 42.4602L140.99 75.9625L163.005 100.542L0 42.4602Z" fill="#1D68F0" />
                                        <path opacity="0.6" d="M0 42.4602L140.99 75.9625L163.005 100.542L0 42.4602Z" fill="white" />
                                        <path d="M0 42.4602L140.99 75.9625L113.584 109L0 42.4602Z" fill="#1D68F0" />
                                    </svg>
                                </div>
                                <button className='mir-btn-md-primary mt-4' onClick={(e) => { setShowRegionPopup(true) }}>Contact us today</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}