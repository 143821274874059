import React, { useEffect, useRef, useState } from 'react'
import './SectionTabs.scss'

export default function SectionTabs({ tabs }) {

    const [activeSection, setActiveSection] = useState('Overview');
    const sections = useRef([]);

    const handleScroll = () => {
        const pageYOffset = window.pageYOffset;
        let newActiveSection = null;
  
        sections?.current?.forEach((section) => {
           const sectionOffsetTop = section.offsetTop;
           const sectionHeight = section.offsetHeight;
           if (
              pageYOffset + 130 >= sectionOffsetTop &&
              pageYOffset + 130 < sectionOffsetTop + sectionHeight
           ) {
              newActiveSection = section.id;
           }
        });
  
        setActiveSection(newActiveSection);
     };
  
     useEffect(() => {
        sections.current = document.querySelectorAll("[data-section]");
        window.addEventListener("scroll", handleScroll);
  
        return () => {
           window.removeEventListener("scroll", handleScroll);
        };
     }, []);

    return (
        <div className='section-headings !tw-top-[59px] md:!tw-top-[73px]'>
            <div className="container">
                <div className="items justify-content-center">
                    {
                        tabs.map((tab) => {
                            return <a style={{ marginRight: '52px' }}
                                className={`mir-p-sm-regular ${activeSection === tab.id ? 'active' : ''}`}
                                key={`tab-item-${tab.name}`} href={`#${tab.id}`}>{tab.name}</a>
                        })
                    }
                </div>
            </div>
        </div>
    )
}
