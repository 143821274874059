import CaseStudiesContent from "./caseStudiesContent";

const CaseStudyUrls = {
    SupplyChain: `/about-us/case-studies/${CaseStudiesContent.find(x => x.id == 1).metaSlug}`,
    BusinessCentral: `/about-us/case-studies/${CaseStudiesContent.find(x => x.id == 2).metaSlug}`,
    PowerApps: `/about-us/case-studies/${CaseStudiesContent.find(x => x.id == 3).metaSlug}`,
    PowerBi: `/about-us/case-studies/${CaseStudiesContent.find(x => x.id == 2).metaSlug}`,
    CloudServices: `/about-us/case-studies/${CaseStudiesContent.find(x => x.id == 6).metaSlug}`,
    ProjectRescue: `/about-us/case-studies/${CaseStudiesContent.find(x => x.id == 2).metaSlug}`,
    InterviewAssesment: `/about-us/case-studies/${CaseStudiesContent.find(x => x.id == 8).metaSlug}`,
    SharePoint: `/about-us/case-studies/${CaseStudiesContent.find(x => x.id == 6).metaSlug}`
}

export default CaseStudyUrls;