import React from 'react';
import OverlayCard from "./OverlayCard";

const SectionCards = ({ title, cards }) => {
    return (
        <section className="section-cards">
            {/*<div className='row'>*/}
                <div className='col-12 section-title mir-h5-medium mir-text-neutral-700'>
                    {title}
                </div>
                <div className='col-12'>
                    <div className='section-border'></div>
                </div>

                <div className='cards-container'>
                    {/*<div className='row'>*/}
                        {cards.map((card, index) => (
                            <OverlayCard key={index} imageUrl={card.imageUrl} title={card.title} link={card.link} />
                        ))}
                    {/*</div>*/}

                </div>
            {/*</div>*/}
        </section>
    );
};

export default SectionCards;
