import React, { useState } from 'react'
import './index.scss'
import BookNowModal from '../../../../CommonElements/BookNowModal'

export default function GetStartedBlueBanner({ heading, buttonContent, url }) {

    const [showRegionPopup, setShowRegionPopup] = useState(false);

    return (
        <div className='cloud-solutions' >
            <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />
            <div className="container">
                <div className={`banner-wrapper row`}>
                    <div className="col-12 col-md-12 col-lg-8">
                        <div className="left">
                            <h3 className='mir-h5-semibold m-0'>{heading}</h3>
                        </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-4">
                        <div className="right">
                            <button className='mir-btn-md mir-btn-primary mir-p-rg-medium' onClick={() => { 
                                if(url){
                                    window.open(url, '_blank');
                                }
                                else{
                                    setShowRegionPopup(true)
                                }
                            }}>
                                <span className='btn-content'>{buttonContent ? buttonContent : 'Get started now'}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M17.3172 10.4422L11.6922 16.0672C11.5749 16.1844 11.4159 16.2503 11.25 16.2503C11.0841 16.2503 10.9251 16.1844 10.8078 16.0672C10.6905 15.9499 10.6247 15.7908 10.6247 15.625C10.6247 15.4591 10.6905 15.3001 10.8078 15.1828L15.3664 10.625H3.125C2.95924 10.625 2.80027 10.5591 2.68306 10.4419C2.56585 10.3247 2.5 10.1657 2.5 9.99998C2.5 9.83422 2.56585 9.67525 2.68306 9.55804C2.80027 9.44083 2.95924 9.37498 3.125 9.37498H15.3664L10.8078 4.81717C10.6905 4.69989 10.6247 4.54083 10.6247 4.37498C10.6247 4.20913 10.6905 4.05007 10.8078 3.93279C10.9251 3.81552 11.0841 3.74963 11.25 3.74963C11.4159 3.74963 11.5749 3.81552 11.6922 3.93279L17.3172 9.55779C17.3753 9.61584 17.4214 9.68477 17.4529 9.76064C17.4843 9.83652 17.5005 9.91785 17.5005 9.99998C17.5005 10.0821 17.4843 10.1634 17.4529 10.2393C17.4214 10.3152 17.3753 10.3841 17.3172 10.4422Z" fill="white" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
