import React, { useState } from 'react'
import './index.scss'
import BookNowModal from '../../../../CommonElements/BookNowModal';

export default function BlueFluidBanner({ title, button, desc, url }) {

    const [showRegionPopup, setShowRegionPopup] = useState(false);

    return (
        <section className='cloud-solutions fluid-banner streamline-banner'>
            <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />
            <div className="container">
                <div className="banner row">
                    <div className="col-12 col-md-12 col-lg-8">
                        <div className="left tw-max-w-full md:tw-max-w-[593px]">
                            <h3 className='mir-h4-semibold'>{title}</h3>
                            {
                                desc && desc.props && desc.props.children &&  desc.props.children.length > 0 ?
                                <p className='mir-paragraph-rg-l4-l7 text-secondary-300 mt-3'>{desc}</p>
                                : ""
                            }

                        </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-4">
                        <div className="right">
                            <button onClick={(e) => { 
                                if(url){
                                    window.open(url, '_blank');
                                }else{
                                    setShowRegionPopup(true)
                                }
                            }}
                                className='mir-btn-lg-outlined mir-paragraph-rg-l2-l7'>
                                {button}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
