import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import { BusinessCentralWrapper } from './BusinessCentral.styled';

import '../general.scss';
import './style.scss';
import StickyBanner from '../../StickyBanner/StickyBanner';

const BusinessCentral = () => {

   const [activeSection, setActiveSection] = useState("section1");
   const sections = useRef([]);

   const handleScroll = () => {
      const pageYOffset = window.pageYOffset;
      let newActiveSection = null;

      sections.current.forEach((section) => {
         const sectionOffsetTop = section.offsetTop;
         const sectionHeight = section.offsetHeight;
         if (
            pageYOffset + 130 >= sectionOffsetTop &&
            pageYOffset + 130 < sectionOffsetTop + sectionHeight
         ) {
            newActiveSection = section.id;
         }
      });

      setActiveSection(newActiveSection);
   };

   useEffect(() => {
      sections.current = document.querySelectorAll("[data-section]");
      window.addEventListener("scroll", handleScroll);

      return () => {
         window.removeEventListener("scroll", handleScroll);
      };
   }, []);

   const activeStyle = {
      fontWeight: "bold",
      color: "red",
      textDecoration: "underline"
   };

   return (
      <>
         <BusinessCentralWrapper>
            <StickyBanner />
            <section className='sec-1'>
               <div className='container'>
                  <div className='row'>
                     <div className='col-lg-12'>
                        <svg className='sec-1-icon' xmlns="http://www.w3.org/2000/svg" width="55" height="57" viewBox="0 0 55 57" fill="none">
                           <mask id="mask0_4709_22831" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="55" height="57">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 28.4999C0 30.9841 1.68978 33.0658 3.9613 33.6154C5.92597 43.06 13.22 50.497 22.4829 52.5002C23.0219 54.8163 25.0636 56.5392 27.5 56.5392C29.9364 56.5392 31.9781 54.8163 32.5171 52.5002C41.78 50.497 49.0741 43.06 51.0387 33.6155C53.3102 33.0659 55 30.9842 55 28.5C55 26.0159 53.3103 23.9342 51.0388 23.3845C49.0741 13.9399 41.7801 6.50286 32.5171 4.49966C31.9781 2.18361 29.9364 0.460693 27.5 0.460693C25.0636 0.460693 23.0219 2.18361 22.4829 4.49966C13.22 6.50286 5.92597 13.9399 3.9613 23.3845C1.68978 23.934 0 26.0158 0 28.4999Z" fill="#8DF7DB" />
                           </mask>
                           <g mask="url(#mask0_4709_22831)">
                              <path d="M27.5 56.5392C42.6879 56.5392 55.0001 43.9856 55.0001 28.4999C55.0001 13.0143 42.6879 0.460693 27.5 0.460693C12.3122 0.460693 0 13.0143 0 28.4999C0 43.9856 12.3122 56.5392 27.5 56.5392Z" fill="url(#paint0_linear_4709_22831)" />
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M29.7917 10.4289C31.4895 9.56866 32.6563 7.78227 32.6563 5.71805C32.6563 2.81449 30.3477 0.460693 27.5 0.460693C24.6523 0.460693 22.3438 2.81449 22.3438 5.71805C22.3438 7.78227 23.5105 9.56866 25.2083 10.4289V13.8962H29.7917V10.4289Z" fill="#9DF3F9" />
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M25.2083 46.5705C23.5106 47.4308 22.3438 49.2172 22.3438 51.2814C22.3438 54.185 24.6523 56.5387 27.5 56.5387C30.3477 56.5387 32.6563 54.185 32.6563 51.2814C32.6563 49.2172 31.4895 47.4308 29.7917 46.5705V43.1033H25.2083V46.5705Z" fill="#82EBF1" />
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.77655 26.1629C8.93281 24.4319 7.18077 23.2422 5.15626 23.2422C2.30854 23.2422 -1.24478e-07 25.596 0 28.4995C1.24478e-07 31.4031 2.30854 33.7569 5.15626 33.7569C7.18077 33.7569 8.93281 32.5672 9.77655 30.8362H13.1771V26.1629H9.77655Z" fill="#9DF3F9" />
                              <g filter="url(#filter0_f_4709_22831)">
                                 <path d="M27.4974 44.8563C35.7242 44.8563 42.3933 38.0565 42.3933 29.6684C42.3933 21.2803 35.7242 14.4805 27.4974 14.4805C19.2707 14.4805 12.6016 21.2803 12.6016 29.6684C12.6016 38.0565 19.2707 44.8563 27.4974 44.8563Z" fill="black" fill-opacity="0.32" />
                                 <path fill-rule="evenodd" clip-rule="evenodd" d="M33.9877 32.0034C33.0439 34.726 30.4973 36.6766 27.5039 36.6766C23.707 36.6766 20.6289 33.5382 20.6289 29.6668C20.6289 25.7954 23.707 22.657 27.5039 22.657C30.4973 22.657 33.0439 24.6076 33.9877 27.3302H45.8373V32.0034H33.9877Z" fill="black" fill-opacity="0.32" />
                                 <path d="M49.8438 34.9241C46.996 34.9241 44.6875 32.5704 44.6875 29.6668C44.6875 26.7633 46.996 24.4094 49.8438 24.4094C52.6915 24.4094 55 26.7633 55 29.6668C55 32.5704 52.6915 34.9241 49.8438 34.9241Z" fill="black" fill-opacity="0.32" />
                              </g>
                              <g filter="url(#filter1_f_4709_22831)">
                                 <path d="M27.4974 43.9086C35.7242 43.9086 42.3933 37.1087 42.3933 28.7206C42.3933 20.3326 35.7242 13.5327 27.4974 13.5327C19.2707 13.5327 12.6016 20.3326 12.6016 28.7206C12.6016 37.1087 19.2707 43.9086 27.4974 43.9086Z" fill="black" fill-opacity="0.24" />
                                 <path fill-rule="evenodd" clip-rule="evenodd" d="M33.9877 31.0556C33.0439 33.7783 30.4973 35.7289 27.5039 35.7289C23.707 35.7289 20.6289 32.5904 20.6289 28.719C20.6289 24.8476 23.707 21.7092 27.5039 21.7092C30.4973 21.7092 33.0439 23.6599 33.9877 26.3824H45.8373V31.0556H33.9877Z" fill="black" fill-opacity="0.24" />
                                 <path d="M49.8438 33.9764C46.996 33.9764 44.6875 31.6226 44.6875 28.719C44.6875 25.8155 46.996 23.4617 49.8438 23.4617C52.6915 23.4617 55 25.8155 55 28.719C55 31.6226 52.6915 33.9764 49.8438 33.9764Z" fill="black" fill-opacity="0.24" />
                              </g>
                              <path d="M27.4974 43.6874C35.7242 43.6874 42.3933 36.8875 42.3933 28.4994C42.3933 20.1114 35.7242 13.3115 27.4974 13.3115C19.2707 13.3115 12.6016 20.1114 12.6016 28.4994C12.6016 36.8875 19.2707 43.6874 27.4974 43.6874Z" fill="url(#paint1_linear_4709_22831)" />
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M33.9877 30.8366C33.0439 33.5593 30.4973 35.5099 27.5039 35.5099C23.707 35.5099 20.6289 32.3714 20.6289 28.5C20.6289 24.6286 23.707 21.4902 27.5039 21.4902C30.4973 21.4902 33.0439 23.4409 33.9877 26.1634H45.8373V30.8366H33.9877Z" fill="url(#paint2_linear_4709_22831)" />
                              <path d="M49.8438 33.7574C46.996 33.7574 44.6875 31.4036 44.6875 28.5C44.6875 25.5965 46.996 23.2427 49.8438 23.2427C52.6915 23.2427 55 25.5965 55 28.5C55 31.4036 52.6915 33.7574 49.8438 33.7574Z" fill="url(#paint3_linear_4709_22831)" />
                           </g>
                           <defs>
                              <filter id="filter0_f_4709_22831" x="7.04282" y="8.92172" width="53.5159" height="41.4932" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                 <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                 <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                 <feGaussianBlur stdDeviation="2.77937" result="effect1_foregroundBlur_4709_22831" />
                              </filter>
                              <filter id="filter1_f_4709_22831" x="12.0735" y="13.0046" width="43.4546" height="31.4319" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                 <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                 <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                 <feGaussianBlur stdDeviation="0.26404" result="effect1_foregroundBlur_4709_22831" />
                              </filter>
                              <linearGradient id="paint0_linear_4709_22831" x1="13.0068" y1="7.09162" x2="42.3859" y2="49.3773" gradientUnits="userSpaceOnUse">
                                 <stop stop-color="#009CA8" />
                                 <stop offset="1" stop-color="#007D85" />
                              </linearGradient>
                              <linearGradient id="paint1_linear_4709_22831" x1="18.2069" y1="15.806" x2="38.4692" y2="48.8022" gradientUnits="userSpaceOnUse">
                                 <stop stop-color="#36E3EE" />
                                 <stop offset="1" stop-color="#00B1BE" />
                              </linearGradient>
                              <linearGradient id="paint2_linear_4709_22831" x1="17.8418" y1="14.4804" x2="43.5487" y2="37.0975" gradientUnits="userSpaceOnUse">
                                 <stop stop-color="#D2F6F9" />
                                 <stop offset="1" stop-color="#82EBF1" />
                              </linearGradient>
                              <linearGradient id="paint3_linear_4709_22831" x1="55.5575" y1="30.2051" x2="41.3753" y2="25.2636" gradientUnits="userSpaceOnUse">
                                 <stop stop-color="#9AEEF3" />
                                 <stop offset="1" stop-color="#82EBF1" />
                              </linearGradient>
                           </defs>
                        </svg>
                     </div>
                     <div className='col-lg-12'>
                        <h1 className='mir-heading-l8-l2 sec-1-header text-shades-100'>
                           Microsoft Dynamics 365<br />
                           Business Central
                        </h1>
                     </div>
                     <div className='col-lg-12'>
                        <h1 className='mir-paragraph-lg-l3-l5 sec-1-desc text-shades-100'>
                           Streamline Your Financials and Boost Efficiency
                        </h1>
                     </div>
                  </div>
               </div>
            </section>
            <section className='sec-2'>
               <div className='container'>
                  <ul className="navbar navbar navbar-expand-md navbar-light nav nav-fill navbar-custom">
                     <li className="nav-item">
                        <a className={activeSection === "section1" ? "nav-link active" : "nav-link"} href="#">Overview</a>
                     </li>
                     <li className="nav-item">
                        <a className={activeSection === "section2" ? "nav-link active" : "nav-link"} href="#">Introducing Business Central</a>
                     </li>
                     <li className="nav-item">
                        <a className={activeSection === "section3" ? "nav-link active" : "nav-link"} href="#">Service Bundles</a>
                     </li>
                     <li className="nav-item">
                        <a className={activeSection === "section4" ? "nav-link active" : "nav-link"} href="#">Benefits of BC</a>
                     </li>
                     <li className="nav-item">
                        <a className={activeSection === "section5" ? "nav-link active" : "nav-link"} href="#">How we help you</a>
                     </li>
                     <li className="nav-item">
                        <a className={activeSection === "section6" ? "nav-link active" : "nav-link"} href="#">Success Story</a>
                     </li>
                     <li className="nav-item">
                        <a className={activeSection === "section7" ? "nav-link active" : "nav-link"} href="#">Contact Us</a>
                     </li>
                  </ul>
               </div>
            </section>
            <section data-section id="section1">
               
               <div className="sec-1-sub1">
                  <div className="container">
                     <div className="row">
                     <div className="col-lg-7 section-1-styles">
                        <div className="heading">
                           Overview
                        </div>
                     </div>
                        <div className="col-lg-7 section-1-styles">
                           <div className="mir-heading-l6-l2 heading-main text-secondary-800">
                              Overcoming Business Challenges With Cloud Solutions
                           </div>
                           <div className="mir-paragraph-md-l4-l7 desc text-secondary-800">
                              In the post-pandemic landscape, businesses are grappling with new obstacles, encompassing labor shortages, evolving workforce expectations, and the detrimental effects of disjointed systems and processes. These challenges include:
                           </div>
                           <div className="points">
                              <div className="row points">
                                 <div className="col-lg-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                       <g clip-path="url(#clip0_4641_34704)">
                                          <path d="M32.5 40H7.5C3.3575 40 0 36.6425 0 32.5V7.5C0 3.3575 3.3575 0 7.5 0H32.5C36.6425 0 40 3.3575 40 7.5V32.5C40 36.6425 36.6425 40 32.5 40Z" fill="#FEEFEF" />
                                          <path d="M20 10C14.4837 10 10 14.4837 10 20C10 25.5163 14.4837 30 20 30C25.5163 30 30 25.5163 30 20C30 14.4837 25.5163 10 20 10ZM14.1663 17.5588C14.1663 16.6337 14.9163 15.8925 15.8325 15.8925C16.7488 15.8925 17.4987 16.6337 17.4987 17.5588C17.4987 18.475 16.7488 19.225 15.8325 19.225C14.9163 19.225 14.1663 18.475 14.1663 17.5588ZM24.7563 25.6188C24.5938 25.7813 24.38 25.8625 24.1675 25.8625C23.9537 25.8625 23.7413 25.7813 23.5788 25.6188C22.6225 24.6625 21.3512 24.1375 20 24.1375C18.6488 24.1375 17.3775 24.6638 16.4225 25.62C16.0975 25.945 15.57 25.945 15.2437 25.62C14.9187 25.295 14.9187 24.7675 15.2437 24.4412C16.5137 23.1712 18.2037 22.4712 20 22.4712C21.7963 22.4712 23.485 23.1712 24.7563 24.4412C25.0813 24.7662 25.0813 25.2938 24.7563 25.6188ZM24.1663 19.225C23.25 19.225 22.5 18.475 22.5 17.5588C22.5 16.6337 23.25 15.8925 24.1663 15.8925C25.0825 15.8925 25.8325 16.6337 25.8325 17.5588C25.8338 18.475 25.0838 19.225 24.1663 19.225Z" fill="#E96767" />
                                       </g>
                                       <defs>
                                          <clipPath id="clip0_4641_34704">
                                             <rect width="40" height="40" fill="white" />
                                          </clipPath>
                                       </defs>
                                    </svg>
                                 </div>
                                 <div className="col-lg-7 mir-paragraph-rg-l3-l7 text-secondary-800">
                                    Absence of a finance and customer engagement platform for the sales and accounting departments to support business expansion.
                                 </div>
                              </div>
                              <div className="row points">
                                 <div className="col-lg-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                       <g clip-path="url(#clip0_4641_34704)">
                                          <path d="M32.5 40H7.5C3.3575 40 0 36.6425 0 32.5V7.5C0 3.3575 3.3575 0 7.5 0H32.5C36.6425 0 40 3.3575 40 7.5V32.5C40 36.6425 36.6425 40 32.5 40Z" fill="#FEEFEF" />
                                          <path d="M20 10C14.4837 10 10 14.4837 10 20C10 25.5163 14.4837 30 20 30C25.5163 30 30 25.5163 30 20C30 14.4837 25.5163 10 20 10ZM14.1663 17.5588C14.1663 16.6337 14.9163 15.8925 15.8325 15.8925C16.7488 15.8925 17.4987 16.6337 17.4987 17.5588C17.4987 18.475 16.7488 19.225 15.8325 19.225C14.9163 19.225 14.1663 18.475 14.1663 17.5588ZM24.7563 25.6188C24.5938 25.7813 24.38 25.8625 24.1675 25.8625C23.9537 25.8625 23.7413 25.7813 23.5788 25.6188C22.6225 24.6625 21.3512 24.1375 20 24.1375C18.6488 24.1375 17.3775 24.6638 16.4225 25.62C16.0975 25.945 15.57 25.945 15.2437 25.62C14.9187 25.295 14.9187 24.7675 15.2437 24.4412C16.5137 23.1712 18.2037 22.4712 20 22.4712C21.7963 22.4712 23.485 23.1712 24.7563 24.4412C25.0813 24.7662 25.0813 25.2938 24.7563 25.6188ZM24.1663 19.225C23.25 19.225 22.5 18.475 22.5 17.5588C22.5 16.6337 23.25 15.8925 24.1663 15.8925C25.0825 15.8925 25.8325 16.6337 25.8325 17.5588C25.8338 18.475 25.0838 19.225 24.1663 19.225Z" fill="#E96767" />
                                       </g>
                                       <defs>
                                          <clipPath id="clip0_4641_34704">
                                             <rect width="40" height="40" fill="white" />
                                          </clipPath>
                                       </defs>
                                    </svg>
                                 </div>
                                 <div className="col-lg-7 mir-paragraph-rg-l3-l7 text-secondary-800">
                                    Inability to operate the business from anywhere and harness the benefits of a cloud-based solution.
                                 </div>
                              </div>
                              <div className="row points">
                                 <div className="col-lg-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                       <g clip-path="url(#clip0_4641_34704)">
                                          <path d="M32.5 40H7.5C3.3575 40 0 36.6425 0 32.5V7.5C0 3.3575 3.3575 0 7.5 0H32.5C36.6425 0 40 3.3575 40 7.5V32.5C40 36.6425 36.6425 40 32.5 40Z" fill="#FEEFEF" />
                                          <path d="M20 10C14.4837 10 10 14.4837 10 20C10 25.5163 14.4837 30 20 30C25.5163 30 30 25.5163 30 20C30 14.4837 25.5163 10 20 10ZM14.1663 17.5588C14.1663 16.6337 14.9163 15.8925 15.8325 15.8925C16.7488 15.8925 17.4987 16.6337 17.4987 17.5588C17.4987 18.475 16.7488 19.225 15.8325 19.225C14.9163 19.225 14.1663 18.475 14.1663 17.5588ZM24.7563 25.6188C24.5938 25.7813 24.38 25.8625 24.1675 25.8625C23.9537 25.8625 23.7413 25.7813 23.5788 25.6188C22.6225 24.6625 21.3512 24.1375 20 24.1375C18.6488 24.1375 17.3775 24.6638 16.4225 25.62C16.0975 25.945 15.57 25.945 15.2437 25.62C14.9187 25.295 14.9187 24.7675 15.2437 24.4412C16.5137 23.1712 18.2037 22.4712 20 22.4712C21.7963 22.4712 23.485 23.1712 24.7563 24.4412C25.0813 24.7662 25.0813 25.2938 24.7563 25.6188ZM24.1663 19.225C23.25 19.225 22.5 18.475 22.5 17.5588C22.5 16.6337 23.25 15.8925 24.1663 15.8925C25.0825 15.8925 25.8325 16.6337 25.8325 17.5588C25.8338 18.475 25.0838 19.225 24.1663 19.225Z" fill="#E96767" />
                                       </g>
                                       <defs>
                                          <clipPath id="clip0_4641_34704">
                                             <rect width="40" height="40" fill="white" />
                                          </clipPath>
                                       </defs>
                                    </svg>
                                 </div>
                                 <div className="col-lg-7 mir-paragraph-rg-l3-l7 text-secondary-800">
                                    Lack of a unified ERP and CRM tool for comprehensive management and tracking of all operational facets.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="sec-1-sub2">
                  <div className="container">
                     <div className="row">
                        <div className="mir-heading-l2-l2 text-shades-100 col-lg-6 offset-lg-3">
                           So, how are companies overcoming these challenges and adjusting to the evolving business landscape?
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section data-section id="section2">
               <div>
                  <div className="sec-1-sub1">
                     <div className="container">
                        <div className="heading">
                           section 1 scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn ionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn ionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnnscetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn ionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn ionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnnscetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn ionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn ionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn scetionnnnscetionnnn scetionnnn scetionnnn scetionnnn
                           scetionnnn
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </BusinessCentralWrapper>
      </>
   );
};

BusinessCentral.propTypes = {};

BusinessCentral.defaultProps = {};

export default BusinessCentral;
