import SectionCards from "./SectionCards";
import SwipeSlider from "./SwipeSlider";
import React from "react";

const EventSection = () => {

    const lunchAndLearnCards = [{
        imageUrl: '/mir-culture/images/lunch_learn/lunch_and_learn_1.png',
        title: 'Lunch & learn with Norvic Chanco',
        link: 'https://www.youtube.com/watch?v=8Y06ayf7Wug&ab_channel=MIRDigitalSolutions'
    }, {
        imageUrl: '/mir-culture/images/lunch_learn/lunch_and_learn_2.png',
        title: 'Lunch & learn with Saurabh Rattan',
        link: 'https://www.youtube.com/watch?v=MKrF_bUvz3Q'
    },];

    const corporateEventCards = [{
        imageUrl: '/mir-culture/images/events/event_1.png',
        title: 'MIR Christmas Party',
        link: 'https://www.youtube.com/watch?v=wH9Cet1OXzw'
    }, {
        imageUrl: '/mir-culture/images/events/event_2.png',
        title: 'Summer Bonding Activities',
        link: 'https://www.youtube.com/watch?v=D590CK7cb0g'
    },];

    const teamBuildingEventCards = [{
        imageUrl: '/mir-culture/images/events/event3.png',
        title: 'MIR Bowling Session',
        link: 'https://www.youtube.com/watch?v=Rif8GvrdlLI'
    }, {
        imageUrl: '/mir-culture/images/events/event4.png',
        title: 'Inflatable Football Session',
        link: 'https://www.youtube.com/watch?v=9Xaj2YlXbx0'
    }, {
        imageUrl: '/mir-culture/images/events/event5.png',
        title: 'Team Building Session',
        link: 'https://www.youtube.com/watch?v=rnA0M8I2qxk'
    }]

    return (
        <section className="events-section">
            <div className="container-fluid p-0">
                <div className="row events-top justify-content-start">
                    <div className="col-12 col-lg-8">
                        <div className="section-intro">
                            <div className="title mir-display-semibold-sm mir-text-neutral-800">We put our people’s
                                growth first
                            </div>
                            <div className="description mir-p-md-regular mir-text-neutral-700">Join a dynamic team where
                                corporate experience meets the traditional cultural richness of diverse backgrounds. Be
                                part of a workplace that values both professional expertise and individual uniqueness.
                            </div>
                        </div>
                    </div>
                </div>
                <div className='section-card-deck'>
                    <SectionCards title="Lunch and learn" cards={lunchAndLearnCards}/>
                    <SectionCards title="Corporate events" cards={corporateEventCards}/>
                </div>
                <section className="section-cards swipe-deck">
                    <div className='row'>
                        <div className='col-12 section-title mir-h5-medium mir-text-neutral-700'>
                            Team building events
                        </div>
                        <div className='col-12'>
                            <div className='section-border'></div>
                        </div>
                        <SwipeSlider cards={teamBuildingEventCards}/>
                    </div>
                </section>

            </div>
        </section>
    );
}

export default EventSection;