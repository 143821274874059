import React from 'react'
import './index.scss'
import { Link } from 'react-router-dom'
import MIRRoutes from '../../../../../lib/routes'

export default function SuccessStory({ image, overline, heading, desc, button, url }) {
  return (
    <section style={{ background: image }}
    className="cloud-solutions success-story-wrapper">
        <div className="container mt-5">
            <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                        <p className="subtitle d-flex align-items-center">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 3H10C9.61189 3 9.2291 3.09036 8.88197 3.26393C8.53483 3.4375 8.23287 3.68951 8 4C7.76713 3.68951 7.46517 3.4375 7.11803 3.26393C6.7709 3.09036 6.38811 3 6 3H2C1.73478 3 1.48043 3.10536 1.29289 3.29289C1.10536 3.48043 1 3.73478 1 4V12C1 12.2652 1.10536 12.5196 1.29289 12.7071C1.48043 12.8946 1.73478 13 2 13H6C6.39782 13 6.77936 13.158 7.06066 13.4393C7.34196 13.7206 7.5 14.1022 7.5 14.5C7.5 14.6326 7.55268 14.7598 7.64645 14.8536C7.74021 14.9473 7.86739 15 8 15C8.13261 15 8.25979 14.9473 8.35355 14.8536C8.44732 14.7598 8.5 14.6326 8.5 14.5C8.5 14.1022 8.65804 13.7206 8.93934 13.4393C9.22064 13.158 9.60218 13 10 13H14C14.2652 13 14.5196 12.8946 14.7071 12.7071C14.8946 12.5196 15 12.2652 15 12V4C15 3.73478 14.8946 3.48043 14.7071 3.29289C14.5196 3.10536 14.2652 3 14 3ZM6 12H2V4H6C6.39782 4 6.77936 4.15804 7.06066 4.43934C7.34196 4.72064 7.5 5.10218 7.5 5.5V12.5C7.06766 12.1747 6.54106 11.9991 6 12ZM14 12H10C9.45893 11.9991 8.93234 12.1747 8.5 12.5V5.5C8.5 5.10218 8.65804 4.72064 8.93934 4.43934C9.22064 4.15804 9.60218 4 10 4H14V12Z" fill="#1D68F0"/>
                            </svg>
                            <span className='ms-2 mir-p-overline-lg'>{overline}</span>
                        </p>
                        <h2 className='mir-h4-semibold tw-max-w-full md:tw-max-w-[591px] !tw-w-auto'>{heading}</h2>
                        <div className="content">
                            <p className='mir-p-md-regular'>
                            {desc}
                            </p>
                        </div>
                        <Link to={url ? url : MIRRoutes.CaseStudies}>
                            <button className="mir-btn-lg-primary">
                                {button}
                            </button>
                        </Link>
                </div>
                <div className="col-12 col-md-6 col-lg-6 right-content">
                    
                </div>
            </div>
        </div>
    </section>
  )
}
