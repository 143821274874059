import React, { useState } from 'react'
import StickyBanner from '../../StickyBanner/StickyBanner'
import SectionTabs from '../Elements/Sections/SectionTabs/SectionTabs'
import Footer from '../../Footer/Footer'
import OverviewSection from '../Elements/Sections/OverviewSection';
import HowHelpSection from '../Elements/Sections/HowHelpSection';
import StepsSlider from '../Elements/StepProcess/StepProcess';
import BookNowModal from '../../CommonElements/BookNowModal';
import './index.scss'
import SuccessStory from '../Elements/Sections/SuccessStory';
import TransformationSection from '../Elements/Sections/TransformationSection';
import ContactSection from '../Elements/Sections/ContactSection';
import { Helmet } from 'react-helmet';
import CaseStudyUrls from '../../../lib/singleCaseStudyUrls';
import { Link } from 'react-router-dom';
import MIRRoutes from '../../../lib/routes';
import SuccessStoriesDynamic from '../../CommonElements/SuccessStoriesDynamic';

export default function BusinessCentralPage() {
    const [showVideo, setShowVideo] = useState(false);
    const [tabs, setTabs] = useState([
        { name: 'Overview', id: 'section-1' },
        { name: 'Introduction', id: 'section-2' },
        { name: 'Service Bundles', id: 'section-3' },
        { name: 'Benefits', id: 'section-4' },
        { name: 'How we help you', id: 'section-5' },
        { name: 'Case studies', id: 'section-6' },
        { name: 'Contact Us', id: 'section-7' },
    ]);

    const successStoriesContent = [
        
        {
            image: "/success-stories-custom/2.png",
            timeToRead: "10",
            desc: "A&A Pharmachem Inc drives operational efficiencies with D365 Business Central and Power BI",
            linkToRead: "/about-us/case-studies/aanda-pharmachem-business-central-power-bi"
        },
        {
            image: "/success-stories-custom/14.png",
            timeToRead: "5",
            desc: "Easy Generator adapts to meet the demands of their expanding operations in the Middle East",
            linkToRead: "/about-us/case-studies/easy-generator"
        },
        {
            image: "/success-stories-custom/9.png",
            timeToRead: "3",
            desc: "MIR finds nearly a 50% saving in Microsoft D365 Licensing Costs for a Top-tier Organization",
            linkToRead: "/about-us/case-studies/saving-in-microsoft-d365-licensing-costs"
        }
    ];

    const [showRegionPopup, setShowRegionPopup] = useState(false);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Microsoft Dynamics 365 Business Central | MIR Digital Solutions</title>
                <meta name="description" content="Take your business to new heights with Dynamics 365 Business Central. Streamline operations, finance, customer service with this powerful cloud-based solution. " />
                <link rel="canonical" href={`${window.location.origin}/cloud-solutions/dynamics-365-business-central-solution`} />
            </Helmet>
            <main>
                <StickyBanner />

                <section className='tw-pt-[120px] tw-pb-[200px] tw-bg-cover tw-bg-no-repeat tw-bg-center' style={{ backgroundImage: 'url(/business/hero-bg.png)' }}>
                    <div className="container mx-auto">
                        <img src="/business/hero-icon.svg" className='tw-mb-[42px]' alt="business-central-logo-image" />
                        <h2 className='mir-text-neutral-100 mir-display-semibold-lg tw-mb-[24px]'>Microsoft Dynamics 365 <br /> Business Central</h2>
                        <h3 className='text-white mir-p-lg-medium tw-mb-[24px]'>Streamline your financials and boost efficiency</h3>
                    </div>
                </section>

                <SectionTabs tabs={tabs} />

                <section data-section id="section-1">
                    <OverviewSection
                        overline={'OVERVIEW'}
                        heading={<>Overcoming business challenges with Cloud Solutions</>}
                        desc={<>
                            In the post-pandemic landscape, businesses are grappling with new obstacles, encompassing labor shortages, evolving workforce expectations, and the detrimental effects of disjointed systems and processes. These challenges include:<br /><br />
                            <div className='tw-mt-[36px]'>
                                <div className='tw-flex tw-items-start tw-justify-start tw-mb-[24px]'>
                                    <img src="/business/sad.svg" alt="sadness-icon" className='tw-mr-[24px]' />
                                    <p className='mir-p-rg-medium tw-max-w-full md:tw-max-w-[385px]'>Absence of a finance and customer engagement platform for the sales and accounting departments to support business expansion.</p>
                                </div>
                                <div className='tw-flex tw-items-start tw-justify-start tw-mb-[24px]'>
                                    <img src="/business/sad.svg" alt="sadness-icon" className='tw-mr-[24px]' />
                                    <p className='mir-p-rg-medium tw-max-w-full md:tw-max-w-[385px]'>Inability to operate the business from anywhere and harness the benefits of a cloud-based solution.</p>
                                </div>
                                <div className='tw-flex tw-items-start tw-justify-start tw-mb-[24px]'>
                                    <img src="/business/sad.svg" alt="sadness-icon" className='tw-mr-[24px]' />
                                    <p className='mir-p-rg-medium tw-max-w-full md:tw-max-w-[385px]'>Lack of a unified ERP and CRM tool for comprehensive management and tracking of all operational facets.</p>
                                </div>
                            </div>
                        </>}
                        bg={'url(/business/overview-bg.png)'}
                    />
                </section>

                <section className='mir-bg-primary-500 tw-py-[80px]'>
                    <div className="container mx-auto text-center">
                        <h5 className='tw-max-w-full mx-auto md:tw-max-w-[608px] mir-h5-semibold mir-text-primary-100'>So, how are companies overcoming these challenges and adjusting to the evolving business landscape?</h5>
                    </div>
                </section>

                <section className='mir-bg-primary-900 tw-pt-[120px] tw-relative tw-overflow-hidden'>
                    <img src="/business/question-bg.png" className='tw-absolute tw-w-full tw-bottom-0 tw-left-0 tw-right-0 tw-z-0' alt="question-bg-image" />
                    <div className="tw-relative tw-z-10">
                        <div className='container mx-auto text-center '>
                            <img src="/business/question-icon.svg" alt="question-icon" className='tw-mb-[16px]' />
                            <h4 className='mir-p-overline-lg mir-text-primary-400 tw-pb-[24px]'>Question to ask your team</h4>
                            <h2 className='mir-text-neutral-100 mx-auto tw-max-w-full md:tw-max-w-[800px] mir-h2-semibold tw-pb-[64px]'>Is your business prepared for rapid change? What actions are you taking in response?</h2>
                        </div>
                        <div className='tw-pb-0 text-center md:tw-pb-[147px]'>
                            {
                                !showVideo && <div className='tw-flex tw-relative tw-max-w-full md:tw-max-w-[1012px] tw-min-h-[220px] tw-max-h-fit md:tw-h-[540px] tw-mx-auto tw-items-center tw-justify-center'>
                                    <img src="/business/video-placeholder.png" className='tw-absolute tw-z-[-10] tw-inset-0 tw-w-full tw-object-cover tw-h-full' alt="business-central-video" />
                                    <img src="/business/play-button.svg" className='tw-w-[32px] tw-h-[32px] md:tw-w-fit md:tw-h-fit tw-cursor-pointer tw-transition hover:tw-scale-90 tw-transform' alt="play-button-icon" onClick={() => { setShowVideo(true) }} />
                                </div>
                            }
                            {showVideo && <iframe className='tw-max-w-full md:tw-max-w-[1012px] mx-auto tw-min-h-[220px] tw-max-h-fit md:tw-h-[540px]' width={'100%'} src="https://www.youtube.com/embed/wVFZVajK2YI?si=1JDnyYMUtgtV6vxW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>}
                        </div>
                    </div>
                </section>

                <section className='mir-bg-neutral-100' data-section id="section-2">
                    <div className='container tw-mx-auto tw-text-center tw-pt-[64px] tw-pb-[96px]'>
                        <img src="/business/hero-icon.svg" alt="sales-icon" className='tw-mb-[52px]' />
                        <h4 className='mir-p-overline-lg text-primary-500 tw-mb-[16px]'>Introducing Microsoft Business Central: A Solution for Streamlining Operations</h4>
                        <h2 className='mir-h3-semibold mir-text-neutral-800 tw-mb-[24px] tw-max-w-[100%] md:tw-max-w-[500px] tw-mx-auto'>What is Microsoft Dynamics 365 Business Central?</h2>
                        <p className='mir-text-neutral-800 tw-mx-auto tw-max-w-[100%] md:tw-max-w-[802px] mir-p-rg-regular'><a href="https://dynamics.microsoft.com/en-ca/business-central/overview/" className='mir-p-rg-medium' target='_blank'>Microsoft Dynamics 365 Business Central</a> is an all-in-one cloud-based solution designed for medium-sized businesses to increase efficiency in every aspect of their operations, from lead acquisition to financial management. Tailored for manufacturing, distribution, retail, and professional services, it conquers challenges like limited flexibility, security concerns, duplicated data, weak financial control, and intricate manual processes.</p>
                    </div>
                </section>
                <section className='mir-bg-neutral-100 tw-pb-[120px]'>
                    <div className="container mx-auto text-center">
                        <img src="/business/intro-bg.png" alt="business-central-image" className='tw-max-w-full md:tw-max-w-[866px] tw-mx-auto' />
                    </div>
                </section>


                <section data-section id='section-3' className='mir-bg-neutral-800 tw-bg-bottom tw-bg-cover tw-bg-no-repeat' style={{ backgroundImage: 'url(/business/service-bundles-bg.png)' }}>
                    <div className='container tw-mx-auto tw-text-center tw-pt-[120px] tw-pb-[236px]'>
                        <h4 className='mir-p-overline-lg text-primary-500 tw-mb-[24px]'>SERVICE BUNDLES</h4>
                        <h2 className='mir-h1-semibold mir-text-neutral-100 tw-mb-[36px] tw-max-w-[100%] md:tw-max-w-[802px] tw-mx-auto'>Dynamics 365 Business <br /> Central packages</h2>
                        <h4 className='mir-text-neutral-100 mir-p-lg-regular tw-mb-[72px] tw-max-w-[100%] md:tw-max-w-[802px] tw-mx-auto'>Here are our Microsoft Dynamics 365 Business Central fixed-priced packages. Take the first step towards success and schedule a meeting today! </h4>
                    </div>
                </section>

                <section className='mir-bg-neutral-100'>
                    <div className='container tw-mx-auto tw-text-center tw-pb-[96px]'>
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[32px] tw-max-w-full md:tw-max-w-[1120px] tw-mx-auto">
                            <div className='tw-bg-white tw-rounded-[8px] tw-shadow-md tw-max-w-full -tw-mt-[148px]'>
                                <div className='tw-p-[36px] tw-text-left' style={{ borderBottom: '1px solid #EEEEF2' }}>
                                    <h3 className='mir-h5-semibold'>Basic Finance <br /> Package</h3>
                                </div>
                                <div className='tw-p-[36px] text-start tw-flex tw-flex-col tw-justify-between tw-items-start' style={{ height: 'calc(100% - 148px)' }}>
                                    <div>
                                        <p className='mir-p-sm-semibold mir-text-neutral-800'>What's included</p>
                                        {
                                            [
                                                'Chart of Accounts', '1 legal entity', 'AR/AP Module Setup', 'Up to 1000 Vendors and Customers',
                                                'Up to 1000 Vendors and Customers', 'Out of Box Reports', 'Last 2 years of Net Change Data Migration',
                                                'Company Information', '1 Localization', 'Payment Terms and Methods'
                                            ].map((item, index) => {
                                                return <div className='tw-flex tw-items-start tw-mb-[18px]' key={`point-item-${index}`}>
                                                    <img src="/business/service-check.svg" alt="service-check-icon" className='tw-mr-[16px] tw-mt-[5px]' />
                                                    <p className='mir-p-rg-regular m-0'>{item}</p>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className='tw-mt-[52px] tw-w-full'>
                                        <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-lg mir-btn-primary tw-w-full'>Schedule a Meeting</button>
                                        <Link to={MIRRoutes.BCBundles}><button className='mir-btn-lg mir-btn-secondary tw-w-full'>Learn More</button></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='tw-bg-white tw-rounded-[8px] tw-shadow-md tw-max-w-full md:-tw-mt-[148px]'>
                                <div className='tw-p-[36px] tw-text-left' style={{ borderBottom: '1px solid #EEEEF2' }}>
                                    <h3 className='mir-h5-semibold'>Basic Inventory <br /> Management</h3>
                                </div>
                                <div className='tw-p-[36px] text-start tw-flex tw-flex-col tw-justify-between tw-items-start' style={{ height: 'calc(100% - 148px)' }}>
                                    <div className='tw-grow tw-h-full'>
                                        <p className='mir-p-sm-semibold mir-text-neutral-800'>What's included</p>
                                        {
                                            [
                                                'Inventory Costing FIFO/Specific Setup', 'Up to 20 basic inventory items/service items',
                                                'Assembly, Kits (assembly Bill of Materials (BOMs), Assemble-To-Stock, Assemble-To-Order',
                                                'Item Tracking Codes', 'Locations', 'Units of Measure (UOM): Base, Purchase and Sales', 'Journals'
                                            ].map((item, index) => {
                                                return <div className='tw-flex tw-items-start tw-mb-[18px]' key={`point-item-${index}`}>
                                                    <img src="/business/service-check.svg" alt="service-check-icon" className='tw-mr-[16px] tw-mt-[5px]' />
                                                    <p className='mir-p-rg-regular m-0'>{item}</p>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className='tw-w-full'>
                                        <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-lg mir-btn-primary tw-w-full'>Schedule a Meeting</button>
                                        <Link to={MIRRoutes.BCBundles}><button className='mir-btn-lg mir-btn-secondary tw-w-full'>Learn More</button></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='tw-bg-white tw-rounded-[8px] tw-shadow-md tw-max-w-full md:-tw-mt-[148px]'>
                                <div className='tw-p-[36px] tw-text-left' style={{ borderBottom: '1px solid #EEEEF2' }}>
                                    <h3 className='mir-h5-semibold tw-mb-[12px]'>Jobs Module Pack</h3>
                                    <div className='tw-flex tw-items-center tw-justify-start'>
                                        <span className='tw-px-[12px] mir-p-xs-medium tw-py-[4px] tw-rounded-full mir-bg-neutral-200'>Required</span>
                                        <span className='tw-px-[12px] mir-p-sm-regular tw-py-[4px]'>Finance Pack/Sales Pack</span>
                                    </div>
                                </div>
                                <div className='tw-p-[36px] text-start tw-flex tw-flex-col tw-justify-between tw-items-start' style={{ height: 'calc(100% - 148px)' }}>
                                    <div>
                                        <p className='mir-p-sm-semibold mir-text-neutral-800'>What's included</p>
                                        {
                                            [
                                                'Job Specific Prices for Resources/Items',
                                                'Job Specific Costs for Resources',
                                                'Resource Group Pricing',
                                                'Resources/Resources Work Types',
                                                'Job and Task level tracking of budget vs Actual Cost and Expected Billable vs Actual Invoiced Sales',
                                                'Usage/consumption for Resources/Items', 'Purchases association to Jobs for Actual Cost visibility'
                                            ].map((item, index) => {
                                                return <div className='tw-flex tw-items-start tw-mb-[18px]' key={`point-item-${index}`}>
                                                    <img src="/business/service-check.svg" alt="service-check-icon" className='tw-mr-[16px] tw-mt-[5px]' />
                                                    <p className='mir-p-rg-regular m-0'>{item}</p>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className='tw-mt-[52px] tw-w-full'>
                                        <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-lg mir-btn-primary tw-w-full'>Schedule a Meeting</button>
                                        <Link to={MIRRoutes.BCBundles}><button className='mir-btn-lg mir-btn-secondary tw-w-full'>Learn More</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='container tw-mx-auto tw-text-left tw-pt-[96px] tw-pb-[96px]'>
                    <div className="tw-w-full tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-3 md:tw-gap-[153px]">
                        <div className='tw-flex tw-items-start tw-justify-center tw-flex-col'>
                            <h2 className='mir-h3-semibold tw-mb-[24px] tw-max-w-[100%] md:tw-max-w-[544px]'>Who would benefit from using Dynamics 365 Business Central?</h2>
                            <p className='mir-text-neutral-700 tw-max-w-[100%] md:tw-max-w-[544px] tw-mb-[36px]'>Business Central is designed specifically for organizations in the areas of manufacturing, distribution, retail trade and professional services.</p>
                            <h3 className='mir-h6-semibold mir-text-neutral-800 tw-max-w-[100%] md:tw-max-w-[425px] tw-mb-[24px]'>This all-in-one system is perfect for those who are met with <span className='mir-text-error-400'>challenges</span> of: </h3>
                            <div>
                                {
                                    [
                                        'Limited extensibility',
                                        'Security issues',
                                        'Duplicate data',
                                        'Inferior financial controls',
                                        'Manual and complex processes',
                                    ].map((item, index) => {
                                        return <div className='tw-mb-[24px] tw-flex tw-items-center tw-justify-start' key={`challenge-item-${index}`}>
                                            <img src="/business/sadness.svg" alt="sadness-icon" className='tw-mr-[16px]' />
                                            <p className='mir-p-md-regular m-0'>{item}</p>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        <div className=''>
                            <img className='tw-w-full' src="/business/enterprise.png" alt="microsoft dynamics 365 business central dashboard screen" />
                        </div>
                    </div>
                </section>

                <div className='tw-pb-[52px]'>
                    <div className="container mx-auto">
                        <div className={`tw-rounded-[12px] tw-px-[36px] tw-py-[36px] md:tw-px-[64px] md:tw-py-[52px] tw-relative mir-bg-primary-500 tw-overflow-hidden`}>
                            <img src="/sales/blue-banner-bg.png" alt="blue-banner-bg" className='tw-absolute tw-z-0 tw-right-0 tw-bottom-0' />
                            <div className='tw-relative tw-z-10 tw-flex tw-justify-between tw-items-center tw-flex-wrap'>
                                <h3 className='tw-max-w-full tw-mb-[32px] md:tw-mb-0 md:tw-max-w-[650px] mir-h5-semibold text-white'>Schedule a consultation with a Microsoft Dynamics 365 Business Central expert</h3>
                                <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-secondary mir-btn-md mir-text-primary-500 mir-bg-white'>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.25 2.5H14.375V1.875C14.375 1.70924 14.3092 1.55027 14.1919 1.43306C14.0747 1.31585 13.9158 1.25 13.75 1.25C13.5842 1.25 13.4253 1.31585 13.3081 1.43306C13.1908 1.55027 13.125 1.70924 13.125 1.875V2.5H6.875V1.875C6.875 1.70924 6.80915 1.55027 6.69194 1.43306C6.57473 1.31585 6.41576 1.25 6.25 1.25C6.08424 1.25 5.92527 1.31585 5.80806 1.43306C5.69085 1.55027 5.625 1.70924 5.625 1.875V2.5H3.75C3.41848 2.5 3.10054 2.6317 2.86612 2.86612C2.6317 3.10054 2.5 3.41848 2.5 3.75V16.25C2.5 16.5815 2.6317 16.8995 2.86612 17.1339C3.10054 17.3683 3.41848 17.5 3.75 17.5H16.25C16.5815 17.5 16.8995 17.3683 17.1339 17.1339C17.3683 16.8995 17.5 16.5815 17.5 16.25V3.75C17.5 3.41848 17.3683 3.10054 17.1339 2.86612C16.8995 2.6317 16.5815 2.5 16.25 2.5ZM5.625 3.75V4.375C5.625 4.54076 5.69085 4.69973 5.80806 4.81694C5.92527 4.93415 6.08424 5 6.25 5C6.41576 5 6.57473 4.93415 6.69194 4.81694C6.80915 4.69973 6.875 4.54076 6.875 4.375V3.75H13.125V4.375C13.125 4.54076 13.1908 4.69973 13.3081 4.81694C13.4253 4.93415 13.5842 5 13.75 5C13.9158 5 14.0747 4.93415 14.1919 4.81694C14.3092 4.69973 14.375 4.54076 14.375 4.375V3.75H16.25V6.25H3.75V3.75H5.625ZM16.25 16.25H3.75V7.5H16.25V16.25Z" fill="#1D68F0" />
                                    </svg>
                                    <span className='tw-ml-[12px] mir-p-rg-regular'>Book Now</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <section data-section id='section-4' className='mir-bg-neutral-100 tw-pt-[96px] tw-pb-[96px]'>
                    <div className='container tw-mx-auto tw-text-left tw-mb-[64px]'>
                        <div className="tw-w-full tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-3 md:tw-gap-[153px]">
                            <div className='tw-flex tw-items-start tw-justify-center tw-flex-col'>
                                <h4 className='mir-text-primary-500 mir-p-overline-lg tw-mb-[24px]'>BENEFITS</h4>
                                <h2 className='mir-h1-semibold tw-mb-[36px] tw-max-w-[100%] md:tw-max-w-[544px]'>Why should you choose <span className='mir-text-primary-500'>Microsoft Dynamics 365 Business Central</span> to streamline your business? </h2>
                                <p className='mir0p-md-regular mir-text-neutral-700 tw-max-w-[100%] md:tw-max-w-[544px] tw-mb-[36px]'>Microsoft Dynamics 365 Business Central helps businesses</p>
                            </div>
                            <div className=''>
                                <img className='tw-w-full' src="/business/benefits-bg.png" alt="dynamics 365 business central sales, customer service, marketing, financials, manufacturing and distribution operation management wheel" />
                            </div>
                        </div>
                    </div>
                    <div className='tw-h-[1px] container mx-auto tw-bg-[#D6DBE0]'></div>
                    <div className="container mx-auto tw-pt-[96px]">
                        {/* grid */}
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-x-[52px] tw-gap-y-[56px]">
                            {
                                [
                                    { icon: '/business/benefits/1.svg', heading: 'Optimize and manage your operations better', desc: 'Adapt faster with connected processes across finance, sales, service, projects and operations ' },
                                    { icon: '/business/benefits/2.svg', heading: 'Integrate with Microsoft products ', desc: 'Work smarter with interoperability with Microsoft Teams, Outlook, Excel, and Word' },
                                    { icon: '/business/benefits/3.svg', heading: 'Access intelligence at your fingertips', desc: 'Perform better using guidance and real-time insights within the flow of work and Power BI' },
                                    { icon: '/business/benefits/4.svg', heading: 'Improve data analysis and financial management', desc: 'Extend using Power Platform or over 2000 purpose-built applications on Microsoft AppSource ' },
                                    { icon: '/business/benefits/5.svg', heading: 'Deliver projects on time and with the right budget ', desc: 'Track client projects with timesheets and reporting while managing your resource levels with planning by capacity ' },
                                    { icon: '/business/benefits/6.svg', heading: 'Optimize your supply chain and warehouse efficiency ', desc: `Built-in intelligence, system-generated suggestions that provides a holistic view of your inventory/storage capacities and a proactive interaction with suppliers. ` },
                                ].map((item) => {
                                    return <div className='tw-text-left'>
                                        <img src={`${item.icon}`} className='tw-mb-[24px]' alt="benefit-icon" />
                                        <h5 className='mir-text-neutral-800 mir-h6-semibold tw-mb-[12px]'>{item.heading}</h5>
                                        <p className='tw-max-w-full md:tw-max-w-[350px] mir-text-neutral-600 mir-p-rg-regular'>{item.desc}</p>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </section>

                <section className="tw-py-[96px]">
                    <div className="container mx-auto text-center">
                        <h2 className='tw-max-w-full md:tw-max-w-[732px] mx-auto mir-h2-semibold mir-text-neutral-800 tw-mb-[96px]'>Step into the future: AI-enhanced business central takes the lead</h2>
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-x-[64px]">
                            <div className='text-start'>
                                <img src="/business/ai-features/1.svg" alt="ai-feature-icon" className='tw-mb-[24px]' />
                                <h5 className='mir-h6-semibold mir-text-neutral-800 tw-mb-[16px]'>Save time and keep your inventory stocked</h5>
                                <p className='mir-p-rg-regular mir-text-neutral-600'>The Sales and Inventory Forecast extension uses AI to predict future sales, preventing stock shortages and simplifying restocking.  </p>
                            </div>
                            <div className='text-start'>
                                <img src="/business/ai-features/2.svg" alt="ai-feature-icon" className='tw-mb-[24px]' />
                                <h5 className='mir-h6-semibold mir-text-neutral-800 tw-mb-[16px]'>Manage receivables for your business’ financial health</h5>
                                <p className='mir-p-rg-regular mir-text-neutral-600'>The Late Payment Prediction feature helps reduce outstanding payments by predicting late payments and allowing for timely adjustments.</p>
                            </div>
                            <div className='text-start'>
                                <img src="/business/ai-features/3.svg" alt="ai-feature-icon" className='tw-mb-[24px]' />
                                <h5 className='mir-h6-semibold mir-text-neutral-800 tw-mb-[16px]'>Enhance decision making and stay in control of your cash flow</h5>
                                <p className='mir-p-rg-regular mir-text-neutral-600'>With Azure AI in Business Central, you can make proactive adjustments to maintain fiscal health, like reducing credit with a cash surplus or securing loans during cash deficits.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='tw-py-[96px] mir-bg-primary-600 tw-relative'>
                    <img src="/supply-chain/light-blue-banner-bg.png" alt="light-banner-bg" className='tw-absolute tw-inset-0 tw-object-cover tw-h-full tw-w-full' />
                    <div className="container mx-auto tw-relative z-10">
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <h3 className='mir-text-neutral-100 mir-h4-semibold tw-max-w-full tw-mb-[36px]'>Elevate your inventory management, receivables, and cash flow control today!Seize the AI advantage and drive your business to new heights.</h3>
                            </div>
                            <div className="col-12 col-md-12 tw-flex tw-justify-start tw-items-center">
                                <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-lg mir-btn-secondary tw-mt-[32px] md:tw-mt-0'>
                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.3727 12.8797L13.6922 11.2305L13.682 11.2258C13.491 11.1441 13.2825 11.1113 13.0756 11.1304C12.8687 11.1495 12.6698 11.2198 12.4969 11.3352C12.4765 11.3486 12.457 11.3632 12.4383 11.3789L10.5367 13C9.33204 12.4148 8.08829 11.1805 7.50313 9.99141L9.12657 8.06094C9.14219 8.04141 9.15704 8.02188 9.1711 8.00078C9.28394 7.82836 9.35239 7.63072 9.37039 7.42545C9.38838 7.22018 9.35534 7.01364 9.27423 6.82422V6.81485L7.62032 3.12813C7.51309 2.88068 7.3287 2.67455 7.09468 2.5405C6.86067 2.40646 6.58958 2.35169 6.32188 2.38438C5.26326 2.52368 4.29155 3.04357 3.58824 3.84695C2.88492 4.65033 2.49809 5.68226 2.50001 6.75C2.50001 12.9531 7.54688 18 13.75 18C14.8177 18.0019 15.8497 17.6151 16.6531 16.9118C17.4564 16.2085 17.9763 15.2367 18.1156 14.1781C18.1484 13.9105 18.0937 13.6395 17.9598 13.4055C17.8259 13.1715 17.62 12.9871 17.3727 12.8797ZM13.75 16.75C11.0987 16.7471 8.55687 15.6926 6.68214 13.8179C4.8074 11.9431 3.7529 9.40128 3.75001 6.75C3.74707 5.9871 4.02192 5.24922 4.52324 4.67415C5.02456 4.09908 5.71806 3.72615 6.47423 3.625C6.47392 3.62812 6.47392 3.63126 6.47423 3.63438L8.11485 7.30625L6.50001 9.23906C6.48362 9.25792 6.46873 9.27804 6.45548 9.29922C6.33791 9.47963 6.26894 9.68734 6.25525 9.90224C6.24157 10.1171 6.28362 10.3319 6.37735 10.5258C7.08516 11.9734 8.54376 13.4211 10.007 14.1281C10.2023 14.221 10.4184 14.2616 10.634 14.246C10.8497 14.2304 11.0576 14.1591 11.2375 14.0391C11.2576 14.0256 11.2769 14.0109 11.2953 13.9953L13.1945 12.375L16.8664 14.0195C16.8664 14.0195 16.8727 14.0195 16.875 14.0195C16.7751 14.7768 16.4027 15.4717 15.8275 15.9742C15.2524 16.4768 14.5138 16.7526 13.75 16.75Z" fill="#1D68F0" />
                                    </svg>
                                    <span className='tw-ml-2'>Contact us Now</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                <div data-section id='section-5'>
                    <HowHelpSection
                        overline={'HOW WE HELP YOU'}
                        heading={<>Why should you choose <span style={{ color: '#1D68F0' }}>MIR Digital Solutions</span> to help streamline your business?</>}
                        desc={<>MIR Digital Solutions has a proven track record of successful implementations and a strong commitment to Microsoft technologies, resulting in unrivaled product knowledge and process improvement expertise. Our experience and expertise have enabled us to develop efficient processes and approaches for cloud solutions, specifically Microsoft Dynamics 365 Business Central. <br /><br />
                            Our specialist team offers custom-tailored implementation of Microsoft Dynamics 365 Business Central for improved resource utilization, visibility, and data management.
                        </>}
                        image={'url(/business/how-help-bg.png) 70% no-repeat'}
                    />
                </div>

                <StepsSlider />

                <section className="process-slider-features" style={{ background: '#FAFAFC', padding: '96px 0' }}>
                    <div className="container">
                        <div className="row features">
                            <div className="col-12 col-md-12 col-lg-4">
                                <div className="feature-item">
                                    <img src="/supply-chain/setting.svg" alt="feature-item-icon" className='mb-4' />
                                    <div style={{ maxWidth: '90%' }}>
                                        <h4 className='mir-h6-semibold'>An end-to-end implementation</h4>
                                        <p className='mir-p-rg-regular'>We provide full-length implementation framework, including assessment, strategy development, systems integration, data migration and training.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-4">
                                <div className="feature-item">
                                    <img src="/supply-chain/monitor.svg" alt="feature-item-icon" className='mb-4' />
                                    <div style={{ maxWidth: '90%' }}>
                                        <h4 className='mir-h6-semibold'>Industry-focused and technology <br /> best practices</h4>
                                        <p className='mir-p-rg-regular'>Our approach involves understanding specific needs of your organization's industry that can help solve the problem.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-4">
                                <div className="feature-item">
                                    <img src="/supply-chain/service.svg" alt="feature-item-icon" className='mb-4' />
                                    <div style={{ maxWidth: '90%' }}>
                                        <h4 className='mir-h6-semibold'>A dedicated 24x7 office support team</h4>
                                        <p className='mir-p-rg-regular'>In the Go-Live phase, our QA specialists will conduct in-depth training sessions for all users. We also provide 24x7 office support for post-implementation issues.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />
                <section className='business-get-in-touch-wrapper'>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-6">
                                <div className="left-content">
                                    <h2 className='mir-h3-semibold' style={{ maxWidth: '550px' }}>
                                        <span style={{ color: "#1D68F0" }}>Get in touch</span>
                                        &nbsp;to transform the way your organization operates with Microsoft Dynamics 365 Business Central
                                    </h2>
                                    <button onClick={(e) => { setShowRegionPopup(true) }}
                                        className='mir-btn-lg mir-bg-primary-500 text-white' style={{ marginTop: '48px', marginBottom: '48px' }}>
                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.3727 12.8797L13.6922 11.2304L13.682 11.2257C13.491 11.144 13.2825 11.1112 13.0756 11.1303C12.8687 11.1494 12.6698 11.2198 12.4969 11.3351C12.4765 11.3486 12.457 11.3632 12.4383 11.3789L10.5367 13C9.33204 12.4148 8.08829 11.1804 7.50313 9.99137L9.12657 8.0609C9.14219 8.04137 9.15704 8.02184 9.1711 8.00075C9.28394 7.82833 9.35239 7.63068 9.37039 7.42541C9.38838 7.22014 9.35534 7.0136 9.27423 6.82418V6.81481L7.62032 3.12809C7.51309 2.88064 7.3287 2.67451 7.09468 2.54046C6.86067 2.40642 6.58958 2.35165 6.32188 2.38434C5.26326 2.52364 4.29155 3.04353 3.58824 3.84691C2.88492 4.65029 2.49809 5.68222 2.50001 6.74996C2.50001 12.9531 7.54688 18 13.75 18C14.8177 18.0019 15.8497 17.6151 16.6531 16.9117C17.4564 16.2084 17.9763 15.2367 18.1156 14.1781C18.1484 13.9105 18.0937 13.6395 17.9598 13.4055C17.8259 13.1715 17.62 12.987 17.3727 12.8797ZM13.75 16.75C11.0987 16.7471 8.55687 15.6926 6.68214 13.8178C4.8074 11.9431 3.7529 9.40124 3.75001 6.74996C3.74707 5.98706 4.02192 5.24918 4.52324 4.67411C5.02456 4.09904 5.71806 3.72611 6.47423 3.62496C6.47392 3.62808 6.47392 3.63122 6.47423 3.63434L8.11485 7.30621L6.50001 9.23903C6.48362 9.25789 6.46873 9.278 6.45548 9.29918C6.33791 9.47959 6.26894 9.6873 6.25525 9.9022C6.24157 10.1171 6.28362 10.3319 6.37735 10.5257C7.08516 11.9734 8.54376 13.4211 10.007 14.1281C10.2023 14.2209 10.4184 14.2615 10.634 14.2459C10.8497 14.2303 11.0576 14.159 11.2375 14.039C11.2576 14.0255 11.2769 14.0109 11.2953 13.9953L13.1945 12.375L16.8664 14.0195C16.8664 14.0195 16.8727 14.0195 16.875 14.0195C16.7751 14.7767 16.4027 15.4716 15.8275 15.9742C15.2524 16.4767 14.5138 16.7525 13.75 16.75Z" fill="white" />
                                        </svg>
                                        <span className='ms-2'>Contact Us Now</span>
                                    </button>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-6">
                                <div className="right-content">
                                    <div className="deliver-item d-flex align-items-start justify-content-start mb-3">
                                        <img src="/project-operations/blue-check.svg" className='me-3' alt="delivering-icon" />
                                        <div>
                                            <h5 className='mir-h6-semibold'>Maximize Cloud Benefits</h5>
                                            <p className='mir-p-rg-regular mt-3' style={{ maxWidth: '480px' }}>Realize the benefits of this new cloud platform with our highly trained and certified consulting and project team, committed to understanding your unique business challenges.</p>
                                        </div>
                                    </div>
                                    <div className="deliver-item d-flex align-items-start justify-content-start mb-3">
                                        <img src="/project-operations/blue-check.svg" className='me-3' alt="delivering-icon" />
                                        <div>
                                            <h5 className='mir-h6-semibold'>Project Management and Expertise</h5>
                                            <p className='mir-p-rg-regular mt-3' style={{ maxWidth: '480px' }}>We offer exceptional project and change management experience, specialized industry and business process expertise, straightforward and honest communication, as well as one-on-one and group training.</p>
                                        </div>
                                    </div>
                                    <div className="deliver-item d-flex align-items-start justify-content-start mb-3">
                                        <img src="/project-operations/blue-check.svg" className='me-3' alt="delivering-icon" />
                                        <div>
                                            <h5 className='mir-h6-semibold'>Ongoing Support</h5>
                                            <p className='mir-p-rg-regular mt-3' style={{ maxWidth: '480px' }}>Our support extends beyond go-live to ensure continued success. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div data-section id='section-6' className='business-succ-stories'>
                    <SuccessStoriesDynamic
                        successStoriesContent={successStoriesContent}
                    />
                </div>

                <TransformationSection
                    heading={<>Maximize your digital transformation with Dynamics 365 next-generation application</>}
                    desc={<>Unlike traditional systems, Microsoft Dynamics 365 is an intelligent and scalable ERP platform of business applications that can work seamlessly together to modernize business operation. With our seasoned expert team, MIR can help you tailor and adapt any functionality to your business requirements.</>}
                    items={[
                        {
                            icon: '/sales/hero-icon.png',
                            heading: <>Dynamics 365 <br /> Sales</>,
                            link: <Link to={MIRRoutes.Sales}>
                                <span className='mir-p-sm-medium me-2'>Learn More</span>
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.3172 7.44254L9.69219 13.0675C9.57491 13.1848 9.41585 13.2507 9.25 13.2507C9.08415 13.2507 8.92509 13.1848 8.80781 13.0675C8.69054 12.9503 8.62465 12.7912 8.62465 12.6253C8.62465 12.4595 8.69054 12.3004 8.80781 12.1832L13.3664 7.62535H1.125C0.95924 7.62535 0.800269 7.5595 0.683058 7.44229C0.565848 7.32508 0.5 7.16611 0.5 7.00035C0.5 6.83459 0.565848 6.67562 0.683058 6.55841C0.800269 6.4412 0.95924 6.37535 1.125 6.37535H13.3664L8.80781 1.81753C8.69054 1.70026 8.62465 1.5412 8.62465 1.37535C8.62465 1.2095 8.69054 1.05044 8.80781 0.93316C8.92509 0.815885 9.08415 0.75 9.25 0.75C9.41585 0.75 9.57491 0.815885 9.69219 0.93316L15.3172 6.55816C15.3753 6.61621 15.4214 6.68514 15.4529 6.76101C15.4843 6.83688 15.5005 6.91821 15.5005 7.00035C15.5005 7.08248 15.4843 7.16381 15.4529 7.23969C15.4214 7.31556 15.3753 7.38449 15.3172 7.44254Z" fill="#1D68F0" />
                                </svg>
                            </Link>
                        },
                        {
                            icon: '/supply-chain/hero-icon.png',
                            heading: <>Dynamics 365 Supply <br />Chain Management</>,
                            link: <Link to={MIRRoutes.SupplyChain}><span className='mir-p-sm-medium me-2'>Learn More</span>
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.3172 7.44254L9.69219 13.0675C9.57491 13.1848 9.41585 13.2507 9.25 13.2507C9.08415 13.2507 8.92509 13.1848 8.80781 13.0675C8.69054 12.9503 8.62465 12.7912 8.62465 12.6253C8.62465 12.4595 8.69054 12.3004 8.80781 12.1832L13.3664 7.62535H1.125C0.95924 7.62535 0.800269 7.5595 0.683058 7.44229C0.565848 7.32508 0.5 7.16611 0.5 7.00035C0.5 6.83459 0.565848 6.67562 0.683058 6.55841C0.800269 6.4412 0.95924 6.37535 1.125 6.37535H13.3664L8.80781 1.81753C8.69054 1.70026 8.62465 1.5412 8.62465 1.37535C8.62465 1.2095 8.69054 1.05044 8.80781 0.93316C8.92509 0.815885 9.08415 0.75 9.25 0.75C9.41585 0.75 9.57491 0.815885 9.69219 0.93316L15.3172 6.55816C15.3753 6.61621 15.4214 6.68514 15.4529 6.76101C15.4843 6.83688 15.5005 6.91821 15.5005 7.00035C15.5005 7.08248 15.4843 7.16381 15.4529 7.23969C15.4214 7.31556 15.3753 7.38449 15.3172 7.44254Z" fill="#1D68F0" />
                                </svg>
                            </Link>
                        },
                        {
                            icon: '/project-operations/hero-icon.png',
                            heading: <>Dynamics Project Operations</>,
                            link: <Link to={MIRRoutes.ProjectOperations}><span className='mir-p-sm-medium me-2'>Learn More</span>
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.3172 7.44254L9.69219 13.0675C9.57491 13.1848 9.41585 13.2507 9.25 13.2507C9.08415 13.2507 8.92509 13.1848 8.80781 13.0675C8.69054 12.9503 8.62465 12.7912 8.62465 12.6253C8.62465 12.4595 8.69054 12.3004 8.80781 12.1832L13.3664 7.62535H1.125C0.95924 7.62535 0.800269 7.5595 0.683058 7.44229C0.565848 7.32508 0.5 7.16611 0.5 7.00035C0.5 6.83459 0.565848 6.67562 0.683058 6.55841C0.800269 6.4412 0.95924 6.37535 1.125 6.37535H13.3664L8.80781 1.81753C8.69054 1.70026 8.62465 1.5412 8.62465 1.37535C8.62465 1.2095 8.69054 1.05044 8.80781 0.93316C8.92509 0.815885 9.08415 0.75 9.25 0.75C9.41585 0.75 9.57491 0.815885 9.69219 0.93316L15.3172 6.55816C15.3753 6.61621 15.4214 6.68514 15.4529 6.76101C15.4843 6.83688 15.5005 6.91821 15.5005 7.00035C15.5005 7.08248 15.4843 7.16381 15.4529 7.23969C15.4214 7.31556 15.3753 7.38449 15.3172 7.44254Z" fill="#1D68F0" />
                                </svg></Link>
                        },
                    ]}
                />

                <div data-section id='section-7'>
                    <ContactSection />
                </div>

                <Footer />

            </main>
        </>
    )
}
