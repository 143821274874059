import React from 'react'
import './index.scss'

export default function BenefitsSection({
    overline, heading, desc,
    items
}) {
  return (
    <section className='cloud-solutions supply-benefits-wrapper'>
        <div className="container">
            <div className="header-content">
                <small className='mir-p-overline-lg'>{overline}</small>
                <h3 className='mir-h1-semibold'>{heading}</h3>
                <p className='mir-p-md-regular'>{desc}</p>
            </div>
        </div>
        <div className="container">
            <div className="row">
                {
                    items.map((item, index) => {
                        return <div className={`benefit-item-wrapper col-12 col-md-6 col-lg-4`}>
                            <div className="benefit-item">
                                { item.icon }
                                <h4 className='mir-h6-semibold'>{item.heading}</h4>
                                <p className='mir-p-rg-regular'>{item.desc}</p>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    </section>
  )
}
