import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import MIRRoutes from '../../../lib/routes'
import BookNowModal from '../../CommonElements/BookNowModal'
import Footer from '../../Footer/Footer';
import { Helmet } from 'react-helmet';

export default function BCBundles() {

    const [showRegionPopup, setShowRegionPopup] = useState(false);

  return (
    <main>

        <Helmet>
            <meta charSet="utf-8" />
            <title>Dynamics 365 Business Central Sales Bundles | MIR Digital Solutions</title>
            <meta name="description" content="Unlock the potential of your small or medium-sized enterprise with our tailored solution, enabling seamless integration and informed decision-making." />
            <link rel="canonical" href={`${window.location.origin}${MIRRoutes.BCBundles}`} />
        </Helmet>
        <section className='tw-pt-[72px] tw-bg-no-repeat tw-bg-cover position-relative mir-bg-primary-100'>
            <img src="/bc-bundles/bg.png" className='tw-absolute tw-object-center tw-top-0 tw-left-0 tw-right-0 tw-h-[85%] tw-w-full tw-object-cover tw-z-0' alt="bc-bundles-bg" />
            <div className="container mx-auto tw-pb-[72px] text-center tw-relative tw-z-10">
                <h1 className='mir-display-semibold-lg text-white mx-auto tw-max-w-full md:tw-max-w-[695px] tw-mb-[24px]'>Dynamics 365 Business Central bundles</h1>
                <h3 className='mir-p-md-regular mir-text-neutral-100 mx-auto tw-max-w-full md:tw-max-w-[695px]'>Discover a tailored solution for small and medium-sized enterprises, enabling seamless integration and informed decision-making.</h3>
            </div>
            <div className="container mx-auto tw-relative tw-z-10">
                <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[32px] tw-max-w-full md:tw-max-w-[1008px] mx-auto  tw-h-full">
                    <div className='tw-group tw-flex tw-flex-col tw-h-full tw-items-start tw-justify-between tw-p-[32px] tw-bg-white tw-rounded-[8px]' style={{ boxShadow: '0px 4px 9px -1px rgba(16, 24, 40, 0.04), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)' }}>
                        <div>
                            <svg width="36" height="36" className='tw-mb-[24px]' viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_7554_29)">
                                    <path d="M18 0C8.07351 0 0 8.07591 0 18C0 27.9241 8.07591 36 18 36C27.9241 36 36 27.9241 36 18C36 8.07591 27.9265 0 18 0ZM17.3791 16.7727H18.6233C20.5356 16.7727 22.0919 18.329 22.0919 20.2413C22.0919 21.9618 20.8526 23.3755 19.2273 23.6656V24.9541C19.2273 25.6312 18.6772 26.1814 18 26.1814C17.3228 26.1814 16.7727 25.6312 16.7727 24.9541V23.7267H15.1354C14.4577 23.7267 13.9081 23.1766 13.9081 22.4994C13.9081 21.8216 14.4577 21.2721 15.1354 21.2721H18.6209C19.1806 21.2721 19.6348 20.8154 19.6348 20.2581C19.6348 19.684 19.1782 19.2273 18.6209 19.2273H17.3767C15.4644 19.2273 13.9081 17.671 13.9081 15.7587C13.9081 14.0382 15.1474 12.6245 16.7727 12.3344V11.0459C16.7727 10.3688 17.3228 9.81862 18 9.81862C18.6772 9.81862 19.2273 10.3688 19.2273 11.0459V12.2733H20.8646C21.5423 12.2733 22.0919 12.8234 22.0919 13.5006C22.0919 14.1784 21.5423 14.7279 20.8646 14.7279H17.3791C16.8194 14.7279 16.3652 15.1846 16.3652 15.7419C16.3628 16.316 16.8194 16.7727 17.3791 16.7727Z" fill="#EEEEF2" className='group-hover:tw-fill-[#1D68F0]'/>
                                </g>
                                <defs>
                                <clipPath id="clip0_7554_29">
                                <rect width="36" height="36" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>
                            <h3 className='mir-h5-semibold tw-mb-[12px] mir-text-neutral-800'>BC Core Finance</h3>
                            <p className='mir-p-rg-regular mir-text-neutral-600'>A standard finance ERP with CRM functionality designed for small and medium companies.</p>
                        </div>
                        <a href={`${MIRRoutes.BCBundles}#bc-core-finance`} className='group-hover:tw-bg-[#1D68F0] mir-btn-lg mir-btn-lg-tertiary tw-w-full'>
                            <span className=''>See details</span>
                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.1505 10.942L12.5255 16.567C12.4082 16.6843 12.2492 16.7502 12.0833 16.7502C11.9175 16.7502 11.7584 16.6843 11.6411 16.567C11.5239 16.4498 11.458 16.2907 11.458 16.1249C11.458 15.959 11.5239 15.7999 11.6411 15.6827L16.1997 11.1249H3.95831C3.79255 11.1249 3.63358 11.059 3.51637 10.9418C3.39916 10.8246 3.33331 10.6656 3.33331 10.4999C3.33331 10.3341 3.39916 10.1751 3.51637 10.0579C3.63358 9.94071 3.79255 9.87486 3.95831 9.87486H16.1997L11.6411 5.31705C11.5239 5.19977 11.458 5.04071 11.458 4.87486C11.458 4.70901 11.5239 4.54995 11.6411 4.43267C11.7584 4.3154 11.9175 4.24951 12.0833 4.24951C12.2492 4.24951 12.4082 4.3154 12.5255 4.43267L18.1505 10.0577C18.2086 10.1157 18.2547 10.1846 18.2862 10.2605C18.3176 10.3364 18.3338 10.4177 18.3338 10.4999C18.3338 10.582 18.3176 10.6633 18.2862 10.7392C18.2547 10.8151 18.2086 10.884 18.1505 10.942Z" fill="white"/>
                            </svg>
                        </a>
                    </div>
                    <div className='tw-group tw-flex tw-flex-col tw-h-full tw-items-start tw-justify-between tw-p-[32px] tw-bg-white tw-rounded-[8px]' style={{ boxShadow: '0px 4px 9px -1px rgba(16, 24, 40, 0.04), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)' }}>
                        <div>
                            <svg width="37" height="36" className='tw-mb-[24px]' viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_7367_5512)">
                                <path d="M17.1659 20.626C17.1659 20.0072 16.6597 19.501 16.0409 19.501H10.4159V24.001C10.4159 24.829 9.74541 25.5017 8.91516 25.5017C8.08716 25.5017 7.41441 24.8312 7.41441 24.001V19.501H1.79166C1.17291 19.501 0.666656 20.0072 0.666656 20.626V34.8752C0.666656 35.494 1.17291 36.0002 1.79166 36.0002H16.0409C16.6597 36.0002 17.1659 35.494 17.1659 34.8752V20.626Z" fill="#D6DBE0" className='group-hover:tw-fill-[#1D68F0]'/>
                                <path d="M36.6667 20.626C36.6667 20.0072 36.1604 19.501 35.5417 19.501H29.9167V24.001C29.9167 24.829 29.2462 25.5017 28.4159 25.5017C27.5879 25.5017 26.9152 24.8312 26.9152 24.001V19.501H21.2902C20.6714 19.501 20.1652 20.0072 20.1652 20.626V34.8752C20.1652 35.494 20.6714 36.0002 21.2902 36.0002H35.5417C36.1604 36.0002 36.6667 35.494 36.6667 34.8752V20.626Z" fill="#D6DBE0" className='group-hover:tw-fill-[#1D68F0]'/>
                                <path d="M26.9174 1.125C26.9174 0.50625 26.4112 0 25.7924 0H20.1674V4.5C20.1674 5.328 19.4969 6.00075 18.6667 6.00075C17.8387 6.00075 17.1659 5.33025 17.1659 4.5V0H11.5409C10.9222 0 10.4159 0.50625 10.4159 1.125V15.3743C10.4159 15.993 10.9222 16.4993 11.5409 16.4993H25.7902C26.4089 16.4993 26.9152 15.993 26.9152 15.3743L26.9174 1.125Z" fill="#EEEEF2" className='group-hover:tw-fill-[#1D68F0]'/>
                                </g>
                                <defs>
                                <clipPath id="clip0_7367_5512">
                                <rect width="36" height="36" fill="white" transform="translate(0.666656)"/>
                                </clipPath>
                                </defs>
                            </svg>
                            <h3 className='mir-h5-semibold tw-mb-[12px] mir-text-neutral-800'>BC Core Inventory</h3>
                            <p className='mir-p-rg-regular mir-text-neutral-600'>A package tailored for businesses dealing with goods and services</p>
                        </div>
                        <a href={`${MIRRoutes.BCBundles}#bc-core-inventory`} className='group-hover:tw-bg-[#1D68F0] mir-btn-lg mir-btn-lg-tertiary tw-w-full'>
                            <span className=''>See details</span>
                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.1505 10.942L12.5255 16.567C12.4082 16.6843 12.2492 16.7502 12.0833 16.7502C11.9175 16.7502 11.7584 16.6843 11.6411 16.567C11.5239 16.4498 11.458 16.2907 11.458 16.1249C11.458 15.959 11.5239 15.7999 11.6411 15.6827L16.1997 11.1249H3.95831C3.79255 11.1249 3.63358 11.059 3.51637 10.9418C3.39916 10.8246 3.33331 10.6656 3.33331 10.4999C3.33331 10.3341 3.39916 10.1751 3.51637 10.0579C3.63358 9.94071 3.79255 9.87486 3.95831 9.87486H16.1997L11.6411 5.31705C11.5239 5.19977 11.458 5.04071 11.458 4.87486C11.458 4.70901 11.5239 4.54995 11.6411 4.43267C11.7584 4.3154 11.9175 4.24951 12.0833 4.24951C12.2492 4.24951 12.4082 4.3154 12.5255 4.43267L18.1505 10.0577C18.2086 10.1157 18.2547 10.1846 18.2862 10.2605C18.3176 10.3364 18.3338 10.4177 18.3338 10.4999C18.3338 10.582 18.3176 10.6633 18.2862 10.7392C18.2547 10.8151 18.2086 10.884 18.1505 10.942Z" fill="white"/>
                            </svg>
                        </a>
                    </div>
                    <div className='tw-group tw-flex tw-flex-col tw-h-full tw-items-start tw-justify-between tw-p-[32px] tw-bg-white tw-rounded-[8px]' style={{ boxShadow: '0px 4px 9px -1px rgba(16, 24, 40, 0.04), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)' }}>
                        <div>
                            <svg width="37" height="36" className='tw-mb-[24px]' viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_7367_5523)">
                                <path d="M22.8334 9.74902C22.0054 9.74902 21.3326 9.07627 21.3326 8.24827V5.99827H15.3319V8.24827C15.3319 9.07627 14.6591 9.74902 13.8311 9.74902C13.0031 9.74902 12.3304 9.07627 12.3304 8.24827V5.99827C12.3304 4.34452 13.6759 2.99902 15.3296 2.99902H21.3304C22.9841 2.99902 24.3296 4.34452 24.3296 5.99827V8.24827C24.3341 9.07852 23.6614 9.74902 22.8334 9.74902Z" fill="#EEEEF2" className='group-hover:tw-fill-[#1D68F0]'/>
                                <path d="M19.3976 23.0692C19.1276 23.1749 18.7384 23.2492 18.3334 23.2492C17.9284 23.2492 17.5391 23.1749 17.1791 23.0399L0.333374 17.4307V28.8764C0.333374 31.1557 2.17837 33.0007 4.45763 33.0007H32.2069C34.4861 33.0007 36.3311 31.1557 36.3311 28.8764V17.4307L19.3976 23.0692Z" fill="#D6DBE0" className='group-hover:tw-fill-[#1D68F0]'/>
                                <path d="M36.3334 11.6255V15.0613L18.6934 20.9405C18.5741 20.9855 18.4526 21.0013 18.3334 21.0013C18.2141 21.0013 18.0926 20.9855 17.9734 20.9405L0.333374 15.059V11.6233C0.333374 9.34402 2.17837 7.49902 4.45762 7.49902H32.2069C34.4884 7.49902 36.3334 9.34402 36.3334 11.6255Z" fill="#D6DBE0" className='group-hover:tw-fill-[#1D68F0]'/>
                                </g>
                                <defs>
                                <clipPath id="clip0_7367_5523">
                                <rect width="36" height="36" fill="white" transform="translate(0.333374)"/>
                                </clipPath>
                                </defs>
                            </svg>
                            <h3 className='mir-h5-semibold tw-mb-[12px] mir-text-neutral-800'>BC Core Jobs</h3>
                            <p className='mir-p-rg-regular mir-text-neutral-600'>A comprehensive jobs package enables you to create, schedule, and manage your project resources.</p>
                        </div>
                        <a href={`${MIRRoutes.BCBundles}#bc-core-jobs`} className='group-hover:tw-bg-[#1D68F0] mir-btn-lg mir-btn-lg-tertiary tw-w-full'>
                            <span className=''>See details</span>
                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.1505 10.942L12.5255 16.567C12.4082 16.6843 12.2492 16.7502 12.0833 16.7502C11.9175 16.7502 11.7584 16.6843 11.6411 16.567C11.5239 16.4498 11.458 16.2907 11.458 16.1249C11.458 15.959 11.5239 15.7999 11.6411 15.6827L16.1997 11.1249H3.95831C3.79255 11.1249 3.63358 11.059 3.51637 10.9418C3.39916 10.8246 3.33331 10.6656 3.33331 10.4999C3.33331 10.3341 3.39916 10.1751 3.51637 10.0579C3.63358 9.94071 3.79255 9.87486 3.95831 9.87486H16.1997L11.6411 5.31705C11.5239 5.19977 11.458 5.04071 11.458 4.87486C11.458 4.70901 11.5239 4.54995 11.6411 4.43267C11.7584 4.3154 11.9175 4.24951 12.0833 4.24951C12.2492 4.24951 12.4082 4.3154 12.5255 4.43267L18.1505 10.0577C18.2086 10.1157 18.2547 10.1846 18.2862 10.2605C18.3176 10.3364 18.3338 10.4177 18.3338 10.4999C18.3338 10.582 18.3176 10.6633 18.2862 10.7392C18.2547 10.8151 18.2086 10.884 18.1505 10.942Z" fill="white"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <section className='tw-pt-[96px] tw-pb-[52px] mir-bg-primary-100'>
            <div className="container mx-auto text-center">
                <h2 className='mir-display-semibold-sm mir-text-neutral-800 tw-mb-[24px]'>Our packages</h2>
                <p className='mir-text-neutral-700 mir-p-md-regular mx-auto tw-max-w-full md:tw-max-w-[656px]'>Our packages encompass various modules that unify essential business functions, including Finance, Sales, Jobs, Human Resources, Field Operations, Manufacturing, and Inventory.</p>
            </div>
        </section>

        <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />

        <section className='tw-pb-[32px] mir-bg-primary-100' id="bc-core-finance">
            <div className="container mx-auto">
                <div className='tw-bg-white tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-[104px] tw-px-[24px] tw-py-[32px] md:tw-px-[72px] md:tw-py-[64px]' style={{ borderTop: '6px solid var(--ui-primary-500, #1D68F0)' }}>
                    <div>
                        <svg className='tw-mb-[24px]' width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_7367_5177)">
                            <path d="M18 0C8.07351 0 0 8.07591 0 18C0 27.9241 8.07591 36 18 36C27.9241 36 36 27.9241 36 18C36 8.07591 27.9265 0 18 0ZM17.3791 16.7727H18.6233C20.5356 16.7727 22.0919 18.329 22.0919 20.2413C22.0919 21.9618 20.8526 23.3755 19.2273 23.6656V24.9541C19.2273 25.6312 18.6772 26.1814 18 26.1814C17.3228 26.1814 16.7727 25.6312 16.7727 24.9541V23.7267H15.1354C14.4577 23.7267 13.9081 23.1766 13.9081 22.4994C13.9081 21.8216 14.4577 21.2721 15.1354 21.2721H18.6209C19.1806 21.2721 19.6348 20.8154 19.6348 20.2581C19.6348 19.684 19.1782 19.2273 18.6209 19.2273H17.3767C15.4644 19.2273 13.9081 17.671 13.9081 15.7587C13.9081 14.0382 15.1474 12.6245 16.7727 12.3344V11.0459C16.7727 10.3688 17.3228 9.81862 18 9.81862C18.6772 9.81862 19.2273 10.3688 19.2273 11.0459V12.2733H20.8646C21.5423 12.2733 22.0919 12.8234 22.0919 13.5006C22.0919 14.1784 21.5423 14.7279 20.8646 14.7279H17.3791C16.8194 14.7279 16.3652 15.1846 16.3652 15.7419C16.3628 16.316 16.8194 16.7727 17.3791 16.7727Z" fill="#1D68F0"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_7367_5177">
                            <rect width="36" height="36" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                        <h3 className='mir-h3-semibold mir-neutral-800 tw-mb-[24px]'>BC Core Finance</h3>
                        <p className='tw-mb-[36px] mir-p-rg-regular mir-text-neutral-600'>
                            Our BC Core Finance package is a standard finance ERP for small and medium companies which provides the ideal starting point for your business needs. In just 6-7 weeks, you can streamline your business operations into a single, efficient solution.  <br /><br />
                            BC Core Finance Package requires a Microsoft Dynamics 365 Business Central Essentials license. MIR is a Microsoft Solutions Partner, and our licensing specialists will ensure that your license investment is efficient and maximized. 
                        </p>
                        <button onClick={() => { setShowRegionPopup(true) }} className='tw-w-full md:tw-w-fit mir-btn-lg mir-btn-lg-primary tw-mb-[16px]'>Schedule a Meeting</button>
                        <h4 className='mir-p-rg-regular'>or <Link to={MIRRoutes.Contact} className='mir-text-primary-500 !tw-underline'>Let us reach out to you</Link></h4>
                    </div>
                    <div>
                        <h5 className='mir-p-sm-semibold tw-mb-[32px]'>Included the following features:</h5>
                        <div>
                            {
                                [
                                    'Chart of Accounts (General ledger)',
                                    '1 legal entity',
                                    'Account payables and Account receivables',
                                    'Support migration for up to 1000 Vendors and 1000 Customers',
                                    'Setup for up to 3 Financial Institutions',
                                    'Sales orders, Sales invoices and Returns',
                                    'Opening balances ',
                                    'Data Migration for the Last 2 Years of Net Change Data',
                                    'Company Information',
                                    'Localization by one location',
                                    'Out of the box role specific Profiles',
                                    'Payment Terms and Methods',
                                    'Dimensions and Combinations',
                                    'Basic out-of-the-box Reports',
                                    'User training',
                                ].map((item, index) => {
                                    return <div className='tw-flex tw-items-center tw-justify-start tw-pt-[16px] first:tw-pt-0 tw-pb-[16px] last:tw-pb-0 tw-border-b-1 last:tw-border-none tw-border-[#EEEEF2]' key={`bc-core-item-${index}`} style={{ borderBottom: index != 14 ? '1px solid #EEEEF2' : 'none' }}>
                                        <svg className='tw-mr-[16px] tw-mt-[5px] tw-min-w-[16px]' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.00001 11.4393L3.03055 8.46928L3.03053 8.46926C2.88983 8.32856 2.69899 8.24951 2.5 8.24951C2.30102 8.24951 2.11018 8.32856 1.96948 8.46926C1.82877 8.60997 1.74973 8.8008 1.74973 8.99979C1.74973 9.19878 1.82877 9.38961 1.96948 9.53032L5.46938 13.0302C5.53903 13.0999 5.62175 13.1553 5.7128 13.193C5.80385 13.2308 5.90144 13.2502 6 13.2502C6.09856 13.2502 6.19616 13.2308 6.28721 13.193C6.37822 13.1553 6.4609 13.1 6.53053 13.0303C6.53056 13.0303 6.5306 13.0303 6.53063 13.0302L14.5305 5.03032C14.6712 4.88961 14.7503 4.69878 14.7503 4.49979C14.7503 4.3008 14.6712 4.10997 14.5305 3.96926C14.3898 3.82856 14.199 3.74951 14 3.74951C13.801 3.74951 13.6102 3.82856 13.4695 3.96926L13.4695 3.96927L6.00001 11.4393Z" fill="#1D68F0" stroke="#1D68F0" stroke-width="0.5"/>
                                        </svg>
                                        <p className='m-0 mir-p-rg-regular mir-text-neutral-800'>{item}</p>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section className='tw-pb-[32px] tw-pt-[32px] mir-bg-primary-100' id="bc-core-inventory">
            <div className="container mx-auto">
                <div className='tw-bg-white tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-[104px] tw-px-[24px] tw-py-[32px] md:tw-px-[72px] md:tw-py-[64px]' style={{ borderTop: '6px solid var(--ui-primary-500, #1D68F0)' }}>
                    <div>
                        <svg className='tw-mb-[24px]' width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_7367_5266)">
                            <path d="M16.4993 20.626C16.4993 20.0072 15.993 19.501 15.3743 19.501H9.74925V24.001C9.74925 24.829 9.07875 25.5017 8.2485 25.5017C7.4205 25.5017 6.74775 24.8312 6.74775 24.001V19.501H1.125C0.50625 19.501 0 20.0072 0 20.626V34.8752C0 35.494 0.50625 36.0002 1.125 36.0002H15.3743C15.993 36.0002 16.4993 35.494 16.4993 34.8752V20.626Z" fill="#1D68F0"/>
                            <path d="M36 20.626C36 20.0072 35.4937 19.501 34.875 19.501H29.25V24.001C29.25 24.829 28.5795 25.5017 27.7492 25.5017C26.9212 25.5017 26.2485 24.8312 26.2485 24.001V19.501H20.6235C20.0047 19.501 19.4985 20.0072 19.4985 20.626V34.8752C19.4985 35.494 20.0047 36.0002 20.6235 36.0002H34.875C35.4937 36.0002 36 35.494 36 34.8752V20.626Z" fill="#1D68F0"/>
                            <path d="M26.2508 1.125C26.2508 0.50625 25.7445 0 25.1258 0H19.5008V4.5C19.5008 5.328 18.8303 6.00075 18 6.00075C17.172 6.00075 16.4993 5.33025 16.4993 4.5V0H10.8743C10.2555 0 9.74927 0.50625 9.74927 1.125V15.3743C9.74927 15.993 10.2555 16.4993 10.8743 16.4993H25.1235C25.7423 16.4993 26.2485 15.993 26.2485 15.3743L26.2508 1.125Z" fill="#8EB8F7"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_7367_5266">
                            <rect width="36" height="36" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>

                        <h3 className='mir-h3-semibold mir-neutral-800 tw-mb-[24px]'>BC Core Inventory</h3>
                        <p className='tw-mb-[36px] mir-p-rg-regular mir-text-neutral-600'>
                            Designed to cater to businesses involved in the procurement, storage, and sale of goods (both tangible and services), our BC Core Inventory package is designed to have you up and running successfully within 3-4 weeks. <br /><br />
                            The BC Core Inventory package requires a Microsoft Dynamics 365 Business Central Essentials license. MIR is a Microsoft Solutions Partner, and our licensing specialists will ensure that your license investment is efficient and maximized. 
                        </p>
                        <button onClick={() => { setShowRegionPopup(true) }} className='tw-w-full md:tw-w-fit mir-btn-lg mir-btn-lg-primary tw-mb-[16px]'>Schedule a Meeting</button>
                        <h4 className='mir-p-rg-regular'>or <Link to={MIRRoutes.Contact} className='mir-text-primary-500 !tw-underline'>Let us reach out to you</Link></h4>
                    </div>
                    <div>
                        <h5 className='mir-p-sm-semibold tw-mb-[32px]'>Included the following features:</h5>
                        <div>
                            {
                                [
                                    'Inventory Costing ',
                                    'Stock and non-stock items (Physical goods and Services) ',
                                    'FIFO Costing ',
                                    'Support for up to 20 basic inventory items/service items ',
                                    'Assembly and Kits (Assembly Bill of Materials (BOMs), Assemble-To-Stock, Assemble-To-Order) ',
                                    'Physical inventory – inventory counting ',
                                    'Item Tracking Codes ',
                                    'Item – Inventory quantity and cost adjustments ',
                                    'Item reclassification – inventory location and other corrections ',
                                    'Locations ',
                                    'Units of Measure (UOM): Base, Purchase, and Sales ',
                                    'Journals',
                                    'User Training',
                                ].map((item, index) => {
                                    return <div className='tw-flex tw-items-center tw-justify-start tw-pt-[16px] first:tw-pt-0 tw-pb-[16px] last:tw-pb-0 tw-border-b-1 last:tw-border-none tw-border-[#EEEEF2]' key={`bc-core-item-${index}`} style={{ borderBottom: index != 12 ? '1px solid #EEEEF2' : 'none' }}>
                                        <svg className='tw-mr-[16px] tw-mt-[5px] tw-min-w-[16px]' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.00001 11.4393L3.03055 8.46928L3.03053 8.46926C2.88983 8.32856 2.69899 8.24951 2.5 8.24951C2.30102 8.24951 2.11018 8.32856 1.96948 8.46926C1.82877 8.60997 1.74973 8.8008 1.74973 8.99979C1.74973 9.19878 1.82877 9.38961 1.96948 9.53032L5.46938 13.0302C5.53903 13.0999 5.62175 13.1553 5.7128 13.193C5.80385 13.2308 5.90144 13.2502 6 13.2502C6.09856 13.2502 6.19616 13.2308 6.28721 13.193C6.37822 13.1553 6.4609 13.1 6.53053 13.0303C6.53056 13.0303 6.5306 13.0303 6.53063 13.0302L14.5305 5.03032C14.6712 4.88961 14.7503 4.69878 14.7503 4.49979C14.7503 4.3008 14.6712 4.10997 14.5305 3.96926C14.3898 3.82856 14.199 3.74951 14 3.74951C13.801 3.74951 13.6102 3.82856 13.4695 3.96926L13.4695 3.96927L6.00001 11.4393Z" fill="#1D68F0" stroke="#1D68F0" stroke-width="0.5"/>
                                        </svg>
                                        <p className='m-0 mir-p-rg-regular mir-text-neutral-800'>{item}</p>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section className='tw-pb-[32px] tw-pt-[32px] mir-bg-primary-100' id="bc-core-jobs">
            <div className="container mx-auto">
                <div className='tw-bg-white tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-[104px] tw-px-[24px] tw-py-[32px] md:tw-px-[72px] md:tw-py-[64px]' style={{ borderTop: '6px solid var(--ui-primary-500, #1D68F0)' }}>
                    <div>
                        <svg className='tw-mb-[24px]' width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_7367_5347)">
                            <path d="M22.5 9.74902C21.672 9.74902 20.9993 9.07627 20.9993 8.24827V5.99827H14.9985V8.24827C14.9985 9.07627 14.3258 9.74902 13.4978 9.74902C12.6698 9.74902 11.997 9.07627 11.997 8.24827V5.99827C11.997 4.34452 13.3425 2.99902 14.9963 2.99902H20.997C22.6508 2.99902 23.9963 4.34452 23.9963 5.99827V8.24827C24.0008 9.07852 23.328 9.74902 22.5 9.74902Z" fill="#8EB8F7"/>
                            <path d="M19.0643 23.0692C18.7943 23.1749 18.405 23.2492 18 23.2492C17.595 23.2492 17.2058 23.1749 16.8458 23.0399L0 17.4307V28.8764C0 31.1557 1.845 33.0007 4.12425 33.0007H31.8735C34.1528 33.0007 35.9978 31.1557 35.9978 28.8764V17.4307L19.0643 23.0692Z" fill="#1D68F0"/>
                            <path d="M36 11.6255V15.0613L18.36 20.9405C18.2408 20.9855 18.1193 21.0013 18 21.0013C17.8808 21.0013 17.7593 20.9855 17.64 20.9405L0 15.059V11.6233C0 9.34402 1.845 7.49902 4.12425 7.49902H31.8735C34.155 7.49902 36 9.34402 36 11.6255Z" fill="#1D68F0"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_7367_5347">
                            <rect width="36" height="36" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>

                        <h3 className='mir-h3-semibold mir-neutral-800 tw-mb-[24px]'>BC Core Jobs</h3>
                        <p className='tw-mb-[36px] mir-p-rg-regular mir-text-neutral-600'>
                            Managing the day-to-day workforce and project resources is made easy with Microsoft Dynamics 365 Business Central. Our jobs package is comprehensive and can get you started in less than 6-7 weeks. You would be able to create jobs, set price tags, group them, budget, create schedules, and much more. <br /><br />
                            The BC Core Inventory package requires a Microsoft Dynamics 365 Business Central Essentials license. MIR is a Microsoft Solutions Partner, and our licensing specialists will ensure that your license investment is efficient and maximized. 
                        </p>
                        <button onClick={() => { setShowRegionPopup(true) }} className='tw-w-full md:tw-w-fit mir-btn-lg mir-btn-lg-primary tw-mb-[16px]'>Schedule a Meeting</button>
                        <h4 className='mir-p-rg-regular'>or <Link to={MIRRoutes.Contact} className='mir-text-primary-500 !tw-underline'>Let us reach out to you</Link></h4>
                    </div>
                    <div>
                        <h5 className='mir-p-sm-semibold tw-mb-[32px]'>Included the following features:</h5>
                        <div>
                            {
                                [
                                    'Job Setup and Management – Training ',
                                    'Job Specific Prices for Resources/Items ',
                                    'Job Specific Costs for Resources ',
                                    'Resource Group Pricing ',
                                    'Resources/Resources Work Types (overtime, travel etc.) ',
                                    'Job and Task level tracking of budget vs Actual Cost and Expected Billable vs Actual Invoiced Sales ',
                                    'Posting usage/consumption for Resources/Items ',
                                    'Ability to associate Purchases to a Job for Actual Cost visibility ',
                                    'Ability to setup billing schedules ',
                                    'Batch creating invoices across all jobs ',
                                    'Creating Invoices/Credit Memos from Job Planning Lines ',
                                    'Batch creating invoices across all jobs ',
                                    'Creating Invoices/Credit Memos from Job Planning Lines ',
                                    'Job G/L Journals ',
                                    'Job Journals ',
                                    'User Training ',
                                ].map((item, index) => {
                                    return <div className='tw-flex tw-items-start tw-justify-start tw-pt-[16px] first:tw-pt-0 tw-pb-[16px] last:tw-pb-0 tw-border-b-1 last:tw-border-none tw-border-[#EEEEF2]' key={`bc-core-item-${index}`} style={{ borderBottom: index != 15 ? '1px solid #EEEEF2' : 'none' }}>
                                        <svg className='tw-mr-[16px] tw-mt-[5px] tw-min-w-[16px]' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.00001 11.4393L3.03055 8.46928L3.03053 8.46926C2.88983 8.32856 2.69899 8.24951 2.5 8.24951C2.30102 8.24951 2.11018 8.32856 1.96948 8.46926C1.82877 8.60997 1.74973 8.8008 1.74973 8.99979C1.74973 9.19878 1.82877 9.38961 1.96948 9.53032L5.46938 13.0302C5.53903 13.0999 5.62175 13.1553 5.7128 13.193C5.80385 13.2308 5.90144 13.2502 6 13.2502C6.09856 13.2502 6.19616 13.2308 6.28721 13.193C6.37822 13.1553 6.4609 13.1 6.53053 13.0303C6.53056 13.0303 6.5306 13.0303 6.53063 13.0302L14.5305 5.03032C14.6712 4.88961 14.7503 4.69878 14.7503 4.49979C14.7503 4.3008 14.6712 4.10997 14.5305 3.96926C14.3898 3.82856 14.199 3.74951 14 3.74951C13.801 3.74951 13.6102 3.82856 13.4695 3.96926L13.4695 3.96927L6.00001 11.4393Z" fill="#1D68F0" stroke="#1D68F0" stroke-width="0.5"/>
                                        </svg>
                                        <p className='m-0 mir-p-rg-regular mir-text-neutral-800'>{item}</p>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section className='tw-py-[96px] mir-bg-primary-100'>
            <div className="container mx-auto text-center">
                <h4 className='tw-uppercase mir-p-overline-lg tw-px-[24px] tw-mb-[24px] tw-py-[8px] mir-bg-primary-500 text-white tw-w-fit mx-auto tw-rounded-full'>A study found that</h4>
                <h2 className='mir-h3-semibold mir-text-neutral-800 tw-mb-[24px] tw-max-w-full md:tw-max-w-[880px] mx-auto '>Users of Microsoft Dynamics 365 Business Central enhanced productivity, reduced operational costs, and improved business agility</h2>
                <p className='m-0 mir-text-neutral-400 mir-h5-medium'>by <span className='mir-h5-semibold'>Nucleus Research</span></p>
                <img src="/bc-bundles/study-bg.png" className='tw-py-[48px] tw-max-w-full md:tw-max-w-[658px] mx-auto ' alt="study-bg" />
                <div className='tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-[64px]'>
                    <div className='text-center'>
                        <h5 className='mir-text-primary-500 tw-mb-[16px] text-center mir-display-bold-lg' style={{ fontWeight: 600, fontSize: '80px' }}>$15</h5>
                        <p className='m-0 mir-p-md-medium tw-max-w-full mx-auto md:tw-max-w-[214px] mir-text-neutral-900'>Savings per invoice</p>
                    </div>
                    <div className='text-center'>
                        <h5 className='mir-text-primary-500 tw-mb-[16px] text-center mir-display-bold-lg' style={{ fontWeight: 600, fontSize: '80px' }}>12hrs</h5>
                        <p className='m-0 mir-p-md-medium tw-max-w-full mx-auto md:tw-max-w-[250px] mir-text-neutral-900'>Saved per user per month</p>
                    </div>
                    <div className='text-center'>
                        <h5 className='mir-text-primary-500 tw-mb-[16px] text-center mir-display-bold-lg' style={{ fontWeight: 600, fontSize: '80px' }}>4x</h5>
                        <p className='m-0 mir-p-md-medium tw-max-w-full mx-auto md:tw-max-w-[214px] mir-text-neutral-900'>Faster new market penetration</p>
                    </div>
                    <div className='text-center'>
                        <h5 className='mir-text-primary-500 tw-mb-[16px] text-center mir-display-bold-lg' style={{ fontWeight: 600, fontSize: '80px' }}>12%</h5>
                        <p className='m-0 mir-p-md-medium tw-max-w-full mx-auto md:tw-max-w-[214px] mir-text-neutral-900'>Increase in project handling productivity</p>
                    </div>
                </div>
            </div>
        </section>

        <section className='tw-pb-[96px] tw-pt-[32px] mir-bg-primary-100'>
            <div className="container mx-auto">
                <div className='tw-bg-white tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[96px] tw-px-[24px] tw-py-[32px] md:tw-px-[72px] md:tw-py-[64px]' style={{ borderTop: '6px solid var(--ui-primary-500, #1D68F0)' }}>
                    <div className='md:tw-col-span-1'>
                        <h3 className='mir-h3-semibold mir-neutral-800 tw-mb-[24px]'>Other Modules</h3>
                        <p className='tw-mb-[36px] mir-p-rg-regular mir-text-neutral-600'>
                            Microsoft Dynamics 365 Business Central is not limited to the 3 modules. It is a comprehensive  application that has multiple other capabilities.
                        </p>
                        <button onClick={() => { setShowRegionPopup(true) }} className='tw-w-full md:tw-w-fit mir-btn-lg mir-btn-lg-primary tw-mb-[16px]'>Schedule a Meeting</button>
                        <h4 className='mir-p-rg-regular'>or <Link to={MIRRoutes.Contact} className='mir-text-primary-500 !tw-underline'>Let us reach out to you</Link></h4>
                    </div>
                    <div className='md:tw-col-span-2'>
                        <h5 className='mir-p-sm-semibold tw-mb-[32px]'>Included the following features:</h5>
                        <div>
                            {
                                [
                                    <span><h5 className='tw-hidden'>Project management</h5><span className='mir-text-primary-500 mir-p-rg-semibold'>Project Management</span> to ensure successful project execution and profitability with planning, resourcing, tracking, costing, billing, accounting, and real-time intelligence. </span>,
                                    <span><h5 className='tw-hidden'>Supply chain management</h5><span className='mir-text-primary-500 mir-p-rg-semibold'>Supply chain management</span> to deliver products on time and adapt to changing business models with visibility across purchasing, manufacturing, inventory, and warehouses. </span>,
                                    <span><h5 className='tw-hidden'>Sales</h5><span className='mir-text-primary-500 mir-p-rg-semibold'>Sales</span> to empower your sales team to take better care of customers by establishing best practices using familiar productivity tools to manage the entire sales process from within Microsoft Outlook. </span>,
                                    <span><h5 className='tw-hidden'>Service Management</h5><span className='mir-text-primary-500 mir-p-rg-semibold'>Service Management</span> to deliver better service experiences with planning, dispatching, and service contract management. </span>,
                                    <span><h5 className='tw-hidden'>Warehouse management</h5><span className='mir-text-primary-500 mir-p-rg-semibold'>Warehouse management</span> to reduce operations costs with efficient warehouse operations that help you ship orders on time and deliver on promised customer outcomes. </span>,
                                    <span><h5 className='tw-hidden'>Manufacturing</h5><span className='mir-text-primary-500 mir-p-rg-semibold'>Manufacturing</span> to enable proactively manufacturing operations to improve throughput, quality & uptime, while reducing costs and delivering on customer expectations.       </span>,
                                ].map((item, index) => {
                                    return <div className='tw-flex tw-items-start tw-justify-start tw-pt-[16px] first:tw-pt-0 tw-pb-[16px] last:tw-pb-0 tw-border-b-1 last:tw-border-none tw-border-[#EEEEF2]' key={`bc-core-item-${index}`} style={{ borderBottom: index != 5 ? '1px solid #EEEEF2' : 'none' }}>
                                        <svg className='tw-mr-[16px] tw-mt-[5px] tw-min-w-[16px]' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.00001 11.4393L3.03055 8.46928L3.03053 8.46926C2.88983 8.32856 2.69899 8.24951 2.5 8.24951C2.30102 8.24951 2.11018 8.32856 1.96948 8.46926C1.82877 8.60997 1.74973 8.8008 1.74973 8.99979C1.74973 9.19878 1.82877 9.38961 1.96948 9.53032L5.46938 13.0302C5.53903 13.0999 5.62175 13.1553 5.7128 13.193C5.80385 13.2308 5.90144 13.2502 6 13.2502C6.09856 13.2502 6.19616 13.2308 6.28721 13.193C6.37822 13.1553 6.4609 13.1 6.53053 13.0303C6.53056 13.0303 6.5306 13.0303 6.53063 13.0302L14.5305 5.03032C14.6712 4.88961 14.7503 4.69878 14.7503 4.49979C14.7503 4.3008 14.6712 4.10997 14.5305 3.96926C14.3898 3.82856 14.199 3.74951 14 3.74951C13.801 3.74951 13.6102 3.82856 13.4695 3.96926L13.4695 3.96927L6.00001 11.4393Z" fill="#1D68F0" stroke="#1D68F0" stroke-width="0.5"/>
                                        </svg>
                                        <div className='m-0 mir-p-rg-regular mir-text-neutral-800'>{item}</div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section className='tw-py-[96px] mir-bg-primary-700 tw-bg-cover tw-bg-no-repeat tw-bg-bottom' style={{ backgroundImage: 'url(/bc-bundles/cta-bg.jpeg)' }}>
            <div className="container mx-auto text-center">
                <h2 className='mx-auto mir-text-neutral-100 mir-h1-semibold tw-mb-[16px]'>Schedule a call with our team now!</h2>
                <p className='mx-auto mir-text-neutral-100 mir-p-rg-medium tw-max-w-full md:tw-max-w-[608px] tw-mb-[52px]'>Our Business Central specialists are available for a free 1-hour consultation to understand your needs and to equip you with the knowledge and power to identify the right business tools for you. </p>
                <div className='mx-auto tw-flex tw-items-center tw-justify-center flex-wrap'>
                    <a className='mir-btn-lg mir-btn-lg-primary tw-w-full md:tw-w-fit tw-mb-[12px] md:tw-mr-[24px]' href='tel:+18664373812'><span className='tw-px-[32px]'>Call us now</span></a>
                    <button onClick={() => { setShowRegionPopup(true) }} className='tw-w-full md:tw-w-fit tw-mb-[12px] mir-btn-lg mir-btn-lg-secondary'>Schedule a Meeting</button>
                </div>
            </div>
        </section>

        <Footer />

    </main>
  )
}
