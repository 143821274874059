import React, { useEffect, useRef, useState } from 'react'
import Slider from "react-slick";
import './QuestionSlider.scss'

export default function QuestionSlider({ questions }) {

    const sliderRef = useRef(null);
    const sliderItemRefs = useRef(null);
    const [currentSlideWidth, setCurrentSlideWidth] = useState(400);
    const [centerPadding, setCenterPadding] = useState('34%');

    useEffect(() => {
        let slides = document.querySelectorAll('.slick-slide.slick-current');
        if(slides){
            let slide = slides[0];
            let width = slide.offsetWidth;
            setCurrentSlideWidth(width + 70)
        }

        // check the width of the screen and based on the that change the centerPadding
        let width = window.screen.width;
        if(width > 1080) setCenterPadding('30%')
        if(width < 1080 && width >= 768) setCenterPadding('20%')
        if(width < 768) setCenterPadding('10%');

    }, [])

    var settings = {
        centerMode: true,
        centerPadding: centerPadding,
        slidesToShow: 1,
        slideToScroll: 1,
        infinite: true,
        speed: 800,
        arrows: false,
        dots: true,
        focusOnSelect: true,

        // responsive: [
            // {
            //     breakpoint: 768,
            //     settings: {
            //         arrows: false,
            //         centerMode: true,
            //         centerPadding: '10%',
            //         slidesToShow: 1,
            //         variableWidth: true
            //     }
            // },
            // {
            //     breakpoint: 480,
            //     settings: {
            //         arrows: false,
            //         centerMode: true,
            //         centerPadding: '2%',
            //         slidesToShow: 1
            //     }
            // }
        // ]
    };

    return (
        <div className=''>
            <div className="question-slider-wrapper">
                <div style={{ maxWidth: '1000px', margin: '0 auto' }}>
                    <h2 className="mir-h2-semibold">Questions to ask your team</h2>
                    <Slider 
                    {...settings} ref={sliderRef}>
                        {
                            questions?.map((item) => {
                                return <div className='tile' style={{ maxWidth: '150px' }}>
                                    <QuestionIcon />
                                    <h5 className='mir-h5-medium text-shades-100'>{item}</h5>
                                </div>
                            })
                        }
                    </Slider>
                </div>
            </div>
            {/* <div className='mir-heading-l4-l1 main-heading'>Questions to ask your team</div>
            <div className='slider-wrapper'>
                <div className="arrow-wrapper d-none d-md-block">
                    <div style={{ width: `${currentSlideWidth}px` }} className='btn-wrapper'>
                        <button className='arrow-btn arrow-btn-prev' onClick={() => { sliderRef.current.slickPrev() }}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" fill="#1D68F0"/>
                            </svg>
                        </button>
                        <button className='arrow-btn arrow-btn-next' onClick={() => { sliderRef.current.slickNext() }}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.11959e-07 9L12.17 9L6.58 14.59L8 16L16 8L8 -6.99382e-07L6.59 1.41L12.17 7L7.86805e-07 7L6.11959e-07 9Z" fill="#1D68F0"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <Slider {...settings} ref={sliderRef}>
                    {
                        items.map((item, i) => {
                            return <div ref={sliderItemRefs} className='customer-slide-item-wrapper'>
                                <div className='customer-slide-item'>
                                    <img src={item.img} alt="customer-success-logo-image" />
                                    <div className="message mir-paragraph-md-l3-l7" style={{ fontSize: '20px' }}>{item.message}</div>
                                    <div className="person">
                                        <h4 className='mir-heading-l1-l1'>{item.name}</h4>
                                        <h6 className='mir-paragraph-rg-l1-l7'>{item.designation}</h6>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </Slider>
            </div> */}
        </div>
    )
}

const QuestionIcon = () => {
    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.99673 0.788576C8.33047 0.789094 6.69557 1.24156 5.26614 2.09777C3.83671 2.95399 2.6663 4.18189 1.87954 5.65069C1.09278 7.11949 0.719161 8.77419 0.798462 10.4385C0.877763 12.1029 1.40702 13.7146 2.32987 15.1019L0.785156 19.2117L5.9578 18.2763C7.20336 18.885 8.57024 19.2047 9.95654 19.2116C11.3428 19.2185 12.7128 18.9123 13.9643 18.316C15.2158 17.7197 16.3167 16.8486 17.1847 15.7677C18.0527 14.6867 18.6655 13.4238 18.9775 12.073C19.2894 10.7223 19.2925 9.31852 18.9864 7.9664C18.6804 6.6143 18.0731 5.3487 17.2098 4.26397C16.3465 3.17925 15.2495 2.30336 14.0006 1.70162C12.7517 1.09986 11.383 0.787757 9.99673 0.788576Z" fill="#4592F7" stroke="#4592F7" stroke-width="1.42857" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M7.87109 7.87429C7.87109 7.45386 7.99577 7.04286 8.22935 6.69328C8.46292 6.3437 8.79492 6.07124 9.18335 5.91036C9.57178 5.74946 9.99919 5.70736 10.4116 5.78938C10.8239 5.8714 11.2027 6.07386 11.5 6.37116C11.7973 6.66844 11.9997 7.04721 12.0817 7.45957C12.1638 7.87193 12.1217 8.29934 11.9608 8.68777C11.7999 9.0762 11.5274 9.4082 11.1779 9.64177C10.8283 9.87536 10.4173 10 9.99684 10V11.4172" stroke="#EEF3FD" stroke-width="1.42857" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M9.99882 14.9603C9.80315 14.9603 9.64453 14.8016 9.64453 14.606C9.64453 14.4103 9.80315 14.2517 9.99882 14.2517" stroke="#EEF3FD" stroke-width="1.42857" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M10 14.9605C10.1957 14.9605 10.3543 14.802 10.3543 14.6062C10.3543 14.4105 10.1957 14.252 10 14.252" stroke="#EEF3FD" stroke-width="1.42857" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
}