import React from 'react'
import './index.scss'

export default function HowHelpSection({ overline, heading, desc, image }) {
  return (
    <div className="cloud-solutions how-help" style={{ background: image}}>
        <div className="container pt-5">
            <div className="row">
                <div className="col-12 col-md-12 col-lg-6">
                        <h4 className="mir-p-overline-lg subtitle">{overline}</h4>
                        <h2 className='mir-h1-semibold'>{heading}</h2>
                        <div className="content">
                            <p className='mir-p-md-regular'>{desc}</p>
                        </div>
                </div>
                <div className="col-12 col-md-12 col-lg-6 right-side">
                    {/* <img src="/supply-chain/help-bg.png" className='w-100' alt="helping-bg" /> */}
                </div>
            </div>
        </div>
    </div>
  )
}
