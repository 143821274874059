import React, { useEffect, useState } from 'react';
import card1 from './images/sec-11-card-1.png';
import card2 from './images/sec-11-card-2.png';
import card3 from './images/sec-11-card-3.png';
import { Link } from 'react-router-dom';
import MIRRoutes from '../../../lib/routes';
import CaseStudiesContent from '../../../lib/caseStudiesContent';

export default function SuccessStories({ heading, hideHeading, hideButton, centerHeading }) {

  return (
    <section className='sec-11'>
        <div className='container'>
            <div className='row'>
                {
                    !hideHeading && <div className='col-lg-7 col-sm-12 col-md-6 col-xs-12 col-12' style={{ letterSpacing: "-0.8px" }}>
                        <h2 className='mir-heading-l6-l2 text-shades-200'>{ heading ? heading : <>Explore Our <span className='text-primary-500'>Success Stories</span></> }</h2>
                    </div>
                }
                {
                    centerHeading && <div className='col-lg-12 col-sm-12 col-md-12 col-xs-12 col-12 text-center' style={{ letterSpacing: "-0.8px" }}>
                        <h2 className='mir-heading-l6-l2 text-shades-200'>{ heading ? heading : <>Explore Our <span className='text-primary-500'>Success Stories</span></> }</h2>
                    </div>
                }
                {
                    !hideButton && <div className='col-lg-5 col-sm-12 col-md-6 col-xs-12 col-12 view-all-btn'>
                        <Link to={MIRRoutes.CaseStudies}>
                            <button className='mir-btn-md-tertiary'>View All</button>
                        </Link>
                    </div>
                }
            </div>
            <div className='row'>
                <div className='col-lg-12'>
                    <div className='sec-11-card-group'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12'>
                            <div className='sec-11-card'>
                                <img className='sec-11-card-image' src={card1}>
                                </img>
                                <div className='sec-11-card-details'>
                                case study · 3 min read
                                </div>
                                <div className='sec-11-card-desc mir-heading-l1-l2'>
                                Construction & Mining Giant drives Operational Efficiency with Dynamics 365 Inventory and Warehouse Management
                                </div>
                                <Link to={`/about-us/case-studies/construction-mining-inventory-warehouse-management`}>
                                    <button className='sec-11-card-more mir-btn-md-transparent' style={{backgroundColor: 'transparent'}}>
                                    Read more
                                    <svg style={{ marginLeft: "8px" }} xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                        <path d="M17.3172 10.9425L11.6922 16.5675C11.5749 16.6848 11.4159 16.7507 11.25 16.7507C11.0841 16.7507 10.9251 16.6848 10.8078 16.5675C10.6905 16.4503 10.6247 16.2912 10.6247 16.1253C10.6247 15.9595 10.6905 15.8004 10.8078 15.6832L15.3664 11.1253H3.125C2.95924 11.1253 2.80027 11.0595 2.68306 10.9423C2.56585 10.8251 2.5 10.6661 2.5 10.5003C2.5 10.3346 2.56585 10.1756 2.68306 10.0584C2.80027 9.9412 2.95924 9.87535 3.125 9.87535H15.3664L10.8078 5.31753C10.6905 5.20026 10.6247 5.0412 10.6247 4.87535C10.6247 4.7095 10.6905 4.55044 10.8078 4.43316C10.9251 4.31588 11.0841 4.25 11.25 4.25C11.4159 4.25 11.5749 4.31588 11.6922 4.43316L17.3172 10.0582C17.3753 10.1162 17.4214 10.1851 17.4529 10.261C17.4843 10.3369 17.5005 10.4182 17.5005 10.5003C17.5005 10.5825 17.4843 10.6638 17.4529 10.7397C17.4214 10.8156 17.3753 10.8845 17.3172 10.9425Z" fill="#1D68F0" />
                                    </svg>
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12'>
                            <div className='sec-11-card'>
                                <img className='sec-11-card-image' src={card2}>
                                </img>
                                <div className='sec-11-card-details'>
                                case study · 10 min read
                                </div>
                                <div className='sec-11-card-desc mir-heading-l1-l2'>
                                Leading law firm reduced 300+ hours of manual work with MIR RecruIt Application
                                </div>
                                <Link to={`/about-us/case-studies/law-firm-azure-interview-assessment-tool`}>
                                    <button className='sec-11-card-more mir-btn-md-transparent' style={{backgroundColor: 'transparent'}}>
                                    Read more
                                    <svg style={{ marginLeft: "8px" }} xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                        <path d="M17.3172 10.9425L11.6922 16.5675C11.5749 16.6848 11.4159 16.7507 11.25 16.7507C11.0841 16.7507 10.9251 16.6848 10.8078 16.5675C10.6905 16.4503 10.6247 16.2912 10.6247 16.1253C10.6247 15.9595 10.6905 15.8004 10.8078 15.6832L15.3664 11.1253H3.125C2.95924 11.1253 2.80027 11.0595 2.68306 10.9423C2.56585 10.8251 2.5 10.6661 2.5 10.5003C2.5 10.3346 2.56585 10.1756 2.68306 10.0584C2.80027 9.9412 2.95924 9.87535 3.125 9.87535H15.3664L10.8078 5.31753C10.6905 5.20026 10.6247 5.0412 10.6247 4.87535C10.6247 4.7095 10.6905 4.55044 10.8078 4.43316C10.9251 4.31588 11.0841 4.25 11.25 4.25C11.4159 4.25 11.5749 4.31588 11.6922 4.43316L17.3172 10.0582C17.3753 10.1162 17.4214 10.1851 17.4529 10.261C17.4843 10.3369 17.5005 10.4182 17.5005 10.5003C17.5005 10.5825 17.4843 10.6638 17.4529 10.7397C17.4214 10.8156 17.3753 10.8845 17.3172 10.9425Z" fill="#1D68F0" />
                                    </svg>
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12'>
                            <div className='sec-11-card'>
                                <img className='sec-11-card-image' src={card3}>
                                </img>
                                <div className='sec-11-card-details'>
                                case study · 3 min read
                                </div>
                                <div className='sec-11-card-desc mir-heading-l1-l2'>
                                A&A Pharmachem Inc Drives Operational Efficiencies With D365 Business Central and PowerBI
                                </div>
                                <Link to={`/about-us/case-studies/woodgreen-tax-clinic-services-with-microsoft-power-platform`}>
                                    <button className='sec-11-card-more mir-btn-md-transparent' style={{backgroundColor: 'transparent'}}>
                                    Read more
                                    <svg style={{ marginLeft: "8px" }} xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                        <path d="M17.3172 10.9425L11.6922 16.5675C11.5749 16.6848 11.4159 16.7507 11.25 16.7507C11.0841 16.7507 10.9251 16.6848 10.8078 16.5675C10.6905 16.4503 10.6247 16.2912 10.6247 16.1253C10.6247 15.9595 10.6905 15.8004 10.8078 15.6832L15.3664 11.1253H3.125C2.95924 11.1253 2.80027 11.0595 2.68306 10.9423C2.56585 10.8251 2.5 10.6661 2.5 10.5003C2.5 10.3346 2.56585 10.1756 2.68306 10.0584C2.80027 9.9412 2.95924 9.87535 3.125 9.87535H15.3664L10.8078 5.31753C10.6905 5.20026 10.6247 5.0412 10.6247 4.87535C10.6247 4.7095 10.6905 4.55044 10.8078 4.43316C10.9251 4.31588 11.0841 4.25 11.25 4.25C11.4159 4.25 11.5749 4.31588 11.6922 4.43316L17.3172 10.0582C17.3753 10.1162 17.4214 10.1851 17.4529 10.261C17.4843 10.3369 17.5005 10.4182 17.5005 10.5003C17.5005 10.5825 17.4843 10.6638 17.4529 10.7397C17.4214 10.8156 17.3753 10.8845 17.3172 10.9425Z" fill="#1D68F0" />
                                    </svg>
                                    </button>
                                </Link>
                            </div>
                        </div>

                    </div>
                    </div>
                </div>
            </div>
        </div>
        </section>
  )
}
