import React from "react";
import { ReactComponent as PlayButtonSvg } from "../svg/play-btn.svg";
import {Link} from "react-router-dom";

const OverlayCard = ({ imageUrl, title, link }) => {
    return (
        <a href={link} target="_blank" rel="noopener noreferrer" className="event-card">
            <div className='gradient-overlay-black'>
                <img className='card-image' src={imageUrl} alt={title} />
                <div className="overlay-content d-flex align-items-end justify-content-between w-100">
                    <div className="text-container text-white mb-0 mir-h4-semibold flex-fill text-start">
                        {title}
                    </div>
                    <div className="play-button-container d-flex justify-content-end flex-fill">
                        <PlayButtonSvg />
                    </div>
                </div>
            </div>
        </a>

    );
};

export default OverlayCard;
