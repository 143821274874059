import Modal from 'react-bootstrap/Modal';
import React from 'react'
import { ContactForm } from '../../CloudSolutions/Elements/Sections/ContactSection';

export default function ContactUsModal({ showModal, setShowModal }) {
  return (
    <div>
        <Modal show={showModal} size="lg" onHide={() => { setShowModal(false) }}>
            <Modal.Header closeButton>
               <Modal.Title>Contact Us</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <ContactForm gridLayout={true} />
            </Modal.Body>
         </Modal>
    </div>
  )
}
