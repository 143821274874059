import React, { useEffect, useState } from "react";
import sanityClient from "../../../client";
import imageUrlBuilder from "@sanity/image-url";
import './index.scss'
import { useParams } from 'react-router-dom'
import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";
import BookNowModal from "../../CommonElements/BookNowModal";
import { PortableText } from "@portabletext/react";
import { RichTextComponents } from "../BlockRenderer ";
import Footer from "../../Footer/Footer";
import StickyBanner from "../../StickyBanner/StickyBanner";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source);
}

Date.prototype.toShortFormat = function () {

    const monthNames = ["Jan", "Feb", "Mar", "Apr",
        "May", "Jun", "Jul", "Aug",
        "Sep", "Oct", "Nov", "Dec"];

    const day = this.getDate();

    const monthIndex = this.getMonth();
    const monthName = monthNames[monthIndex];

    const year = this.getFullYear();

    return `${day} ${monthName} ${year}`;
}

export default function ReadBlog() {
    const { random, name } = useParams()
    const [allPostsData, setAllPosts] = useState(null);
    const [showRegionPopup, setShowRegionPopup] = useState(false);

    function postInLinkedin() {
        let url = "https://www.linkedin.com/sharing/share-offsite/?url=" + window.location.href;
        window.open(url, '_blank').focus();
    }
    
    function postInFacebook() {
        // https://www.facebook.com/sharer/sharer.php?u=https://mirdigitalsolutions.com/blogs/20231121/microsoftprojectrescueimplementation
        let url = "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href;
        window.open(url, '_blank').focus();
    }

    useEffect(() => {

        sanityClient
            .fetch(
                `*[_type == "mir-blogs"]| order(_createdAt desc){
                    _id,
                    title,
                    shortDescription,
                    cardImage,
                    cardImageAlternateText,
                    url,
                    content,
                    date,
                    timeToRead,
                    writtenBy,
                    metaTag,
                    pageTitle
                  }`
            )
            .then((data) => {
                data.forEach(element => {
                    var dateEnc = element.date.replaceAll("-", "");
                    if (dateEnc === random && name.trim() === element.url.trim()) {
                        setAllPosts([element]);
                    }
                });

            })
            .catch(console.error);
    }, []);

    return (
        <div>
            <StickyBanner />
            <div className="container">
                <div className="blog-read-container">
                    <div className="row">
                        {allPostsData &&
                            allPostsData.map((post, index) => (
                                <div className="col-lg-10 offset-lg-1">
                                    <Helmet>
                                        <title>{post.pageTitle}</title>
                                        <meta name="description" content={post.metaTag} />
                                        {post.metaTag &&
                                            post.metaTag.map((tag, i) => {
                                                <meta name="description" content="sdfsdfsfdg" />
                                            })
                                        }
                                    </Helmet>
                                    <p className="posted-date mir-paragraph-sm-l2-l7 text-secondary-400 text-center">{post.writtenBy} {post.writtenBy ? '•' : ''} {new Date(post.date).toShortFormat()}</p>
                                    <h1 className="blog-title mir-heading-l6-l1 text-secondary-800 text-center">{post.title}</h1>
                                    <div className="blog-content">
                                        <div className="row" >
                                            {post.content &&
                                                <PortableText value={post.content} components={RichTextComponents} />
                                            }

                                        </div>

                                    </div>
                                    <div className="divider"></div>
                                    <div className="social-media">
                                        <p className="mir-paragraph-sm-l3-l7 text-secondary-400">Share this post</p>
                                        <div style={{ display: "inline-block" }}>
                                            <span className="social-media-buttons" onClick={() => { navigator.clipboard.writeText(window.location.href) }}>
                                                <img src="../../../../blogs/link.png" className="blog-social-icon"></img>
                                            </span>
                                            <span className="social-media-buttons" onClick={() => {postInLinkedin()}}>
                                                <img src="../../../../blogs/linkedin.png" className="blog-social-icon"></img>
                                            </span>
                                            <span className="social-media-buttons" onClick={() => {postInFacebook()}}>
                                                <img src="../../../../blogs/fb.png" className="blog-social-icon"></img>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="schedule-meeting-section">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <h5 className="mir-heading-l2-l2 text-shades-100 contactus-heading-blog">
                                            Start your journey to streamlined operations
                                        </h5>
                                        <button className='mir-btn-sm-primary blog-contact-btn' onClick={(e) => { setShowRegionPopup(true) }}>Schedule now</button>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />

            </div>
            <Footer />
        </div>



    );
}