import React, { useState } from 'react'
import StickyBanner from '../../StickyBanner/StickyBanner'
import Footer from '../../Footer/Footer'
import BookNowModal from '../../CommonElements/BookNowModal'
import SectionTabs from '../../CloudSolutions/Elements/Sections/SectionTabs/SectionTabs';
import HowHelpSection from '../../CloudSolutions/Elements/Sections/HowHelpSection';
import { Banner } from '../../HomeComponent/Banner';

import blakes from '../../HomeComponent/images/blakes.png';
import DMCMining2 from '../../HomeComponent/images/DMCMining2.svg';
import metrolinx from '../../HomeComponent/images/metrolinx.svg';
import Rogers from '../../HomeComponent/images/Rogers_logo.svg';
import transunion from '../../HomeComponent/images/transunion_logo.svg';
import StepsSlider from '../../CloudSolutions/Elements/StepProcess/StepProcess';
import ContactSection from '../../CloudSolutions/Elements/Sections/ContactSection';
import TransformationSection from '../../CloudSolutions/Elements/Sections/TransformationSection';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import MIRRoutes from '../../../lib/routes';
import SuccessStoriesDynamic from '../../CommonElements/SuccessStoriesDynamic';
const images = [
    blakes,
    DMCMining2,
    metrolinx,
    Rogers,
    transunion,
    blakes,
    DMCMining2,
    metrolinx,
    Rogers,
    transunion,
    blakes,
    DMCMining2,
    metrolinx,
    Rogers,
    transunion
].map((image) => ({
    id: crypto.randomUUID(),
    image
}));

export default function ERP() {

    const [tabs, setTabs] = useState([
        { name: 'Overview', id: 'section-1' },
        { name: 'Introduction', id: 'section-2' },
        { name: 'Benefits', id: 'section-3' },
        { name: 'How we can help', id: 'section-4' },
        { name: 'Case studies', id: 'section-5' },
        { name: 'Contact Us', id: 'section-6' },
    ]);

    const successStoriesContent = [
        {
            image: "/success-stories-custom/9.png",
            timeToRead: "3",
            desc: "MIR finds nearly a 50% saving in Microsoft D365 Licensing Costs for a Top-tier Organization",
            linkToRead: "/about-us/case-studies/saving-in-microsoft-d365-licensing-costs"
        },
        {
            image: "/success-stories-custom/5.png",
            timeToRead: "10",
            desc: "Construction & Mining Giant Achieves Procurement Automation & Vendor Management Excellence",
            linkToRead: "/about-us/case-studies/dynamics-365-procurement-automation"
        },
        {
            image: "/success-stories-custom/2.png",
            timeToRead: "5",
            desc: "A&A Pharmachem Inc Drives Operational Efficiencies With D365 Business Central and PowerBI",
            linkToRead: "/about-us/case-studies/aanda-pharmachem-business-central-power-bi"
        }
    ];

    const [showRegionPopup, setShowRegionPopup] = useState(false);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Your Excellent Microsoft ERP Software Partner | MIR Digital Solutions</title>
                <meta name="description" content="Your Excellent Microsoft ERP Software Partner | MIR Digital Solutions" />
                <link rel="canonical" href={`${window.location.origin}/business-solutions/enterprise-resource-planning-erp`} />
            </Helmet>
            <main>
                <StickyBanner />
                <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />
                <section className='tw-pt-[120px] tw-pb-[160px] tw-bg-cover tw-bg-center tw-bg-no-repeat' style={{ backgroundImage: 'url(/supply-chain/bg.svg)' }}>
                    <div className="container mx-auto">
                        <h1 className='tw-mb-[16px] mir-display-semibold-sm mir-text-neutral-800 tw-max-w-full md:tw-max-w-[482px]'>Enterprise Resource Planning Solutions</h1>
                        <h3 className='mir-text-neutral-600 tw-pb-[36px] mir-p-lg-regular m-0'>Streamline, scale, succeed with Microsoft ERP Solutions</h3>
                        <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-lg mir-btn-primary mir-p-rg-medium'>
                            Contact us today
                        </button>
                    </div>
                </section>

                {/* section tabs */}
                <SectionTabs tabs={tabs} />

                <section className='tw-py-[120px]' data-section id='section-1'>
                    <div className="container mx-auto text-center">
                        <h4 className='mir-p-overline-lg mir-text-primary-500 tw-mb-[16px]'>OVERVIEW</h4>
                        <h2 className='mx-auto tw-max-w-full md:tw-max-w-[640px] mir-h1-semibold mir-text-neutral-800 tw-mb-[36px]'>Elevate your business's performance with ERP solutions</h2>
                        <p className='mx-auto mir-p-md-regular tw-max-w-full md:tw-max-w-[830px] mir-text-neutral-800'>
                            As technology expanded, enabling global communication and opportunities, businesses required robust systems to manage their operations effectively across borders. The advent of Materials Requirements Planning (MRP) software marked a significant step forward in automating and optimizing their manufacturing and procurement processes. However, it wasn’t enough. <br /><br />
                            Enterprise Resource Planning (ERP) systems began as solutions to ensure data accuracy in large enterprises, initially focusing on coordinating manufacturing processes. Over time, these systems evolved with advancements in computer hardware and became crucial for modern businesses. It is now essential for businesses, facilitating efficient operations and decision-making in today's interconnected world.
                        </p>
                    </div>
                </section>

                <section className='tw-py-[96px] tw-bg-[#000213] tw-bg-cover tw-bg-no-repeat' style={{ backgroundImage: 'url(/erp/erp-dark-bg.png)' }}>
                    <div className="container mx-auto">
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-[97px]">
                            <div>
                                <h2 className='mir-h1-semibold mir-text-neutral-100 tw-max-w-full md:tw-max-w-[447px]'><span className='mir-text-primary-500'>Three signs</span> <br /> that you need ERP Software</h2>
                            </div>
                            <div>
                                <div className='tw-flex tw-items-start tw-justify-start tw-mb-[56px]'>
                                    <h6 className='mir-h6-semibold tw-mr-[36px] mir-text-primary-400'>01</h6>
                                    <div>
                                        <h5 className='mir-h6-semibold tw-mb-[12px] mir-text-neutral-100'>Outgrowing Basic Tools</h5>
                                        <p className='mir-p-rg-regular  mir-text-neutral-400'>If your current software is hindering your ability to expand in the market and grow globally, it's time for a more flexible ERP system that can support your ambitions.</p>
                                    </div>
                                </div>
                                <div className='tw-flex tw-items-start tw-justify-start tw-mb-[56px]'>
                                    <h6 className='mir-h6-semibold tw-mr-[36px] mir-text-primary-400'>02</h6>
                                    <div>
                                        <h5 className='mir-h6-semibold tw-mb-[12px] mir-text-neutral-100'>Dealing with Disparate Systems</h5>
                                        <p className='mir-p-rg-regular  mir-text-neutral-400'>When your various systems can't effectively communicate with each other, it leads to inefficiencies. If you're tired of trying to patch together incompatible software, it's a sign that you need a cohesive ERP solution. </p>
                                    </div>
                                </div>
                                <div className='tw-flex tw-items-start tw-justify-start tw-mb-[56px]'>
                                    <h6 className='mir-h6-semibold tw-mr-[36px] mir-text-primary-400'>03</h6>
                                    <div>
                                        <h5 className='mir-h6-semibold tw-mb-[12px] mir-text-neutral-100'>Falling Short of Customer Expectations</h5>
                                        <p className='mir-p-rg-regular  mir-text-neutral-400'>If your staff and customers are increasingly mobile, and your current system can't keep up with their needs, it's wise to invest in an ERP system that can provide the necessary tools and meet everyone's expectations, ensuring you remain competitive.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='tw-py-[96px] mir-bg-neutral-100' data-section id='section-2'>
                    <div className="container mx-auto">
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-[32px] md:tw-gap-[129px]">
                            <div>
                                <h4 className='mir-p-overline-lg mir-text-primary-500 tw-text-uppercase tw-mb-[24px]'>Introduction</h4>
                                <h2 className='mir-h1-semibold mir-text-neutral-800 tw-max-w-full md:tw-max-w-[448px]'>What is an Enterprise Resource Planning (ERP) system?</h2>
                            </div>
                            <div>
                                <p className='mir-p-md-regular'>
                                    Enterprise Resource Planning (ERP) is advanced software that helps businesses automate and improve their core operations. It makes things run more efficiently. ERP software connects different parts of a company's work, like finances, supply chains, operations, and more, all on one system.
                                    <br /><br />
                                    In the past, companies used separate systems that couldn't work together well. They couldn't handle big changes or growth. But today's ERP systems are different. They bring everything together in one place. They don't just connect data within the ERP system but also with other tools you use, like e-commerce and customer engagement solutions. This helps you see all your data in one spot, making it easier to make smart decisions.
                                    <br /><br />
                                    <a className='mir-p-md-medium mir-text-primary-500' href="https://dynamics.microsoft.com/en-ca/erp/what-is-erp/" target='_blank'>Modern ERP software also offers flexibility, better security, and is eco-friendly.&nbsp;</a> It can be customized without a lot of complicated code. Most importantly, it helps your business stay strong and adapt to changes. It gives you insights to improve your operations now and in the future.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* blue banner */}
                <div className='mir-bg-neutral-100 tw-pb-[52px]'>
                    <div className="container mx-auto">
                        <div className={`tw-rounded-[12px] tw-px-[36px] tw-py-[36px] md:tw-px-[64px] md:tw-py-[52px] tw-relative mir-bg-primary-500 tw-overflow-hidden`}>
                            <img src="/sales/blue-banner-bg.png" alt="blue-banner-bg" className='tw-absolute tw-z-0 tw-right-0 tw-bottom-0' />
                            <div className='tw-relative tw-z-10 tw-flex tw-justify-between tw-items-center tw-flex-wrap'>
                                <h3 className='tw-max-w-full tw-mb-[32px] md:tw-mb-0 md:tw-max-w-[650px] mir-h5-semibold text-white'>Take the first step towards efficiency and growth by initiating their ERP implementation process</h3>
                                <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-secondary mir-btn-md mir-text-primary-500 mir-bg-white'>
                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.3727 12.8795L13.6922 11.2303L13.682 11.2256C13.491 11.1439 13.2825 11.1111 13.0756 11.1302C12.8687 11.1493 12.6698 11.2197 12.4969 11.335C12.4765 11.3484 12.457 11.3631 12.4383 11.3787L10.5367 12.9998C9.33203 12.4147 8.08828 11.1803 7.50312 9.99125L9.12656 8.06078C9.14219 8.04125 9.15703 8.02172 9.17109 8.00062C9.28393 7.8282 9.35239 7.63056 9.37038 7.42529C9.38837 7.22002 9.35534 7.01348 9.27422 6.82406V6.81469L7.62031 3.12797C7.51308 2.88052 7.32869 2.67438 7.09468 2.54034C6.86066 2.4063 6.58957 2.35153 6.32187 2.38422C5.26326 2.52352 4.29155 3.04341 3.58823 3.84679C2.88491 4.65017 2.49809 5.6821 2.5 6.74984C2.5 12.953 7.54687 17.9998 13.75 17.9998C14.8177 18.0018 15.8497 17.6149 16.653 16.9116C17.4564 16.2083 17.9763 15.2366 18.1156 14.178C18.1484 13.9104 18.0937 13.6393 17.9598 13.4053C17.8259 13.1713 17.62 12.9869 17.3727 12.8795ZM13.75 16.7498C11.0987 16.7469 8.55687 15.6924 6.68213 13.8177C4.80739 11.943 3.75289 9.40112 3.75 6.74984C3.74706 5.98694 4.02191 5.24906 4.52323 4.67399C5.02455 4.09892 5.71805 3.72599 6.47422 3.62484C6.47391 3.62796 6.47391 3.6311 6.47422 3.63422L8.11484 7.30609L6.5 9.2389C6.48361 9.25776 6.46872 9.27788 6.45547 9.29906C6.3379 9.47947 6.26893 9.68718 6.25525 9.90208C6.24156 10.117 6.28362 10.3318 6.37734 10.5256C7.08516 11.9733 8.54375 13.4209 10.007 14.128C10.2023 14.2208 10.4183 14.2614 10.634 14.2458C10.8497 14.2302 11.0576 14.1589 11.2375 14.0389C11.2576 14.0254 11.2769 14.0108 11.2953 13.9952L13.1945 12.3748L16.8664 14.0194C16.8664 14.0194 16.8727 14.0194 16.875 14.0194C16.7751 14.7766 16.4027 15.4715 15.8275 15.9741C15.2524 16.4766 14.5138 16.7524 13.75 16.7498Z" fill="#1D68F0" />
                                    </svg>
                                    <span className='tw-ml-[12px] mir-p-rg-regular'>Contact us now</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <section className='tw-py-[96px] mir-bg-neutral-100' data-section id='section-3'>
                    <div className="container mx-auto">
                        <div className='text-center'>
                            <h4 className='mx-auto mir-p-overline-lg mir-text-primary-500 tw-text-uppercase tw-mb-[16px]'>BENEFITS</h4>
                            <h2 className='mx-auto mir-h1-semibold mir-text-neutral-800 tw-max-w-full md:tw-max-w-[448px] tw-mb-[24px]'>Why is ERP important for business?</h2>
                            <p className='mx-auto mir-p-md-regular mir-text-neutral-700 tw-max-w-full md:tw-max-w-[732px] tw-mb-[80px]'>ERP (Enterprise Resource Planning) plays a pivotal role in enhancing business operations. It integrates processes, systems, and data to provide valuable insights, accelerate performance, and ensure adaptability.</p>
                        </div>
                        <div className='tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[64px]'>
                            <div>
                                <img src="/erp/benefits/1.svg" className='tw-mb-[24px]' alt="benefit-icon" />
                                <h5 className='mir-h6-semibold mir-text-neutral-800 tw-mb-[12px]'>Optimal Performance</h5>
                                <p className='mir-text-neutral-600 mir-p-rg-regular'>AI-driven solutions offer insights for better decision-making, enhancing operational efficiency.</p>
                            </div>
                            <div>
                                <img src="/erp/benefits/2.svg" className='tw-mb-[24px]' alt="benefit-icon" />
                                <h5 className='mir-h6-semibold mir-text-neutral-800 tw-mb-[12px]'>Operational Acceleration</h5>
                                <p className='mir-text-neutral-600 mir-p-rg-regular'>Connecting processes and data boosts visibility and flexibility, enabling quick action and increased value delivery. </p>
                            </div>
                            <div>
                                <img src="/erp/benefits/1.svg" className='tw-mb-[24px]' alt="benefit-icon" />
                                <h5 className='mir-h6-semibold mir-text-neutral-800 tw-mb-[12px]'>Business Agility</h5>
                                <p className='mir-text-neutral-600 mir-p-rg-regular'>ERP solutions are designed to adapt and grow with your business, facilitating proactive preparation and response to operational disruptions or market changes.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='tw-py-[96px]'>
                    <div className="container mx-auto text-center">
                        <h2 className='mx-auto tw-mb-[24px] mir-text-neutral-800 mir-h1-semibold tw-max-w-full md:tw-max-w-[732px]'>What business functions can be optimized with ERP?</h2>
                        <p className='mx-auto mir-p-md-regular mir-text-neutral-700 tw-max-w-full md:tw-max-w-[732px] tw-mb-[80px]'>ERP covers various core functions across organizations, breaking down barriers between front and back offices. Key optimized business functions include</p>
                        <div className='tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[32px]'>
                            <div className='tw-p-[36px] mir-bg-neutral-100 tw-rounded-[8px] text-start'>
                                <img src="/erp/business/1.svg" alt="commerce" className='tw-mb-[24px]' />
                                <h5 className='mir-h6-semibold mir-text-neutral-800 tw-mb-[12px]'>Commerce</h5>
                                <p className='mir-p-rg-regular m-0 mir-text-neutral-600'>ERP provides an omnichannel commerce solution, enhancing customer experiences and increasing productivity</p>
                            </div>
                            <div className='tw-p-[36px] mir-bg-neutral-100 tw-rounded-[8px] text-start'>
                                <img src="/erp/business/2.svg" alt="finance" className='tw-mb-[24px]' />
                                <h5 className='mir-h6-semibold mir-text-neutral-800 tw-mb-[12px]'>Finance</h5>
                                <p className='mir-p-rg-regular m-0 mir-text-neutral-600'>Modern ERP streamlines financial processes, offers real-time insights, and automates tasks, ensuring compliance. </p>
                            </div>
                            <div className='tw-p-[36px] mir-bg-neutral-100 tw-rounded-[8px] text-start'>
                                <img src="/erp/business/3.svg" alt="manufacturing" className='tw-mb-[24px]' />
                                <h5 className='mir-h6-semibold mir-text-neutral-800 tw-mb-[12px]'>Manufacturing</h5>
                                <p className='mir-p-rg-regular m-0 mir-text-neutral-600'>ERP improves communication, automates processes, optimizes project management, cost control, and production planning.</p>
                            </div>
                            <div className='tw-p-[36px] mir-bg-neutral-100 tw-rounded-[8px] text-start'>
                                <img src="/erp/business/4.svg" alt="human resources" className='tw-mb-[24px]' />
                                <h5 className='mir-h6-semibold mir-text-neutral-800 tw-mb-[12px]'>Human Resources</h5>
                                <p className='mir-p-rg-regular m-0 mir-text-neutral-600'>It simplifies employee management, aiding in recruitment, retention, and performance tracking.</p>
                            </div>
                            <div className='tw-p-[36px] md:tw-col-span-2 mir-bg-neutral-100 tw-rounded-[8px] text-start'>
                                <img src="/erp/business/5.svg" alt="Supply Chain" className='tw-mb-[24px]' />
                                <h5 className='mir-h6-semibold mir-text-neutral-800 tw-mb-[12px]'>Supply Chain</h5>
                                <p className='mir-p-rg-regular m-0 mir-text-neutral-600'>Automating manual processes, offering insights through dashboards, business intelligence, and IoT technology, ERP streamlines inventory management and supply chain operations. </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='tw-py-[72px] mir-bg-primary-600 tw-relative'>
                    <img src="/supply-chain/light-blue-banner-bg.png" alt="light-banner-bg" className='tw-absolute tw-inset-0 tw-object-cover tw-h-full tw-w-full' />
                    <div className="container mx-auto tw-relative z-10">
                        <div className="row">
                            <div className="col-12 col-md-9">
                                <h3 className='mir-text-neutral-100 mir-h4-semibold tw-max-w-full md:tw-max-w-[736px] !tw-w-auto tw-mb-[8px]'>Ready to harness the power of efficient ERP software?</h3>
                                <h4 className='mir-text-neutral-300 mir-p-md-regular tw-max-w-full md:tw-max-w-[736px]'>Unify your business processes, boost productivity, and drive growth today!</h4>
                            </div>
                            <div className="col-12 col-md-3 tw-flex tw-justify-start md:tw-justify-end tw-items-center">
                                <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-lg mir-btn-secondary tw-mt-[32px] md:tw-mt-0'>
                                    <span className='tw-mr-2 mir-p-rg-medium'>Get started today</span>
                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.3172 10.9423L11.6922 16.5673C11.5749 16.6846 11.4159 16.7505 11.25 16.7505C11.0841 16.7505 10.9251 16.6846 10.8078 16.5673C10.6905 16.45 10.6247 16.291 10.6247 16.1251C10.6247 15.9593 10.6905 15.8002 10.8078 15.6829L15.3664 11.1251H3.125C2.95924 11.1251 2.80027 11.0593 2.68306 10.942C2.56585 10.8248 2.5 10.6659 2.5 10.5001C2.5 10.3343 2.56585 10.1754 2.68306 10.0582C2.80027 9.94095 2.95924 9.8751 3.125 9.8751H15.3664L10.8078 5.31729C10.6905 5.20002 10.6247 5.04096 10.6247 4.8751C10.6247 4.70925 10.6905 4.55019 10.8078 4.43292C10.9251 4.31564 11.0841 4.24976 11.25 4.24976C11.4159 4.24976 11.5749 4.31564 11.6922 4.43292L17.3172 10.0579C17.3753 10.116 17.4214 10.1849 17.4529 10.2608C17.4843 10.3366 17.5005 10.418 17.5005 10.5001C17.5005 10.5822 17.4843 10.6636 17.4529 10.7394C17.4214 10.8153 17.3753 10.8842 17.3172 10.9423Z" fill="#1D68F0" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                <div className='tw-mb-[64px]' data-section id="section-4">
                    <HowHelpSection
                        overline={'HOW WE HELP YOU'}
                        heading={<>Why should you choose <span style={{ color: '#1D68F0' }}>MIR Digital Solutions</span> to find the right ERP Solution for your business?</>}
                        desc={<>MIR Digital Solutions has a proven track record of successful implementations and a strong commitment to Microsoft technologies, resulting in unrivaled product knowledge and process improvement expertise.<br /><br />
                            <span className='mir-p-md-semibold'>Our experience and expertise have enabled us to develop the ideal ERP software solution through these processes</span></>}
                        image={'url(/erp/help-bg.png) no-repeat'}
                    />
                </div>

                <section>
                    <div className="container mx-auto">
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[32px] tw-pt-[36px] tw-pb-[64px]" style={{ borderTop: '1px solid var(--neutral-200, #EEEEF2)' }}>
                            <div className='tw-flex tw-items-start tw-justify-start'>
                                <h6 className='tw-mr-[16px] mir-p-rg-medium mir-text-primary-500'>01</h6>
                                <h3 className='mir-h5-semibold mir-text-neutral-800'>We do your research</h3>
                            </div>
                            <div className='tw-col-span-2'>
                                <p className='mir-p-rg-regular mir-text-neutral-700 tw-max-w-full md:tw-max-w-[640px]'>We begin with an in-depth examination of your business requirements. Assess your strengths and weaknesses in various processes. Then, expand your research by studying industry trends, competition, and customer expectations. </p>
                            </div>
                        </div>
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[32px] tw-pt-[36px] tw-pb-[64px]" style={{ borderTop: '1px solid var(--neutral-200, #EEEEF2)' }}>
                            <div className='tw-flex tw-items-start tw-justify-start'>
                                <h6 className='tw-mr-[16px] mir-p-rg-medium mir-text-primary-500'>02</h6>
                                <h3 className='mir-h5-semibold mir-text-neutral-800'>We consider your options</h3>
                            </div>
                            <div className='tw-col-span-2'>
                                <p className='mir-p-rg-regular mir-text-neutral-700 tw-max-w-full md:tw-max-w-[640px]'>Once you have a clear understanding of your needs, we delve into ERP technologies that align with them. Evaluate your status, project your future goals, and select an ERP solution that helps you reach those goals.</p>
                            </div>
                        </div>
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[32px] tw-pt-[36px] tw-pb-[64px]" style={{ borderTop: '1px solid var(--neutral-200, #EEEEF2)' }}>
                            <div className='tw-flex tw-items-start tw-justify-start'>
                                <h6 className='tw-mr-[16px] mir-p-rg-medium mir-text-primary-500'>03</h6>
                                <h3 className='mir-h5-semibold mir-text-neutral-800'>We aim to contribute to your company’s long-term growth</h3>
                            </div>
                            <div className='tw-col-span-2'>
                                <p className='mir-p-rg-regular mir-text-neutral-700 tw-max-w-full md:tw-max-w-[640px]'>By streamlining your daily tasks with software the complements your existing businesses processes; from financial management to manufacturing and supply chain management. </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='tw-pb-[24px]'>
                    <div className="container mx-auto tw-bg-[#001d6c] tw-relative md:tw-rounded-[12px]">
                        <img src="/supply-chain/blue-banner-bg.png" className='tw-absolute tw-z-0 tw-inset-0 tw-w-full tw-h-full tw-rounded-[12px]'
                            alt="Streamline data analysis and reporting with Microsoft Power BI" />
                        <div className="tw-py-[52px] tw-px-[30px] md:tw-py-[52px] md:tw-px-[64px] tw-rounded-[12px] tw-flex tw-items-center tw-flex-wrap tw-justify-between tw-relative tw-z-10">
                            <h3 className='tw-mb-[24px] md:tw-mb-0 tw-max-w-full md:tw-max-w-[717px] mir-h5-semibold mir-text-neutral-100'>Partner with MIR Digital Solutions for a proven path to transform your operations and foster long-term growth together</h3>
                            <button className='mir-btn-md mir-btn-primary mir-p-rg-medium' onClick={() => { setShowRegionPopup(true) }}>
                                <svg width="20" height="21" className='tw-mr-2' viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.25 3H14.375V2.375C14.375 2.20924 14.3092 2.05027 14.1919 1.93306C14.0747 1.81585 13.9158 1.75 13.75 1.75C13.5842 1.75 13.4253 1.81585 13.3081 1.93306C13.1908 2.05027 13.125 2.20924 13.125 2.375V3H6.875V2.375C6.875 2.20924 6.80915 2.05027 6.69194 1.93306C6.57473 1.81585 6.41576 1.75 6.25 1.75C6.08424 1.75 5.92527 1.81585 5.80806 1.93306C5.69085 2.05027 5.625 2.20924 5.625 2.375V3H3.75C3.41848 3 3.10054 3.1317 2.86612 3.36612C2.6317 3.60054 2.5 3.91848 2.5 4.25V16.75C2.5 17.0815 2.6317 17.3995 2.86612 17.6339C3.10054 17.8683 3.41848 18 3.75 18H16.25C16.5815 18 16.8995 17.8683 17.1339 17.6339C17.3683 17.3995 17.5 17.0815 17.5 16.75V4.25C17.5 3.91848 17.3683 3.60054 17.1339 3.36612C16.8995 3.1317 16.5815 3 16.25 3ZM5.625 4.25V4.875C5.625 5.04076 5.69085 5.19973 5.80806 5.31694C5.92527 5.43415 6.08424 5.5 6.25 5.5C6.41576 5.5 6.57473 5.43415 6.69194 5.31694C6.80915 5.19973 6.875 5.04076 6.875 4.875V4.25H13.125V4.875C13.125 5.04076 13.1908 5.19973 13.3081 5.31694C13.4253 5.43415 13.5842 5.5 13.75 5.5C13.9158 5.5 14.0747 5.43415 14.1919 5.31694C14.3092 5.19973 14.375 5.04076 14.375 4.875V4.25H16.25V6.75H3.75V4.25H5.625ZM16.25 16.75H3.75V8H16.25V16.75Z" fill="white" />
                                </svg>
                                <span className='tw-mr-[12px]'>Book Now</span>
                            </button>
                        </div>
                    </div>
                </section>

                <section className='tw-pt-[64px] tw-pb-[96px]'>
                    <div className="container mx-auto text-center">
                        <h4 className='tw-mb-[64px] mir-p-overline-lg tw-text-uppercase mir-text-neutral-500'>Trusted by businesses of all sizes, across North America, Middle East and Africa</h4>
                    </div>
                    <Banner images={images} speed={25000} />
                </section>

                <div className="delivering-wrapper tw-py-[96px] mir-bg-neutral-100">
                    <div className="container">
                        <h2 className='mx-auto text-center mir-h3-semibold tw-mb-[62px] tw-max-w-full md:tw-max-w-[536px]'>Elevate your business with our Microsoft Partner expertise</h2>
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-y-[32px] tw-gap-x-[44px]">
                            {
                                [
                                    'An end-to-end digital transformation platform that drives project-based operations from prospect to payment to profit',
                                    'An extensive partner ecosystem and go-to-market channels, including AppSource Marketplace, partner-to partner network, and Microsoft sellers',
                                    'Dynamics 365 extensibility to unite Finance, Human Resources, Sales, Supply Chain Management, Customer Service, Field Service, and more',
                                    'Deep expertise and a rich history with CRM, ERP, HCM, collaboration, productivity, and project management applications',
                                    'Complete cloud vision that encompasses Azure, Office 365, and Dynamics 365',
                                    'Accelerated application development and flexibility with the Power Platform',
                                ].map((item) => {
                                    return <div className="deliver-item d-flex align-items-start justify-content-start">
                                        <img src="/project-operations/blue-check.svg" className='mt-3 me-3' alt="delivering-icon" />
                                        <h3 className='mir-p-rg-regular mt-3' style={{ maxWidth: '450px' }}>{item}</h3>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>

                <section className='tw-pt-[96px]'>
                    <StepsSlider
                        subtitle={'Our specialist team offers custom-tailored implementation of Microsoft Power Apps for crafting personalized applications, bypassing intricate coding.'}
                    />
                </section>
                <section className='tw-pb-[96px]'>
                    <div className="container mx-auto text-center">
                        <h3 className='tw-mb-[36px] tw-max-w-full md:tw-max-w-[544px] mir-h4-semibold mir-text-neutral-800 mx-auto text-center'>Consider ERP in the cloud and gain a competitive edge today</h3>
                        <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-md mir-btn-primary mx-auto'>
                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.75 3H14.875V2.375C14.875 2.20924 14.8092 2.05027 14.6919 1.93306C14.5747 1.81585 14.4158 1.75 14.25 1.75C14.0842 1.75 13.9253 1.81585 13.8081 1.93306C13.6908 2.05027 13.625 2.20924 13.625 2.375V3H7.375V2.375C7.375 2.20924 7.30915 2.05027 7.19194 1.93306C7.07473 1.81585 6.91576 1.75 6.75 1.75C6.58424 1.75 6.42527 1.81585 6.30806 1.93306C6.19085 2.05027 6.125 2.20924 6.125 2.375V3H4.25C3.91848 3 3.60054 3.1317 3.36612 3.36612C3.1317 3.60054 3 3.91848 3 4.25V16.75C3 17.0815 3.1317 17.3995 3.36612 17.6339C3.60054 17.8683 3.91848 18 4.25 18H16.75C17.0815 18 17.3995 17.8683 17.6339 17.6339C17.8683 17.3995 18 17.0815 18 16.75V4.25C18 3.91848 17.8683 3.60054 17.6339 3.36612C17.3995 3.1317 17.0815 3 16.75 3ZM6.125 4.25V4.875C6.125 5.04076 6.19085 5.19973 6.30806 5.31694C6.42527 5.43415 6.58424 5.5 6.75 5.5C6.91576 5.5 7.07473 5.43415 7.19194 5.31694C7.30915 5.19973 7.375 5.04076 7.375 4.875V4.25H13.625V4.875C13.625 5.04076 13.6908 5.19973 13.8081 5.31694C13.9253 5.43415 14.0842 5.5 14.25 5.5C14.4158 5.5 14.5747 5.43415 14.6919 5.31694C14.8092 5.19973 14.875 5.04076 14.875 4.875V4.25H16.75V6.75H4.25V4.25H6.125ZM16.75 16.75H4.25V8H16.75V16.75Z" fill="white" />
                            </svg>
                            <span className='tw-ml-2 mir-p-rg-medium'>Book a meeting</span>
                        </button>
                    </div>
                </section>

                <div style={{ background: '#FAFAFC' }} data-section id='section-5'>
                    <SuccessStoriesDynamic
                        successStoriesContent={successStoriesContent}
                    />
                </div>

                <TransformationSection
                    heading={<>Discover the potential of Modern ERP Software</>}
                    desc={<>Find the ideal ERP solution for your business through our Microsoft Dynamics 365 finance and operations applications—intelligent business solutions designed to drive growth, enable real-time data-driven decisions on a global scale, and digitally transform your business.</>}
                    items={[
                        {
                            icon: '/supply-chain/transformation-icon-1.png',
                            heading: <>Dynamics Business <br /> Central</>,
                            link: <Link to={MIRRoutes.BusinessCentral}><span className='mir-p-sm-medium me-2'>Learn More</span>
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.3172 7.44254L9.69219 13.0675C9.57491 13.1848 9.41585 13.2507 9.25 13.2507C9.08415 13.2507 8.92509 13.1848 8.80781 13.0675C8.69054 12.9503 8.62465 12.7912 8.62465 12.6253C8.62465 12.4595 8.69054 12.3004 8.80781 12.1832L13.3664 7.62535H1.125C0.95924 7.62535 0.800269 7.5595 0.683058 7.44229C0.565848 7.32508 0.5 7.16611 0.5 7.00035C0.5 6.83459 0.565848 6.67562 0.683058 6.55841C0.800269 6.4412 0.95924 6.37535 1.125 6.37535H13.3664L8.80781 1.81753C8.69054 1.70026 8.62465 1.5412 8.62465 1.37535C8.62465 1.2095 8.69054 1.05044 8.80781 0.93316C8.92509 0.815885 9.08415 0.75 9.25 0.75C9.41585 0.75 9.57491 0.815885 9.69219 0.93316L15.3172 6.55816C15.3753 6.61621 15.4214 6.68514 15.4529 6.76101C15.4843 6.83688 15.5005 6.91821 15.5005 7.00035C15.5005 7.08248 15.4843 7.16381 15.4529 7.23969C15.4214 7.31556 15.3753 7.38449 15.3172 7.44254Z" fill="#1D68F0" />
                                </svg></Link>
                        },
                        {
                            icon: '/project-operations/hero-icon.png',
                            heading: <>Dynamics 365 <br /> Project Operations</>,
                            link: <Link to={MIRRoutes.ProjectOperations}><span className='mir-p-sm-medium me-2'>Learn More</span>
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.3172 7.44254L9.69219 13.0675C9.57491 13.1848 9.41585 13.2507 9.25 13.2507C9.08415 13.2507 8.92509 13.1848 8.80781 13.0675C8.69054 12.9503 8.62465 12.7912 8.62465 12.6253C8.62465 12.4595 8.69054 12.3004 8.80781 12.1832L13.3664 7.62535H1.125C0.95924 7.62535 0.800269 7.5595 0.683058 7.44229C0.565848 7.32508 0.5 7.16611 0.5 7.00035C0.5 6.83459 0.565848 6.67562 0.683058 6.55841C0.800269 6.4412 0.95924 6.37535 1.125 6.37535H13.3664L8.80781 1.81753C8.69054 1.70026 8.62465 1.5412 8.62465 1.37535C8.62465 1.2095 8.69054 1.05044 8.80781 0.93316C8.92509 0.815885 9.08415 0.75 9.25 0.75C9.41585 0.75 9.57491 0.815885 9.69219 0.93316L15.3172 6.55816C15.3753 6.61621 15.4214 6.68514 15.4529 6.76101C15.4843 6.83688 15.5005 6.91821 15.5005 7.00035C15.5005 7.08248 15.4843 7.16381 15.4529 7.23969C15.4214 7.31556 15.3753 7.38449 15.3172 7.44254Z" fill="#1D68F0" />
                                </svg></Link>
                        },
                        {
                            icon: '/supply-chain/hero-icon.png',
                            heading: <>Dynamics 365 Supply <br />Chain Management</>,
                            link: <Link to={MIRRoutes.SupplyChain}><span className='mir-p-sm-medium me-2'>Learn More</span>
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.3172 7.44254L9.69219 13.0675C9.57491 13.1848 9.41585 13.2507 9.25 13.2507C9.08415 13.2507 8.92509 13.1848 8.80781 13.0675C8.69054 12.9503 8.62465 12.7912 8.62465 12.6253C8.62465 12.4595 8.69054 12.3004 8.80781 12.1832L13.3664 7.62535H1.125C0.95924 7.62535 0.800269 7.5595 0.683058 7.44229C0.565848 7.32508 0.5 7.16611 0.5 7.00035C0.5 6.83459 0.565848 6.67562 0.683058 6.55841C0.800269 6.4412 0.95924 6.37535 1.125 6.37535H13.3664L8.80781 1.81753C8.69054 1.70026 8.62465 1.5412 8.62465 1.37535C8.62465 1.2095 8.69054 1.05044 8.80781 0.93316C8.92509 0.815885 9.08415 0.75 9.25 0.75C9.41585 0.75 9.57491 0.815885 9.69219 0.93316L15.3172 6.55816C15.3753 6.61621 15.4214 6.68514 15.4529 6.76101C15.4843 6.83688 15.5005 6.91821 15.5005 7.00035C15.5005 7.08248 15.4843 7.16381 15.4529 7.23969C15.4214 7.31556 15.3753 7.38449 15.3172 7.44254Z" fill="#1D68F0" />
                                </svg></Link>
                        },
                    ]}
                />

                <div data-section id='section-6'>
                    <ContactSection />
                </div>

                <Footer />
            </main>
        </>
    )
}