import React from 'react'
import './HeroSection.scss'

export default function HeroSection({ icon, overline, heading }) {

    return (
        <div className="cloud-solutions-wrapper">
            <div className='hero-wrapper'>
                <div className='container'>
                    <div className="content">
                        <img src={icon} alt="supply-chain-management-icon" />
                        <p className='mir-h6-medium mt-4'>{overline}</p>
                        <h1 className='mir-display-semibold-sm tw-max-w-full md:tw-max-w-[626px] mx-auto '>{heading}</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}
