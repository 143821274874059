import React from 'react';
import {Helmet} from "react-helmet";
import StickyBanner from "../StickyBanner/StickyBanner";
import './mir-culture.scss';
import BannerSection from "./components/BannerSection";
import FuelYourPassionSection from "./components/FuelYourPassionSection";
import SuccessSection from "./components/SuccessSection";
import EmployeeTestimonials from "./components/EmployeeTestimonials";
import Departments from "./components/Departments";
import Footer from "../Footer/Footer";
import MapSection from "./components/MapSection";
import TeamSection from "./components/TeamSection";
import WomenEmpowerSection from "./components/WomenEmpowerSection";
import EventSection from "./components/EventSection";


const MIRCulture = () => {


    return (<main className='mir-bg-neutral-100'>

        {/*title and meta details for seo*/}
        <Helmet>
            <meta charSet="utf-8"/>
            <title>Discover MIR Culture: Where Tradition Meets Innovation</title>
            <meta name="description"
                  content="Discover MIR workplace, where corporate experience meets cultural richness. Join a dynamic team that values professional expertise and individual uniqueness."/>
            <link rel="canonical" href={`${window.location.origin}//mir-culture`}/>
        </Helmet>

        {/*sticky banner*/}
        <StickyBanner/>

        {/* Banner Section */}
        <BannerSection/>

        {/* Fuel Your Passion Section */}
        <FuelYourPassionSection/>

        {/* Success Section */}
        <SuccessSection/>

        {/*Employee testimonial section*/}
        <EmployeeTestimonials/>

        {/*Events section*/}
        <EventSection />

        {/*Women Empower section*/}
        <WomenEmpowerSection/>

        {/*Team  section*/}
        <TeamSection/>

        {/*Departments*/}
        <Departments/>

        {/*Map section*/}
        <MapSection/>

        {/*Footer*/}
        <Footer/>

    </main>);
}

export default MIRCulture;