import React from 'react'
import './index.scss'

export default function EfficiencyFeatures({ items }) {
  return (
    <div className="cloud-solutions features-wrapper">
        <div className="container">
            <div className="row features">
                {
                    items.map((item) => {
                        return <div className="col-12 col-md-4">
                            <div className="feature-item">
                                <img src={item.icon} alt="feature-item-icon" />
                                <h4 className='mir-h6-semibold'>{item.heading}</h4>
                                <p className='mir-p-rg-regular'>{item.desc}</p>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    </div>
  )
}
