import React, { useState } from 'react'
import StickyBanner from '../../StickyBanner/StickyBanner';
import HeroSection from '../Elements/Sections/HeroSection/HeroSection';
import SectionTabs from '../Elements/Sections/SectionTabs/SectionTabs';
import OverviewSection from '../Elements/Sections/OverviewSection';
import BlueFluidBanner from '../Elements/Sections/BlueFluidBanner';
import BenefitsSection from '../Elements/Sections/Benefits';
import Footer from '../../Footer/Footer';
import BlueBanner from '../Elements/Sections/BlueBanner';
import HowHelpSection from '../Elements/Sections/HowHelpSection';
import StepsSlider from '../Elements/StepProcess/StepProcess';
import BookNowModal from '../../CommonElements/BookNowModal';
import ContactSection from '../Elements/Sections/ContactSection';
import TransformationSection from '../Elements/Sections/TransformationSection';
import SuccessStories from '../../CommonElements/SuccessStories';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import MIRRoutes from '../../../lib/routes';
import SuccessStoriesDynamic from '../../CommonElements/SuccessStoriesDynamic';

export default function Sales() {

    const [tabs, setTabs] = useState([
        { name: 'Overview', id: 'section-1' },
        { name: 'Introduction', id: 'section-2' },
        { name: 'Benefits', id: 'section-3' },
        { name: 'How we help you', id: 'section-4' },
        { name: 'Case studies', id: 'section-5' },
        { name: 'Contact Us', id: 'section-6' },
    ]);

    const successStoriesContent = [
        {
            image: "/success-stories-custom/12.png",
            timeToRead: "3",
            desc: "Boosting Internal Efficiency: Construction and Mining Leader Integrates Self-Serve Portal With Microsoft Dynamics 365",
            linkToRead: "/about-us/case-studies/self-serve-portal"
        },
        {
            image: "/success-stories-custom/8.png",
            timeToRead: "10",
            desc: "Leading Law Firm Reduced 300+ Hours of Manual Work with MIR Interview Assessment Solution",
            linkToRead: "/about-us/case-studies/law-firm-azure-interview-assessment-tool"
        },
        {
            image: "/success-stories-custom/10.png",
            timeToRead: "5",
            desc: "MIR and Compugen’s partnership transforms WoodGreen Tax Clinic services with Microsoft Power Platform, reducing wait times significantly",
            linkToRead: "/about-us/case-studies/woodgreen-tax-clinic-services-with-microsoft-power-platform"
        }
    ];

    const [showRegionPopup, setShowRegionPopup] = useState(false);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Microsoft Dynamics 365 Sales - CRM Solution | MIR Digital Solutions</title>
                <meta name="description" content="Discover the power of Dynamics 365 Sales with MIR's expert assistance. Unlock the potential to enhance customer relationships, drive revenue and maximize ROI." />
                <link rel="canonical" href={`${window.location.origin}/cloud-solutions/dynamics-365-sales-crm-solution`} />
            </Helmet>
            <main style={{ position: 'relative' }} className='sales-main-wrapper'>

                <StickyBanner />

                <HeroSection
                    icon={'/sales/hero-icon.png'}
                    overline={'Streamline your sales processes, elevate your customer engagement and drive growth'}
                    heading={<span className='tw-max-w-full mx-auto md:tw-max-w-[426px]'>Microsoft Dynamics 365 for Sales</span>}
                />

                {/* section tabs */}
                <SectionTabs tabs={tabs} />

                <section data-section id="section-1">
                    <OverviewSection
                        overline={'OVERVIEW'}
                        heading={<>Overcome modern selling challenges with Dynamics 365 for Sales</>}
                        desc={<>
                            The world of selling has become more challenging due to various roadblocks, including the dispersion of people, increased distractions for both buyers and sellers, and a growing number of stakeholders in B2B deals. Additionally, the post-pandemic era has permanently accelerated the shift towards remote work, making it harder for buyers and sellers to connect. <br /><br />
                            In recent months, the adoption of digital experiences has surged more rapidly than in previous years. About 80% of businesses anticipate a continual shift to digital commerce, where remote guidance and collaboration between sellers and customers are facilitated by seamless, automated, and user-friendly tools.
                        </>}
                        bg={'url(/sales/overview-bg.png)'}
                    />
                </section>

                <section className='container tw-text-center tw-mx-auto tw-pt-[52px] tw-pb-[96px]'>
                    <h4 className='mir-p-overline-lg text-primary-500 tw-mb-[24px]'>CHALLENGES</h4>
                    <h2 className='mir-h3-semibold mir-text-neutral-800 tw-max-w-[542px] tw-mx-auto'>Business challenges with current non-automated platforms</h2>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-[40px] tw-mt-[52px]">
                        {
                            [
                                'Sellers grapple with manual research, remote limitations, and multitasking inefficiency.',
                                'Sales managers lack deal insight and coaching data, undermining forecasts. ',
                                'Biased, error-prone sales forecasting lacks comprehensive data. ',
                                'Fragmented customer data hampers digital buying journey evolution. '
                            ].map((item) => {
                                return <div className='tw-text-left'>
                                    <img src="/sales/challenge-icon.png" alt="challenge-icon" className='tw-pb-[24px]' />
                                    <p className='mir-p-rg-regular mir-text-neutral-700 tw-max-w-[100%] md:tw-max-w-[250px]'>{item}</p>
                                </div>
                            })
                        }
                    </div>
                </section>

                <section className='mir-bg-neutral-100' data-section id="section-2">
                    <div className='container tw-mx-auto tw-text-center tw-pt-[64px] tw-pb-[96px]'>
                        <img src="/sales/hero-icon.png" alt="sales-icon" className='tw-mb-[52px]' />
                        <h4 className='mir-p-overline-lg text-primary-500 tw-mb-[16px]'>Introducing Microsoft dynamics 365 sales</h4>
                        <h2 className='mir-h3-semibold mir-text-neutral-800 tw-mb-[24px] tw-max-w-[100%] md:tw-max-w-[500px] tw-mx-auto'>What is Microsoft Dynamics 365 for Sales? </h2>
                        <p className='mir-text-neutral-800 tw-mx-auto tw-max-w-[100%] md:tw-max-w-[802px]'>Microsoft Dynamics 365 for Sales streamlines processes, strengthens relationships, and drives growth through data-driven insights and automation. It empowers digital selling, fosters trust, aligns sales and marketing, and provides real-time insights for agile decisions. </p>
                    </div>
                </section>
                <section className='mir-bg-neutral-100'>
                    <div className="container text-center">
                        <img src="/sales/dynamics.png" alt="dynamics-image" className='tw-max-w-full md:tw-max-w-[866px] tw-mx-auto' />
                    </div>
                </section>

                <section className='tw-bg-white'>
                    <div className="container tw-mx-auto tw-text-center tw-pt-[96px] tw-pb-[96px]">
                        <h2 className='mir-h3-semibold mir-text-neutral-800 tw-mb-[48px] tw-max-w-[100%] md:tw-max-w-[500px] tw-mx-auto'>What does Microsoft Dynamics 365 for Sales offer?</h2>
                        <div className="tw-flex tw-flex-wrap tw-justify-center tw-items-center">
                            {
                                [
                                    'Sales Process Management',
                                    'Lead and Opportunity Management',
                                    'Customer Insights',
                                    'Mobile Access', 'Sales Forecasting', 'Relationship Management',
                                    'Integration with Office 365 and LinkedIn', 'Automation and Workflows',
                                    'Reporting and Analytics', 'AI-Powered Insights'
                                ].map((item) => {
                                    return <div className='tw-rounded-full mir-bg-primary-100 mir-text-primary-600 mir-p-sm-medium md:!mir-p-rg-medium tw-px-[32px] tw-py-[12px] tw-mb-[16px] tw-mr-[12px]'>
                                        {item}
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </section>

                <BlueFluidBanner
                    button={<><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.3727 12.3798L13.6922 10.7306L13.682 10.7259C13.491 10.6441 13.2825 10.6114 13.0756 10.6304C12.8687 10.6495 12.6698 10.7199 12.4969 10.8352C12.4765 10.8487 12.457 10.8633 12.4383 10.879L10.5367 12.5001C9.33204 11.9149 8.08829 10.6806 7.50313 9.49149L9.12657 7.56102C9.14219 7.54149 9.15704 7.52196 9.1711 7.50087C9.28394 7.32845 9.35239 7.13081 9.37039 6.92553C9.38838 6.72026 9.35534 6.51372 9.27423 6.3243V6.31493L7.62032 2.62821C7.51309 2.38076 7.3287 2.17463 7.09468 2.04058C6.86067 1.90654 6.58958 1.85177 6.32188 1.88446C5.26326 2.02376 4.29155 2.54366 3.58824 3.34704C2.88492 4.15042 2.49809 5.18234 2.50001 6.25009C2.50001 12.4532 7.54688 17.5001 13.75 17.5001C14.8177 17.502 15.8497 17.1152 16.6531 16.4119C17.4564 15.7085 17.9763 14.7368 18.1156 13.6782C18.1484 13.4106 18.0937 13.1396 17.9598 12.9056C17.8259 12.6716 17.62 12.4871 17.3727 12.3798ZM13.75 16.2501C11.0987 16.2472 8.55687 15.1927 6.68214 13.318C4.8074 11.4432 3.7529 8.90136 3.75001 6.25009C3.74707 5.48719 4.02192 4.7493 4.52324 4.17423C5.02456 3.59916 5.71806 3.22623 6.47423 3.12509C6.47392 3.1282 6.47392 3.13134 6.47423 3.13446L8.11485 6.80634L6.50001 8.73915C6.48362 8.75801 6.46873 8.77812 6.45548 8.7993C6.33791 8.97971 6.26894 9.18743 6.25525 9.40232C6.24157 9.61722 6.28362 9.83201 6.37735 10.0259C7.08516 11.4735 8.54376 12.9212 10.007 13.6282C10.2023 13.7211 10.4184 13.7617 10.634 13.7461C10.8497 13.7304 11.0576 13.6592 11.2375 13.5391C11.2576 13.5256 11.2769 13.511 11.2953 13.4954L13.1945 11.8751L16.8664 13.5196C16.8664 13.5196 16.8727 13.5196 16.875 13.5196C16.7751 14.2769 16.4027 14.9718 15.8275 15.4743C15.2524 15.9769 14.5138 16.2527 13.75 16.2501Z" fill="#1D68F0" />
                    </svg>
                        <span>Get Started Now</span></>}
                    title={<>Connect with a Microsoft Dynamics 365 Sales expert for tailored sales automation solutions</>}
                />

                <section className='container tw-mx-auto tw-text-left tw-pt-[72px] tw-pb-[72px]'>
                    <div className="tw-w-full tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-3 md:tw-gap-[153px]">
                        <div className='tw-flex tw-items-start tw-justify-center tw-flex-col'>
                            <h3 className='mir-h3-semibold tw-mb-[24px] tw-max-w-[100%] md:tw-max-w-[544px]'>What is Microsoft Dynamics 365 Sales Enterprise? </h3>
                            <p className='mir-text-neutral-700 tw-max-w-[100%] md:tw-max-w-[544px]'>Microsoft Dynamics 365 Sales Enterprise is a comprehensive CRM software from Microsoft, included in the broader Dynamics 365 suite of business applications. This powerful CRM offers features like case management, real-time customer insights, communication, and upselling/cross-selling capabilities. It serves as the central hub for customer information, transactions, and communication, enabling sales and support teams to better understand customer needs for effective assistance and service. </p>
                        </div>
                        <div className=''>
                            <img className='tw-w-full' src="/sales/sales-enterprise.png" alt="dynamics-sales-enterprise-image" />
                        </div>
                    </div>
                </section>

                {/* benefits */}
                <section className='mir-bg-neutral-100 tw-py-[96px]' data-section id="section-3">
                    <div className="container tw-mx-auto tw-text-center">
                        <div className='tw-max-w-full md:tw-max-w-[700px] mx-auto'>
                            <h4 className='mir-p-overline-lg mir-text-primary-500 tw-mb-[16px]'>BENEFITS</h4>
                            <h2 className='mir-h1-semibold mir-text-neutral-800 tw-mb-[24px]'>Why should you choose <span className='mir-text-primary-500'>Microsoft Dynamics 365 Sales?</span> </h2>
                            <p className='mir-text-neutral-700 mir-p-md-regular tw-mb-[24px]'>In the world of sales, finding the right prospects is only half the battle. Staying connected to customers in a digital world filled with distractions is the other half. That is where Microsoft Dynamics 365 Sales comes in.</p>
                            <h5 className='mir-text-primary-500 mir-p-md-medium tw-mb-[72px]'>Here are the top 9 reasons why our clients choose Microsoft Dynamics 365 Sales</h5>
                        </div>
                        {/* grid */}
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-x-[52px] tw-gap-y-[56px]">
                            {
                                [
                                    { icon: '/sales/benefits/1.svg', heading: 'Cloud-based convenience', desc: 'With access to CRM data from anywhere' },
                                    { icon: '/sales/benefits/2.svg', heading: 'Increased conversion rates', desc: 'With automated qualification stage ' },
                                    { icon: '/sales/benefits/3.svg', heading: 'Social media integration', desc: 'To listen to prospects and clients' },
                                    { icon: '/sales/benefits/4.svg', heading: 'Track and tailor conversations', desc: 'With insights' },
                                    { icon: '/sales/benefits/5.svg', heading: 'Competitor analysis capabilities', desc: 'To differentiate yourself' },
                                    { icon: '/sales/benefits/6.svg', heading: 'Real-time sales dashboards', desc: `For better interpretation of prospects' needs` },
                                    { icon: '/sales/benefits/7.svg', heading: 'Quick integration', desc: `With other business applications like Office 365 and SharePoint Libraries` },
                                    { icon: '/sales/benefits/8.svg', heading: 'Event tracking and monitoring', desc: `To interact in person with clients and prospects` },
                                    { icon: '/sales/benefits/9.svg', heading: 'Automated lead scoring strategies', desc: `For faster lead gathering, organizing, qualifying, accessing, and scoring.` },
                                ].map((item) => {
                                    return <div className='tw-text-left'>
                                        <img src={`${item.icon}`} className='tw-mb-[24px]' alt="benefit-icon" />
                                        <h4 className='mir-text-neutral-800 mir-h6-semibold tw-mb-[12px]'>{item.heading}</h4>
                                        <p className='tw-max-w-full md:tw-max-w-[350px] mir-text-neutral-600 mir-p-rg-regular'>{item.desc}</p>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </section>

                <div className='mir-bg-neutral-100 tw-pb-[52px]'>
                    <div className="container mx-auto">
                        <div className={`tw-rounded-[12px] tw-px-[36px] tw-py-[36px] md:tw-px-[64px] md:tw-py-[52px] tw-relative mir-bg-primary-500 tw-overflow-hidden`}>
                            <img src="/sales/blue-banner-bg.png" alt="blue-banner-bg" className='tw-absolute tw-z-0 tw-right-0 tw-bottom-0' />
                            <div className='tw-relative tw-z-10 tw-flex tw-justify-between tw-items-center tw-flex-wrap'>
                                <h3 className='tw-max-w-full tw-mb-[32px] md:tw-mb-0 md:tw-max-w-[650px] mir-h5-semibold text-white'>Streamline your sales operations with a Microsoft Dynamics 365 Sales Professional </h3>
                                <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-secondary mir-btn-md mir-text-primary-500 mir-bg-white'>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.25 2.5H14.375V1.875C14.375 1.70924 14.3092 1.55027 14.1919 1.43306C14.0747 1.31585 13.9158 1.25 13.75 1.25C13.5842 1.25 13.4253 1.31585 13.3081 1.43306C13.1908 1.55027 13.125 1.70924 13.125 1.875V2.5H6.875V1.875C6.875 1.70924 6.80915 1.55027 6.69194 1.43306C6.57473 1.31585 6.41576 1.25 6.25 1.25C6.08424 1.25 5.92527 1.31585 5.80806 1.43306C5.69085 1.55027 5.625 1.70924 5.625 1.875V2.5H3.75C3.41848 2.5 3.10054 2.6317 2.86612 2.86612C2.6317 3.10054 2.5 3.41848 2.5 3.75V16.25C2.5 16.5815 2.6317 16.8995 2.86612 17.1339C3.10054 17.3683 3.41848 17.5 3.75 17.5H16.25C16.5815 17.5 16.8995 17.3683 17.1339 17.1339C17.3683 16.8995 17.5 16.5815 17.5 16.25V3.75C17.5 3.41848 17.3683 3.10054 17.1339 2.86612C16.8995 2.6317 16.5815 2.5 16.25 2.5ZM5.625 3.75V4.375C5.625 4.54076 5.69085 4.69973 5.80806 4.81694C5.92527 4.93415 6.08424 5 6.25 5C6.41576 5 6.57473 4.93415 6.69194 4.81694C6.80915 4.69973 6.875 4.54076 6.875 4.375V3.75H13.125V4.375C13.125 4.54076 13.1908 4.69973 13.3081 4.81694C13.4253 4.93415 13.5842 5 13.75 5C13.9158 5 14.0747 4.93415 14.1919 4.81694C14.3092 4.69973 14.375 4.54076 14.375 4.375V3.75H16.25V6.25H3.75V3.75H5.625ZM16.25 16.25H3.75V7.5H16.25V16.25Z" fill="#1D68F0" />
                                    </svg>
                                    <span className='tw-ml-[12px] mir-p-rg-regular'>Book Now</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='tw-mb-[64px]' data-section id="section-4">
                    <HowHelpSection
                        overline={'HOW WE HELP YOU'}
                        heading={<>Why should you choose <span style={{ color: '#1D68F0' }}>MIR Digital Solutions</span> to streamline your sales process?</>}
                        desc={<>MIR Digital Solutions has a proven track record of successful implementations and a strong commitment to Microsoft technologies, resulting in unrivaled product knowledge and process improvement expertise.<br /><br />
                            Our experience and expertise have enabled us to develop efficient processes and approaches for cloud solutions, enabling innovation in a way other competitors simply cannot.</>}
                        image={'url(/sales/help-bg.png) no-repeat'}
                    />
                </div>

                <StepsSlider
                    subtitle={'Our specialist team offers custom-tailored implementation of Microsoft Dynamics 365 Project Operations for Improved productivity and profitability with an optimized workforce.'}
                />
                <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />

                <section className="process-slider-features" style={{ background: '#FAFAFC', padding: '96px 0' }}>
                    <div className="container">
                        <div className="row features">
                            <div className="col-12 col-md-12 col-lg-4">
                                <div className="feature-item">
                                    <img src="/supply-chain/setting.svg" alt="feature-item-icon" className='mb-4' />
                                    <div style={{ maxWidth: '90%' }}>
                                        <h4 className='mir-h6-semibold'>An end-to-end implementation</h4>
                                        <p className='mir-p-rg-regular'>We provide full-length implementation framework, including assessment, strategy development, systems integration, data migration and training.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-4">
                                <div className="feature-item">
                                    <img src="/supply-chain/monitor.svg" alt="feature-item-icon" className='mb-4' />
                                    <div style={{ maxWidth: '90%' }}>
                                        <h4 className='mir-h6-semibold'>Industry-focused and technology <br /> best practices</h4>
                                        <p className='mir-p-rg-regular'>Our approach involves understanding specific needs of your organization's industry that can help solve the problem.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-4">
                                <div className="feature-item">
                                    <img src="/supply-chain/service.svg" alt="feature-item-icon" className='mb-4' />
                                    <div style={{ maxWidth: '90%' }}>
                                        <h4 className='mir-h6-semibold'>A dedicated 24x7 office support team</h4>
                                        <p className='mir-p-rg-regular'>In the Go-Live phase, our QA specialists will conduct in-depth training sessions for all users. We also provide 24x7 office support for post-implementation issues.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='tw-py-[96px] mir-bg-primary-600 tw-relative'>
                    <img src="/supply-chain/light-blue-banner-bg.png" alt="light-banner-bg" className='tw-absolute tw-inset-0 tw-object-cover tw-h-full tw-w-full' />
                    <div className="container mx-auto tw-relative z-10">
                        <div className="row">
                            <div className="col-12 col-md-9">
                                <h3 className='mir-text-neutral-100 mir-h4-semibold tw-max-w-full md:tw-max-w-[640px] tw-mb-[16px]'>Streamline your teams with Microsoft Dynamics 365 for Sales and Marketing</h3>
                                <p className='mir-text-neutral-300 mir-p-md-regular tw-max-w-full md:tw-max-w-[640px]'>Keep leads on the radar and turn your pipeline into success.</p>
                            </div>
                            <div className="col-12 col-md-3 tw-flex tw-justify-start md:tw-justify-end tw-items-center">
                                <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-lg mir-btn-secondary tw-mt-[32px] md:tw-mt-0'>
                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.3727 12.8797L13.6922 11.2305L13.682 11.2258C13.491 11.1441 13.2825 11.1113 13.0756 11.1304C12.8687 11.1495 12.6698 11.2198 12.4969 11.3352C12.4765 11.3486 12.457 11.3632 12.4383 11.3789L10.5367 13C9.33204 12.4148 8.08829 11.1805 7.50313 9.99141L9.12657 8.06094C9.14219 8.04141 9.15704 8.02188 9.1711 8.00078C9.28394 7.82836 9.35239 7.63072 9.37039 7.42545C9.38838 7.22018 9.35534 7.01364 9.27423 6.82422V6.81485L7.62032 3.12813C7.51309 2.88068 7.3287 2.67455 7.09468 2.5405C6.86067 2.40646 6.58958 2.35169 6.32188 2.38438C5.26326 2.52368 4.29155 3.04357 3.58824 3.84695C2.88492 4.65033 2.49809 5.68226 2.50001 6.75C2.50001 12.9531 7.54688 18 13.75 18C14.8177 18.0019 15.8497 17.6151 16.6531 16.9118C17.4564 16.2085 17.9763 15.2367 18.1156 14.1781C18.1484 13.9105 18.0937 13.6395 17.9598 13.4055C17.8259 13.1715 17.62 12.9871 17.3727 12.8797ZM13.75 16.75C11.0987 16.7471 8.55687 15.6926 6.68214 13.8179C4.8074 11.9431 3.7529 9.40128 3.75001 6.75C3.74707 5.9871 4.02192 5.24922 4.52324 4.67415C5.02456 4.09908 5.71806 3.72615 6.47423 3.625C6.47392 3.62812 6.47392 3.63126 6.47423 3.63438L8.11485 7.30625L6.50001 9.23906C6.48362 9.25792 6.46873 9.27804 6.45548 9.29922C6.33791 9.47963 6.26894 9.68734 6.25525 9.90224C6.24157 10.1171 6.28362 10.3319 6.37735 10.5258C7.08516 11.9734 8.54376 13.4211 10.007 14.1281C10.2023 14.221 10.4184 14.2616 10.634 14.246C10.8497 14.2304 11.0576 14.1591 11.2375 14.0391C11.2576 14.0256 11.2769 14.0109 11.2953 13.9953L13.1945 12.375L16.8664 14.0195C16.8664 14.0195 16.8727 14.0195 16.875 14.0195C16.7751 14.7768 16.4027 15.4717 15.8275 15.9742C15.2524 16.4768 14.5138 16.7526 13.75 16.75Z" fill="#1D68F0" />
                                    </svg>
                                    <span className='tw-ml-2'>Contact us today</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='tw-pt-[200px] tw-pb-[120px] tw-bg-[#000213] tw-relative tw-overflow-hidden'>
                    <img src="/sales/globe-vector.png" alt="globe-vector-banner-bg" className='tw-absolute tw-inset-0 tw-h-[13%] md:tw-h-auto tw-w-full' />
                    <div className="container mx-auto tw-relative tw-z-20">
                        <h2 className='mir-h1-semibold mir-text-neutral-100 tw-text-center md:tw-text-left tw-max-w-full md:tw-max-w-[613px] tw-mb-[24px]'>What is Microsoft Dynamics 365 Sales Insights?</h2>
                        <p className='mir-p-md-regular mir-text-neutral-400 tw-text-center md:tw-text-left tw-max-w-full md:tw-max-w-[546px] tw-mb-[80px]'>Microsoft Dynamics 365 Sales Insights boosts seller and manager capabilities through AI. Key features include: </p>
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-x-[56px] tw-gap-y-[52px]">
                            <div className='tw-max-w-full md:tw-max-w-[336px]'>
                                <h5 className='mir-text-neutral-100 mir-h6-medium tw-mb-[12px]'>Enhanced abilities</h5>
                                <p className='mir-text-neutral-400 mir-p-rg-regular'>AI augments natural skills of sellers and managers</p>
                            </div>
                            <div className='tw-max-w-full md:tw-max-w-[336px]'>
                                <h5 className='mir-text-neutral-100 mir-h6-medium tw-mb-[12px]'>Accurate forecasting </h5>
                                <p className='mir-text-neutral-400 mir-p-rg-regular'>Automation and AI enhance revenue forecasting accuracy</p>
                            </div>
                            <div className='tw-max-w-full md:tw-max-w-[336px]'>
                                <h5 className='mir-text-neutral-100 mir-h6-medium tw-mb-[12px]'>Complementary insights</h5>
                                <p className='mir-text-neutral-400 mir-p-rg-regular'>Built-in AI complements seller forecasts, refining predictions</p>
                            </div>
                            <div className='tw-max-w-full md:tw-max-w-[336px]'>
                                <h5 className='mir-text-neutral-100 mir-h6-medium tw-mb-[12px]'>Snapshot analysis</h5>
                                <p className='mir-text-neutral-400 mir-p-rg-regular'>Freeze and analyze forecasts for deeper understanding</p>
                            </div>
                            <div className='tw-max-w-full md:tw-max-w-[336px]'>
                                <h5 className='mir-text-neutral-100 mir-h6-medium tw-mb-[12px]'>Conversation intelligence</h5>
                                <p className='mir-text-neutral-400 mir-p-rg-regular'>Uncover customer sentiment and conversational KPIs</p>
                            </div>
                            <div className='tw-max-w-full md:tw-max-w-[336px]'>
                                <h5 className='mir-text-neutral-100 mir-h6-medium tw-mb-[12px]'>Tailored AI</h5>
                                <p className='mir-text-neutral-400 mir-p-rg-regular'>Customize AI with no-code tools for personalized recommendations and scoring.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <div style={{ background: '#FAFAFC' }} data-section id='section-5'>
                    <SuccessStoriesDynamic
                        successStoriesContent={successStoriesContent}
                    />
                </div>

                <TransformationSection
                    heading={<>Maximize your digital transformation with Dynamics 365 next-generation application</>}
                    desc={<>Unlike traditional systems, Microsoft Dynamics 365 is an intelligent and scalable ERP platform of business applications that can work seamlessly together to modernize business operation. With our seasoned expert team, MIR can help you tailor and adapt any functionality to your business requirements. </>}
                    items={[
                        {
                            icon: '/supply-chain/transformation-icon-1.png',
                            heading: <>Dynamics Business <br /> Central</>,
                            link: <Link to={MIRRoutes.BusinessCentral}><span className='mir-p-sm-medium me-2'>Learn More</span>
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.3172 7.44254L9.69219 13.0675C9.57491 13.1848 9.41585 13.2507 9.25 13.2507C9.08415 13.2507 8.92509 13.1848 8.80781 13.0675C8.69054 12.9503 8.62465 12.7912 8.62465 12.6253C8.62465 12.4595 8.69054 12.3004 8.80781 12.1832L13.3664 7.62535H1.125C0.95924 7.62535 0.800269 7.5595 0.683058 7.44229C0.565848 7.32508 0.5 7.16611 0.5 7.00035C0.5 6.83459 0.565848 6.67562 0.683058 6.55841C0.800269 6.4412 0.95924 6.37535 1.125 6.37535H13.3664L8.80781 1.81753C8.69054 1.70026 8.62465 1.5412 8.62465 1.37535C8.62465 1.2095 8.69054 1.05044 8.80781 0.93316C8.92509 0.815885 9.08415 0.75 9.25 0.75C9.41585 0.75 9.57491 0.815885 9.69219 0.93316L15.3172 6.55816C15.3753 6.61621 15.4214 6.68514 15.4529 6.76101C15.4843 6.83688 15.5005 6.91821 15.5005 7.00035C15.5005 7.08248 15.4843 7.16381 15.4529 7.23969C15.4214 7.31556 15.3753 7.38449 15.3172 7.44254Z" fill="#1D68F0" />
                                </svg></Link>
                        },
                        {
                            icon: '/supply-chain/hero-icon.png',
                            heading: <>Dynamics Supply <br />Chain Management</>,
                            link: <Link to={MIRRoutes.SupplyChain}><span className='mir-p-sm-medium me-2'>Learn More</span>
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.3172 7.44254L9.69219 13.0675C9.57491 13.1848 9.41585 13.2507 9.25 13.2507C9.08415 13.2507 8.92509 13.1848 8.80781 13.0675C8.69054 12.9503 8.62465 12.7912 8.62465 12.6253C8.62465 12.4595 8.69054 12.3004 8.80781 12.1832L13.3664 7.62535H1.125C0.95924 7.62535 0.800269 7.5595 0.683058 7.44229C0.565848 7.32508 0.5 7.16611 0.5 7.00035C0.5 6.83459 0.565848 6.67562 0.683058 6.55841C0.800269 6.4412 0.95924 6.37535 1.125 6.37535H13.3664L8.80781 1.81753C8.69054 1.70026 8.62465 1.5412 8.62465 1.37535C8.62465 1.2095 8.69054 1.05044 8.80781 0.93316C8.92509 0.815885 9.08415 0.75 9.25 0.75C9.41585 0.75 9.57491 0.815885 9.69219 0.93316L15.3172 6.55816C15.3753 6.61621 15.4214 6.68514 15.4529 6.76101C15.4843 6.83688 15.5005 6.91821 15.5005 7.00035C15.5005 7.08248 15.4843 7.16381 15.4529 7.23969C15.4214 7.31556 15.3753 7.38449 15.3172 7.44254Z" fill="#1D68F0" />
                                </svg></Link>
                        },
                        {
                            icon: '/project-operations/hero-icon.png',
                            heading: <>Dynamics Project Operations</>,
                            link: <Link to={MIRRoutes.ProjectOperations}><span className='mir-p-sm-medium me-2'>Learn More</span>
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.3172 7.44254L9.69219 13.0675C9.57491 13.1848 9.41585 13.2507 9.25 13.2507C9.08415 13.2507 8.92509 13.1848 8.80781 13.0675C8.69054 12.9503 8.62465 12.7912 8.62465 12.6253C8.62465 12.4595 8.69054 12.3004 8.80781 12.1832L13.3664 7.62535H1.125C0.95924 7.62535 0.800269 7.5595 0.683058 7.44229C0.565848 7.32508 0.5 7.16611 0.5 7.00035C0.5 6.83459 0.565848 6.67562 0.683058 6.55841C0.800269 6.4412 0.95924 6.37535 1.125 6.37535H13.3664L8.80781 1.81753C8.69054 1.70026 8.62465 1.5412 8.62465 1.37535C8.62465 1.2095 8.69054 1.05044 8.80781 0.93316C8.92509 0.815885 9.08415 0.75 9.25 0.75C9.41585 0.75 9.57491 0.815885 9.69219 0.93316L15.3172 6.55816C15.3753 6.61621 15.4214 6.68514 15.4529 6.76101C15.4843 6.83688 15.5005 6.91821 15.5005 7.00035C15.5005 7.08248 15.4843 7.16381 15.4529 7.23969C15.4214 7.31556 15.3753 7.38449 15.3172 7.44254Z" fill="#1D68F0" />
                                </svg></Link>
                        },
                    ]}
                />

                <div data-section id='section-6'>
                    <ContactSection />
                </div>

                <Footer />

            </main>
        </>
    )
}
