import React from 'react'
import { Link } from 'react-router-dom';
import MIRRoutes from '../../lib/routes';

export default function GalaxyIcons() {



    const boxRingBg = 'cursor-pointer tw-absolute tw-z-[1000] md:tw-h-[70px] md:tw-w-[70px] lg:tw-h-[112px] lg:tw-w-[112px] tw-rounded-full tw-flex tw-items-center tw-justify-center tw-from-[#27518A] tw-to-transparent tw-p-[2px]';

    return (
        <div className='tw-pt-[133px] tw-overflow-hidden tw-w-full' style={{ background: `url('/galaxy/bg.webp')`, backgroundSize: 'cover', backgroundPosition: 'bottom'}}>
            <h2 className='tw-pb-[5rem] mir-text-neutral-100 tw-max-w-full md:tw-max-w-[655px] mir-h6-semibold md:mir-h5-semibold text-center mx-auto tw-mb-[133px] tw-px-4'>Is your business seeking the perfect blend of streamlined operations, rock-solid security, and cutting-edge technology? <span className='tw-text-[#0094FF]'>Look no further - you’ve arrived at your destination.</span> </h2>
            <h2 className='tw-hidden mir-text-neutral-100 tw-max-w-full md:tw-max-w-[787px] mir-h6-semibold md:mir-h5-semibold text-center mx-auto tw-mb-[176px]'>Look no further - you’ve arrived at your destination.</h2>

            <div className='md:tw-w-[632px] md:tw-h-[632px] lg:tw-w-[1009px] lg:tw-h-[750px] mx-auto tw-relative tw-items-center tw-justify-center tw-hidden md:tw-flex'>
                {/* large ring */}
                <div className="tw-absolute tw-inset-0 tw-flex tw-items-center md:tw-max-w-[632px] md:tw-max-h-[632px] lg:tw-max-w-[1009px] lg:tw-max-h-[1010px]">
                    <div className='tw-relative tw-w-fit tw-mx-auto tw-h-fit'>
                        <img height="auto" width="auto" src="/galaxy/rings/large.svg" className='tw-mx-auto tw-w-full tw-h-full' alt="ellipse-ring-large" />
                        {/* ring 1 */}
                        <Link to={MIRRoutes.ProjectOperations}>
                            <div className={`${boxRingBg} tw-bg-gradient-to-br transition hover:tw-bg-blue-400 tw-cursor-pointer tw-bottom-[27%] tw-left-[-3%]`}>
                                <div className="tw-bg-[#030a2b] tw-rounded-full tw-relative tw-h-full tw-w-full tw-p-[24px] tw-flex tw-items-center tw-justify-center tw-group">
                                    <img height="auto" width="auto" src="/galaxy/icons/project.svg" alt="ring-app-icon" className='tw-transform group-hover:tw-scale-90 tw-transition md:tw-w-[32px] lg:tw-w-[52px]' />
                                    <div className='tw-py-[8px] tw-absolute tw-bottom-0 tw-top-0 tw-flex tw-items-center tw-justify-start tw-left-[90%]'>
                                        <div className='tw-flex tw-justify-center tw-items-center'>
                                            <img height="auto" width="auto" src="/galaxy/circle-icon.svg" alt="circle-text-icon" />
                                            <img height="auto" width="auto" src="/galaxy/icon-arrow.svg" alt="text-icon-arrow" className='transform tw-rotate-90 tw-ml-2' />
                                        </div>
                                        <p className='md:mir-p-xs-medium lg:mir-h6-medium mir-text-neutral-100 tw-w-[262px] tw-m-0 tw-ml-3'>Project Operations</p>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        {/* ring 2 */}
                        <Link to={MIRRoutes.SupplyChain}>
                            <div className={`${boxRingBg} tw-bg-gradient-to-r transition hover:tw-bg-blue-400 tw-cursor-pointer tw-bottom-[80%] tw-left-[9%]`}>
                                <div className="tw-bg-[#030a2b] tw-rounded-full tw-relative tw-h-full tw-w-full tw-p-[24px] tw-flex tw-items-center tw-justify-center tw-group">
                                    <img height="auto" width="auto" src="/galaxy/icons/supply.svg" alt="ring-app-icon" className='tw-transform group-hover:tw-scale-90 tw-transition md:tw-w-[32px] lg:tw-w-[52px]' />
                                    <div className='tw-py-[8px] tw-absolute tw-bottom-0 tw-top-0 tw-flex tw-items-center lg:tw-justify-start md:tw-justify-center md:tw-flex-col lg:tw-flex-row md:tw-top-[-180%] lg:tw-top-[0%] lg:tw-right-[90%]'>
                                        <p className='md:mir-p-xs-medium lg:mir-h6-medium mir-text-neutral-100 tw-w-[262px] tw-m-0 md:tw-text-center lg:tw-text-right lg:tw-mr-3'>Supply Chain <br /> Management</p>
                                        <div className='tw-flex tw-justify-center tw-items-center md:tw-flex-col lg:tw-flex-row'>
                                            <img height="auto" width="auto" src="/galaxy/icon-arrow.svg" alt="text-icon-arrow" className='transform lg:-tw-rotate-90 lg:tw-mr-2' />
                                            <img height="auto" width="auto" src="/galaxy/circle-icon.svg" alt="circle-text-icon" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        {/* ring 3 */}
                        <Link to={MIRRoutes.PowerApps}>
                            <div className={`${boxRingBg} tw-bg-gradient-to-bl transition hover:tw-bg-blue-400 tw-cursor-pointer tw-bottom-[85%] tw-right-[16%]`}>
                                <div className="tw-bg-[#030a2b] tw-rounded-full tw-relative tw-h-full tw-w-full tw-p-[24px] tw-flex tw-items-center tw-justify-center tw-group">
                                    <img height="auto" width="auto" src="/galaxy/icons/power-apps.svg" alt="ring-app-icon" className='tw-transform group-hover:tw-scale-90 tw-transition md:tw-w-[32px] lg:tw-w-[52px]' />
                                    <div className='tw-py-[8px] tw-absolute tw-bottom-0 tw-top-0 tw-flex tw-items-center tw-justify-start tw-left-[90%]'>
                                        <div className='tw-flex tw-justify-center tw-items-center'>
                                            <img height="auto" width="auto" src="/galaxy/circle-icon.svg" alt="circle-text-icon" />
                                            <img height="auto" width="auto" src="/galaxy/icon-arrow.svg" alt="text-icon-arrow" className='transform tw-rotate-90 tw-ml-2' />
                                        </div>
                                        <p className='md:mir-p-xs-medium lg:mir-h6-medium mir-text-neutral-100 tw-w-[262px] tw-m-0 tw-ml-3'>Power Apps</p>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        {/* ring 4 */}
                        <Link to={MIRRoutes.SharePoint}>
                            <div className={`${boxRingBg} tw-bg-gradient-to-tl transition hover:tw-bg-blue-400 tw-cursor-pointer tw-bottom-[30%] tw-right-[-3%]`}>
                                <div className="tw-bg-[#030a2b] tw-rounded-full tw-relative tw-h-full tw-w-full tw-p-[24px] tw-flex tw-items-center tw-justify-center tw-group">
                                    <img height="auto" width="auto" src="/galaxy/icons/sharepoint.svg" alt="ring-app-icon" className='tw-transform group-hover:tw-scale-90 tw-transition md:tw-w-[32px] lg:tw-w-[52px]' />
                                    <div className='tw-py-[8px] tw-absolute tw-bottom-0 tw-top-0 tw-flex tw-items-center tw-justify-start tw-right-[90%]'>
                                        <p className='md:mir-p-xs-medium lg:mir-h6-medium mir-text-neutral-100 tw-w-[262px] tw-m-0 tw-text-right tw-mr-3'>SharePoint</p>
                                        <div className='tw-flex tw-justify-center tw-items-center'>
                                            <img height="auto" width="auto" src="/galaxy/icon-arrow.svg" alt="text-icon-arrow" className='transform -tw-rotate-90 tw-mr-2' />
                                            <img height="auto" width="auto" src="/galaxy/circle-icon.svg" alt="circle-text-icon" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                {/* small ring */}
                <div className='tw-absolute tw-mx-auto tw-my-auto tw-inset-0 tw-flex tw-items-center md:tw-max-w-[416px] md:tw-max-h-[416px] lg:tw-max-w-[664px] lg:tw-max-h-[664px]'>
                    <div className='tw-relative tw-w-fit tw-mx-auto tw-h-fit'>
                        <img height="auto" width="auto" src="/galaxy/rings/small.svg" className='tw-mx-auto tw-w-full tw-h-full' alt="ellipse-ring-large" />
                        {/* sm ring 1 */}
                        <Link to={MIRRoutes.BusinessCentral}>
                            <div className={`${boxRingBg} transition hover:tw-bg-blue-400 tw-cursor-pointer tw-bg-gradient-to-bl tw-bottom-[60%] tw-left-[-3%]`}>
                                <div className="tw-bg-[#030a2b] tw-rounded-full tw-relative tw-h-full tw-w-full tw-p-[24px] tw-flex tw-items-center tw-justify-center tw-group">
                                    <img height="auto" width="auto" src="/galaxy/icons/business.svg" alt="ring-app-icon" className='tw-transform group-hover:tw-scale-90 tw-transition md:tw-w-[32px] lg:tw-w-[52px]' />
                                    <div className='tw-py-[8px] tw-absolute tw-bottom-0 tw-top-0 tw-flex tw-items-center tw-justify-start tw-right-[90%]'>
                                        <p className='md:mir-p-xs-medium lg:mir-h6-medium mir-text-neutral-100 tw-w-[262px] tw-m-0 tw-text-right tw-mr-3'>Business Central</p>
                                        <div className='tw-flex tw-justify-center tw-items-center'>
                                            <img height="auto" width="auto" src="/galaxy/icon-arrow.svg" alt="text-icon-arrow" className='transform -tw-rotate-90 tw-mr-2' />
                                            <img height="auto" width="auto" src="/galaxy/circle-icon.svg" alt="circle-text-icon" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        {/* sm ring 2 */}
                        <Link to={MIRRoutes.Sales}>
                            <div className={`${boxRingBg} transition hover:tw-bg-blue-400 tw-cursor-pointer tw-bg-gradient-to-b tw-bottom-[90%] tw-left-0 tw-left-[38%]`}>
                                <div className="tw-bg-[#030a2b] tw-rounded-full tw-relative tw-h-full tw-w-full tw-p-[24px] tw-flex tw-items-center tw-justify-center tw-group">
                                    <img height="auto" width="auto" src="/galaxy/icons/sales.svg" alt="ring-app-icon" className='tw-transform group-hover:tw-scale-90 tw-transition md:tw-w-[32px] lg:tw-w-[52px]' />
                                    <div className='tw-py-[8px] tw-absolute tw-bottom-0 tw-top-0 tw-flex tw-flex-col tw-items-center tw-justify-center tw-top-[-180%]'>
                                        <p className='md:mir-p-xs-medium lg:mir-h6-medium mir-text-neutral-100 tw-w-[262px] tw-m-0 tw-text-center'>CRM Sales</p>
                                        <div className='tw-flex tw-justify-center tw-items-center tw-flex-col'>
                                            <img height="auto" width="auto" src="/galaxy/icon-arrow.svg" alt="text-icon-arrow" className='transform tw-mb-0.5' />
                                            <img height="auto" width="auto" src="/galaxy/circle-icon.svg" alt="circle-text-icon" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        {/* ring 1 */}
                        <Link to={MIRRoutes.PowerBI}>
                            <div className={`${boxRingBg} transition hover:tw-bg-blue-400 tw-cursor-pointer tw-bg-gradient-to-br tw-bottom-[65%] tw-right-[-1%]`}>
                                <div className="tw-bg-[#030a2b] tw-rounded-full tw-relative tw-h-full tw-w-full tw-p-[24px] tw-flex tw-items-center tw-justify-center tw-group">
                                    <img height="auto" width="auto" src="/galaxy/icons/power-bi.svg" alt="ring-app-icon" className='tw-transform group-hover:tw-scale-90 tw-transition md:tw-w-[32px] lg:tw-w-[52px]' />
                                    <div className='tw-py-[8px] tw-absolute tw-bottom-0 tw-top-0 tw-flex tw-items-center tw-justify-start tw-left-[90%]'>
                                        <div className='tw-flex tw-justify-center tw-items-center'>
                                            <img height="auto" width="auto" src="/galaxy/circle-icon.svg" alt="circle-text-icon" />
                                            <img height="auto" width="auto" src="/galaxy/icon-arrow.svg" alt="text-icon-arrow" className='transform tw-rotate-90 tw-ml-2' />
                                        </div>
                                        <p className='md:mir-p-xs-medium lg:mir-h6-medium mir-text-neutral-100 tw-w-[262px] tw-m-0 tw-ml-3'>Power BI</p>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                {/* text details */}
                <div className='tw-flex tw-items-center tw-justify-center tw-flex-col tw-relative tw-z-50'>
                    <h2 className='md:mir-h5-semibold lg:mir-h2-semibold tw-max-w-full md:tw-max-w-[225px] lg:tw-max-w-[338px] mir-text-neutral-100 text-center md:tw-mb-[30px] lg:tw-mb-[36px]'>We specialize in implementing <span className='mir-text-primary-500'>Microsoft solutions</span></h2>
                    <h3 style={{ background: 'conic-gradient(rgb(60, 154, 221) 15deg, rgb(1 3 12 / 0%) 55deg, rgb(35 161 229) 133deg, rgb(5, 11, 43), rgb(59, 151, 218))' }}
                    className='tw-relative md:mir-p-rg-medium lg:mir-p-md-medium mir-text-primary-200 tw-rounded-full md:tw-px-[28px] md:tw-py-[12px] lg:tw-px-[36px] lg:tw-py-[16px]'>
                        <div className='tw-absolute tw-z-10 tw-top-[1px] tw-bottom-[1px] tw-left-[1px] tw-right-[1px] tw-bg-gradient-to-b tw-from-[#000F35] tw-to-[#03174A] tw-rounded-full'></div>
                        <span className='tw-relative tw-z-50'>Microsoft Dynamics 365</span>
                    </h3>
                </div>
            </div>

            <div className='tw-block md:tw-hidden'>
                <div className='tw-flex tw-items-center tw-justify-center tw-flex-col tw-relative tw-z-50'>
                    <h2 className='mir-h5-semibold lg:mir-h2-semibold tw-max-w-[250px] md:tw-max-w-[225px] lg:tw-max-w-[338px] mir-text-neutral-100 text-center tw-mb-[30px] lg:tw-mb-[36px]'>We specialize in implementing <span className='mir-text-primary-500'>Microsoft solutions</span></h2>
                    <h3 style={{ background: 'conic-gradient(rgb(60, 154, 221) 15deg, rgb(1 3 12 / 0%) 55deg, rgb(35 161 229) 133deg, rgb(5, 11, 43), rgb(59, 151, 218))' }}
                    className='tw-relative mir-p-rg-medium md:mir-p-rg-medium lg:mir-p-md-medium mir-text-primary-200 tw-rounded-full tw-mb-[52px] tw-px-[28px] tw-py-[12px] lg:tw-px-[36px] lg:tw-py-[16px]'>
                        <div className='tw-absolute tw-z-10 tw-top-[1px] tw-bottom-[1px] tw-left-[1px] tw-right-[1px] tw-bg-gradient-to-b tw-from-[#000F35] tw-to-[#03174A] tw-rounded-full'></div>
                        <span className='tw-relative tw-z-50'>Microsoft Dynamics 365</span>
                    </h3>
                </div>
                <div className='tw-px-3 tw-pb-3'> 
                    <Link to={MIRRoutes.Sales}>
                        <div className={`cursor-pointer tw-h-[70px] tw-w-[70px] tw-rounded-full tw-flex tw-items-center tw-justify-center tw-from-[#27518A] tw-to-transparent tw-p-[2px] tw-bg-gradient-to-br transition hover:tw-bg-blue-400 tw-cursor-pointer tw-mb-[29px]`}>
                            <div className="tw-bg-[#030a2b] tw-rounded-full tw-relative tw-h-full tw-w-full tw-p-[24px] tw-flex tw-items-center tw-justify-center tw-group">
                                <img height="auto" width="auto" src="/galaxy/icons/sales.svg" alt="ring-app-icon" className='tw-transform group-hover:tw-scale-90 tw-transition tw-w-[32px]' />
                                <div className='tw-py-[8px] tw-absolute tw-bottom-0 tw-top-0 tw-flex tw-items-center tw-justify-start tw-left-[90%]'>
                                    <div className='tw-flex tw-justify-center tw-items-center'>
                                        <img height="auto" width="auto" src="/galaxy/circle-icon.svg" alt="circle-text-icon" />
                                        <img height="auto" width="auto" src="/galaxy/icon-arrow.svg" alt="text-icon-arrow" className='transform tw-rotate-90 tw-ml-2' />
                                    </div>
                                    <p className='mir-p-sm-medium mir-text-neutral-100 tw-w-[200px] tw-m-0 tw-ml-3'>CRM Sales</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to={MIRRoutes.ProjectOperations}>
                        <div className={`cursor-pointer tw-h-[70px] tw-w-[70px] tw-rounded-full tw-flex tw-items-center tw-justify-center tw-from-[#27518A] tw-to-transparent tw-p-[2px] tw-bg-gradient-to-br transition hover:tw-bg-blue-400 tw-cursor-pointer tw-mb-[29px]`}>
                            <div className="tw-bg-[#030a2b] tw-rounded-full tw-relative tw-h-full tw-w-full tw-p-[24px] tw-flex tw-items-center tw-justify-center tw-group">
                                <img height="auto" width="auto" src="/galaxy/icons/project.svg" alt="ring-app-icon" className='tw-transform group-hover:tw-scale-90 tw-transition tw-w-[32px]' />
                                <div className='tw-py-[8px] tw-absolute tw-bottom-0 tw-top-0 tw-flex tw-items-center tw-justify-start tw-left-[90%]'>
                                    <div className='tw-flex tw-justify-center tw-items-center'>
                                        <img height="auto" width="auto" src="/galaxy/circle-icon.svg" alt="circle-text-icon" />
                                        <img height="auto" width="auto" src="/galaxy/icon-arrow.svg" alt="text-icon-arrow" className='transform tw-rotate-90 tw-ml-2' />
                                    </div>
                                    <p className='mir-p-sm-medium mir-text-neutral-100 tw-w-[200px] tw-m-0 tw-ml-3'>Project Operations</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to={MIRRoutes.BusinessCentral}>
                        <div className={`cursor-pointer tw-h-[70px] tw-w-[70px] tw-rounded-full tw-flex tw-items-center tw-justify-center tw-from-[#27518A] tw-to-transparent tw-p-[2px] tw-bg-gradient-to-br transition hover:tw-bg-blue-400 tw-cursor-pointer tw-mb-[29px]`}>
                            <div className="tw-bg-[#030a2b] tw-rounded-full tw-relative tw-h-full tw-w-full tw-p-[24px] tw-flex tw-items-center tw-justify-center tw-group">
                                <img height="auto" width="auto" src="/galaxy/icons/business.svg" alt="ring-app-icon" className='tw-transform group-hover:tw-scale-90 tw-transition tw-w-[32px]' />
                                <div className='tw-py-[8px] tw-absolute tw-bottom-0 tw-top-0 tw-flex tw-items-center tw-justify-start tw-left-[90%]'>
                                    <div className='tw-flex tw-justify-center tw-items-center'>
                                        <img height="auto" width="auto" src="/galaxy/circle-icon.svg" alt="circle-text-icon" />
                                        <img height="auto" width="auto" src="/galaxy/icon-arrow.svg" alt="text-icon-arrow" className='transform tw-rotate-90 tw-ml-2' />
                                    </div>
                                    <p className='mir-p-sm-medium mir-text-neutral-100 tw-w-[200px] tw-m-0 tw-ml-3'>Business Central</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to={MIRRoutes.SupplyChain}>
                        <div className={`cursor-pointer tw-h-[70px] tw-w-[70px] tw-rounded-full tw-flex tw-items-center tw-justify-center tw-from-[#27518A] tw-to-transparent tw-p-[2px] tw-bg-gradient-to-br transition hover:tw-bg-blue-400 tw-cursor-pointer tw-mb-[29px]`}>
                            <div className="tw-bg-[#030a2b] tw-rounded-full tw-relative tw-h-full tw-w-full tw-p-[24px] tw-flex tw-items-center tw-justify-center tw-group">
                                <img height="auto" width="auto" src="/galaxy/icons/supply.svg" alt="ring-app-icon" className='tw-transform group-hover:tw-scale-90 tw-transition tw-w-[32px]' />
                                <div className='tw-py-[8px] tw-absolute tw-bottom-0 tw-top-0 tw-flex tw-items-center tw-justify-start tw-left-[90%]'>
                                    <div className='tw-flex tw-justify-center tw-items-center'>
                                        <img height="auto" width="auto" src="/galaxy/circle-icon.svg" alt="circle-text-icon" />
                                        <img height="auto" width="auto" src="/galaxy/icon-arrow.svg" alt="text-icon-arrow" className='transform tw-rotate-90 tw-ml-2' />
                                    </div>
                                    <p className='mir-p-sm-medium mir-text-neutral-100 tw-w-[200px] tw-m-0 tw-ml-3'>Supply Chain Management</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to={MIRRoutes.SharePoint}>
                        <div className={`cursor-pointer tw-h-[70px] tw-w-[70px] tw-rounded-full tw-flex tw-items-center tw-justify-center tw-from-[#27518A] tw-to-transparent tw-p-[2px] tw-bg-gradient-to-br transition hover:tw-bg-blue-400 tw-cursor-pointer tw-mb-[29px]`}>
                            <div className="tw-bg-[#030a2b] tw-rounded-full tw-relative tw-h-full tw-w-full tw-p-[24px] tw-flex tw-items-center tw-justify-center tw-group">
                                <img height="auto" width="auto" src="/galaxy/icons/sharepoint.svg" alt="ring-app-icon" className='tw-transform group-hover:tw-scale-90 tw-transition tw-w-[32px]' />
                                <div className='tw-py-[8px] tw-absolute tw-bottom-0 tw-top-0 tw-flex tw-items-center tw-justify-start tw-left-[90%]'>
                                    <div className='tw-flex tw-justify-center tw-items-center'>
                                        <img height="auto" width="auto" src="/galaxy/circle-icon.svg" alt="circle-text-icon" />
                                        <img height="auto" width="auto" src="/galaxy/icon-arrow.svg" alt="text-icon-arrow" className='transform tw-rotate-90 tw-ml-2' />
                                    </div>
                                    <p className='mir-p-sm-medium mir-text-neutral-100 tw-w-[200px] tw-m-0 tw-ml-3'>SharePoint</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to={MIRRoutes.PowerBI}>
                        <div className={`cursor-pointer tw-h-[70px] tw-w-[70px] tw-rounded-full tw-flex tw-items-center tw-justify-center tw-from-[#27518A] tw-to-transparent tw-p-[2px] tw-bg-gradient-to-br transition hover:tw-bg-blue-400 tw-cursor-pointer tw-mb-[29px]`}>
                            <div className="tw-bg-[#030a2b] tw-rounded-full tw-relative tw-h-full tw-w-full tw-p-[24px] tw-flex tw-items-center tw-justify-center tw-group">
                                <img height="auto" width="auto" src="/galaxy/icons/power-bi.svg" alt="ring-app-icon" className='tw-transform group-hover:tw-scale-90 tw-transition tw-w-[32px]' />
                                <div className='tw-py-[8px] tw-absolute tw-bottom-0 tw-top-0 tw-flex tw-items-center tw-justify-start tw-left-[90%]'>
                                    <div className='tw-flex tw-justify-center tw-items-center'>
                                        <img height="auto" width="auto" src="/galaxy/circle-icon.svg" alt="circle-text-icon" />
                                        <img height="auto" width="auto" src="/galaxy/icon-arrow.svg" alt="text-icon-arrow" className='transform tw-rotate-90 tw-ml-2' />
                                    </div>
                                    <p className='mir-p-sm-medium mir-text-neutral-100 tw-w-[200px] tw-m-0 tw-ml-3'>Power BI</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to={MIRRoutes.PowerApps}>
                        <div className={`tw-mb-[120px] cursor-pointer tw-h-[70px] tw-w-[70px] tw-rounded-full tw-flex tw-items-center tw-justify-center tw-from-[#27518A] tw-to-transparent tw-p-[2px] tw-bg-gradient-to-br transition hover:tw-bg-blue-400 tw-cursor-pointer tw-mb-[29px]`}>
                            <div className="tw-bg-[#030a2b] tw-rounded-full tw-relative tw-h-full tw-w-full tw-p-[24px] tw-flex tw-items-center tw-justify-center tw-group">
                                <img height="auto" width="auto" src="/galaxy/icons/power-apps.svg" alt="ring-app-icon" className='tw-transform group-hover:tw-scale-90 tw-transition tw-w-[32px]' />
                                <div className='tw-py-[8px] tw-absolute tw-bottom-0 tw-top-0 tw-flex tw-items-center tw-justify-start tw-left-[90%]'>
                                    <div className='tw-flex tw-justify-center tw-items-center'>
                                        <img height="auto" width="auto" src="/galaxy/circle-icon.svg" alt="circle-text-icon" />
                                        <img height="auto" width="auto" src="/galaxy/icon-arrow.svg" alt="text-icon-arrow" className='transform tw-rotate-90 tw-ml-2' />
                                    </div>
                                    <p className='mir-p-sm-medium mir-text-neutral-100 tw-w-[200px] tw-m-0 tw-ml-3'>Power Apps</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}