
const companyImages = [
    { id: crypto.randomUUID(), image: "/company-logos/2db7a9a8-aa1e-4f2c-b02c-9dff1421beac 1.png"},
    { id: crypto.randomUUID(), image: "/company-logos/6Harmonics.png"},
    { id: crypto.randomUUID(), image: "/company-logos/AA_pharmachem_logo 1.png"},
    { id: crypto.randomUUID(), image: "/company-logos/AGMD_group_logo 1.png"},
    { id: crypto.randomUUID(), image: "/company-logos/Bell.png"},
    { id: crypto.randomUUID(), image: "/company-logos/blake-logo@logotyp.us 2.png"},
    // { id: crypto.randomUUID(), image: "/company-logos/CIBC_logo_2021 2.png"},
    { id: crypto.randomUUID(), image: "/company-logos/DMC Mining  2.png"},
    { id: crypto.randomUUID(), image: "/company-logos/make-a-wish-vector-logo 2.png"},
    { id: crypto.randomUUID(), image: "/company-logos/metrolinx-vector-logo 1.png"},
    { id: crypto.randomUUID(), image: "/company-logos/Rogers_logo 2.png"},
    { id: crypto.randomUUID(), image: "/company-logos/svgexport-1 (5) 1.png"},
    { id: crypto.randomUUID(), image: "/company-logos/telecon-logo 2 1.png"},
    { id: crypto.randomUUID(), image: "/company-logos/Toronto-Dominion_Bank_logo 2.png"},
    { id: crypto.randomUUID(), image: "/company-logos/transunion_logo 1.png"},
    { id: crypto.randomUUID(), image: "/company-logos/wash-laundry-logo 1.png"},
    { id: crypto.randomUUID(), image: "/company-logos/Woodgreen logo 2.png"},
    { id: crypto.randomUUID(), image: "/company-logos/ceg-bg-black.png"},
    { id: crypto.randomUUID(), image: "/company-logos/neighborly-mark-white.png"},
    // { id: crypto.randomUUID(), image: "/company-logos/Glencore_logo-white.svg"},
    // { id: crypto.randomUUID(), image: "/company-logos/CCC-logo-colour.webp"},
]

const Banner = ({ images, speed = 0 }) => {
    return (
        <div className="inner">
            <div className="wrapper">
                <section className="banner-sec" style={{ "--speed": `${45000}ms` }}>
                    {companyImages.map(({ id, image }) => (
                        <div className="tw-flex tw-items-center tw-justify-center" key={id}>
                            <img height="auto" width="auto" className="banner-img" src={image} alt={id} />
                        </div>
                    ))}
                </section>
                <section className="banner-sec" style={{ "--speed": `${45000}ms` }}>
                    {companyImages.map(({ id, image }) => (
                        <div className="tw-flex tw-items-center tw-justify-center" key={id}>
                            <img height="auto" width="auto" className="banner-img" src={image} alt={id} />
                        </div>
                    ))}
                </section>
                <section className="banner-sec" style={{ "--speed": `${45000}ms` }}>
                    {companyImages.map(({ id, image }) => (
                        <div className="tw-flex tw-items-center tw-justify-center" key={id}>
                            <img height="auto" width="auto" className="banner-img tw-object-contain" src={image} alt={id} />
                        </div>
                    ))}
                </section>
            </div>
        </div>
    );
};

export { Banner };
