import React, { useState } from 'react'
import Footer from '../Footer/Footer'
import StickyBanner from '../StickyBanner/StickyBanner'
import BookNowModal from '../CommonElements/BookNowModal'

export default function TimeIt() {

    const [showRegionPopup, setShowRegionPopup] = useState(false);

    return (
        <main>
            <StickyBanner />
            <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />

            <section className='tw-relative mir-bg-neutral-100'>
                <div className='container mx-auto'>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2">
                        <div className='tw-py-[120px] tw-w-full tw-h-full tw-flex tw-items-center tw-justify-start'>
                            <div>
                                <h4 className='tw-uppercase mir-p-overline-lg mir-text-primary-500 tw-mb-[16px]'>workforce management solution</h4>
                                <h1 className='mir-display-semibold-lg mir-text-neutral-800 tw-mb-[24px]'>One unified time management software</h1>
                                <p className='tw-mb-[52px] mir-text-neutral-600 mir-p-rg-regular tw-max-w-full md:tw-max-w-[592px]'>Meet the support platform that works the way you work with your customers. Incredibly fast, integrated with Linear and Slack, and a powerful API for everything else.</p>
                                <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-lg mir-btn-lg-primary'>Explore More</button>
                            </div>
                        </div>
                        <div className='tw-w-full tw-h-full tw-flex tw-items-end tw-justify-start'>
                            <img src="/timeit/hero-bg.png" alt="time-it-application"/>
                        </div>
                    </div>
                </div>
            </section>

            <section className='tw-py-[96px]'>
                <div className="container mx-auto">
                    <h2 className='mir-h1-semibold mir-text-neutral-800 tw-mb-[24px] mx-auto text-center '>Empower your employee workforce</h2>
                    <p className='mir-text-neutral-600 tw-max-w-full mir-p-md-regular tw-mb-[64px] md:tw-max-w-[590px] mx-auto text-center'>Upgrade from Excel sheets, outdated timesheet templates, or punch cards to TimeIt for free. </p>
                    <div className="tw-flex tw-items-start tw-justify-center tw-flex-wrap">
                        <div className='tw-max-w-full md:tw-max-w-[390px] tw-mr-[32px] tw-mb-[64px]'> 
                            <img src="/timeit/icons/1.png" className='tw-mb-[24px]' alt="timeit-feature-icon" />
                            <h4 className='mir-text-neutral-800 mir-h6-semibold tw-mb-[12px]'>Flexible Submission Frequency</h4>
                            <p className='mir-p-rg-regular mir-text-neutral-600'>Customize your submission frequency to your liking – choose from weekly, bi-weekly, semi-monthly, or monthly timesheets.</p>
                        </div>
                        <div className='tw-max-w-full md:tw-max-w-[390px] tw-mr-[32px] tw-mb-[64px]'> 
                            <img src="/timeit/icons/2.png" className='tw-mb-[24px]' alt="timeit-feature-icon" />
                            <h4 className='mir-text-neutral-800 mir-h6-semibold tw-mb-[12px]'>Project-Based Approvers</h4>
                            <p className='mir-p-rg-regular mir-text-neutral-600'>Setup approvers by projects and let them approve timesheets sequentially. There are no limits to the number of approvers.  </p>
                        </div>
                        <div className='tw-max-w-full md:tw-max-w-[390px] tw-mr-[32px] tw-mb-[64px]'> 
                            <img src="/timeit/icons/3.png" className='tw-mb-[24px]' alt="timeit-feature-icon" />
                            <h4 className='mir-text-neutral-800 mir-h6-semibold tw-mb-[12px]'>Intelligent Time Calculator</h4>
                            <p className='mir-p-rg-regular mir-text-neutral-600'>With TimeIt's intelligent time calculator logic, overtime hours are automatically calculated, saving you time and ensuring accuracy.</p>
                        </div>
                        <div className='tw-max-w-full md:tw-max-w-[390px] tw-mr-[32px] tw-mb-[64px]'> 
                            <img src="/timeit/icons/4.png" className='tw-mb-[24px]' alt="timeit-feature-icon" />
                            <h4 className='mir-text-neutral-800 mir-h6-semibold tw-mb-[12px]'>Timely Notifications</h4>
                            <p className='mir-p-rg-regular mir-text-neutral-600'>Enjoy timely in-app notifications and email notifications for swift timesheet approvals. </p>
                        </div>
                        <div className='tw-max-w-full md:tw-max-w-[390px] tw-mr-[32px] tw-mb-[64px]'> 
                            <img src="/timeit/icons/4.png" className='tw-mb-[24px]' alt="timeit-feature-icon" />
                            <h4 className='mir-text-neutral-800 mir-h6-semibold tw-mb-[12px]'>Mobile Accessibility</h4>
                            <p className='mir-p-rg-regular mir-text-neutral-600'>Timesheets are easily captured wherever you are, accessible right from your mobile phone. </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='tw-py-[80px]' style={{ backgroundImage: 'url(/timeit/dot-bg.png)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="container mx-auto text-center">
                    <h2 className='mir-text-neutral-800 text-center mx-auto tw-max-w-full md:tw-max-w-[800px] tw-mb-[24px] mir-h2-semibold'>TimeIt makes it easy <br /> <span className='mir-text-neutral-600'>to track and manage your team's work hours</span></h2>
                    <p className='mir-text-neutral-600 tw-mb-[52px] text-center mir-p-md-regular tw-max-w-full md:tw-max-w-[800px] mx-auto'>Accurate timekeeping is vital for any business, as it directly impacts your financial bottom line. It's crucial to have a clear picture of how your employees are utilizing their time.</p>
                    <button onClick={() => { setShowRegionPopup(true) }} className='mx-auto mir-btn-lg mir-btn-lg-primary'>{`Explore More ->`}</button>
                </div>
            </section>

            <section className='tw-py-[120px]'>
                <div className="container mx-auto">
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-py-[72px] tw-gap-[104px] !tw-border-l-[#fff] md:!tw-border-l-[#D1E5FF]" style={{ borderLeft: '1px solid #D1E5FF' }}>
                        <div className='tw-pl-[31px] tw-relative'>
                            <div className='tw-h-[50px]'></div>
                            <div className='tw-relative'>
                                <div className='tw-hidden md:tw-block tw-py-[10px] tw-bg-white tw-absolute tw-left-[-38px] tw-top-[-11px]'>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="7" cy="7" r="6.75" stroke="#1D68F0" stroke-width="0.5"/>
                                        <circle cx="7" cy="7" r="4" fill="#1D68F0"/>
                                    </svg>
                                </div>
                                <h4 className='tw-uppercase mir-text-primary-500 mir-p-overline-lg tw-mb-[16px]'>Secured</h4>
                            </div>
                            <h2 className='mir-text-neutral-800 mir-h2-semibold tw-mb-[24px] tw-max-w-full md:tw-max-w-[395px]'>Anti-fraud detection and resolution</h2>
                            <p className='mir-p-md-regular mir-text-neutral-700 tw-mb-[16px]'>TimeIt includes vital business rules for efficient employee attendance management, especially for hourly contract workers. </p>
                            <ul>
                                <li className='mir-p-md-regular mir-text-neutral-700 tw-mb-[16px]'>Covering aspects from work hours to breaks, overtime, and geofencing.</li>
                                <li className='mir-p-md-regular mir-text-neutral-700'>Prevent time fraud and identify discrepancies, saving up to 18-20% of project costs.</li>
                            </ul>
                        </div>
                        <div>
                            <img src="/timeit/features/1.png" className='tw-w-full' alt="timeit-feature-1" />
                        </div>
                    </div>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-py-[72px] tw-gap-[104px] !tw-border-l-[#fff] md:!tw-border-l-[#D1E5FF]" style={{ borderLeft: '1px solid #D1E5FF' }}>
                        <div className='tw-pl-[31px] tw-relative'>
                            <div className='tw-h-[50px]'></div>
                            <div className='tw-relative'>
                                <div className='tw-hidden md:tw-block tw-py-[10px] tw-bg-white tw-absolute tw-left-[-38px] tw-top-[-11px]'>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="7" cy="7" r="6.75" stroke="#1D68F0" stroke-width="0.5"/>
                                        <circle cx="7" cy="7" r="4" fill="#1D68F0"/>
                                    </svg>
                                </div>
                                <h4 className='tw-uppercase mir-text-primary-500 mir-p-overline-lg tw-mb-[16px]'>Insightful</h4>
                            </div>
                            <h2 className='mir-text-neutral-800 mir-h2-semibold tw-mb-[24px] tw-max-w-full md:tw-max-w-[592px]'>Real-time time tracking dashboards for every user</h2>
                            <ul>
                                <li className='mir-p-md-regular mir-text-neutral-700 tw-mb-[16px]'>Customized dashboards for different user roles – Employees, Contractors, Supervisors, Approvers, and Administrators.</li>
                                <li className='mir-p-md-regular mir-text-neutral-700 tw-mb-[16px]'>Easily view submitted timesheets categorized by intervals like Daily, Weekly, and Monthly.</li>
                                <li className='mir-p-md-regular mir-text-neutral-700'>Empower Administrators with insights into project time allocation for informed resource planning.</li>
                            </ul>
                        </div>
                        <div>
                            <img src="/timeit/features/2.png" className='tw-w-full' alt="timeit-feature-1" />
                        </div>
                    </div>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-py-[72px] tw-gap-[104px] !tw-border-l-[#fff] md:!tw-border-l-[#D1E5FF]" style={{ borderLeft: '1px solid #D1E5FF' }}>
                        <div className='tw-pl-[31px] tw-relative'>
                            <div className='tw-h-[50px]'></div>
                            <div className='tw-relative'>
                                <div className='tw-hidden md:tw-block tw-py-[10px] tw-bg-white tw-absolute tw-left-[-38px] tw-top-[-11px]'>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="7" cy="7" r="6.75" stroke="#1D68F0" stroke-width="0.5"/>
                                        <circle cx="7" cy="7" r="4" fill="#1D68F0"/>
                                    </svg>
                                </div>
                                <h4 className='tw-uppercase mir-text-primary-500 mir-p-overline-lg tw-mb-[16px]'>Supervise with Ease</h4>
                            </div>
                            <h2 className='mir-text-neutral-800 mir-h2-semibold tw-mb-[24px] tw-max-w-full md:tw-max-w-[592px]'>Empowered supervisors</h2>
                            <ul>
                                {
                                    [
                                        'TimeIt was developed with a motivation to assist Supervisors in efficiently managing employee timesheets.',
                                        'Especially beneficial in field service environments with contractor employees.',
                                        'Simplify scheduling, timesheet handling, and accurate time tracking – all directly from mobile phones.',
                                    ].map((item, index) => {
                                        return <li key={`feature-item-${index}`} className='mir-p-md-regular mir-text-neutral-700 tw-mb-[16px]'>{item}</li>
                                    })
                                }
                            </ul>
                        </div>
                        <div>
                            <img src="/timeit/features/3.png" className='tw-w-full' alt="timeit-feature-3" />
                        </div>
                    </div>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-py-[72px] tw-gap-[104px] !tw-border-l-[#fff] md:!tw-border-l-[#D1E5FF]" style={{ borderLeft: '1px solid #D1E5FF' }}>
                        <div className='tw-pl-[31px] tw-relative'>
                            <div className='tw-h-[50px]'></div>
                            <div className='tw-relative'>
                                <div className='tw-hidden md:tw-block tw-py-[10px] tw-bg-white tw-absolute tw-left-[-38px] tw-top-[-11px]'>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="7" cy="7" r="6.75" stroke="#1D68F0" stroke-width="0.5"/>
                                        <circle cx="7" cy="7" r="4" fill="#1D68F0"/>
                                    </svg>
                                </div>
                                <h4 className='tw-uppercase mir-text-primary-500 mir-p-overline-lg tw-mb-[16px]'>Flexible</h4>
                            </div>
                            <h2 className='mir-text-neutral-800 mir-h2-semibold tw-mb-[24px] tw-max-w-full md:tw-max-w-[592px]'>Unlimited project tracking and approvers</h2>
                            <ul>
                                {
                                    [
                                        'Unlike competitors limiting free versions, TimeIt offers unlimited project tracking.',
                                        'Track as many projects as needed without constraints.',
                                    ].map((item, index) => {
                                        return <li key={`feature-item-${index}`} className='mir-p-md-regular mir-text-neutral-700 tw-mb-[16px]'>{item}</li>
                                    })
                                }
                            </ul>
                        </div>
                        <div>
                            <img src="/timeit/features/4.png" className='tw-w-full' alt="timeit-feature-3" />
                        </div>
                    </div>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-py-[72px] tw-gap-[104px] !tw-border-l-[#fff] md:!tw-border-l-[#D1E5FF]" style={{ borderLeft: '1px solid #D1E5FF' }}>
                        <div className='tw-pl-[31px] tw-relative'>
                            <div className='tw-h-[50px]'></div>
                            <div className='tw-relative'>
                                <div className='tw-hidden md:tw-block tw-py-[10px] tw-bg-white tw-absolute tw-left-[-38px] tw-top-[-11px]'>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="7" cy="7" r="6.75" stroke="#1D68F0" stroke-width="0.5"/>
                                        <circle cx="7" cy="7" r="4" fill="#1D68F0"/>
                                    </svg>
                                </div>
                                <h4 className='tw-uppercase mir-text-primary-500 mir-p-overline-lg tw-mb-[16px]'>EFFORTLESS</h4>
                            </div>
                            <h2 className='mir-text-neutral-800 mir-h2-semibold tw-mb-[24px] tw-max-w-full md:tw-max-w-[592px]'>Effortless timesheet attendance approval for business and external stakeholders</h2>
                            <ul>
                                {
                                    [
                                        'Simplify timesheet approval for business and external stakeholders with TimeIt.',
                                        'Conveniently approve employee time via email without logging into the app.',
                                        'Intelligent application synchronizes email approvals, ensuring timely payroll processing.',
                                    ].map((item, index) => {
                                        return <li key={`feature-item-${index}`} className='mir-p-md-regular mir-text-neutral-700 tw-mb-[16px]'>{item}</li>
                                    })
                                }
                            </ul>
                        </div>
                        <div>
                            <img src="/timeit/features/5.png" className='tw-w-full' alt="timeit-feature-5" />
                        </div>
                    </div>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-py-[72px] tw-gap-[104px] !tw-border-l-[#fff] md:!tw-border-l-[#D1E5FF]" style={{ borderLeft: '1px solid #D1E5FF' }}>
                        <div className='tw-pl-[31px] tw-relative'>
                            <div className='tw-h-[50px]'></div>
                            <div className='tw-relative'>
                                <div className='tw-hidden md:tw-block tw-py-[10px] tw-bg-white tw-absolute tw-left-[-38px] tw-top-[-11px]'>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="7" cy="7" r="6.75" stroke="#1D68F0" stroke-width="0.5"/>
                                        <circle cx="7" cy="7" r="4" fill="#1D68F0"/>
                                    </svg>
                                </div>
                                <h4 className='tw-uppercase mir-text-primary-500 mir-p-overline-lg tw-mb-[16px]'>Automated</h4>
                            </div>
                            <h2 className='mir-text-neutral-800 mir-h2-semibold tw-mb-[24px] tw-max-w-full md:tw-max-w-[592px]'>Automated invoice generation</h2>
                            <ul>
                                {
                                    [
                                        "TimeIt automates employee invoice generation based on Administrators' schedules.",
                                        "Adaptable to pay cycles, providing efficiency for bi-weekly processing.",
                                        "Admins can set their pay cycles easily from the admin panel for seamless financial operations.",
                                    ].map((item, index) => {
                                        return <li key={`feature-item-${index}`} className='mir-p-md-regular mir-text-neutral-700 tw-mb-[16px]'>{item}</li>
                                    })
                                }
                            </ul>
                        </div>
                        <div>
                            <img src="/timeit/features/6.png" className='tw-w-full' alt="timeit-feature-6" />
                        </div>
                    </div>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-py-[72px] tw-gap-[104px] !tw-border-l-[#fff] md:!tw-border-l-[#D1E5FF]" style={{ borderLeft: '1px solid #D1E5FF' }}>
                        <div className='tw-pl-[31px] tw-relative'>
                            <div className='tw-h-[50px]'></div>
                            <div className='tw-relative'>
                                <div className='tw-hidden md:tw-block tw-py-[10px] tw-bg-white tw-absolute tw-left-[-38px] tw-top-[-11px]'>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="7" cy="7" r="6.75" stroke="#1D68F0" stroke-width="0.5"/>
                                        <circle cx="7" cy="7" r="4" fill="#1D68F0"/>
                                    </svg>
                                </div>
                                <h4 className='tw-uppercase mir-text-primary-500 mir-p-overline-lg tw-mb-[16px]'>Seamless</h4>
                            </div>
                            <h2 className='mir-text-neutral-800 mir-h2-semibold tw-mb-[24px] tw-max-w-full md:tw-max-w-[592px]'>Seamless attendance management with geofencing</h2>
                            <ul>
                                {
                                    [
                                        'Geofencing in TimeIt determines precise employee locations during work hours.',
                                        'Serving multiple purposes, including employee tracking, fraud prevention, and assistance when necessary.',
                                    ].map((item, index) => {
                                        return <li key={`feature-item-${index}`} className='mir-p-md-regular mir-text-neutral-700 tw-mb-[16px]'>{item}</li>
                                    })
                                }
                            </ul>
                        </div>
                        <div>
                            <img src="/timeit/features/7.png" className='tw-w-full' alt="timeit-feature-7" />
                        </div>
                    </div>
                </div>
            </section>

            <section className='tw-py-[96px] mir-bg-neutral-100 text-center'>
                <div className="container mx-auto">
                    <h2 className='mir-h1-semibold mir-text-neutral-800 tw-max-w-full md:tw-max-w-[664px] tw-mb-[24px] mx-auto'>Ready to elevate your business efficiency with TimeIt?</h2>
                    <h5 className='tw-mb-[52px] mir-text-neutral-500 mir-p-md-medium'>Let we help you transform your time management effortlessly.</h5>
                    <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-lg mir-btn-lg-primary'>{`Explore More ->`}</button>
                </div>
            </section>

            <Footer></Footer>
        </main>
    )
}
