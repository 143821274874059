import Slider from "react-slick";
import React from "react";
import {ReactComponent as PlayButtonSvg} from "../svg/play-btn.svg";


const SwipeSlider = ({cards}) => {
    const settings = {
        infinite: true,
        centerPadding: "0", // Adjust padding as needed
        slidesToShow: 2, // Show 2 items per page
        swipeToSlide: true,
        autoplay: true,
        dots: false,
        arrows: false,

        responsive: [
            {
                breakpoint: 575.98,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className='swipe-slider'>
            <div className='row'>
                {/*<div className='col-12'>*/}
                <Slider {...settings}>
                    {cards.map((card, index) => (
                        <a href={card.link} target="_blank" rel="noopener noreferrer" className="gradient-overlay-black">
                            <img className='card-image' src={card.imageUrl} alt={card.title}/>
                            <div className="overlay-content d-flex align-items-end justify-content-between w-100">
                                <div
                                    className="text-container text-white mb-0 mir-h4-semibold flex-fill text-start">
                                    {card.title}
                                </div>
                                <div className="play-button-container d-flex justify-content-end flex-fill">
                                    <PlayButtonSvg/>
                                </div>
                            </div>
                        </a>
                    ))}
                </Slider>
            </div>
            {/*</div>*/}
        </div>
    );
};

export default SwipeSlider;
