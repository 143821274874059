import {Link} from "react-router-dom";

const FuelYourPassionSection = () => {
    return (
        <section className="passion-section">
            <div className="container">
                <div className='passion-content row justify-content-center'>
                    <div className='row justify-content-center'>
                        <div className="col-12 col-md-8 text-center">
                            <h4 className="mir-text-primary-500 mir-p-overline-lg mb-4 passion-title">What We Do</h4>
                            <h2 className="mir-display-semibold-sm passion-subtitle">Fuel your passion,<br/> elevate
                                your growth</h2>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className="col-12 col-md-10 text-center">
                            <p className="mir-p-md-regular passion-text">Energized by our start-up spirit, we foster
                                tight-knit teams that seamlessly collaborate across various functions. Dive into a
                                culture of continuous learning, where we fearlessly tackle ambitious challenges. We get
                                it – success in your career is no longer just ticking traditional boxes; it's about
                                embracing flexibility and having the freedom to sculpt the career you dream of. Come
                                join us for a journey where innovation and personal growth matter!</p>
                            <div className="d-grid gap-2 d-md-inline-block">
                                <Link to="/about-us/mir-team" className="btn btn-primary mir-btn-md mir-btn-md-primary">
                                    <span className="mir-p-rg-medium mir-text-white">Discover our core values</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>);
};

export default FuelYourPassionSection;
