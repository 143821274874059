import React, { useState } from 'react'
import StickyBanner from '../StickyBanner/StickyBanner'
import Footer from '../Footer/Footer'
import CaseStudiesContent from '../../lib/caseStudiesContent'
import BookNowModal from '../CommonElements/BookNowModal';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function CaseStudies() {
    const [showRegionPopup, setShowRegionPopup] = useState(false);
    const navigate = useNavigate();

    return (
        <main>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Microsoft Success Case Studies | MIR Digital Solutions</title>
                <meta name="description" content=" Explore our client's Microsoft Success Stories and witness how MIR unlocked their business potential with real-world results, fueling exponential growth" />
                <link rel="canonical" href={`${window.location.origin}/about-us/case-studies`} />
            </Helmet>

            <StickyBanner />
            <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />
            <section className='tw-pt-[80px] tw-pb-[96px] bg-no-repeat' style={{ backgroundImage: 'url(/case-studies/hero-bg.png)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="container mx-auto">
                    <div className='text-center mx-auto tw-max-w-full md:tw-max-w-[447px]'>
                        <h1 className='mir-display-semibold-sm mir-text-neutral-100 tw-mb-[16px]'>Case studies</h1>
                        <h4 className='mir-text-neutral-400 mir-p-md-regular'>Microsoft Success Stories: Unlocking Business Potential with Real-World Results </h4>
                    </div>
                </div>
            </section>

            <section className='tw-pt-[72px] tw-pb-[120px]'>
                <div className="container mx-auto">
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-y-[52px] md:tw-gap-x-[42px]">
                        <div className="tw-col-span-2 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-x-[32px] tw-gap-y-[80px]">
                            {
                                CaseStudiesContent.map((item, index) => {
                                    return <div className='tw-w-full tw-group tw-cursor-pointer' onClick={() => { navigate(`/about-us/case-studies/${item.metaSlug}`) }}>
                                        <div className='tw-relative tw-mb-[32px] tw-w-[389.5px] tw-h-[250px] tw-overflow-hidden tw-rounded-[8px]'>
                                            <img src={item.image} className='tw-w-full tw-object-cover tw-h-full tw-transition tw-transform group-hover:tw-scale-105' alt={item.title} />
                                        </div>
                                        <h4 className='tw-uppercase mir-p-overline-lg mir-text-neutral-400 tw-mb-[16px]'>{item.date}</h4>
                                        <h3 className='mir-text-neutral-800 group-hover:tw-text-[#004BD3] mir-h6-bold tw-pr-4'>{item.title}</h3>
                                    </div>
                                })
                            }
                        </div>
                        <div className='tw-col-span-1 tw-flex tw-justify-end md:tw-justify-start tw-relative md:tw-pl-[52px] tw-border-l-none md:tw-border-l-solid' style={{ borderLeft: '1px solid var(--neutral-200, #EEEEF2)' }}>
                            <div className='text-center tw-h-fit tw-rounded-[4px] tw-sticky tw-top-[79px] mir-bg-neutral-100 tw-p-[36px] tw-max-w-full md:tw-max-w-[280px]'>
                                <h4 className='mir-neutral-800 mir-h4-bold tw-mb-[12px]'>Schedule a meeting today</h4>
                                <p className='mir-neutral-800 mir-p-rg-medium tw-mb-[42px]'>with a Microsoft expert to streamline your business operations</p>
                                <img src="/case-studies/send-email.svg" className='tw-mb-[42px] mx-auto' alt="send-email-icon" />
                                <button onClick={() => { setShowRegionPopup(true) }} className='tw-w-full mir-btn-lg mir-btn-primary mir-p-rg-medium'>Schedule Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </main>
    )
}
