import React from 'react'
import './index.scss'

export default function Challenges({ overline, heading, challenges, sideImage }) {

    return (
        <div className="cloud-solutions challenges">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-6">
                        <div className="right-content">
                            <p className="mir-p-overline-lg">{overline}</p>
                            <h3 className='mir-h3-semibold'>{heading}</h3>
                        </div>
                        <div className="left-content" style={{ marginTop: '2.25rem' }}>
                            {
                                challenges.map((item) => {
                                    return <div className='item'>
                                        <img src="/supply-chain/challenge-icon.svg" className='me-3' alt="challenge-icon" />
                                        <p className="mir-p-rg-regular">{item}</p>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6">
                        <img className='challenge-image w-100' src={sideImage} alt="challenge-image-bg" />  
                    </div>
                </div>
            </div>
        </div>
    )
}
