const MIRRoutes = {
    Home: "/",
    SupplyChain: "/cloud-solutions/dynamics-365-supply-chain-management-solution",
    ProjectOperations: "/cloud-solutions/dynamics-365-project-operations-solution",
    Sales: "/cloud-solutions/dynamics-365-sales-crm-solution",
    BusinessCentral: "/cloud-solutions/dynamics-365-business-central-solution",
    BusinessIntelligence: "/business-solutions/business-intelligence-data-analytics",
    BusinessScience: "/business-solutions/data-science-and-ai",
    PowerApps: "/cloud-solutions/microsoft-power-apps-solution",
    PowerBI: "/cloud-solutions/microoft-power-bi-intelligence-solution",
    StaffingSolutions: "/business-solutions/digital-staffing-solutions",
    ProjectRescue: "/business-solutions/project-rescue",
    ERP: "/business-solutions/enterprise-resource-planning-erp",
    CRM: "/business-solutions/customer-relationship-management",
    CloudServices: "/business-solutions/cloud-services",
    Contact: "/contact-us",
    CaseStudies: '/about-us/case-studies',
    MIRCulture: '/mir-culture',
    PrivacyPolicy: "/privacy-policy",
    BCBundles: "/cloud-solutions/dynamics-365-business-central-solution/sales-bundles",
    CRMBundles: "/business-solutions/customer-relationship-management/sales-bundles",
    InterviewAssesment: '/business-solutions/recruit-app',
    MIRTeam: '/about-us/mir-team',
    SharePoint: '/cloud-solutions/microsoft-sharepoint-solution',
    Microsoft365: '/cloud-solutions/microsoft-365-productivity-solution',
    LowCode: '/business-solutions/low-code-development',
    TimeIt: '/business-solutions/timeit',
}

export const ContactFormIdLabel = {
    Home: { url: "/", formId: 'pushform_home'},
    SupplyChain: { url: "/cloud-solutions/dynamics-365-supply-chain-management-solution", formId: 'pushform_supplychain'},
    ProjectOperations: { url: "/cloud-solutions/dynamics-365-project-operations-solution", formId: 'pushform_projectoperations'},
    Sales: { url: "/cloud-solutions/dynamics-365-sales-crm-solution", formId: 'pushform_sales'},
    BusinessCentral: { url: "/cloud-solutions/dynamics-365-business-central-solution", formId: 'pushform_businesscentral'},
    BusinessIntelligence: { url: "/business-solutions/business-intelligence-data-analytics", formId: 'pushform_businessintelligence'},
    BusinessScience: { url: "/business-solutions/data-science-and-ai", formId: 'pushform_science_and_ai'},
    PowerApps: { url: "/cloud-solutions/microsoft-power-apps-solution", formId: 'pushform_powerapps'},
    PowerBI: { url: "/cloud-solutions/microoft-power-bi-intelligence-solution", formId: 'pushform_powerbi'},
    StaffingSolutions: { url: "/business-solutions/digital-staffing-solutions", formId: 'pushform_staffingsolutions'},
    ProjectRescue: { url: "/business-solutions/project-rescue", formId: 'pushform_projectrescue'},
    ERP: { url: "/business-solutions/enterprise-resource-planning-erp", formId: 'pushform_erp'},
    CRM: { url: "/business-solutions/customer-relationship-management", formId: 'pushform_crm'},
    CloudServices: { url: "/business-solutions/cloud-services", formId: 'pushform_cloudservices'},
    Contact: { url: "/contact-us", formId: 'pushform_contact'},
    CaseStudies: { url: '/about-us/case-studies', formId: 'pushform_casestudies'},
    PrivacyPolicy: { url: "/privacy-policy", formId: 'pushform_privacypolicy'},
    BCBundles: { url: "/cloud-solutions/dynamics-365-business-central-solution/sales-bundles", formId: 'pushform_bcbundles'},
    CRMBundles: { url: "/business-solutions/customer-relationship-management/sales-bundles", formId: 'pushform_crmbundles'},
    InterviewAssesment: { url: '/business-solutions/recruit-app', formId: 'pushform_interviewassesment'},
    MIRTeam: { url: '/about-us/mir-team', formId: 'pushform_mirteam'},
    SharePoint: { url: '/cloud-solutions/microsoft-sharepoint-solution', formId: 'pushform_sharepoint'},
    Microsoft365: { url: '/cloud-solutions/microsoft-365-productivity-solution', formId: 'pushform_365'},
    LowCode: { url: '/business-solutions/low-code-development', formId: 'pushform_lowcode'},
    TimeIt: { url: '/business-solutions/timeit', formId: 'pushform_timeit'},
}

export default MIRRoutes;