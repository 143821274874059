import Slider from "react-slick";
import React from "react";

const Departments = () => {

    const centerPaddingValue = window.innerWidth < 768 ? '0%' : '20%';

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: centerPaddingValue,
        slidesToShow: 1,
        speed: 1500,
        dots: false,
        autoplay: true,
        arrows: false,

        responsive: [
            {
                breakpoint: 575.98,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const departmentDetails = [
        {
            imageUrl: '/mir-culture/images/departments/s&m.jpeg',
            caption: 'Sales and Marketing team'
        },
        {
            imageUrl: '/mir-culture/images/departments/engineering_group.jpeg',
            caption: 'Product and Engineering team'
        },
        {
            imageUrl: '/mir-culture/images/departments/HR_group.jpeg',
            caption: 'Corporate Operations team'
        }
    ];

    return (
        <section className='departments-section mir-bg-neutral-800'>
            <div className='departments-container'>
                <div className='row'>
                    <Slider {...settings}>
                        {departmentDetails.map((department, index) => (
                            <div key={index} className="slider-card">
                                <div className='image-container'>
                                    <img src={department.imageUrl} alt={department.caption}
                                         className='department-image'/>
                                    <div
                                        className='caption-overlay mir-text-neutral-100 mir-h6-semibold'>{department.caption}</div>
                                </div>

                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    );
}


export default Departments;