import React from 'react'
import './index.scss'

export default function TransformationSection({
    heading, desc, items
}) {
  return (
    <section className='cloud-solutions transformation-wrapper'>
        <div className="container">
            <h3 className='mir-h2-semibold'>{heading}</h3>
            <p className='mir-p-md-regular'>{desc}</p>
            <div className="row icons">
                {
                    items.map((item) => {
                        return <div className="col-12 col-md-4 item mb-5">
                            <div>
                                <img src={item.icon} alt="transformation-icon" />
                                <h5 className='mir-h6-semibold'>{item.heading}</h5>
                            </div>
                            <a className='link tw-cursor-pointer'>
                                {item.link}
                            </a>
                        </div>
                    })
                }
            </div>
        </div>
    </section>
  )
}
