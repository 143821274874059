import React, { useEffect, useState } from 'react'
import StickyBanner from '../../StickyBanner/StickyBanner';
import Footer from '../../Footer/Footer';
import BookNowModal from '../../CommonElements/BookNowModal';
import SectionTabs from '../../CloudSolutions/Elements/Sections/SectionTabs/SectionTabs';
import { Helmet } from "react-helmet";
import HowHelpSection from '../../CloudSolutions/Elements/Sections/HowHelpSection';
import StepsSlider from '../../CloudSolutions/Elements/StepProcess/StepProcess';
import { Banner } from '../../HomeComponent/Banner';
import blakes from '../../HomeComponent/images/blakes.png';
import DMCMining2 from '../../HomeComponent/images/DMCMining2.svg';
import metrolinx from '../../HomeComponent/images/metrolinx.svg';
import Rogers from '../../HomeComponent/images/Rogers_logo.svg';
import transunion from '../../HomeComponent/images/transunion_logo.svg';
import SuccessStories from '../../CommonElements/SuccessStories';
import TransformationSection from '../../CloudSolutions/Elements/Sections/TransformationSection';
import ContactSection from '../../CloudSolutions/Elements/Sections/ContactSection';
import { Link } from 'react-router-dom';
import MIRRoutes from '../../../lib/routes';
import SuccessStoriesDynamic from '../../CommonElements/SuccessStoriesDynamic';

const images = [
    blakes,
    DMCMining2,
    metrolinx,
    Rogers,
    transunion,
    blakes,
    DMCMining2,
    metrolinx,
    Rogers,
    transunion,
    blakes,
    DMCMining2,
    metrolinx,
    Rogers,
    transunion
].map((image) => ({
    id: crypto.randomUUID(),
    image
}));

export default function CRM() {

    const [tabs, setTabs] = useState([
        { name: 'Overview', id: 'section-1' },
        { name: 'Introduction', id: 'section-2' },
        { name: 'Benefits', id: 'section-3' },
        { name: 'Service Bundles', id: 'section-7' },
        { name: 'How we can help', id: 'section-4' },
        { name: 'Case studies', id: 'section-5' },
        { name: 'Contact Us', id: 'section-6' },
    ]);

    const successStoriesContent = [
        {
            image: "/success-stories-custom/12.png",
            timeToRead: "3",
            desc: "Boosting Internal Efficiency: Construction and Mining Leader Integrates Self-Serve Portal With Microsoft Dynamics 365",
            linkToRead: "/about-us/case-studies/self-serve-portal"
        },
        {
            image: "/success-stories-custom/8.png",
            timeToRead: "10",
            desc: "Leading Law Firm Reduced 300+ Hours of Manual Work with MIR Interview Assessment Solution",
            linkToRead: "/about-us/case-studies/law-firm-azure-interview-assessment-tool"
        },
        {
            image: "/success-stories-custom/10.png",
            timeToRead: "5",
            desc: "MIR and Compugen’s partnership transforms WoodGreen Tax Clinic services with Microsoft Power Platform, reducing wait times significantly",
            linkToRead: "/about-us/case-studies/woodgreen-tax-clinic-services-with-microsoft-power-platform"
        }
    ];

    const [showRegionPopup, setShowRegionPopup] = useState(false);
    const [showBenefitDetails, setShowBenefitDetails] = useState([false, false, false]);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Your Excellent Microsoft CRM Partner | MIR Digital Solutions</title>
                <link rel="canonical" href={`${window.location.origin}/business-solutions/customer-relationship-management`} />
            </Helmet>
            <main>
                <StickyBanner />

                <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />
                <section className='tw-pt-[120px] tw-pb-[160px] tw-bg-cover tw-bg-center tw-bg-no-repeat' style={{ backgroundImage: 'url(/supply-chain/bg.svg)' }}>
                    <div className="container mx-auto">
                        <h1 className='tw-mb-[16px] mir-display-semibold-sm mir-text-neutral-800 tw-max-w-full md:tw-max-w-[529px]'>Customer Relationship Management </h1>
                        <h3 className='mir-text-neutral-600 tw-pb-[36px] mir-p-lg-regular m-0 tw-max-w-full md:tw-max-w-[576px]'>Fueling business growth through stronger customer relationships</h3>
                        <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-lg mir-btn-primary mir-p-rg-medium'>
                            Contact us today
                        </button>
                    </div>
                </section>

                {/* section tabs */}
                <SectionTabs tabs={tabs} />

                <section className='tw-pt-[120px] tw-pb-[96px]' data-section id='section-1'>
                    <div className="container mx-auto text-left">
                        <h4 className='mir-p-overline-lg mir-text-primary-500 tw-mb-[16px]'>OVERVIEW</h4>
                        <h2 className='tw-max-w-full md:tw-max-w-[640px] mir-h1-semibold mir-text-neutral-800 tw-mb-[32px]'>Efficiency boost with CRM to leave errors behind</h2>
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-[52px]">
                            <p className='mir-p-md-regular tw-max-w-full md:tw-max-w-[534px] mir-text-neutral-800'>
                                In the past, companies without CRM (Customer Relationship Management) systems often struggled to efficiently manage and track customer interactions. They relied on manual methods, such as spreadsheets and paper records, which were prone to errors and inefficiencies. This resulted in missed opportunities, poor customer service, and disjointed sales and marketing efforts.
                            </p>
                            <p className='mir-p-md-regular tw-max-w-full md:tw-max-w-[534px] mir-text-neutral-800'>
                                Without a centralized CRM system, businesses couldn't effectively nurture leads, personalize customer interactions, or gain insights from data. As a consequence, they faced challenges in retaining customers, identifying growth opportunities, and staying competitive in the market. CRM has since transformed how companies operate, enabling them to better serve customers and thrive in the modern business landscape.
                            </p>
                        </div>
                    </div>
                </section>

                <section className='mir-bg-neutral-100 tw-py-[96px] tw-overflow-hidden' data-section id='section-2'>
                    <div className="container mx-auto">
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-[129px]">
                            <div>
                                <h4 className='mir-p-overline-lg mir-text-primary-500 tw-mb-[16px]'>INTRODUCTION</h4>
                                <h2 className='mir-h1-semibold mir-text-neutral-800 tw-mb-[32px]'>What is a CRM?</h2>
                                <p className='mir-p-md-regular mir-text-neutral-800'>
                                    CRM, or Customer Relationship Management, refers to a suite of integrated software solutions designed to manage, track, and store information related to both current and potential customers. It serves as a centralized system where vital customer data is stored, offering immediate access to insights for various business teams. <br /><br />
                                    These CRM systems also integrate with other tools like chat apps and offer built-in business intelligence and AI capabilities, streamlining administrative tasks and providing actionable insights. This enables sales, marketing, commerce, field service, and customer service teams to gain real-time visibility into customer relationships.
                                </p>
                            </div>
                            <div>
                                <img className='tw-w-full md:tw-w-auto' src="/crm/intro-bg.png" alt="Microsoft Customer relationship management solution dashboard including leads by source, sales pipeline, open opportunities and more to keep the sales team updated." />
                            </div>
                        </div>
                    </div>
                </section>

                <section className='tw-py-[96px]'>
                    <div className="container mx-auto">
                        <div className="text-center tw-max-w-full md:tw-max-w-[732px] mx-auto">
                            <h4 className='tw-text-transform mir-p-overline-lg mir-text-primary-500 tw-mb-[16px]'>capabilities</h4>
                            <h2 className='mir-h1-semibold mir-text-neutral-800 tw-mb-[80px]'>CRM capabilities offer several advantages to businesses</h2>
                        </div>
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-x-[64px] tw-gap-y-[56px]">
                            {
                                [
                                    { img: '/crm/capabilities/1.svg', heading: 'Sales Funnel Management', desc: "CRM tracks lead-related data, helping sales and marketing teams stay organized, understand each lead's position in the sales process, and keep track of team efforts." },
                                    { img: '/crm/capabilities/2.svg', heading: 'Real-time Performance Data', desc: "By integrating sales data into CRM, you gain an immediate, accurate view of your sales pipeline, allowing you to identify slowdowns or celebrate major wins." },
                                    { img: '/crm/capabilities/3.svg', heading: 'Insight Generation', desc: "AI and built-in intelligence help prioritize tasks, ensuring your team focuses on high-impact activities. For example, sales teams can identify leads ready for conversion." },
                                    { img: '/crm/capabilities/4.svg', heading: 'Multi-platform Engagement', desc: "CRM tools enable communication through various channels, including live chat, calls, email, and social interactions, enhancing customer trust and loyalty. " },
                                    { img: '/crm/capabilities/5.svg', heading: 'Workflow Optimization', desc: "Task automation streamlines marketing, sales, and customer service processes, eliminating repetitive tasks and boosting efficiency. " },
                                    { img: '/crm/capabilities/6.svg', heading: 'Customer Interaction Tracking', desc: "CRM solutions monitor customer behavior and highlight opportunities for optimization, improving engagement across various touchpoints. " },
                                    { img: '/crm/capabilities/7.svg', heading: 'Scalability', desc: "Scalable CRM solutions built on secure platforms meet evolving business needs, allowing rapid responses to market conditions and consumer demands. " },
                                ].map((item, index) => {
                                    return <div key={`capability-item-index-${index}`}>
                                        <img src={item.img} alt={item.heading} className='tw-mb-[24px]' />
                                        <h5 className='mir-h6-semibold tw-mb-[12px]'>{item.heading}</h5>
                                        <p className='mir-p-rg-regular mir-text-neutral-600'>{item.desc}</p>
                                    </div>
                                })
                            }
                            <div className='tw-w-full tw-h-full tw-flex tw-items-center tw-justify-start'>
                                <div>
                                    <h3 className='mir-h6-semibold mir-text-neutral-800 tw-mb-[12px]'>Transform your business with CRM now</h3>
                                    <a className='mir-p-rg-medium mir-text-primary-500 tw-cursor-pointer hover:tw-opacity-75' onClick={() => { setShowRegionPopup(true) }}>
                                        <span className='tw-mr-[12px]'>Book a meeting with us</span>
                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.3172 10.9423L11.6922 16.5673C11.5749 16.6846 11.4159 16.7505 11.25 16.7505C11.0841 16.7505 10.9251 16.6846 10.8078 16.5673C10.6905 16.45 10.6247 16.291 10.6247 16.1251C10.6247 15.9593 10.6905 15.8002 10.8078 15.6829L15.3664 11.1251H3.125C2.95924 11.1251 2.80027 11.0593 2.68306 10.942C2.56585 10.8248 2.5 10.6659 2.5 10.5001C2.5 10.3343 2.56585 10.1754 2.68306 10.0582C2.80027 9.94095 2.95924 9.8751 3.125 9.8751H15.3664L10.8078 5.31729C10.6905 5.20002 10.6247 5.04096 10.6247 4.8751C10.6247 4.70925 10.6905 4.55019 10.8078 4.43292C10.9251 4.31564 11.0841 4.24976 11.25 4.24976C11.4159 4.24976 11.5749 4.31564 11.6922 4.43292L17.3172 10.0579C17.3753 10.116 17.4214 10.1849 17.4529 10.2608C17.4843 10.3366 17.5005 10.418 17.5005 10.5001C17.5005 10.5822 17.4843 10.6636 17.4529 10.7394C17.4214 10.8153 17.3753 10.8842 17.3172 10.9423Z" fill="#1D68F0" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='mir-bg-neutral-100 tw-pt-[96px] tw-pb-[64px] tw-overflow-hidden'>
                    <div className="container mx-auto">
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-[129px]">
                            <div className='tw-flex tw-items-center tw-justify-end'>
                                <img className='tw-w-full md:tw-w-auto' src="/crm/reason-bg.png" alt="Microsoft customer relationship management solution ready to transform how sellers work with innovative sales pipeline, case mix, top customers, case by case data and insights." />
                            </div>
                            <div>
                                <h2 className='mir-h1-semibold mir-text-neutral-800 tw-mb-[32px]'><span className='mir-text-primary-500'>Top 4 reasons</span> why a business should implement a CRM </h2>
                                <p className='mir-p-md-regular mir-text-neutral-800'>
                                    When crafting your CRM strategy and assessing customer relationship management solutions, it's crucial to seek out a system that offers a comprehensive perspective on each customer relationship. You require a solution adept at gathering pertinent data from every customer interaction point, analyzing it intelligently, and surfacing actionable insights. <br /><br />
                                    By adopting the right CRM system, your company can significantly enhance communication and ensure exceptional experiences throughout the entire customer journey.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='mir-bg-neutral-100 tw-pt-[64px] tw-pb-[120px] tw-overflow-hidden'>
                    <div className="container mx-auto">
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-y-[64px] tw-gap-x-[32px]">
                            <div>
                                <h4 className='mir-h6-semibold mir-text-primary-500 tw-mb-[8px]'>1st reason</h4>
                                <h5 className='mir-h5-semibold mir-text-neutral-800 tw-mb-[12px]'>Identify and engage the right customers</h5>
                                <p className='mir-p-rg-regular mir-text-neutral-600'>Leverage predictive insights and data-driven understanding of buyer behavior to pinpoint, target, and convert promising leads into loyal customers. </p>
                            </div>
                            <div>
                                <h4 className='mir-h6-semibold mir-text-primary-500 tw-mb-[8px]'>2nd reason</h4>
                                <h5 className='mir-h5-semibold mir-text-neutral-800 tw-mb-[12px]'>Enhance customer interactions</h5>
                                <p className='mir-p-rg-regular mir-text-neutral-600'>With a holistic customer view, your sales team gains access to valuable information like purchase histories, enabling them to deliver highly personalized service. </p>
                            </div>
                            <div>
                                <h4 className='mir-h6-semibold mir-text-primary-500 tw-mb-[8px]'>3rd reason</h4>
                                <h5 className='mir-h5-semibold mir-text-neutral-800 tw-mb-[12px]'>Monitor progress across the customer journey</h5>
                                <p className='mir-p-rg-regular mir-text-neutral-600'>Precisely track a customer's position within the sales lifecycle to effectively tailor campaigns and seize engagement opportunities. </p>
                            </div>
                            <div>
                                <h4 className='mir-h6-semibold mir-text-primary-500 tw-mb-[8px]'>4th reason</h4>
                                <h5 className='mir-h5-semibold mir-text-neutral-800 tw-mb-[12px]'>Boost team productivity</h5>
                                <p className='mir-p-rg-regular mir-text-neutral-600'>Enhanced visibility and streamlined processes empower your team to concentrate on their most important tasks, thereby increasing overall productivity. </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='tw-pt-[200px] tw-pb-[120px] tw-bg-[#000213] tw-relative tw-overflow-hidden'>
                    <img src="/sales/globe-vector.png" alt="globe-vector-banner-bg" className='tw-absolute tw-inset-0 tw-h-[13%] md:tw-h-auto tw-w-full' />
                    <div className="container mx-auto tw-relative tw-z-20">
                        <h4 className='mir-p-overline-lg mir-text-primary-500 tw-text-center md:tw-text-left tw-max-w-full md:tw-max-w-[546px] tw-mb-[24px]'>DISCOVER</h4>
                        <h2 className='mir-h1-semibold mir-text-neutral-100 tw-text-center md:tw-text-left tw-max-w-full md:tw-max-w-[613px] tw-mb-[80px]'>The advantages of CRM for organization teams</h2>
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-x-[56px] tw-gap-y-[52px]">
                            <div className='tw-max-w-full md:tw-max-w-[336px]'>
                                <h5 className='mir-text-neutral-100 mir-h6-medium tw-mb-[12px]'>Marketing Teams</h5>
                                <p className='mir-text-neutral-400 mir-p-rg-regular'>CRM enables multichannel marketing campaigns, lead nurturing, and data-driven insights, allowing for personalized marketing strategies. </p>
                            </div>
                            <div className='tw-max-w-full md:tw-max-w-[336px]'>
                                <h5 className='mir-text-neutral-100 mir-h6-medium tw-mb-[12px]'>Sales Teams</h5>
                                <p className='mir-text-neutral-400 mir-p-rg-regular'>CRM empowers salespeople to engage effectively, find the right prospects, and build smarter selling strategies using AI insights. </p>
                            </div>
                            <div className='tw-max-w-full md:tw-max-w-[336px]'>
                                <h5 className='mir-text-neutral-100 mir-h6-medium tw-mb-[12px]'>Customer Service Teams</h5>
                                <p className='mir-text-neutral-400 mir-p-rg-regular'>CRM offers an effortless omnichannel experience with service bots and personalized services, enhancing customer interactions. </p>
                            </div>
                            <div className='tw-max-w-full md:tw-max-w-[336px]'>
                                <h5 className='mir-text-neutral-100 mir-h6-medium tw-mb-[12px]'>Field Service Teams</h5>
                                <p className='mir-text-neutral-400 mir-p-rg-regular'>CRM helps streamline scheduling, dispatching, and inventory management, improving in-person experiences and reducing costs. </p>
                            </div>
                            <div className='tw-max-w-full md:tw-max-w-[336px]'>
                                <h5 className='mir-text-neutral-100 mir-h6-medium tw-mb-[12px]'>Project Service Automation Teams</h5>
                                <p className='mir-text-neutral-400 mir-p-rg-regular'>CRM enhances profitability with project planning tools, analytics, and resource optimization, resulting in simplified processes and increased productivity.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='mir-bg-neutral-100 tw-py-[96px]' data-section id="section-3">
                    <div className="container tw-mx-auto">
                        <div className='mx-auto  tw-text-center'>
                            <h4 className='mir-p-overline-lg mir-text-primary-500 tw-mb-[16px]'>BENEFITS</h4>
                            <h2 className='tw-max-w-full md:tw-max-w-[738px] mx-auto mir-h1-semibold mir-text-neutral-800 tw-mb-[24px]'>Why should you choose <span className='mir-text-primary-500'>Microsoft Dynamics 365 CRM</span> for your business?</h2>
                            <p className='tw-max-w-full md:tw-max-w-[866px] mx-auto  mir-text-neutral-700 mir-p-md-regular tw-mb-[64px]'>Dynamics 365 CRM provides a versatile and adaptable solution designed to align with your specific business requirements. You have the flexibility to opt for standalone applications or combine multiple CRM applications that seamlessly integrate, fostering connections among your workforce, data, and operational workflows throughout your organization.</p>
                            <h3 className='mir-text-neutral-800 mir-p-md-semibold tw-mb-[64px]'>A robust CRM tool streamlines tasks, provides insights, and elevates customer service. <br /> Consider these key scenarios:</h3>
                        </div>
                        {/* grid */}
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[32px] tw-max-w-full md:tw-max-w-[1120px] mx-auto">
                            <div className='tw-p-[36px] tw-flex tw-flex-col tw-items-start tw-justify-between tw-h-[381px] tw-bg-white tw-rounded-[8px] tw-relative'>
                                {
                                    showBenefitDetails[0] && <div className="tw-absolute tw-inset-0 tw-rounded-[8px] mir-bg-primary-600 tw-p-[36px]">
                                        <div>
                                            {
                                                [
                                                    'Foster collaboration between sales and marketing for effective customer engagement',
                                                    'Optimal customer outreach through preferred channels',
                                                    'Lead prioritization and smooth transitions',
                                                ].map((item, index) => {
                                                    return <div key={`sales-marketing-index-${index}`} className='tw-flex tw-items-start tw-justify-start'>
                                                        <svg className='tw-mt-2 tw-mr-3 tw-min-w-[14px]' width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10.8128 1.07878L4.04557 7.81428C3.98663 7.87314 3.9166 7.91985 3.83948 7.95172C3.76237 7.98359 3.67969 8 3.59619 8C3.51269 8 3.43001 7.98359 3.3529 7.95172C3.27578 7.91985 3.20574 7.87314 3.1468 7.81428L0.186142 4.86749C0.127128 4.80876 0.0803149 4.73902 0.0483766 4.66228C0.0164384 4.58554 8.79381e-10 4.50328 0 4.42022C-8.7938e-10 4.33715 0.0164384 4.25489 0.0483766 4.17815C0.0803149 4.1014 0.127128 4.03167 0.186142 3.97294C0.245156 3.9142 0.315216 3.8676 0.392322 3.83582C0.469428 3.80403 0.552069 3.78767 0.635528 3.78767C0.718987 3.78767 0.801628 3.80403 0.878734 3.83582C0.95584 3.8676 1.0259 3.9142 1.08491 3.97294L3.59672 6.47296L9.91508 0.185269C10.0343 0.0666432 10.1959 0 10.3645 0C10.533 0 10.6947 0.0666432 10.8139 0.185269C10.933 0.303895 11 0.464786 11 0.632549C11 0.800311 10.933 0.961202 10.8139 1.07983L10.8128 1.07878Z" fill="#8EB8F7" />
                                                        </svg>
                                                        <p className='mir-p-sm-regular tw-text-white'>{item}</p>
                                                    </div>
                                                })
                                            }
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-w-full tw-absolute tw-right-10 tw-bottom-10">
                                            <button onClick={() => { setShowBenefitDetails(showBenefitDetails.map((x, i) => { return i == 0 ? false : x })) }} className='tw-w-[40px] tw-h-[40px] tw-bg-white tw-rounded-full tw-outline-none tw-border-none tw-transform tw-rotate-180'>
                                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className='tw-mb-[3px]'>
                                                    <path d="M14.5198 8.35375L10.0198 12.8538C9.92595 12.9476 9.7987 13.0003 9.66602 13.0003C9.53333 13.0003 9.40609 12.9476 9.31227 12.8538C9.21844 12.7599 9.16574 12.6327 9.16574 12.5C9.16574 12.3673 9.21844 12.2401 9.31227 12.1463L12.9591 8.5H3.16602C3.03341 8.5 2.90623 8.44733 2.81246 8.35356C2.71869 8.25979 2.66602 8.13261 2.66602 8C2.66602 7.86739 2.71869 7.74022 2.81246 7.64645C2.90623 7.55268 3.03341 7.5 3.16602 7.5H12.9591L9.31227 3.85375C9.21844 3.75993 9.16574 3.63269 9.16574 3.5C9.16574 3.36732 9.21844 3.24007 9.31227 3.14625C9.40609 3.05243 9.53333 2.99973 9.66602 2.99973C9.7987 2.99973 9.92595 3.05243 10.0198 3.14625L14.5198 7.64625C14.5663 7.69269 14.6031 7.74783 14.6283 7.80853C14.6535 7.86923 14.6664 7.9343 14.6664 8C14.6664 8.06571 14.6535 8.13077 14.6283 8.19147C14.6031 8.25217 14.5663 8.30732 14.5198 8.35375Z" fill="blue" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                }
                                <div className='text-left'>
                                    <img src="/crm/benefits-icon-1.svg" className='tw-mb-[32px]' alt="sales and marketing alignment" />
                                    <h5 className='mir-h5-semibold mir-text-neutral-800'>Sales and Marketing Alignment</h5>
                                </div>
                                <div className="tw-flex tw-justify-end tw-w-full">
                                    <button onClick={() => { setShowBenefitDetails(showBenefitDetails.map((x, i) => { return i == 0 ? true : x })) }} className='tw-w-[40px] tw-h-[40px] mir-bg-primary-500 tw-rounded-full tw-outline-none tw-border-none'>
                                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.5198 8.35375L10.0198 12.8538C9.92595 12.9476 9.7987 13.0003 9.66602 13.0003C9.53333 13.0003 9.40609 12.9476 9.31227 12.8538C9.21844 12.7599 9.16574 12.6327 9.16574 12.5C9.16574 12.3673 9.21844 12.2401 9.31227 12.1463L12.9591 8.5H3.16602C3.03341 8.5 2.90623 8.44733 2.81246 8.35356C2.71869 8.25979 2.66602 8.13261 2.66602 8C2.66602 7.86739 2.71869 7.74022 2.81246 7.64645C2.90623 7.55268 3.03341 7.5 3.16602 7.5H12.9591L9.31227 3.85375C9.21844 3.75993 9.16574 3.63269 9.16574 3.5C9.16574 3.36732 9.21844 3.24007 9.31227 3.14625C9.40609 3.05243 9.53333 2.99973 9.66602 2.99973C9.7987 2.99973 9.92595 3.05243 10.0198 3.14625L14.5198 7.64625C14.5663 7.69269 14.6031 7.74783 14.6283 7.80853C14.6535 7.86923 14.6664 7.9343 14.6664 8C14.6664 8.06571 14.6535 8.13077 14.6283 8.19147C14.6031 8.25217 14.5663 8.30732 14.5198 8.35375Z" fill="white" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className='tw-p-[36px] tw-flex tw-flex-col tw-items-start tw-justify-between tw-h-[381px] tw-bg-white tw-rounded-[8px] tw-relative'>
                                {
                                    showBenefitDetails[1] && <div className="tw-absolute tw-inset-0 tw-rounded-[8px] mir-bg-primary-600 tw-p-[36px]">
                                        <div>
                                            {
                                                [
                                                    'Identify and prioritize leads effectively.',
                                                    'Predict future sales, streamline processes, and centralize data.',
                                                    'Empower sales teams with mobility options.',
                                                ].map((item, index) => {
                                                    return <div key={`sales-marketing-index-${index}`} className='tw-flex tw-items-start tw-justify-start'>
                                                        <svg className='tw-mt-2 tw-mr-3 tw-min-w-[14px]' width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10.8128 1.07878L4.04557 7.81428C3.98663 7.87314 3.9166 7.91985 3.83948 7.95172C3.76237 7.98359 3.67969 8 3.59619 8C3.51269 8 3.43001 7.98359 3.3529 7.95172C3.27578 7.91985 3.20574 7.87314 3.1468 7.81428L0.186142 4.86749C0.127128 4.80876 0.0803149 4.73902 0.0483766 4.66228C0.0164384 4.58554 8.79381e-10 4.50328 0 4.42022C-8.7938e-10 4.33715 0.0164384 4.25489 0.0483766 4.17815C0.0803149 4.1014 0.127128 4.03167 0.186142 3.97294C0.245156 3.9142 0.315216 3.8676 0.392322 3.83582C0.469428 3.80403 0.552069 3.78767 0.635528 3.78767C0.718987 3.78767 0.801628 3.80403 0.878734 3.83582C0.95584 3.8676 1.0259 3.9142 1.08491 3.97294L3.59672 6.47296L9.91508 0.185269C10.0343 0.0666432 10.1959 0 10.3645 0C10.533 0 10.6947 0.0666432 10.8139 0.185269C10.933 0.303895 11 0.464786 11 0.632549C11 0.800311 10.933 0.961202 10.8139 1.07983L10.8128 1.07878Z" fill="#8EB8F7" />
                                                        </svg>
                                                        <p className='mir-p-sm-regular tw-text-white'>{item}</p>
                                                    </div>
                                                })
                                            }
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-w-full tw-absolute tw-right-10 tw-bottom-10">
                                            <button onClick={() => { setShowBenefitDetails(showBenefitDetails.map((x, i) => { return i == 1 ? false : x })) }} className='tw-w-[40px] tw-h-[40px] tw-bg-white tw-rounded-full tw-outline-none tw-border-none tw-transform tw-rotate-180'>
                                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className='tw-mb-[3px]'>
                                                    <path d="M14.5198 8.35375L10.0198 12.8538C9.92595 12.9476 9.7987 13.0003 9.66602 13.0003C9.53333 13.0003 9.40609 12.9476 9.31227 12.8538C9.21844 12.7599 9.16574 12.6327 9.16574 12.5C9.16574 12.3673 9.21844 12.2401 9.31227 12.1463L12.9591 8.5H3.16602C3.03341 8.5 2.90623 8.44733 2.81246 8.35356C2.71869 8.25979 2.66602 8.13261 2.66602 8C2.66602 7.86739 2.71869 7.74022 2.81246 7.64645C2.90623 7.55268 3.03341 7.5 3.16602 7.5H12.9591L9.31227 3.85375C9.21844 3.75993 9.16574 3.63269 9.16574 3.5C9.16574 3.36732 9.21844 3.24007 9.31227 3.14625C9.40609 3.05243 9.53333 2.99973 9.66602 2.99973C9.7987 2.99973 9.92595 3.05243 10.0198 3.14625L14.5198 7.64625C14.5663 7.69269 14.6031 7.74783 14.6283 7.80853C14.6535 7.86923 14.6664 7.9343 14.6664 8C14.6664 8.06571 14.6535 8.13077 14.6283 8.19147C14.6031 8.25217 14.5663 8.30732 14.5198 8.35375Z" fill="blue" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                }
                                <div className='text-left'>
                                    <img src="/crm/benefits-icon-2.svg" className='tw-mb-[32px]' alt="sales and marketing alignment" />
                                    <h5 className='mir-h5-semibold mir-text-neutral-800'>Sales Productivity <br /> Boost</h5>
                                </div>
                                <div className="tw-flex tw-justify-end tw-w-full">
                                    <button onClick={() => { setShowBenefitDetails(showBenefitDetails.map((x, i) => { return i == 1 ? true : x })) }} className='tw-w-[40px] tw-h-[40px] mir-bg-primary-500 tw-rounded-full tw-outline-none tw-border-none'>
                                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.5198 8.35375L10.0198 12.8538C9.92595 12.9476 9.7987 13.0003 9.66602 13.0003C9.53333 13.0003 9.40609 12.9476 9.31227 12.8538C9.21844 12.7599 9.16574 12.6327 9.16574 12.5C9.16574 12.3673 9.21844 12.2401 9.31227 12.1463L12.9591 8.5H3.16602C3.03341 8.5 2.90623 8.44733 2.81246 8.35356C2.71869 8.25979 2.66602 8.13261 2.66602 8C2.66602 7.86739 2.71869 7.74022 2.81246 7.64645C2.90623 7.55268 3.03341 7.5 3.16602 7.5H12.9591L9.31227 3.85375C9.21844 3.75993 9.16574 3.63269 9.16574 3.5C9.16574 3.36732 9.21844 3.24007 9.31227 3.14625C9.40609 3.05243 9.53333 2.99973 9.66602 2.99973C9.7987 2.99973 9.92595 3.05243 10.0198 3.14625L14.5198 7.64625C14.5663 7.69269 14.6031 7.74783 14.6283 7.80853C14.6535 7.86923 14.6664 7.9343 14.6664 8C14.6664 8.06571 14.6535 8.13077 14.6283 8.19147C14.6031 8.25217 14.5663 8.30732 14.5198 8.35375Z" fill="white" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className='tw-p-[36px] tw-flex tw-flex-col tw-items-start tw-justify-between tw-h-[381px] tw-bg-white tw-rounded-[8px] tw-relative'>
                                {
                                    showBenefitDetails[2] && <div className="tw-absolute tw-inset-0 tw-rounded-[8px] mir-bg-primary-600 tw-p-[36px]">
                                        <div>
                                            {
                                                [
                                                    'Predict customer preferences and create seamless interactions.',
                                                    'Insights into the customer journey, mobile accessibility, and self-service options.'
                                                ].map((item, index) => {
                                                    return <div key={`sales-marketing-index-${index}`} className='tw-flex tw-items-start tw-justify-start'>
                                                        <svg className='tw-mt-2 tw-mr-3 tw-min-w-[14px]' width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10.8128 1.07878L4.04557 7.81428C3.98663 7.87314 3.9166 7.91985 3.83948 7.95172C3.76237 7.98359 3.67969 8 3.59619 8C3.51269 8 3.43001 7.98359 3.3529 7.95172C3.27578 7.91985 3.20574 7.87314 3.1468 7.81428L0.186142 4.86749C0.127128 4.80876 0.0803149 4.73902 0.0483766 4.66228C0.0164384 4.58554 8.79381e-10 4.50328 0 4.42022C-8.7938e-10 4.33715 0.0164384 4.25489 0.0483766 4.17815C0.0803149 4.1014 0.127128 4.03167 0.186142 3.97294C0.245156 3.9142 0.315216 3.8676 0.392322 3.83582C0.469428 3.80403 0.552069 3.78767 0.635528 3.78767C0.718987 3.78767 0.801628 3.80403 0.878734 3.83582C0.95584 3.8676 1.0259 3.9142 1.08491 3.97294L3.59672 6.47296L9.91508 0.185269C10.0343 0.0666432 10.1959 0 10.3645 0C10.533 0 10.6947 0.0666432 10.8139 0.185269C10.933 0.303895 11 0.464786 11 0.632549C11 0.800311 10.933 0.961202 10.8139 1.07983L10.8128 1.07878Z" fill="#8EB8F7" />
                                                        </svg>
                                                        <p className='mir-p-sm-regular tw-text-white'>{item}</p>
                                                    </div>
                                                })
                                            }
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-w-full tw-absolute tw-right-10 tw-bottom-10">
                                            <button onClick={() => { setShowBenefitDetails(showBenefitDetails.map((x, i) => { return i == 2 ? false : x })) }} className='tw-w-[40px] tw-h-[40px] tw-bg-white tw-rounded-full tw-outline-none tw-border-none tw-transform tw-rotate-180'>
                                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className='tw-mb-[3px]'>
                                                    <path d="M14.5198 8.35375L10.0198 12.8538C9.92595 12.9476 9.7987 13.0003 9.66602 13.0003C9.53333 13.0003 9.40609 12.9476 9.31227 12.8538C9.21844 12.7599 9.16574 12.6327 9.16574 12.5C9.16574 12.3673 9.21844 12.2401 9.31227 12.1463L12.9591 8.5H3.16602C3.03341 8.5 2.90623 8.44733 2.81246 8.35356C2.71869 8.25979 2.66602 8.13261 2.66602 8C2.66602 7.86739 2.71869 7.74022 2.81246 7.64645C2.90623 7.55268 3.03341 7.5 3.16602 7.5H12.9591L9.31227 3.85375C9.21844 3.75993 9.16574 3.63269 9.16574 3.5C9.16574 3.36732 9.21844 3.24007 9.31227 3.14625C9.40609 3.05243 9.53333 2.99973 9.66602 2.99973C9.7987 2.99973 9.92595 3.05243 10.0198 3.14625L14.5198 7.64625C14.5663 7.69269 14.6031 7.74783 14.6283 7.80853C14.6535 7.86923 14.6664 7.9343 14.6664 8C14.6664 8.06571 14.6535 8.13077 14.6283 8.19147C14.6031 8.25217 14.5663 8.30732 14.5198 8.35375Z" fill="blue" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                }
                                <div className='text-left'>
                                    <img src="/crm/benefits-icon-3.svg" className='tw-mb-[32px]' alt="sales and marketing alignment" />
                                    <h5 className='mir-h5-semibold mir-text-neutral-800'>Enhanced Customer Relations</h5>
                                </div>
                                <div className="tw-flex tw-justify-end tw-w-full">
                                    <button onClick={() => { setShowBenefitDetails(showBenefitDetails.map((x, i) => { return i == 2 ? true : x })) }} className='tw-w-[40px] tw-h-[40px] mir-bg-primary-500 tw-rounded-full tw-outline-none tw-border-none'>
                                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.5198 8.35375L10.0198 12.8538C9.92595 12.9476 9.7987 13.0003 9.66602 13.0003C9.53333 13.0003 9.40609 12.9476 9.31227 12.8538C9.21844 12.7599 9.16574 12.6327 9.16574 12.5C9.16574 12.3673 9.21844 12.2401 9.31227 12.1463L12.9591 8.5H3.16602C3.03341 8.5 2.90623 8.44733 2.81246 8.35356C2.71869 8.25979 2.66602 8.13261 2.66602 8C2.66602 7.86739 2.71869 7.74022 2.81246 7.64645C2.90623 7.55268 3.03341 7.5 3.16602 7.5H12.9591L9.31227 3.85375C9.21844 3.75993 9.16574 3.63269 9.16574 3.5C9.16574 3.36732 9.21844 3.24007 9.31227 3.14625C9.40609 3.05243 9.53333 2.99973 9.66602 2.99973C9.7987 2.99973 9.92595 3.05243 10.0198 3.14625L14.5198 7.64625C14.5663 7.69269 14.6031 7.74783 14.6283 7.80853C14.6535 7.86923 14.6664 7.9343 14.6664 8C14.6664 8.06571 14.6535 8.13077 14.6283 8.19147C14.6031 8.25217 14.5663 8.30732 14.5198 8.35375Z" fill="white" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='tw-pt-[36px] tw-pb-[96px] mir-bg-neutral-100'>
                    <div className="container mx-auto">
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 md:tw-gap-x-[90px]">
                            <div className='tw-flex tw-items-center tw-justify-center'>
                                <img src="/crm/crm-bg.png" alt="" className='tw-w-full tw-max-w-full md:tw-max-w-[526px]' />
                            </div>
                            <div>
                                <h3 className='mir-h3-semibold mir-text-neutral-800 tw-mb-[32px]'>A comprehensive CRM <br /> system also offers</h3>
                                <ul className='tw-p-0'>
                                    {
                                        [
                                            'End-to-end customer views',
                                            'Centralized data and integration capabilities',
                                            'Customizable dashboards and global growth support',
                                            'Unlock customer insights',
                                            'Implement automated contact forms',
                                            'Offer online self-service portals for efficient customer engagement',
                                        ].map((item, index) => {
                                            return <li className='tw-flex tw-items-start tw-justify-start tw-mb-[24px]' key={`advantage-item-${index}`}>
                                                <svg className='tw-min-w-[24px] tw-mr-[16px]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="24" height="24" rx="12" fill="#D1E5FF" />
                                                    <path d="M18.6099 8.34847L10.1905 16.7678C10.1172 16.8414 10.03 16.8998 9.93408 16.9397C9.83814 16.9795 9.73528 17 9.63139 17C9.52751 17 9.42464 16.9795 9.3287 16.9397C9.23276 16.8998 9.14562 16.8414 9.07229 16.7678L5.38882 13.0844C5.31539 13.0109 5.25715 12.9238 5.21742 12.8279C5.17768 12.7319 5.15723 12.6291 5.15723 12.5253C5.15723 12.4214 5.17768 12.3186 5.21742 12.2227C5.25715 12.1268 5.31539 12.0396 5.38882 11.9662C5.46224 11.8927 5.5494 11.8345 5.64533 11.7948C5.74126 11.755 5.84408 11.7346 5.94792 11.7346C6.05175 11.7346 6.15457 11.755 6.2505 11.7948C6.34643 11.8345 6.43359 11.8927 6.50702 11.9662L9.63205 15.0912L17.493 7.23159C17.6413 7.0833 17.8424 7 18.0521 7C18.2618 7 18.4629 7.0833 18.6112 7.23159C18.7595 7.37987 18.8428 7.58098 18.8428 7.79069C18.8428 8.00039 18.7595 8.2015 18.6112 8.34978L18.6099 8.34847Z" fill="#1D68F0" />
                                                </svg>
                                                <h5 className='mir-p-rg-regular mir-text-neutral-800'>{item}</h5>
                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='tw-relative mir-bg-neutral-800 tw-bg-bottom tw-bg-cover tw-bg-no-repeat' style={{ backgroundImage: 'url(/business/service-bundles-bg.png)' }}>
                    <span data-section id="section-7" className='tw-absolute tw-top-[-100px]'></span>
                    <div className='container tw-mx-auto tw-text-center tw-pt-[120px] tw-pb-[236px]'>
                        <h4 className='mir-p-overline-lg text-primary-500 tw-mb-[24px]'>SERVICE BUNDLES</h4>
                        <h2 className='mir-h1-semibold mir-text-neutral-100 tw-mb-[36px] tw-max-w-[100%] md:tw-max-w-[802px] tw-mx-auto'>Customer Relationship <br /> Management packages</h2>
                        <h4 className='mir-text-neutral-100 mir-p-lg-regular tw-mb-[72px] tw-max-w-[100%] md:tw-max-w-[802px] tw-mx-auto'>Here are our Dynamics 365 Customer Engagement fixed-priced packages. Take the first step towards success and schedule a meeting today! </h4>
                    </div>
                </section>

                <section className='mir-bg-neutral-100 tw-relative tw-z-10'>
                    <div className='container tw-mx-auto tw-text-center tw-pb-[96px]'>
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[32px] tw-max-w-full md:tw-max-w-[1120px] tw-mx-auto">
                            <div className='tw-bg-white tw-rounded-[8px] tw-shadow-md tw-max-w-full -tw-mt-[213px]'>
                                <div className='tw-p-[36px] tw-text-left' style={{ borderBottom: '1px solid #EEEEF2' }}>
                                    <h3 className='mir-h5-semibold tw-mb-[36px]'>Core Sales</h3>
                                    <p className='mir-p-sm-regular m-0 mir-text-neutral-600'>For every profit and non-profit organization that has a core sales process to sell products or services.</p>
                                </div>
                                <div className='tw-p-[36px] text-start tw-flex tw-flex-col tw-justify-between tw-items-start' style={{ height: 'calc(100% - 213px)' }}>
                                    <div>
                                        <p className='mir-p-sm-semibold mir-text-neutral-800'>Technical features include</p>
                                        {
                                            [
                                                'Leads, Contacts, Accounts, Contacts, Opportunities, Quotes, Orders and Invoice ',
                                                'Outlook email integration ',
                                                'Customizations limited to 15 hours (2 entities & 30 fields) ',
                                                '2 business process workflows ',
                                                'Power Automate automations limited to 5 business rules ',
                                                'Integration with two 3rd party apps and an email marketing app ',
                                                'Data migration limited to supported apps and formats ',
                                                'Core reports ',
                                                'User training ',
                                            ].map((item, index) => {
                                                return <div className='tw-flex tw-items-start tw-mb-[18px]' key={`point-item-${index}`}>
                                                    <img src="/business/service-check.svg" alt="service-check-icon" className='tw-mr-[16px] tw-mt-[5px]' />
                                                    <p className='mir-p-rg-regular m-0'>{item}</p>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className='tw-mt-[52px] tw-w-full'>
                                        <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-lg mir-btn-primary tw-w-full'>Schedule a Meeting</button>
                                        <Link to={MIRRoutes.CRMBundles}><button className='mir-btn-lg mir-btn-secondary tw-w-full'>Learn More</button></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='tw-bg-white tw-rounded-[8px] tw-shadow-md tw-max-w-full md:-tw-mt-[213px]'>
                                <div className='tw-p-[36px] tw-text-left' style={{ borderBottom: '1px solid #EEEEF2' }}>
                                    <h3 className='mir-h5-semibold tw-mb-[36px]'>Core Sales & Marketing </h3>
                                    <p className='mir-p-sm-regular m-0 mir-text-neutral-600'>For organizations to reach new customers and to have constant communications with their existing customers. </p>
                                </div>
                                <div className='tw-p-[36px] text-start tw-flex tw-flex-col tw-justify-between tw-items-start' style={{ height: 'calc(100% - 213px)' }}>
                                    <div className='tw-grow tw-h-full'>
                                        <p className='mir-p-sm-semibold mir-text-neutral-800'>Includes everything from Core Sales and</p>
                                        {
                                            [
                                                'Campaign Management with 3 templates, 3 customer segments and 3 customer journeys ',
                                                'Customer lead scoring ',
                                                '3 marketing forms, 2 pages and 1 website ',
                                                'Customizations limited to 15 hours (2 entities & 30 fields)',
                                                'Social media handles ',
                                                'Integration with an email marketing app ',
                                                'Core reports ',
                                                'User training ',
                                            ].map((item, index) => {
                                                return <div className='tw-flex tw-items-start tw-mb-[18px]' key={`point-item-${index}`}>
                                                    <img src="/business/service-check.svg" alt="service-check-icon" className='tw-mr-[16px] tw-mt-[5px]' />
                                                    <p className='mir-p-rg-regular m-0'>{item}</p>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className='tw-w-full'>
                                        <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-lg mir-btn-primary tw-w-full'>Schedule a Meeting</button>
                                        <Link to={MIRRoutes.CRMBundles}><button className='mir-btn-lg mir-btn-secondary tw-w-full'>Learn More</button></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='tw-bg-white tw-rounded-[8px] tw-shadow-md tw-max-w-full md:-tw-mt-[213px]'>
                                <div className='tw-p-[36px] tw-text-left' style={{ borderBottom: '1px solid #EEEEF2' }}>
                                    <h3 className='mir-h5-semibold tw-mb-[36px]'>Core Customer Service </h3>
                                    <p className='mir-p-sm-regular m-0 mir-text-neutral-600'>For organizations looking for a ticket management system for their omni-channel customer service teams. </p>
                                </div>
                                <div className='tw-p-[36px] text-start tw-flex tw-flex-col tw-justify-between tw-items-start' style={{ height: 'calc(100% - 213px)' }}>
                                    <div>
                                        <p className='mir-p-sm-semibold mir-text-neutral-800'>Technical features include</p>
                                        {
                                            [
                                                'Service ticket management platform ',
                                                'Integration with 4 channels (email, chat, web and mobile apps) ',
                                                'Service management queues, routing, and SLAs for each channel ',
                                                'Customer portal (including knowledge management) ',
                                                'Customizations limited to 15 hours ',
                                                'Power Automate automations limited to 5 business rules ',
                                                'Branding ',
                                                'Surveys using Customer voice ',
                                                'Core reports ',
                                                'User training ',
                                            ].map((item, index) => {
                                                return <div className='tw-flex tw-items-start tw-mb-[18px]' key={`point-item-${index}`}>
                                                    <img src="/business/service-check.svg" alt="service-check-icon" className='tw-mr-[16px] tw-mt-[5px]' />
                                                    <p className='mir-p-rg-regular m-0'>{item}</p>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className='tw-mt-[52px] tw-w-full'>
                                        <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-lg mir-btn-primary tw-w-full'>Schedule a Meeting</button>
                                        <Link to={MIRRoutes.CRMBundles}><button className='mir-btn-lg mir-btn-secondary tw-w-full'>Learn More</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div data-section id='section-4' style={{ marginTop: '-24px' }}>
                    <HowHelpSection
                        overline={'HOW CAN WE HELP YOU'}
                        heading={<>Why should you choose <span style={{ color: '#1D68F0' }}>MIR Digital Solutions</span> to manage and nurture your customer relationships?</>}
                        desc={<>
                            MIR Digital Solutions has a proven track record of successful implementations and a strong commitment to Microsoft technologies, resulting in unrivaled product knowledge and process improvement expertise.  <br /><br />
                            Our experience has enabled us to develop efficient processes and approaches for cloud solutions, enabling innovation in a way other competitors simply cannot.
                        </>}
                        image={'url(/crm/help-bg.png) no-repeat'}
                    />
                </div>

                <div className="delivering-wrapper py-5 mb-5">
                    <div className="container">
                        <h3 className='mir-h5-semibold mb-4'>As a Microsoft Partner, we deliver</h3>
                        <div className="row">
                            {
                                [
                                    'An end-to-end digital transformation platform that drives project-based operations from prospect to payment to profit',
                                    'An extensive partner ecosystem and go-to-market channels, including AppSource Marketplace, partner-to partner network, and Microsoft sellers',
                                    'Dynamics 365 extensibility to unite Finance, Human Resources, Sales, Supply Chain Management, Customer Service, Field Service, and more',
                                    'Deep expertise and a rich history with CRM, ERP, HCM, collaboration, productivity, and project management applications',
                                    'Complete cloud vision that encompasses Azure, Office 365, and Dynamics 365',
                                    'Accelerated application development and flexibility with the Power Platform',
                                ].map((item) => {
                                    return <div className="col-12 col-md-4">
                                        <div className="deliver-item d-flex align-items-start justify-content-start">
                                            <img src="/project-operations/blue-check.svg" className='mt-3 me-3' alt="delivering-icon" />
                                            <h3 className='mir-p-rg-regular mt-3' style={{ maxWidth: '450px' }}>{item}</h3>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>

                <StepsSlider
                    subtitle={'Our specialist team offers custom-tailored implementation of Microsoft Power Apps for crafting personalized applications, bypassing intricate coding.'}
                />

                <section className='tw-pt-[64px] tw-pb-[96px]'>
                    <div className="container mx-auto text-center">
                        <h4 className='tw-mb-[64px] mir-p-overline-lg tw-text-uppercase mir-text-neutral-500'>Trusted by businesses of all sizes, across North America, Middle East and Africa</h4>
                    </div>
                    <Banner images={images} speed={25000} />
                </section>

                <div style={{ background: '#FAFAFC' }} data-section id='section-5'>
                    <SuccessStoriesDynamic
                        successStoriesContent={successStoriesContent}
                    />
                </div>

                <section className='tw-pt-[96px] tw-pb-[48px]'>
                    <div className="container mx-auto">
                        <div className="text-center tw-max-w-full md:tw-max-w-[802px] tw-pb-[72px] mx-auto">
                            <h2 className='mir-h2-semibold tw-mb-[24px]'>Explore Dynamics 365 Product Suite</h2>
                            <p className='mir-p-md-regular'>Discover the power of Dynamics 365 product range. From customer service to finance, optimize every aspect of your business.</p>
                        </div>
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[32px]">
                            <div className='tw-rounded-[8px] tw-p-[36px] mir-bg-neutral-100 tw-flex tw-flex-col tw-justify-between'>
                                <div>
                                    <img src="/business/hero-icon.svg" alt="supply chain management" className='tw-mb-[24px]' />
                                    <h3 className='mir-h6-semibold tw-pb-[8px]'>Dynamics 365 Business <br /> Central</h3>
                                    <p className='mir-p-rg-regular tw-mb-[48px] mir-text-neutral-600'>Seamlessly connects to Microsoft 365, Dynamics 365, SharePoint, Azure, and more for data-driven apps.</p>
                                </div>
                                <Link to={MIRRoutes.BusinessCentral}>
                                    <a className='mir-text-primary-500 mir-p-rg-medium tw-flex tw-items-center'>
                                        <span className='tw-mr-[12px]'>Explore</span>
                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.3172 10.942L11.6922 16.567C11.5749 16.6843 11.4159 16.7502 11.25 16.7502C11.0841 16.7502 10.9251 16.6843 10.8078 16.567C10.6905 16.4498 10.6247 16.2907 10.6247 16.1249C10.6247 15.959 10.6905 15.7999 10.8078 15.6827L15.3664 11.1249H3.125C2.95924 11.1249 2.80027 11.059 2.68306 10.9418C2.56585 10.8246 2.5 10.6656 2.5 10.4999C2.5 10.3341 2.56585 10.1751 2.68306 10.0579C2.80027 9.94071 2.95924 9.87486 3.125 9.87486H15.3664L10.8078 5.31705C10.6905 5.19977 10.6247 5.04071 10.6247 4.87486C10.6247 4.70901 10.6905 4.54995 10.8078 4.43267C10.9251 4.3154 11.0841 4.24951 11.25 4.24951C11.4159 4.24951 11.5749 4.3154 11.6922 4.43267L17.3172 10.0577C17.3753 10.1157 17.4214 10.1846 17.4529 10.2605C17.4843 10.3364 17.5005 10.4177 17.5005 10.4999C17.5005 10.582 17.4843 10.6633 17.4529 10.7392C17.4214 10.8151 17.3753 10.884 17.3172 10.942Z" fill="#1D68F0" />
                                        </svg>
                                    </a>
                                </Link>
                            </div>
                            <div className='tw-rounded-[8px] tw-p-[36px] mir-bg-neutral-100 tw-flex tw-flex-col tw-justify-between'>
                                <div>
                                    <img src="/project-operations/hero-icon.png" alt="project operations" className='tw-mb-[24px]' />
                                    <h3 className='mir-h6-semibold tw-pb-[8px]'>Dynamics 365 Project <br /> Operations</h3>
                                    <p className='mir-p-rg-regular tw-mb-[48px] mir-text-neutral-600'>Seamlessly connects to Microsoft 365, Dynamics 365, SharePoint, Azure, and more for data-driven apps.</p>
                                </div>
                                <Link to={MIRRoutes.ProjectOperations}>
                                    <a className='mir-text-primary-500 mir-p-rg-medium tw-flex tw-items-center'>
                                        <span className='tw-mr-[12px]'>Explore</span>
                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.3172 10.942L11.6922 16.567C11.5749 16.6843 11.4159 16.7502 11.25 16.7502C11.0841 16.7502 10.9251 16.6843 10.8078 16.567C10.6905 16.4498 10.6247 16.2907 10.6247 16.1249C10.6247 15.959 10.6905 15.7999 10.8078 15.6827L15.3664 11.1249H3.125C2.95924 11.1249 2.80027 11.059 2.68306 10.9418C2.56585 10.8246 2.5 10.6656 2.5 10.4999C2.5 10.3341 2.56585 10.1751 2.68306 10.0579C2.80027 9.94071 2.95924 9.87486 3.125 9.87486H15.3664L10.8078 5.31705C10.6905 5.19977 10.6247 5.04071 10.6247 4.87486C10.6247 4.70901 10.6905 4.54995 10.8078 4.43267C10.9251 4.3154 11.0841 4.24951 11.25 4.24951C11.4159 4.24951 11.5749 4.3154 11.6922 4.43267L17.3172 10.0577C17.3753 10.1157 17.4214 10.1846 17.4529 10.2605C17.4843 10.3364 17.5005 10.4177 17.5005 10.4999C17.5005 10.582 17.4843 10.6633 17.4529 10.7392C17.4214 10.8151 17.3753 10.884 17.3172 10.942Z" fill="#1D68F0" />
                                        </svg>
                                    </a>
                                </Link>
                            </div>
                            <div className='tw-rounded-[8px] tw-p-[36px] mir-bg-neutral-100 tw-flex tw-flex-col tw-justify-between'>
                                <div>
                                    <img src="/supply-chain/hero-icon.png" alt="supply chain management" className='tw-mb-[24px]' />
                                    <h3 className='mir-h6-semibold tw-pb-[8px]'>Dynamics 365 Supply Chain <br /> Management</h3>
                                    <p className='mir-p-rg-regular tw-mb-[48px] mir-text-neutral-600'>Supports both mobile and web app development for widespread user access.</p>
                                </div>
                                <Link to={MIRRoutes.SupplyChain}>
                                    <a className='mir-text-primary-500 mir-p-rg-medium tw-flex tw-items-center'>
                                        <span className='tw-mr-[12px]'>Explore</span>
                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.3172 10.942L11.6922 16.567C11.5749 16.6843 11.4159 16.7502 11.25 16.7502C11.0841 16.7502 10.9251 16.6843 10.8078 16.567C10.6905 16.4498 10.6247 16.2907 10.6247 16.1249C10.6247 15.959 10.6905 15.7999 10.8078 15.6827L15.3664 11.1249H3.125C2.95924 11.1249 2.80027 11.059 2.68306 10.9418C2.56585 10.8246 2.5 10.6656 2.5 10.4999C2.5 10.3341 2.56585 10.1751 2.68306 10.0579C2.80027 9.94071 2.95924 9.87486 3.125 9.87486H15.3664L10.8078 5.31705C10.6905 5.19977 10.6247 5.04071 10.6247 4.87486C10.6247 4.70901 10.6905 4.54995 10.8078 4.43267C10.9251 4.3154 11.0841 4.24951 11.25 4.24951C11.4159 4.24951 11.5749 4.3154 11.6922 4.43267L17.3172 10.0577C17.3753 10.1157 17.4214 10.1846 17.4529 10.2605C17.4843 10.3364 17.5005 10.4177 17.5005 10.4999C17.5005 10.582 17.4843 10.6633 17.4529 10.7392C17.4214 10.8151 17.3753 10.884 17.3172 10.942Z" fill="#1D68F0" />
                                        </svg>
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>


                <section className='tw-pt-[24px] tw-pb-[96px]'>
                    <div className="container mx-auto">
                        <div className="text-center">
                            <h2 className='mir-h4-semibold mir-text-neutral-800 tw-mb-[16px]'>Take the first step towards success</h2>
                            <h4 className='mir-p-md-regular mir-text-neutral-700 tw-mb-[36px]'>From Azure to Dynamics 365, we're your digital transformation partner. </h4>
                            <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-lg mir-btn-primary'>
                                <span className='mir-p-rg-medium tw-mr-[12px]'>Get started today</span>
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.8172 10.942L12.1922 16.567C12.0749 16.6843 11.9159 16.7502 11.75 16.7502C11.5841 16.7502 11.4251 16.6843 11.3078 16.567C11.1905 16.4498 11.1247 16.2907 11.1247 16.1249C11.1247 15.959 11.1905 15.7999 11.3078 15.6827L15.8664 11.1249H3.625C3.45924 11.1249 3.30027 11.059 3.18306 10.9418C3.06585 10.8246 3 10.6656 3 10.4999C3 10.3341 3.06585 10.1751 3.18306 10.0579C3.30027 9.94071 3.45924 9.87486 3.625 9.87486H15.8664L11.3078 5.31705C11.1905 5.19977 11.1247 5.04071 11.1247 4.87486C11.1247 4.70901 11.1905 4.54995 11.3078 4.43267C11.4251 4.3154 11.5841 4.24951 11.75 4.24951C11.9159 4.24951 12.0749 4.3154 12.1922 4.43267L17.8172 10.0577C17.8753 10.1157 17.9214 10.1846 17.9529 10.2605C17.9843 10.3364 18.0005 10.4177 18.0005 10.4999C18.0005 10.582 17.9843 10.6633 17.9529 10.7392C17.9214 10.8151 17.8753 10.884 17.8172 10.942Z" fill="white" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </section>

                <div data-section id='section-6'>
                    <ContactSection />
                </div>


                <Footer />
            </main>
        </>
    )
}
