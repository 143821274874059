import React from 'react'
import './index.scss'
import EfficiencyFeatures from '../Features'
import BlueBanner from '../BlueBanner'

export default function EfficiencyBoost({ heading, image, desc }) {

    return (
        <section className='cloud-solutions efficiency mt-5' >
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-6 tw-flex tw-items-center tw-justify-center">
                        <div className="left-content">
                            <h3 className='mir-h3-semibold heading'>{heading}</h3>
                            <p className='mir-p-md-regular subtitle'>{desc}</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6">
                        <div className="right-content">
                            <img className='w-100' src={image} alt="efficiency-boost-bg" />
                        </div>
                    </div>
                </div>
                <div className="mt-0">
                    <EfficiencyFeatures 
                        items={[
                            {
                                icon: '/supply-chain/business-and-trade.png',
                                heading: 'Back Office',
                                desc: 'Eliminate repetitive and manual tasks across complex supply chain systems '
                            },
                            {
                                icon: '/supply-chain/truck.png',
                                heading: 'Transportation',
                                desc: 'Easily handle order planning, delivery time and prioritization for optimized resources utilization '
                            },
                            {
                                icon: '/supply-chain/warehouse.png',
                                heading: 'Warehouse',
                                desc: 'Minimize labor-intensive tasks of entering inventory data and providing analysis '
                            }
                        ]}
                    />
                </div>
                <BlueBanner heading={<>Transform your business's supply chain with the expertise of a Microsoft Dynamics 365 specialist</>} />
            </div>
        </section>
    )
}
