import React from "react";

const TeamSection = () => {
    return <section className="team-section mir-bg-neutral-800">
        <div className="container">
            <div className="row justify-content-center align-items-center">
                <div className="col-12 col-md-8 text-center">
                    <div className="team-section-heading text-uppercase mir-p-overline-lg mir-text-neutral-400 mb-3">
                        Meet our dream team
                    </div>
                </div>
                <div className="col-12 col-md-8 text-center">
                    <div className="team-section-content mir-h2-medium mir-text-neutral-100">
                        The teams that stand behind our successful and thriving operations. Together, we empower
                        businesses through digital transformation.
                    </div>
                </div>
            </div>
        </div>
    </section>;
}

export default TeamSection;