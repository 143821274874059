import React from 'react'
import './index.scss'

export default function OverviewSection({ overline, heading, desc, bg }) {
    
    return (
        <div className={`cloud-solutions overview !tw-pb-[32px] md:!tw-pb-[92px]`} style={{ backgroundImage: bg }}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-7">
                        <h4 className="mir-p-overline-lg subtitle">{overline}</h4>
                        <h2 className='mir-h1-semibold'>{heading}</h2>
                        <div className="content">
                            <p className='mir-p-md-regular'>{desc}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
