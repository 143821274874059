import React, { useEffect, useRef, useState } from 'react'
import StickyBanner from '../../StickyBanner/StickyBanner'
import Footer from '../../Footer/Footer'
import './StaffingSolutions.scss'
import StepsSlider from '../Elements/StepProcess/StepProcess';
import SectionTabs from '../Elements/Sections/SectionTabs/SectionTabs';
import OverviewSection from '../Elements/Sections/OverviewSection';
import BlueFluidBanner from '../Elements/Sections/BlueFluidBanner';
import HowHelpSection from '../Elements/Sections/HowHelpSection';
import SuccessStory from '../Elements/Sections/SuccessStory';
import ContactSection from '../Elements/Sections/ContactSection';
import { Link } from 'react-router-dom';
import GetStartedBlueBanner from '../Elements/Sections/GetStartedBlueBanner';
import OverviewSectionCenter from '../Elements/Sections/OverviewSectionCenter';

import blakes from './images/blakes.png';
import DMCMining2 from './images/DMCMining2.svg';
import metrolinx from './images/metrolinx.svg';
import Rogers from './images/Rogers_logo.svg';
import transunion from './images/transunion_logo.svg';
import { Banner } from '../../HomeComponent/Banner';
import BookNowModal from '../../CommonElements/BookNowModal';
import { Helmet } from 'react-helmet';

const images = [
    blakes,
    DMCMining2,
    metrolinx,
    Rogers,
    transunion,
    blakes,
    DMCMining2,
    metrolinx,
    Rogers,
    transunion,
    blakes,
    DMCMining2,
    metrolinx,
    Rogers,
    transunion
].map((image) => ({
    id: crypto.randomUUID(),
    image
}));

export default function StaffingSolutions() {
    const [showRegionPopup, setShowRegionPopup] = useState(false);
    const [tabs, setTabs] = useState([
        { name: 'Overview', id: 'section-1' },
        { name: 'Benefits', id: 'section-4' },
        { name: 'How we can help', id: 'section-5' },
        { name: 'Our offerings', id: 'section-2' },
        { name: 'Our specializations', id: 'section-3' },
        { name: 'Case studies', id: 'section-6' },
        { name: 'Contact Us', id: 'section-7' },
        { name: '', id: 'section-8' },
    ]);
    const questions = [
        'How much visibility do you have into logistics, inventory, and factory operations in real time?',
        'How has the pandemic affected your supply chain, and how are you ensuring business continuity post-pandemic?',
        'Are data integrity issues arising from using disparate systems for decision-making?'
    ]
    const childRef = useRef();

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Specialized Tech Staffing Solutions | MIR Digital Solutions</title>
            <meta name="description" content="From fresh talent to seasoned professionals, we have the resources to cater to your staffing requirements across North America, Middle East, & Africa." />
            <link rel="canonical" href={`${window.location.origin}/business-solutions/digital-staffing-solutions`} />
        </Helmet>
        <main style={{ position: 'relative' }} className='staffing-solutions-main-wrapper'>
            <StickyBanner />
            <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />
            <div className="bi-wrapper">
                <div className='hero-wrapper text-left'>
                    <div className='container'>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content text-left">
                                    <h1 className='mir-display-semibold-sm'>MIR Digital Staffing<br /> Solutions</h1>
                                    <p className='mir-paragraph-lg-l4-l5 text-secondary-600 mt-4'>Your gateway to specialized talent and a high-performing team across North America, Middle East and Africa</p>
                                    <button className='mir-btn-md-primary mt-4' onClick={(e) => { 
                                        window.open('https://outlook.office365.com/owa/calendar/MIRDigitalSolutions_Staffing@mirdigitalsolutions.com/bookings/', '_blank')
                                    }}>Contact us today</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* section tabs */}
            <SectionTabs tabs={tabs} />

            {/* overview */}
            <div data-section id='section-1'>
                <OverviewSectionCenter
                    overline={'OVERVIEW'}
                    heading={'The right team at the right time makes a difference'}
                    desc={<>As industries become more digital and tech-driven, staying ahead requires a skilled and adaptable workforce. This is where staffing solutions have emerged as a game changer. By tapping into the right talent and expertise, businesses can drive innovation and achieve their objectives at a faster pace than ever before. <br /><br />The latest statistics show that an astounding 90% of businesses view staffing solutions as critical for their survival in today's digital age. Are you ready to harness the power of staffing to propel your company's growth and ensure its success?</>}
                />
            </div>
            <section data-section id='section-4'>
                <HowHelpSection
                    overline={'benefits'}
                    heading={<>Why is staffing an important process in an organization?</>}
                    desc={<>In today's competitive landscape, more and more forward-thinking companies are turning to tech staffing firms like ours. With an overwhelming number of tech talent options available, finding top-tier candidates can be a daunting task. Staffing solutions have emerged as a preferred solution to find and hire the right talent for businesses seeking to innovate and stay ahead in the game. </>}
                    image={'url(/staffing-solutions/benefits.png) no-repeat'}
                />
                <div className="container">
                    <div className="sec-4-card-container">
                        <div className="row">
                            <div className="col-lg-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                    <g clip-path="url(#clip0_6803_72243)">
                                        <path d="M10.1407 12.166L9.64573 9.21173L9.84148 9.01598L9.22723 8.38598C8.32723 7.47023 8.02798 6.15173 8.41723 4.95023C8.43298 4.90523 8.44648 4.87598 8.46223 4.83098L8.26648 4.80173L7.00648 2.14673C6.82648 1.75748 6.42148 1.50098 5.98723 1.50098C5.55298 1.50098 5.14798 1.75523 4.96798 2.14673L3.71923 4.81523L0.944981 5.24948C0.53998 5.31023 0.195731 5.60948 0.0607313 6.01448C-0.0742687 6.40373 0.031481 6.85373 0.330731 7.15523L2.37148 9.24098L1.88998 12.1952C1.82923 12.6295 2.00923 13.0502 2.35573 13.291C2.54923 13.426 2.77423 13.5002 2.99923 13.5002C3.19498 13.5002 3.38848 13.4552 3.55498 13.3652L6.01423 11.986L8.48923 13.336C8.86498 13.5452 9.32848 13.516 9.68848 13.2752C10.035 13.021 10.215 12.6002 10.1407 12.166Z" fill="#8EB8F7" />
                                        <path d="M35.9392 5.9688C35.8042 5.5638 35.46 5.27805 35.0392 5.21955L32.265 4.7988L31.005 2.1438C30.825 1.75455 30.42 1.49805 29.9857 1.49805C29.5515 1.49805 29.1465 1.7523 28.9665 2.1438L27.72 4.81455L27.5557 4.8438C27.5557 4.85955 27.5715 4.87305 27.5715 4.8888C27.9765 6.08805 27.6907 7.4088 26.8065 8.3088L26.1292 8.99955L26.37 9.2403L25.8907 12.1945C25.83 12.6288 26.01 13.0495 26.3565 13.2903C26.55 13.4253 26.775 13.4995 27 13.4995C27.1957 13.4995 27.3892 13.4545 27.5557 13.3645L30.015 11.9853L32.49 13.3353C32.8657 13.5445 33.3292 13.5153 33.6892 13.2745C34.0335 13.0203 34.2135 12.5995 34.1392 12.1653L33.6442 9.21105L35.685 7.1118C35.9707 6.8103 36.0742 6.3738 35.9392 5.9688Z" fill="#8EB8F7" />
                                        <path d="M14.0872 15.0007C13.86 15.0007 13.635 14.9332 13.4415 14.7982C13.0882 14.5507 12.9082 14.1255 12.9757 13.7002L13.6238 9.65025L10.8247 6.80175C10.5277 6.50025 10.4265 6.05925 10.5592 5.661C10.692 5.2605 11.0362 4.968 11.4525 4.90275L15.2617 4.30875L16.965 0.654751C17.1472 0.254251 17.5432 0.00225 17.9797 0H17.9842C18.4185 0 18.8145 0.249751 19.0012 0.643501L20.7292 4.28625L24.543 4.8555C24.9593 4.9185 25.308 5.20875 25.443 5.607C25.578 6.00525 25.479 6.4485 25.1843 6.75L22.4033 9.6165L23.0782 13.662C23.1502 14.0872 22.9702 14.5148 22.6192 14.7645C22.2682 15.0143 21.8048 15.0413 21.4268 14.8343L18.0247 12.9713L14.634 14.859C14.463 14.9535 14.274 15.0007 14.0872 15.0007Z" fill="#1D68F0" />
                                        <path d="M6.00075 25.4995C7.65719 25.4995 9 24.1567 9 22.5002C9 20.8438 7.65719 19.501 6.00075 19.501C4.34431 19.501 3.0015 20.8438 3.0015 22.5002C3.0015 24.1567 4.34431 25.4995 6.00075 25.4995Z" fill="#8EB8F7" />
                                        <path d="M10.0057 27.6008C8.4915 28.7415 7.5015 30.5707 7.5015 32.625V33.0007H1.125C0.51075 33.0007 0 32.49 0 31.8757V31.1265C0 28.845 1.845 27 4.12425 27H7.875C8.65575 27 9.38925 27.225 10.0057 27.6008Z" fill="#8EB8F7" />
                                        <path d="M29.9993 25.4995C31.6557 25.4995 32.9985 24.1567 32.9985 22.5002C32.9985 20.8438 31.6557 19.501 29.9993 19.501C28.3428 19.501 27 20.8438 27 22.5002C27 24.1567 28.3428 25.4995 29.9993 25.4995Z" fill="#8EB8F7" />
                                        <path d="M36 31.1243V31.8735C36 32.4878 35.4892 32.9985 34.875 32.9985H28.5007V32.625C28.5007 30.5707 27.5107 28.7393 25.9965 27.6008C26.6107 27.225 27.3442 27 28.125 27H31.8757C34.155 27 36 28.845 36 31.1243Z" fill="#8EB8F7" />
                                        <path d="M18 26.251C20.4853 26.251 22.5 24.2363 22.5 21.751C22.5 19.2657 20.4853 17.251 18 17.251C15.5147 17.251 13.5 19.2657 13.5 21.751C13.5 24.2363 15.5147 26.251 18 26.251Z" fill="#1D68F0" />
                                        <path d="M22.1242 28.501H13.8735C11.5987 28.501 9.74924 30.3505 9.74924 32.6252V34.8752C9.74924 35.4962 10.2532 36.0002 10.8742 36.0002H25.1235C25.7445 36.0002 26.2485 35.4962 26.2485 34.8752V32.6252C26.2507 30.3505 24.399 28.501 22.1242 28.501Z" fill="#1D68F0" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_6803_72243">
                                            <rect width="36" height="36" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <h6 className='mir-paragraph-md-l2-l7 text-secondary-800 sec-4-card-header'>Access to specialized skills</h6>
                                <p className='mir-paragraph-rg-l4-l7 text-secondary-600 sec-4-card-desc'>
                                    Connect with top-tier candidates possessing specialized skills to fuel your organization's growth.
                                </p>
                            </div>
                            <div className="col-lg-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36" fill="none">
                                    <g clip-path="url(#clip0_6803_72224)">
                                        <path d="M17.7485 28.114C17.4628 28.114 17.1725 28.033 16.9183 27.862C16.2298 27.403 16.043 26.4715 16.5043 25.783L20.255 20.1692C20.7163 19.4807 21.6478 19.294 22.334 19.7552C23.0225 20.2142 23.2093 21.1457 22.748 21.8342L18.9973 27.448C18.707 27.8822 18.2323 28.114 17.7485 28.114Z" fill="#8EB8F7" />
                                        <path d="M28.5102 11.8895C26.5415 8.29177 22.6827 5.99902 18.5 5.99902C13.1832 5.99902 8.57075 9.69802 7.40525 14.7425C3.5285 15.152 0.499996 18.4078 0.499996 22.3498C0.499996 26.5685 3.96725 29.9998 8.22875 29.9998H27.3042C32.3757 29.9998 36.5 25.9183 36.5 20.8985C36.5 16.2995 33.0395 12.4633 28.5102 11.8895ZM27.1242 25.4998H9.8735C9.2525 25.4998 8.7485 24.9958 8.7485 24.3748C8.7485 19.2043 13.1225 14.999 18.4977 14.999C23.873 14.999 28.247 19.2043 28.247 24.3748C28.2493 24.9958 27.7452 25.4998 27.1242 25.4998Z" fill="#1D68F0" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_6803_72224">
                                            <rect width="36" height="36" fill="white" transform="translate(0.5)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <h6 className='mir-paragraph-md-l2-l7 text-secondary-800 sec-4-card-header'>Speed and agility</h6>
                                <p className='mir-paragraph-rg-l4-l7 text-secondary-600 sec-4-card-desc'>
                                    Quickly adapt to industry changes, seize opportunities, and respond to challenges promptly.
                                </p>
                            </div>
                            <div className="col-lg-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                    <g clip-path="url(#clip0_6402_3025)">
                                        <path d="M15.001 18.7498C14.38 18.7498 13.876 18.2458 13.876 17.6248V12.749C13.876 12.128 14.38 11.624 15.001 11.624C15.622 11.624 16.126 12.128 16.126 12.749V17.6248C16.126 18.2458 15.622 18.7498 15.001 18.7498Z" fill="#1D68F0" />
                                        <path d="M23.6248 21C20.909 21 18.6455 19.065 18.119 16.5H7.12327C4.84402 16.5 2.99902 18.345 2.99902 20.6243V31.8743C2.99902 34.1535 4.84402 35.9985 7.12327 35.9985H22.8733C25.1548 36.0008 26.9998 34.1557 26.9998 31.8765V21.0022L23.6248 21ZM7.49902 24.0015C7.49902 22.3522 8.84902 21.0022 10.4983 21.0022C12.1475 21.0022 13.4975 22.3522 13.4975 24.0015C13.4998 25.6507 12.1498 27.0007 10.5005 27.0007C8.84902 27.0007 7.49902 25.6507 7.49902 24.0015ZM18.3755 31.5007H11.6255C11.0113 31.5007 10.5005 30.99 10.5005 30.3757C10.5005 29.7615 11.0113 29.2507 11.6255 29.2507H18.3755C18.9898 29.2507 19.5005 29.7615 19.5005 30.3757C19.5005 30.99 18.9898 31.5007 18.3755 31.5007ZM19.5005 27.0007C17.8513 27.0007 16.5013 25.6507 16.5013 24.0015C16.5013 22.3522 17.8513 21.0022 19.5005 21.0022C21.1498 21.0022 22.4998 22.35 22.4998 24.0015C22.4998 25.6507 21.1498 27.0007 19.5005 27.0007Z" fill="#1D68F0" />
                                        <path d="M15.0012 13.5005C13.3475 13.5005 12.002 12.155 12.002 10.5012C12.002 8.84745 13.3475 7.50195 15.0012 7.50195C16.655 7.50195 18.0005 8.84745 18.0005 10.5012C18.0005 12.155 16.655 13.5005 15.0012 13.5005Z" fill="#1D68F0" />
                                        <path d="M28.8743 27.376H25.875C25.254 27.376 24.75 26.872 24.75 26.251C24.75 25.63 25.254 25.126 25.875 25.126H28.8743C29.4953 25.126 29.9993 25.63 29.9993 26.251C29.9993 26.872 29.4953 27.376 28.8743 27.376Z" fill="#1D68F0" />
                                        <path d="M28.874 29.9993C28.253 29.9993 27.749 29.4953 27.749 28.8743V23.625C27.749 23.004 28.253 22.5 28.874 22.5C29.495 22.5 29.999 23.004 29.999 23.625V28.8743C29.999 29.4953 29.495 29.9993 28.874 29.9993Z" fill="#1D68F0" />
                                        <path d="M4.12425 27.376H1.125C0.504 27.376 0 26.872 0 26.251C0 25.63 0.504 25.126 1.125 25.126H4.12425C4.74525 25.126 5.24925 25.63 5.24925 26.251C5.24925 26.872 4.74525 27.376 4.12425 27.376Z" fill="#1D68F0" />
                                        <path d="M1.125 29.9993C0.504 29.9993 0 29.4953 0 28.8743V23.625C0 23.004 0.504 22.5 1.125 22.5C1.746 22.5 2.25 23.004 2.25 23.625V28.8743C2.25 29.4953 1.746 29.9993 1.125 29.9993Z" fill="#1D68F0" />
                                        <path d="M33.3744 1.50056H32.2494V1.12486C32.2494 0.503937 31.7455 0 31.1245 0C30.5035 0 29.9995 0.503937 29.9995 1.12486V1.50056H27.0004V1.12486C27.0004 0.503937 26.4965 0 25.8755 0C25.2545 0 24.7506 0.503937 24.7506 1.12486V1.50056H23.6256C22.1789 1.50056 21 2.67716 21 4.12598V15.3746C21 16.8211 22.1767 18 23.6256 18H33.3744C34.8233 17.9977 36 16.8211 36 15.3723V4.12373C36 2.67717 34.8233 1.50056 33.3744 1.50056ZM30.751 13.4983H26.2512C25.6303 13.4983 25.1263 12.9944 25.1263 12.3735C25.1263 11.7525 25.6303 11.2486 26.2512 11.2486H30.751C31.372 11.2486 31.876 11.7525 31.876 12.3735C31.876 12.9944 31.372 13.4983 30.751 13.4983ZM30.751 9.74803H26.2512C25.6303 9.74803 25.1263 9.2441 25.1263 8.62317C25.1263 8.00225 25.6303 7.49831 26.2512 7.49831H30.751C31.372 7.49831 31.876 8.00225 31.876 8.62317C31.876 9.2441 31.372 9.74803 30.751 9.74803Z" fill="#8EB8F7" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_6402_3025">
                                            <rect width="36" height="36" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <h6 className='mir-paragraph-md-l2-l7 text-secondary-800 sec-4-card-header'>Minimized hiring risks</h6>
                                <p className='mir-paragraph-rg-l4-l7 text-secondary-600 sec-4-card-desc'>
                                    Provide candidates with the right skills, experience, and cultural fit, minimizing hiring risks.
                                </p>
                            </div>
                            <div className="col-lg-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                    <g clip-path="url(#clip0_6803_72197)">
                                        <path d="M12.7513 10.4985C19.7933 10.4985 25.502 8.14833 25.502 5.24925C25.502 2.35017 19.7933 0 12.7513 0C5.70925 0 0.000549316 2.35017 0.000549316 5.24925C0.000549316 8.14833 5.70925 10.4985 12.7513 10.4985Z" fill="#1D68F0" />
                                        <path d="M11.1155 17.9524C12.1123 15.8396 13.6085 14.0149 15.4558 12.6221C14.5603 12.6986 13.6558 12.7504 12.749 12.7504C7.78329 12.7504 2.78829 11.6006 -0.00170898 9.37988V12.7504C0.000541016 15.4211 4.84929 17.6216 11.1155 17.9524Z" fill="#1D68F0" />
                                        <path d="M9.7498 24.0011C9.7498 22.6646 9.9478 21.3731 10.2943 20.1446C6.19255 19.8296 2.32705 18.7339 0.000549316 16.8799V20.2504C0.000549316 22.7344 4.1923 24.8089 9.81955 25.3556C9.7768 24.9079 9.7498 24.4579 9.7498 24.0011Z" fill="#1D68F0" />
                                        <path d="M24.0013 11.999C17.3841 11.999 12.0021 17.3833 12.0021 23.9983C12.0021 30.6133 17.3841 35.9998 24.0013 35.9998C30.6186 35.9998 36.0006 30.6155 36.0006 24.0005C36.0006 17.3855 30.6163 11.999 24.0013 11.999ZM23.4298 22.8755H24.5706C26.3233 22.8755 27.7498 24.302 27.7498 26.0548C27.7498 27.632 26.6136 28.928 25.1241 29.1935V30.3748C25.1241 30.9958 24.6201 31.4998 23.9991 31.4998C23.3781 31.4998 22.8741 30.9958 22.8741 30.3748V29.2498H21.3756C20.7546 29.2498 20.2506 28.7458 20.2506 28.1248C20.2506 27.5038 20.7546 26.9998 21.3756 26.9998H24.5706C25.0836 26.9998 25.4998 26.5813 25.4998 26.0705C25.4998 25.544 25.0813 25.1255 24.5706 25.1255H23.4298C21.6771 25.1255 20.2506 23.699 20.2506 21.9463C20.2506 20.369 21.3868 19.073 22.8763 18.8075V17.6263C22.8763 17.0053 23.3803 16.5013 24.0013 16.5013C24.6223 16.5013 25.1263 17.0053 25.1263 17.6263V18.7513H26.6271C27.2481 18.7513 27.7521 19.2553 27.7521 19.8763C27.7521 20.4973 27.2481 21.0013 26.6271 21.0013H23.4321C22.9191 21.0013 22.5028 21.4198 22.5028 21.9305C22.5006 22.457 22.9191 22.8755 23.4298 22.8755Z" fill="#8EB8F7" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_6803_72197">
                                            <rect width="36" height="36" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <h6 className='mir-paragraph-md-l2-l7 text-secondary-800 sec-4-card-header'>Minimize opportunity costs</h6>
                                <p className='mir-paragraph-rg-l4-l7 text-secondary-600 sec-4-card-desc'>
                                    Our staffing solutions provide ready-to-go teams, no matter how ambitious your goals may be.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="sec-4-card-container">
                        <div className="row">
                            <div className="col-lg-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                    <g clip-path="url(#clip0_6803_72215)">
                                        <path d="M34.4427 29.2109H1.55614C0.728711 29.2109 0.0604858 29.8792 0.0604858 30.7066C0.0604858 31.5318 0.730953 32.2023 1.55614 32.2023H34.445C35.2701 32.2023 35.9406 31.534 35.9406 30.7066C35.9384 29.8792 35.2701 29.2109 34.4427 29.2109Z" fill="#1D68F0" />
                                        <path d="M6.04088 11.2725H10.5548C10.5256 11.4967 10.5256 11.7052 10.5256 11.9295V14.2615H6.04088V29.2114H3.04956V14.2615C3.04956 12.6179 4.39498 11.2725 6.04088 11.2725Z" fill="#1D68F0" />
                                        <path d="M32.9493 14.2615V29.2114H29.9602V14.2615H25.4755V11.9295C25.4755 11.7052 25.4755 11.4967 25.4464 11.2725H29.9602C31.6039 11.2725 32.9493 12.6179 32.9493 14.2615Z" fill="#1D68F0" />
                                        <path d="M24.7266 23.2158H11.2723C10.8597 23.2158 10.5256 22.8817 10.5256 22.4691V20.7178C10.5256 19.7199 10.9136 18.7804 11.6199 18.074L13.5147 16.1792V11.9299C13.5147 10.1248 14.1202 8.3444 15.2189 6.91601L17.4052 4.07269C17.6878 3.70494 18.3067 3.70494 18.5914 4.07269L20.7777 6.91601C21.8765 8.3444 22.4819 10.1248 22.4819 11.9299V16.1792L24.3767 18.074C25.0831 18.7804 25.471 19.7177 25.471 20.7178V22.4691C25.4733 22.8817 25.1391 23.2158 24.7266 23.2158Z" fill="#8EB8F7" />
                                        <path d="M19.8754 22.1179C19.4614 22.1179 19.125 21.7816 19.125 21.3676V20.2426C19.125 19.8286 19.4602 19.4922 19.8754 19.4922C20.2894 19.4922 20.6257 19.8286 20.6257 20.2426V21.3676C20.6246 21.7816 20.2894 22.1179 19.8754 22.1179Z" fill="#8EB8F7" />
                                        <path d="M16.1246 22.1179C15.7106 22.1179 15.3742 21.7816 15.3742 21.3676V20.2426C15.3742 19.8286 15.7095 19.4922 16.1246 19.4922C16.5386 19.4922 16.875 19.8286 16.875 20.2426V21.3676C16.875 21.7816 16.5397 22.1179 16.1246 22.1179Z" fill="#8EB8F7" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_6803_72215">
                                            <rect width="36" height="36" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <h6 className='mir-paragraph-md-l2-l7 text-secondary-800 sec-4-card-header'>Productivity and efficiency</h6>
                                <p className='mir-paragraph-rg-l4-l7 text-secondary-600 sec-4-card-desc'>
                                    Bring in industry experts to enhance efficiency, streamline workflows, offer fresh perspectives, and deliver improved outcomes.
                                </p>
                            </div>
                            <div className="col-lg-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                    <g clip-path="url(#clip0_6803_72228)">
                                        <path d="M26.2507 0C20.8755 0 16.5015 4.374 16.5015 9.74925C16.5015 15.1245 20.8755 19.4985 26.2507 19.4985C31.626 19.4985 36 15.1268 36 9.74925C36 4.37175 31.626 0 26.2507 0ZM29.5605 13.0613C29.268 13.3538 28.8832 13.5 28.5007 13.5C28.116 13.5 27.7335 13.3538 27.441 13.0613L25.191 10.8113C24.9075 10.53 24.75 10.1475 24.75 9.74925V5.9985C24.75 5.1705 25.4227 4.5 26.2507 4.5C27.0787 4.5 27.7515 5.1705 27.7515 6.00075V9.1305L29.5627 10.9417C30.1455 11.5245 30.1455 12.474 29.5605 13.0613Z" fill="#8EB8F7" />
                                        <path d="M0.738 18.0022C0.3285 18.0112 0 18.3442 0 18.7537V32.2514C0 32.6654 0.33525 33.0007 0.74925 33.0007H3.933C4.26825 33.0007 4.563 32.7779 4.65525 32.4562L7.974 20.7584C8.18775 20.0024 7.78275 19.2082 7.04475 18.9404C5.68575 18.4432 3.6 17.9549 0.738 18.0022Z" fill="#1D68F0" />
                                        <path d="M33.0007 26.2507C30.0015 26.2507 24.0007 30.0487 21.0015 30.0487C18.0022 30.0487 14.625 27.7492 14.625 27.7492C14.625 27.7492 18.666 28.4984 20.9993 28.4984C23.3325 28.4984 23.9985 27.7019 23.9985 26.6242C23.9985 23.9984 19.539 23.5912 17.2845 23.2492C15.0705 22.0162 13.9208 21.0419 10.2533 20.7314C10.2308 20.9452 10.197 21.1567 10.1385 21.3704L7.12575 31.9904C10.539 33.5407 16.416 35.9999 19.5007 35.9999C24.0007 35.9999 36 29.9992 36 28.5007C36 26.9999 34.4993 26.2507 33.0007 26.2507Z" fill="#1D68F0" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_6803_72228">
                                            <rect width="36" height="36" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <h6 className='mir-paragraph-md-l2-l7 text-secondary-800 sec-4-card-header'>Time and cost savings</h6>
                                <p className='mir-paragraph-rg-l4-l7 text-secondary-600 sec-4-card-desc'>
                                    Say goodbye to the costs associated with recruiting and training in-house staff, such as overhead expenses and ongoing training.
                                </p>
                            </div>
                            <div className="col-lg-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                    <g clip-path="url(#clip0_6803_72234)">
                                        <path d="M26.2507 16.499C20.8755 16.499 16.5015 20.873 16.5015 26.2483C16.5015 31.6235 20.8732 35.9998 26.2507 35.9998C31.6282 35.9998 36 31.6258 36 26.2505C36 20.8753 31.626 16.499 26.2507 16.499ZM32.706 22.4998H29.9992V19.793C31.1242 20.4478 32.0512 21.377 32.706 22.4998ZM33.75 26.2505C33.75 26.7635 33.6982 27.2653 33.5992 27.7513H30.0015V24.7498H33.5992C33.6982 25.2358 33.75 25.7353 33.75 26.2505ZM18.7492 26.2505C18.7492 25.7375 18.801 25.2358 18.9 24.7498H22.5V27.749H18.9022C18.8032 27.2653 18.7492 26.7635 18.7492 26.2505ZM24.75 24.7498H27.7492V27.749H24.75V24.7498ZM27.7492 18.902V22.4998H24.75V18.902C25.2337 18.803 25.7355 18.7513 26.2507 18.7513C26.766 18.7513 27.2655 18.803 27.7492 18.902ZM22.5 19.793V22.4998H19.7932C20.448 21.377 21.3772 20.4478 22.5 19.793ZM19.7932 29.999H22.5V32.7058C21.3772 32.051 20.448 31.124 19.7932 29.999ZM24.75 33.599V30.0013H27.7492V33.599C27.2655 33.698 26.7637 33.7498 26.2507 33.7498C25.7377 33.7498 25.236 33.698 24.75 33.599ZM29.9992 32.7058V29.999H32.706C32.0512 31.124 31.1242 32.051 29.9992 32.7058Z" fill="#8EB8F7" />
                                        <path d="M13.2593 22.5H7.1235C6.5025 22.5 5.9985 21.996 5.9985 21.375C5.9985 20.754 6.5025 20.25 7.1235 20.25H13.2593C13.8803 20.25 14.3843 20.754 14.3843 21.375C14.3843 21.996 13.8803 22.5 13.2593 22.5Z" fill="#1D68F0" />
                                        <path d="M12.375 27H7.12576C6.50476 27 6.00076 26.496 6.00076 25.875C6.00076 25.254 6.50476 24.75 7.12576 24.75H12.375C12.996 24.75 13.5 25.254 13.5 25.875C13.5 26.496 12.996 27 12.375 27Z" fill="#1D68F0" />
                                        <path d="M12.375 31.5H4.12425C1.8495 31.5 0 29.6505 0 27.3757V4.12425C0 1.8495 1.8495 0 4.12425 0H21.375C23.6497 0 25.4993 1.8495 25.4993 4.12425V12.465C25.4993 13.086 24.9953 13.59 24.3743 13.59C23.7533 13.59 23.2493 13.086 23.2493 12.465V4.12425C23.2493 3.0915 22.41 2.25 21.375 2.25H4.12425C3.0915 2.25 2.25 3.0915 2.25 4.12425V27.3735C2.25 28.4085 3.0915 29.25 4.12425 29.25H12.375C12.996 29.25 13.5 29.754 13.5 30.375C13.5 30.996 12.996 31.5 12.375 31.5Z" fill="#1D68F0" />
                                        <path d="M12.7507 10.4985C14.4072 10.4985 15.75 9.15569 15.75 7.49925C15.75 5.84281 14.4072 4.5 12.7507 4.5C11.0943 4.5 9.7515 5.84281 9.7515 7.49925C9.7515 9.15569 11.0943 10.4985 12.7507 10.4985Z" fill="#1D68F0" />
                                        <path d="M17.6243 18.0005H7.875C7.254 18.0005 6.75 17.4965 6.75 16.8755V16.1262C6.75 13.8515 8.5995 12.002 10.8743 12.002H14.625C16.8997 12.002 18.7493 13.8515 18.7493 16.1262V16.8755C18.7493 17.4965 18.2453 18.0005 17.6243 18.0005Z" fill="#1D68F0" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_6803_72234">
                                            <rect width="36" height="36" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <h6 className='mir-paragraph-md-l2-l7 text-secondary-800 sec-4-card-header'>Global reach, local expertise</h6>
                                <p className='mir-paragraph-rg-l4-l7 text-secondary-600 sec-4-card-desc'>
                                    With our local market knowledge, we can assist you in making hiring decisions worldwide.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
                <BlueFluidBanner
                    url={'https://outlook.office365.com/owa/calendar/MIRDigitalSolutions_Staffing@mirdigitalsolutions.com/bookings/'}
                    title={<>Connect with us for a customized staffing solution</>}
                    button={<><span>Get started today</span>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.3172 10.442L11.6922 16.067C11.5749 16.1843 11.4159 16.2502 11.25 16.2502C11.0841 16.2502 10.9251 16.1843 10.8078 16.067C10.6905 15.9498 10.6247 15.7907 10.6247 15.6249C10.6247 15.459 10.6905 15.2999 10.8078 15.1827L15.3664 10.6249H3.125C2.95924 10.6249 2.80027 10.559 2.68306 10.4418C2.56585 10.3246 2.5 10.1656 2.5 9.99986C2.5 9.8341 2.56585 9.67513 2.68306 9.55792C2.80027 9.44071 2.95924 9.37486 3.125 9.37486H15.3664L10.8078 4.81705C10.6905 4.69977 10.6247 4.54071 10.6247 4.37486C10.6247 4.20901 10.6905 4.04995 10.8078 3.93267C10.9251 3.8154 11.0841 3.74951 11.25 3.74951C11.4159 3.74951 11.5749 3.8154 11.6922 3.93267L17.3172 9.55767C17.3753 9.61572 17.4214 9.68465 17.4529 9.76052C17.4843 9.8364 17.5005 9.91772 17.5005 9.99986C17.5005 10.082 17.4843 10.1633 17.4529 10.2392C17.4214 10.3151 17.3753 10.384 17.3172 10.442Z" fill="#1D68F0" />
                        </svg></>}
                />
            </section>
            <section data-section id='section-5'>
                <HowHelpSection
                    overline={'How we can help'}
                    heading={<>Why should you choose <span style={{ color: '#1D68F0' }}>MIR Digital Solutions</span> to find the right talent for your business?</>}
                    desc={<>By partnering with us, you can leverage our wealth of benefits while securing top talent that can contribute to your company's success for years to come. </>}
                    image={'url(/staffing-solutions/how-can-we-help.png) no-repeat'}
                />
                <div className="container">
                    <div className="sec-5-card-container !tw-mb-0 md:!tw-mb-[56px]">
                        <div className="row">
                            <div className="col-lg-4">
                                <h6 className='mir-paragraph-md-l2-l7 text-secondary-800 sec-4-card-header'>Competitive rates </h6>
                                <p className='mir-paragraph-rg-l4-l7 text-secondary-600 sec-4-card-desc'>
                                    Our rates are set to provide exceptional value, ensuring that you can hire top-tier talent without breaking the bank.
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <h6 className='mir-paragraph-md-l2-l7 text-secondary-800 sec-4-card-header'>Customized plans </h6>
                                <p className='mir-paragraph-rg-l4-l7 text-secondary-600 sec-4-card-desc'>
                                    We don't offer one-size-fits-all solutions. MIR tailors staffing strategies to your unique needs, ensuring every candidate is a perfect fit.
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <h6 className='mir-paragraph-md-l2-l7 text-secondary-800 sec-4-card-header'>Transparent & fair pricing structure </h6>
                                <p className='mir-paragraph-rg-l4-l7 text-secondary-600 sec-4-card-desc'>
                                    There are no hidden fees or surprises for top-tier staffing solutions, so you know exactly what you're paying for
                                </p>
                            </div>

                        </div>
                    </div>
                    <div className="sec-5-card-container !tw-mb-0 md:!tw-mb-[56px]">
                        <div className="row">
                            <div className="col-lg-4">
                                <h6 className='mir-paragraph-md-l2-l7 text-secondary-800 sec-4-card-header'>Continuous support </h6>
                                <p className='mir-paragraph-rg-l4-l7 text-secondary-600 sec-4-card-desc'>
                                    We don't just stop at placement. MIR provides ongoing support to ensure the long-term success of your tech and IT teams.
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <h6 className='mir-paragraph-md-l2-l7 text-secondary-800 sec-4-card-header'>Fast turnaround </h6>
                                <p className='mir-paragraph-rg-l4-l7 text-secondary-600 sec-4-card-desc'>
                                    We know time is of the essence. Our swift response times ensure that your talent needs are met promptly.
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <h6 className='mir-paragraph-md-l2-l7 text-secondary-800 sec-4-card-header'>Client-centric approach </h6>
                                <p className='mir-paragraph-rg-l4-l7 text-secondary-600 sec-4-card-desc'>
                                    At MIR, we're not just a vendor; we're your partner. Your goals are our goals, and we're committed to helping you achieve them.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section data-section id='section-2' className='section-2-staffing'>
                <div className="container">
                    <div className="offerings-container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className='overline mir-paragraph-sm-l2-l7 text-primary-500'>Our offerings</div>
                                <h1 className='offering-header mir-heading-l6-l2 text-secondary-800'>
                                    Services offered by us
                                </h1>
                                <p className='mir-paragraph-md-l4-l7 text-secondary-700'>
                                    At MIR, we understand that every business has unique staffing needs. That's why we offer a diverse range of tech staffing solutions designed to cater to those distinct requirements.
                                    <br />
                                    <br />
                                    Our extensive talent pool consists of candidates with a wide spectrum of experience, ranging from under 5 years to over 10 years of expertise. Whether you are seeking fresh talent or seasoned professionals, we have the resources to find the perfect fit for your organization.
                                </p>
                            </div>
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-2">
                                        <div className="offering-card offering-card-1">
                                            <div className="row">
                                                <div className="col-lg-4 col-4">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                                        <path d="M43.414 22.5861L38 17.1721V12.0001C38 11.4696 37.7893 10.9609 37.4142 10.5858C37.0391 10.2108 36.5304 10.0001 36 10.0001H30.828L25.414 4.58606C25.0389 4.21112 24.5303 4.00049 24 4.00049C23.4697 4.00049 22.9611 4.21112 22.586 4.58606L17.172 10.0001H12C11.4696 10.0001 10.9609 10.2108 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12.0001V17.1721L4.586 22.5861C4.21106 22.9611 4.00043 23.4697 4.00043 24.0001C4.00043 24.5304 4.21106 25.039 4.586 25.4141L10 30.8281V36.0001C10 36.5305 10.2107 37.0392 10.5858 37.4143C10.9609 37.7893 11.4696 38.0001 12 38.0001H17.172L22.586 43.4141C22.9611 43.789 23.4697 43.9996 24 43.9996C24.5303 43.9996 25.0389 43.789 25.414 43.4141L30.828 38.0001H36C36.5304 38.0001 37.0391 37.7893 37.4142 37.4143C37.7893 37.0392 38 36.5305 38 36.0001V30.8281L43.414 25.4141C43.7889 25.039 43.9996 24.5304 43.9996 24.0001C43.9996 23.4697 43.7889 22.9611 43.414 22.5861Z" fill="url(#paint0_linear_6530_10193)" />
                                                        <path opacity="0.56" d="M30.3137 16.3725L20.8405 26.2766L18.27 23.3026C18.041 23.069 17.7362 22.9288 17.4131 22.9081C17.09 22.8876 16.7706 22.988 16.5148 23.1907C16.259 23.3934 16.0844 23.6843 16.0237 24.0092C15.9628 24.3339 16.0202 24.6702 16.1849 24.9548L19.2317 30.2327C19.3777 30.4678 19.5795 30.6614 19.8183 30.7955C20.0572 30.9296 20.3253 31 20.5978 31C20.8704 31 21.1386 30.9296 21.3775 30.7955C21.6162 30.6614 21.818 30.4678 21.964 30.2327L31.5899 17.8595C32.7134 16.5377 31.2753 15.3812 30.3047 16.3725H30.3137Z" fill="white" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_6530_10193" x1="10.586" y1="10.5861" x2="37.414" y2="37.4141" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#1C5CA6" />
                                                                <stop offset="1" stop-color="#488FE0" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                </div>
                                                <div className="col-lg-8 col-8">
                                                    <h5 className='mir-heading-l1-l2 text-shades-100 text-left'>Junior</h5>
                                                    <p className='mir-paragraph-sm-l4-l7 text-shades-100 text-left'>Less than 5 years</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-2">
                                        <div className="offering-card offering-card-2">
                                            <div className="row">
                                                <div className="col-lg-4 col-4">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                                        <path d="M43.414 22.5861L38 17.1721V12.0001C38 11.4696 37.7893 10.9609 37.4142 10.5858C37.0391 10.2108 36.5304 10.0001 36 10.0001H30.828L25.414 4.58606C25.0389 4.21112 24.5303 4.00049 24 4.00049C23.4697 4.00049 22.9611 4.21112 22.586 4.58606L17.172 10.0001H12C11.4696 10.0001 10.9609 10.2108 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12.0001V17.1721L4.586 22.5861C4.21106 22.9611 4.00043 23.4697 4.00043 24.0001C4.00043 24.5304 4.21106 25.039 4.586 25.4141L10 30.8281V36.0001C10 36.5305 10.2107 37.0392 10.5858 37.4143C10.9609 37.7893 11.4696 38.0001 12 38.0001H17.172L22.586 43.4141C22.9611 43.789 23.4697 43.9996 24 43.9996C24.5303 43.9996 25.0389 43.789 25.414 43.4141L30.828 38.0001H36C36.5304 38.0001 37.0391 37.7893 37.4142 37.4143C37.7893 37.0392 38 36.5305 38 36.0001V30.8281L43.414 25.4141C43.7889 25.039 43.9996 24.5304 43.9996 24.0001C43.9996 23.4697 43.7889 22.9611 43.414 22.5861Z" fill="url(#paint0_linear_6530_10200)" />
                                                        <path opacity="0.56" d="M30.3137 16.3725L20.8405 26.2766L18.27 23.3026C18.041 23.069 17.7362 22.9288 17.4131 22.9081C17.09 22.8876 16.7706 22.988 16.5148 23.1907C16.259 23.3934 16.0844 23.6843 16.0237 24.0092C15.9628 24.3339 16.0202 24.6702 16.1849 24.9548L19.2317 30.2327C19.3777 30.4678 19.5795 30.6614 19.8183 30.7955C20.0572 30.9296 20.3253 31 20.5978 31C20.8704 31 21.1386 30.9296 21.3775 30.7955C21.6162 30.6614 21.818 30.4678 21.964 30.2327L31.5899 17.8595C32.7134 16.5377 31.2753 15.3812 30.3047 16.3725H30.3137Z" fill="white" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_6530_10200" x1="10.586" y1="10.5861" x2="37.414" y2="37.4141" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#152038" />
                                                                <stop offset="0.984375" stop-color="#304269" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                </div>
                                                <div className="col-lg-8 col-8">
                                                    <h5 className='mir-heading-l1-l2 text-shades-100 text-left'>Intermediate </h5>
                                                    <p className='mir-paragraph-sm-l4-l7 text-shades-100 text-left'>From 5 to 9 years</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-2">
                                        <div className="offering-card offering-card-3">
                                            <div className="row">
                                                <div className="col-lg-4 col-4">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                                        <path d="M43.414 22.5861L38 17.1721V12.0001C38 11.4696 37.7893 10.9609 37.4142 10.5858C37.0391 10.2108 36.5304 10.0001 36 10.0001H30.828L25.414 4.58606C25.0389 4.21112 24.5303 4.00049 24 4.00049C23.4697 4.00049 22.9611 4.21112 22.586 4.58606L17.172 10.0001H12C11.4696 10.0001 10.9609 10.2108 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12.0001V17.1721L4.586 22.5861C4.21106 22.9611 4.00043 23.4697 4.00043 24.0001C4.00043 24.5304 4.21106 25.039 4.586 25.4141L10 30.8281V36.0001C10 36.5305 10.2107 37.0392 10.5858 37.4143C10.9609 37.7893 11.4696 38.0001 12 38.0001H17.172L22.586 43.4141C22.9611 43.789 23.4697 43.9996 24 43.9996C24.5303 43.9996 25.0389 43.789 25.414 43.4141L30.828 38.0001H36C36.5304 38.0001 37.0391 37.7893 37.4142 37.4143C37.7893 37.0392 38 36.5305 38 36.0001V30.8281L43.414 25.4141C43.7889 25.039 43.9996 24.5304 43.9996 24.0001C43.9996 23.4697 43.7889 22.9611 43.414 22.5861Z" fill="url(#paint0_linear_6530_10205)" />
                                                        <path opacity="0.56" d="M30.3137 16.3725L20.8405 26.2766L18.27 23.3026C18.041 23.069 17.7362 22.9288 17.4131 22.9081C17.09 22.8876 16.7706 22.988 16.5148 23.1907C16.259 23.3934 16.0844 23.6843 16.0237 24.0092C15.9628 24.3339 16.0202 24.6702 16.1849 24.9548L19.2317 30.2327C19.3777 30.4678 19.5795 30.6614 19.8183 30.7955C20.0572 30.9296 20.3253 31 20.5978 31C20.8704 31 21.1386 30.9296 21.3775 30.7955C21.6162 30.6614 21.818 30.4678 21.964 30.2327L31.5899 17.8595C32.7134 16.5377 31.2753 15.3812 30.3047 16.3725H30.3137Z" fill="white" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_6530_10205" x1="10.586" y1="10.5861" x2="37.414" y2="37.4141" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#0F4375" />
                                                                <stop offset="1" stop-color="#2D6193" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                </div>
                                                <div className="col-lg-8 col-8">
                                                    <h5 className='mir-heading-l1-l2 text-shades-100 text-left'>Senior</h5>
                                                    <p className='mir-paragraph-sm-l4-l7 text-shades-100 text-left'>More than 10 years</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="offerings-container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 d-none d-lg-block d-md-block">
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-2">
                                        <div className="offering-card offering-card-1">
                                            <div className="row">
                                                <div className="col-lg-4 col-4">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                                        <path d="M43.414 22.5861L38 17.1721V12.0001C38 11.4696 37.7893 10.9609 37.4142 10.5858C37.0391 10.2108 36.5304 10.0001 36 10.0001H30.828L25.414 4.58606C25.0389 4.21112 24.5303 4.00049 24 4.00049C23.4697 4.00049 22.9611 4.21112 22.586 4.58606L17.172 10.0001H12C11.4696 10.0001 10.9609 10.2108 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12.0001V17.1721L4.586 22.5861C4.21106 22.9611 4.00043 23.4697 4.00043 24.0001C4.00043 24.5304 4.21106 25.039 4.586 25.4141L10 30.8281V36.0001C10 36.5305 10.2107 37.0392 10.5858 37.4143C10.9609 37.7893 11.4696 38.0001 12 38.0001H17.172L22.586 43.4141C22.9611 43.789 23.4697 43.9996 24 43.9996C24.5303 43.9996 25.0389 43.789 25.414 43.4141L30.828 38.0001H36C36.5304 38.0001 37.0391 37.7893 37.4142 37.4143C37.7893 37.0392 38 36.5305 38 36.0001V30.8281L43.414 25.4141C43.7889 25.039 43.9996 24.5304 43.9996 24.0001C43.9996 23.4697 43.7889 22.9611 43.414 22.5861Z" fill="url(#paint0_linear_6530_10193)" />
                                                        <path opacity="0.56" d="M30.3137 16.3725L20.8405 26.2766L18.27 23.3026C18.041 23.069 17.7362 22.9288 17.4131 22.9081C17.09 22.8876 16.7706 22.988 16.5148 23.1907C16.259 23.3934 16.0844 23.6843 16.0237 24.0092C15.9628 24.3339 16.0202 24.6702 16.1849 24.9548L19.2317 30.2327C19.3777 30.4678 19.5795 30.6614 19.8183 30.7955C20.0572 30.9296 20.3253 31 20.5978 31C20.8704 31 21.1386 30.9296 21.3775 30.7955C21.6162 30.6614 21.818 30.4678 21.964 30.2327L31.5899 17.8595C32.7134 16.5377 31.2753 15.3812 30.3047 16.3725H30.3137Z" fill="white" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_6530_10193" x1="10.586" y1="10.5861" x2="37.414" y2="37.4141" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#1C5CA6" />
                                                                <stop offset="1" stop-color="#488FE0" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                </div>
                                                <div className="col-lg-8 col-8" style={{ position: "relative" }}>
                                                    <h5 className='mir-heading-l1-l2 text-shades-100 text-left vertical-center'>Onshore</h5>
                                                    {/* <p className='mir-paragraph-sm-l4-l7 text-shades-100 text-left'>Less than 5 years</p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-2">
                                        <div className="offering-card offering-card-2">
                                            <div className="row">
                                                <div className="col-lg-4 col-4">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                                        <path d="M43.414 22.5861L38 17.1721V12.0001C38 11.4696 37.7893 10.9609 37.4142 10.5858C37.0391 10.2108 36.5304 10.0001 36 10.0001H30.828L25.414 4.58606C25.0389 4.21112 24.5303 4.00049 24 4.00049C23.4697 4.00049 22.9611 4.21112 22.586 4.58606L17.172 10.0001H12C11.4696 10.0001 10.9609 10.2108 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12.0001V17.1721L4.586 22.5861C4.21106 22.9611 4.00043 23.4697 4.00043 24.0001C4.00043 24.5304 4.21106 25.039 4.586 25.4141L10 30.8281V36.0001C10 36.5305 10.2107 37.0392 10.5858 37.4143C10.9609 37.7893 11.4696 38.0001 12 38.0001H17.172L22.586 43.4141C22.9611 43.789 23.4697 43.9996 24 43.9996C24.5303 43.9996 25.0389 43.789 25.414 43.4141L30.828 38.0001H36C36.5304 38.0001 37.0391 37.7893 37.4142 37.4143C37.7893 37.0392 38 36.5305 38 36.0001V30.8281L43.414 25.4141C43.7889 25.039 43.9996 24.5304 43.9996 24.0001C43.9996 23.4697 43.7889 22.9611 43.414 22.5861Z" fill="url(#paint0_linear_6530_10200)" />
                                                        <path opacity="0.56" d="M30.3137 16.3725L20.8405 26.2766L18.27 23.3026C18.041 23.069 17.7362 22.9288 17.4131 22.9081C17.09 22.8876 16.7706 22.988 16.5148 23.1907C16.259 23.3934 16.0844 23.6843 16.0237 24.0092C15.9628 24.3339 16.0202 24.6702 16.1849 24.9548L19.2317 30.2327C19.3777 30.4678 19.5795 30.6614 19.8183 30.7955C20.0572 30.9296 20.3253 31 20.5978 31C20.8704 31 21.1386 30.9296 21.3775 30.7955C21.6162 30.6614 21.818 30.4678 21.964 30.2327L31.5899 17.8595C32.7134 16.5377 31.2753 15.3812 30.3047 16.3725H30.3137Z" fill="white" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_6530_10200" x1="10.586" y1="10.5861" x2="37.414" y2="37.4141" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#152038" />
                                                                <stop offset="0.984375" stop-color="#304269" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                </div>
                                                <div className="col-lg-8 col-8" style={{ position: "relative" }}>
                                                    <h5 className='mir-heading-l1-l2 text-shades-100 text-left vertical-center'>Nearshore</h5>
                                                    {/* <p className='mir-paragraph-sm-l4-l7 text-shades-100 text-left'>Less than 5 years</p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-2">
                                        <div className="offering-card offering-card-3">
                                            <div className="row">
                                                <div className="col-lg-4 col-4">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                                        <path d="M43.414 22.5861L38 17.1721V12.0001C38 11.4696 37.7893 10.9609 37.4142 10.5858C37.0391 10.2108 36.5304 10.0001 36 10.0001H30.828L25.414 4.58606C25.0389 4.21112 24.5303 4.00049 24 4.00049C23.4697 4.00049 22.9611 4.21112 22.586 4.58606L17.172 10.0001H12C11.4696 10.0001 10.9609 10.2108 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12.0001V17.1721L4.586 22.5861C4.21106 22.9611 4.00043 23.4697 4.00043 24.0001C4.00043 24.5304 4.21106 25.039 4.586 25.4141L10 30.8281V36.0001C10 36.5305 10.2107 37.0392 10.5858 37.4143C10.9609 37.7893 11.4696 38.0001 12 38.0001H17.172L22.586 43.4141C22.9611 43.789 23.4697 43.9996 24 43.9996C24.5303 43.9996 25.0389 43.789 25.414 43.4141L30.828 38.0001H36C36.5304 38.0001 37.0391 37.7893 37.4142 37.4143C37.7893 37.0392 38 36.5305 38 36.0001V30.8281L43.414 25.4141C43.7889 25.039 43.9996 24.5304 43.9996 24.0001C43.9996 23.4697 43.7889 22.9611 43.414 22.5861Z" fill="url(#paint0_linear_6530_10205)" />
                                                        <path opacity="0.56" d="M30.3137 16.3725L20.8405 26.2766L18.27 23.3026C18.041 23.069 17.7362 22.9288 17.4131 22.9081C17.09 22.8876 16.7706 22.988 16.5148 23.1907C16.259 23.3934 16.0844 23.6843 16.0237 24.0092C15.9628 24.3339 16.0202 24.6702 16.1849 24.9548L19.2317 30.2327C19.3777 30.4678 19.5795 30.6614 19.8183 30.7955C20.0572 30.9296 20.3253 31 20.5978 31C20.8704 31 21.1386 30.9296 21.3775 30.7955C21.6162 30.6614 21.818 30.4678 21.964 30.2327L31.5899 17.8595C32.7134 16.5377 31.2753 15.3812 30.3047 16.3725H30.3137Z" fill="white" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_6530_10205" x1="10.586" y1="10.5861" x2="37.414" y2="37.4141" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#0F4375" />
                                                                <stop offset="1" stop-color="#2D6193" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                </div>
                                                <div className="col-lg-8 col-8" style={{ position: "relative" }}>
                                                    <h5 className='mir-heading-l1-l2 text-shades-100 text-left vertical-center'>Offshore</h5>
                                                    {/* <p className='mir-paragraph-sm-l4-l7 text-shades-100 text-left'>Less than 5 years</p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className='overline mir-paragraph-sm-l2-l7 text-primary-500'>Global Talent</div>
                                <h1 className='offering-header mir-heading-l6-l2 text-secondary-800'>
                                    We leverage skilled tech professionals worldwide to meet your needs
                                </h1>
                                <p className='mir-paragraph-md-l4-l7 text-secondary-700'>
                                    At MIR, we offer our expertise in connecting you with professionals from various geographic locations, allowing you to align your goals with your workforce.
                                    <br />
                                    <br />
                                    Whether you are looking to expand into new markets, access specialized expertise, or optimize your workforce costs, our comprehensive talent solutions cover onshore, nearshore, and offshore locations. With our global scalability, we provide the flexibility you need to achieve success on a global scale.
                                </p>
                            </div>
                            <div className="col-lg-6 d-block d-lg-none d-md-none">
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-2">
                                        <div className="offering-card offering-card-1">
                                            <div className="row">
                                                <div className="col-lg-4 col-4">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                                        <path d="M43.414 22.5861L38 17.1721V12.0001C38 11.4696 37.7893 10.9609 37.4142 10.5858C37.0391 10.2108 36.5304 10.0001 36 10.0001H30.828L25.414 4.58606C25.0389 4.21112 24.5303 4.00049 24 4.00049C23.4697 4.00049 22.9611 4.21112 22.586 4.58606L17.172 10.0001H12C11.4696 10.0001 10.9609 10.2108 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12.0001V17.1721L4.586 22.5861C4.21106 22.9611 4.00043 23.4697 4.00043 24.0001C4.00043 24.5304 4.21106 25.039 4.586 25.4141L10 30.8281V36.0001C10 36.5305 10.2107 37.0392 10.5858 37.4143C10.9609 37.7893 11.4696 38.0001 12 38.0001H17.172L22.586 43.4141C22.9611 43.789 23.4697 43.9996 24 43.9996C24.5303 43.9996 25.0389 43.789 25.414 43.4141L30.828 38.0001H36C36.5304 38.0001 37.0391 37.7893 37.4142 37.4143C37.7893 37.0392 38 36.5305 38 36.0001V30.8281L43.414 25.4141C43.7889 25.039 43.9996 24.5304 43.9996 24.0001C43.9996 23.4697 43.7889 22.9611 43.414 22.5861Z" fill="url(#paint0_linear_6530_10193)" />
                                                        <path opacity="0.56" d="M30.3137 16.3725L20.8405 26.2766L18.27 23.3026C18.041 23.069 17.7362 22.9288 17.4131 22.9081C17.09 22.8876 16.7706 22.988 16.5148 23.1907C16.259 23.3934 16.0844 23.6843 16.0237 24.0092C15.9628 24.3339 16.0202 24.6702 16.1849 24.9548L19.2317 30.2327C19.3777 30.4678 19.5795 30.6614 19.8183 30.7955C20.0572 30.9296 20.3253 31 20.5978 31C20.8704 31 21.1386 30.9296 21.3775 30.7955C21.6162 30.6614 21.818 30.4678 21.964 30.2327L31.5899 17.8595C32.7134 16.5377 31.2753 15.3812 30.3047 16.3725H30.3137Z" fill="white" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_6530_10193" x1="10.586" y1="10.5861" x2="37.414" y2="37.4141" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#1C5CA6" />
                                                                <stop offset="1" stop-color="#488FE0" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                </div>
                                                <div className="col-lg-8 col-8" style={{ position: "relative" }}>
                                                    <h5 className='mir-heading-l1-l2 text-shades-100 text-left vertical-center'>Onshore</h5>
                                                    {/* <p className='mir-paragraph-sm-l4-l7 text-shades-100 text-left'>Less than 5 years</p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-2">
                                        <div className="offering-card offering-card-2">
                                            <div className="row">
                                                <div className="col-lg-4 col-4">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                                        <path d="M43.414 22.5861L38 17.1721V12.0001C38 11.4696 37.7893 10.9609 37.4142 10.5858C37.0391 10.2108 36.5304 10.0001 36 10.0001H30.828L25.414 4.58606C25.0389 4.21112 24.5303 4.00049 24 4.00049C23.4697 4.00049 22.9611 4.21112 22.586 4.58606L17.172 10.0001H12C11.4696 10.0001 10.9609 10.2108 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12.0001V17.1721L4.586 22.5861C4.21106 22.9611 4.00043 23.4697 4.00043 24.0001C4.00043 24.5304 4.21106 25.039 4.586 25.4141L10 30.8281V36.0001C10 36.5305 10.2107 37.0392 10.5858 37.4143C10.9609 37.7893 11.4696 38.0001 12 38.0001H17.172L22.586 43.4141C22.9611 43.789 23.4697 43.9996 24 43.9996C24.5303 43.9996 25.0389 43.789 25.414 43.4141L30.828 38.0001H36C36.5304 38.0001 37.0391 37.7893 37.4142 37.4143C37.7893 37.0392 38 36.5305 38 36.0001V30.8281L43.414 25.4141C43.7889 25.039 43.9996 24.5304 43.9996 24.0001C43.9996 23.4697 43.7889 22.9611 43.414 22.5861Z" fill="url(#paint0_linear_6530_10200)" />
                                                        <path opacity="0.56" d="M30.3137 16.3725L20.8405 26.2766L18.27 23.3026C18.041 23.069 17.7362 22.9288 17.4131 22.9081C17.09 22.8876 16.7706 22.988 16.5148 23.1907C16.259 23.3934 16.0844 23.6843 16.0237 24.0092C15.9628 24.3339 16.0202 24.6702 16.1849 24.9548L19.2317 30.2327C19.3777 30.4678 19.5795 30.6614 19.8183 30.7955C20.0572 30.9296 20.3253 31 20.5978 31C20.8704 31 21.1386 30.9296 21.3775 30.7955C21.6162 30.6614 21.818 30.4678 21.964 30.2327L31.5899 17.8595C32.7134 16.5377 31.2753 15.3812 30.3047 16.3725H30.3137Z" fill="white" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_6530_10200" x1="10.586" y1="10.5861" x2="37.414" y2="37.4141" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#152038" />
                                                                <stop offset="0.984375" stop-color="#304269" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                </div>
                                                <div className="col-lg-8 col-8" style={{ position: "relative" }}>
                                                    <h5 className='mir-heading-l1-l2 text-shades-100 text-left vertical-center'>Nearshore</h5>
                                                    {/* <p className='mir-paragraph-sm-l4-l7 text-shades-100 text-left'>Less than 5 years</p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-2">
                                        <div className="offering-card offering-card-3">
                                            <div className="row">
                                                <div className="col-lg-4 col-4">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                                        <path d="M43.414 22.5861L38 17.1721V12.0001C38 11.4696 37.7893 10.9609 37.4142 10.5858C37.0391 10.2108 36.5304 10.0001 36 10.0001H30.828L25.414 4.58606C25.0389 4.21112 24.5303 4.00049 24 4.00049C23.4697 4.00049 22.9611 4.21112 22.586 4.58606L17.172 10.0001H12C11.4696 10.0001 10.9609 10.2108 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12.0001V17.1721L4.586 22.5861C4.21106 22.9611 4.00043 23.4697 4.00043 24.0001C4.00043 24.5304 4.21106 25.039 4.586 25.4141L10 30.8281V36.0001C10 36.5305 10.2107 37.0392 10.5858 37.4143C10.9609 37.7893 11.4696 38.0001 12 38.0001H17.172L22.586 43.4141C22.9611 43.789 23.4697 43.9996 24 43.9996C24.5303 43.9996 25.0389 43.789 25.414 43.4141L30.828 38.0001H36C36.5304 38.0001 37.0391 37.7893 37.4142 37.4143C37.7893 37.0392 38 36.5305 38 36.0001V30.8281L43.414 25.4141C43.7889 25.039 43.9996 24.5304 43.9996 24.0001C43.9996 23.4697 43.7889 22.9611 43.414 22.5861Z" fill="url(#paint0_linear_6530_10205)" />
                                                        <path opacity="0.56" d="M30.3137 16.3725L20.8405 26.2766L18.27 23.3026C18.041 23.069 17.7362 22.9288 17.4131 22.9081C17.09 22.8876 16.7706 22.988 16.5148 23.1907C16.259 23.3934 16.0844 23.6843 16.0237 24.0092C15.9628 24.3339 16.0202 24.6702 16.1849 24.9548L19.2317 30.2327C19.3777 30.4678 19.5795 30.6614 19.8183 30.7955C20.0572 30.9296 20.3253 31 20.5978 31C20.8704 31 21.1386 30.9296 21.3775 30.7955C21.6162 30.6614 21.818 30.4678 21.964 30.2327L31.5899 17.8595C32.7134 16.5377 31.2753 15.3812 30.3047 16.3725H30.3137Z" fill="white" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_6530_10205" x1="10.586" y1="10.5861" x2="37.414" y2="37.4141" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#0F4375" />
                                                                <stop offset="1" stop-color="#2D6193" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                </div>
                                                <div className="col-lg-8 col-8" style={{ position: "relative" }}>
                                                    <h5 className='mir-heading-l1-l2 text-shades-100 text-left vertical-center'>Offshore</h5>
                                                    {/* <p className='mir-paragraph-sm-l4-l7 text-shades-100 text-left'>Less than 5 years</p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section data-section id='section-3' className='section-3-staffing'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 specialzations">
                            <h4 className='overline mir-paragraph-sm-l2-l7 text-primary-500'>our specializations</h4>
                            <h2 className='mir-heading-l6-l2 text-secondary-800'>Staffing industry specializations</h2>
                            <p className='mir-paragraph-md-l4-l7 text-secondary-700 text-justify intro-desc-2'>We offer comprehensive expertise across various industries, ensuring tailored solutions to meet your unique needs.</p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="sec-4-card-container">
                        <div className="row">
                            <div className="col-lg-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                    <g clip-path="url(#clip0_6803_72169)">
                                        <path d="M16.0605 19.0598L8.56126 26.559C8.26651 26.8538 7.88401 27 7.49926 27C7.11451 27 6.73201 26.8538 6.43951 26.5613C5.85451 25.9763 5.85451 25.0268 6.43951 24.4395L12.879 18L6.43951 11.5605C5.85451 10.9755 5.85451 10.026 6.43951 9.43875C7.02451 8.85375 7.97401 8.85375 8.56126 9.43875L16.0605 16.938C16.6455 17.5253 16.6455 18.4748 16.0605 19.0598Z" fill="#8EB8F7" />
                                        <path d="M28.5007 27H19.5007C18.6705 27 18 26.3295 18 25.4993C18 24.669 18.6705 23.9985 19.5007 23.9985H28.5007C29.331 23.9985 30.0015 24.6713 30.0015 25.4993C30.0015 26.3273 29.3287 27 28.5007 27Z" fill="#8EB8F7" />
                                        <path d="M31.5 36H4.5C2.01825 36 0 33.9818 0 31.5V4.5C0 2.01825 2.01825 0 4.5 0H31.5C33.9818 0 36 2.01825 36 4.5V31.5C36 33.9818 33.9818 36 31.5 36ZM4.5 2.99925C3.672 2.99925 2.99925 3.672 2.99925 4.5V31.5C2.99925 32.328 3.672 33.0007 4.5 33.0007H31.5C32.328 33.0007 33.0007 32.328 33.0007 31.5V4.5C33.0007 3.672 32.328 2.99925 31.5 2.99925H4.5Z" fill="#1D68F0" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_6803_72169">
                                            <rect width="36" height="36" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <h5 className='mir-paragraph-md-l2-l7 text-secondary-800 sec-4-card-header'>Technology and IT</h5>
                                <p className='mir-paragraph-rg-l4-l7 text-secondary-600 sec-4-card-desc'>
                                    Our specialization in Technology and IT guarantees cutting-edge solutions and services for your business.
                                </p>
                            </div>
                            <div className="col-lg-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                    <g clip-path="url(#clip0_6803_72160)">
                                        <path d="M34.875 13.4999C35.496 13.4999 36 12.9959 36 12.3749V10.1249C36 9.7086 35.7705 9.32835 35.4038 9.1326L18.5288 0.132602C18.198 -0.0428984 17.802 -0.0428984 17.4713 0.132602L0.596251 9.1326C0.229501 9.32835 0 9.7086 0 10.1249V12.3749C0 12.9959 0.504 13.4999 1.125 13.4999H4.5V31.4999H1.50075C0.6705 31.4999 0 32.1704 0 33.0006V34.5014C0 35.3293 0.6705 35.9999 1.50075 35.9999H34.5015C35.3295 35.9999 36 35.3294 36 34.4991V32.9984C36 32.1704 35.3295 31.4999 34.4993 31.4999H31.5V13.4999H34.875ZM28.5007 31.4999H7.5015V13.4999H28.5007V31.4999Z" fill="#1D68F0" />
                                        <path d="M17.4308 23.6247H18.5715C19.0845 23.6247 19.5008 24.0432 19.5008 24.5697C19.5008 25.0827 19.0823 25.499 18.5715 25.499H15.3765C14.7555 25.499 14.2515 26.003 14.2515 26.624C14.2515 27.245 14.7555 27.749 15.3765 27.749H16.875V28.874C16.875 29.495 17.379 29.999 18 29.999C18.621 29.999 19.125 29.495 19.125 28.874V27.6927C20.6145 27.4272 21.7508 26.1312 21.7508 24.554C21.7508 22.8012 20.3243 21.3747 18.5715 21.3747H17.4308C16.9178 21.3747 16.5015 20.9562 16.5015 20.4297C16.5015 19.9167 16.92 19.5005 17.4308 19.5005H20.6258C21.2468 19.5005 21.7508 18.9965 21.7508 18.3755C21.7508 17.7545 21.2468 17.2505 20.6258 17.2505H19.125V16.1255C19.125 15.5045 18.621 15.0005 18 15.0005C17.379 15.0005 16.875 15.5045 16.875 16.1255V17.3067C15.3855 17.5722 14.2493 18.8682 14.2493 20.4455C14.2493 22.1982 15.6758 23.6247 17.4308 23.6247Z" fill="#8EB8F7" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_6803_72160">
                                            <rect width="36" height="36" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <h5 className='mir-paragraph-md-l2-l7 text-secondary-800 sec-4-card-header'>Finance and Banking</h5>
                                <p className='mir-paragraph-rg-l4-l7 text-secondary-600 sec-4-card-desc'>
                                    In the field of Finance and Banking, we deliver precision and efficiency, maximizing your financial operations.
                                </p>
                            </div>
                            <div className="col-lg-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                    <g clip-path="url(#clip0_6803_72174)">
                                        <path d="M22.5064 25.5124C21.6773 25.5124 21.0035 24.8387 21.0035 24.0095V19.8794C21.0035 19.0502 21.6773 18.3765 22.5064 18.3765C23.3356 18.3765 24.0093 19.0502 24.0093 19.8794V24.0095C24.0093 24.841 23.3356 25.5124 22.5064 25.5124Z" fill="#1D68F0" />
                                        <path d="M4.48065 25.5124C3.65147 25.5124 2.97775 24.8387 2.97775 24.0095V19.8794C2.97775 19.0502 3.65147 18.3765 4.48065 18.3765C5.30984 18.3765 5.98355 19.0502 5.98355 19.8794V24.0095C5.9813 24.841 5.30984 25.5124 4.48065 25.5124Z" fill="#1D68F0" />
                                        <path d="M24.3834 22.8853H2.60147C1.15265 22.8853 -0.0257874 24.0637 -0.0257874 25.5125V30.7693C-0.0257874 32.2181 1.15265 33.3988 2.60372 33.3988H24.3856C25.8345 33.3988 27.0151 32.2204 27.0151 30.7693V25.5125C27.0129 24.0637 25.8345 22.8853 24.3834 22.8853ZM8.98711 29.6449H5.98356C5.15212 29.6449 4.48066 28.9712 4.48066 28.142C4.48066 27.3128 5.15437 26.6391 5.98356 26.6391H8.98711C9.81629 26.6391 10.49 27.3128 10.49 28.142C10.49 28.9712 9.81629 29.6449 8.98711 29.6449Z" fill="#1D68F0" />
                                        <path d="M22.2518 14.2462C22.2518 14.1854 22.191 14.1268 22.1324 14.1268C19.3564 14.1268 17.5809 11.45 18.0608 9.36572H2.60372C1.15941 9.36572 -0.0257874 10.5509 -0.0257874 11.993V17.2498C-0.0257874 18.6918 1.16166 19.8793 2.60372 19.8793H24.3856C25.4379 19.8793 26.3527 19.2484 26.7583 18.3471C25.2351 18.7279 22.2518 17.2385 22.2518 14.2462ZM8.98711 16.1254H5.98356C5.15663 16.1254 4.48066 15.4494 4.48066 14.6225C4.48066 13.7956 5.15663 13.1196 5.98356 13.1196H8.98711C9.81404 13.1196 10.49 13.7956 10.49 14.6225C10.49 15.4494 9.81178 16.1254 8.98711 16.1254Z" fill="#1D68F0" />
                                        <path d="M34.8992 15.3728C34.2773 15.3728 33.7726 14.8681 33.7726 14.2462C33.7726 7.82678 28.5496 2.60381 22.1302 2.60381C21.5083 2.60381 21.0035 2.09909 21.0035 1.4772C21.0035 0.855309 21.5083 0.350586 22.1302 0.350586C29.7934 0.350586 36.0258 6.583 36.0258 14.2462C36.0258 14.8681 35.5211 15.3728 34.8992 15.3728Z" fill="#8EB8F7" />
                                        <path d="M30.6428 15.3728C30.0209 15.3728 29.5162 14.8681 29.5162 14.2462C29.5162 10.1746 26.204 6.86016 22.1302 6.86016C21.5083 6.86016 21.0035 6.35543 21.0035 5.73355C21.0035 5.11166 21.5083 4.60693 22.1302 4.60693C27.4455 4.60693 31.7694 8.93087 31.7694 14.2462C31.7694 14.8681 31.2647 15.3728 30.6428 15.3728Z" fill="#8EB8F7" />
                                        <path d="M26.3887 15.3728C25.7669 15.3728 25.2621 14.8681 25.2621 14.2462C25.2621 12.5203 23.8584 11.1165 22.1324 11.1165C21.5105 11.1165 21.0058 10.6118 21.0058 9.98989C21.0058 9.368 21.5105 8.86328 22.1324 8.86328C25.0999 8.86328 27.5154 11.2787 27.5154 14.2462C27.5154 14.8681 27.0106 15.3728 26.3887 15.3728Z" fill="#8EB8F7" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_6803_72174">
                                            <rect width="36" height="36" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <h5 className='mir-paragraph-md-l2-l7 text-secondary-800 sec-4-card-header'>Telecom</h5>
                                <p className='mir-paragraph-rg-l4-l7 text-secondary-600 sec-4-card-desc'>
                                    In Telecom, we provide innovative solutions to keep you connected and competitive.
                                </p>
                            </div>
                            <div className="col-lg-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                    <g clip-path="url(#clip0_6803_72191)">
                                        <path d="M22.511 18.0261C22.511 20.5124 20.4951 22.5283 18.0088 22.5283C15.5225 22.5283 13.5066 20.5124 13.5066 18.0261C13.5066 15.5398 15.5225 13.5239 18.0088 13.5239C20.4951 13.5239 22.511 15.5398 22.511 18.0261Z" fill="#1D68F0" />
                                        <path d="M9.00439 18.0261C9.00439 20.5124 6.98852 22.5283 4.5022 22.5283C2.01588 22.5283 0 20.5124 0 18.0261C0 15.5398 2.01588 13.5239 4.5022 13.5239C6.98852 13.5239 9.00439 15.5398 9.00439 18.0261Z" fill="#1D68F0" />
                                        <path d="M36.0176 18.0261C36.0176 20.5124 34.0017 22.5283 31.5154 22.5283C29.0291 22.5283 27.0132 20.5124 27.0132 18.0261C27.0132 15.5398 29.0291 13.5239 31.5154 13.5239C34.0017 13.5239 36.0176 15.5398 36.0176 18.0261Z" fill="#1D68F0" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_6803_72191">
                                            <rect width="36" height="36" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <h6 className='mir-paragraph-md-l2-l7 text-secondary-800 sec-4-card-header'>And more</h6>
                                <p className='mir-paragraph-rg-l4-l7 text-secondary-600 sec-4-card-desc'>
                                    Our industry coverage goes beyond these areas, promising a wide range of offerings tailored to your business.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <GetStartedBlueBanner url={'https://outlook.office365.com/owa/calendar/MIRDigitalSolutions_Staffing@mirdigitalsolutions.com/bookings/'} buttonContent={<>Contact us now</>} heading={<>Your industry. Your needs. Our expertise.</>} />
                </div>
            </section>
            <section data-section id='section-6'>
                <div className="container sec-2-sub-staffing">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <div className='overline overline-success-story mir-paragraph-sm-l2-l7 text-primary-500'>
                                Achieving success through our staffing solutions
                            </div>
                            <div className="card-custom-staffing">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 20" fill="none">
                                    <path d="M23.6571 19.0789C22.7101 19.0789 21.8346 18.9271 21.0305 18.6234C20.2443 18.3197 19.5653 17.882 18.9935 17.3104C18.4217 16.7209 17.975 16.0152 17.6534 15.1935C17.3318 14.3717 17.171 13.4428 17.171 12.4067C17.171 10.0665 17.9661 7.83344 19.5564 5.70761C21.1645 3.5639 23.4963 1.66137 26.5518 0L29.5 4.20702C28.6959 4.63576 27.9812 5.05556 27.3558 5.46644C26.7483 5.87732 26.2123 6.29712 25.7477 6.72586C25.301 7.1546 24.9079 7.60121 24.5684 8.06568C24.2468 8.51228 23.9519 8.99462 23.6839 9.51268C24.5595 9.51268 25.3457 9.63773 26.0425 9.88783C26.7572 10.1379 27.3648 10.4863 27.8651 10.9329C28.3832 11.3616 28.7763 11.8707 29.0444 12.4603C29.3303 13.0319 29.4732 13.6482 29.4732 14.3092C29.4732 14.9702 29.3303 15.5954 29.0444 16.1849C28.7763 16.7566 28.3832 17.2568 27.8651 17.6855C27.3648 18.1143 26.7572 18.4537 26.0425 18.7038C25.3278 18.9539 24.5327 19.0789 23.6571 19.0789ZM6.98614 19.0789C6.03912 19.0789 5.16359 18.9271 4.35952 18.6234C3.57332 18.3197 2.89433 17.882 2.32255 17.3104C1.75077 16.7209 1.30407 16.0152 0.98244 15.1935C0.660813 14.3717 0.5 13.4428 0.5 12.4067C0.5 10.0665 1.29513 7.83344 2.8854 5.70761C4.49353 3.5639 6.82532 1.66137 9.88078 0L12.829 4.20702C12.025 4.63576 11.3102 5.05556 10.6848 5.46644C10.0773 5.87732 9.54128 6.29712 9.07671 6.72586C8.63001 7.1546 8.23691 7.60121 7.89741 8.06568C7.57579 8.51228 7.28096 8.99462 7.01294 9.51268C7.88848 9.51268 8.67468 9.63773 9.37153 9.88783C10.0863 10.1379 10.6938 10.4863 11.1941 10.9329C11.7123 11.3616 12.1054 11.8707 12.3734 12.4603C12.6593 13.0319 12.8022 13.6482 12.8022 14.3092C12.8022 14.9702 12.6593 15.5954 12.3734 16.1849C12.1054 16.7566 11.7123 17.2568 11.1941 17.6855C10.6938 18.1143 10.0863 18.4537 9.37153 18.7038C8.65681 18.9539 7.86168 19.0789 6.98614 19.0789Z" fill="#1D68F0" />
                                </svg>

                                <h4 className='text-center mir-heading-l3-l2 text-secondary-800 successstory-heading'>
                                    MIR knows exactly our clients and business needs. They bring in people with right kind of attitude and skills to help improve our business
                                </h4>
                                <p className='mir-paragraph-rg-l2-l7 success-author text-secondary-800'>Aravind Pawar, Senior Manager</p>
                                <p className='mir-paragraph-rg-l4-l7 text-secondary-500'>Technology Development & Delivery, CIBC </p>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='brands'>
                    <div className="container text-center">
                        <p className='mir-paragraph-sm-l2-l7 text-secondary-500 trusted-text'>Trusted by businesses of all sizes, across North America, Middle East and Africa</p>
                    </div>
                    <Banner images={images} speed={25000} />
                </div>

            </section>


            <div data-section id='section-7'>
                <ContactSection />
            </div>
            <div data-section id='section-8'>
                {/* <ContactSection /> */}
            </div>
            <Footer />
        </main>
        </>
    )
}

export const ChallengeIcon = () => (
    <svg width="35" height="35" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_5352_42016)">
            <path d="M22.75 28H5.25C2.35025 28 0 25.6497 0 22.75V5.25C0 2.35025 2.35025 0 5.25 0H22.75C25.6497 0 28 2.35025 28 5.25V22.75C28 25.6497 25.6497 28 22.75 28Z" fill="#FEEFEF" />
            <path d="M14 7C10.1386 7 7 10.1386 7 14C7 17.8614 10.1386 21 14 21C17.8614 21 21 17.8614 21 14C21 10.1386 17.8614 7 14 7ZM9.91638 12.2911C9.91638 11.6436 10.4414 11.1247 11.0828 11.1247C11.7241 11.1247 12.2491 11.6436 12.2491 12.2911C12.2491 12.9325 11.7241 13.4575 11.0828 13.4575C10.4414 13.4575 9.91638 12.9325 9.91638 12.2911ZM17.3294 17.9331C17.2156 18.0469 17.066 18.1038 16.9172 18.1038C16.7676 18.1038 16.6189 18.0469 16.5051 17.9331C15.8358 17.2638 14.9459 16.8962 14 16.8962C13.0541 16.8962 12.1642 17.2646 11.4957 17.934C11.2682 18.1615 10.899 18.1615 10.6706 17.934C10.4431 17.7065 10.4431 17.3372 10.6706 17.1089C11.5596 16.2199 12.7426 15.7299 14 15.7299C15.2574 15.7299 16.4395 16.2199 17.3294 17.1089C17.5569 17.3364 17.5569 17.7056 17.3294 17.9331ZM16.9164 13.4575C16.275 13.4575 15.75 12.9325 15.75 12.2911C15.75 11.6436 16.275 11.1247 16.9164 11.1247C17.5577 11.1247 18.0828 11.6436 18.0828 12.2911C18.0836 12.9325 17.5586 13.4575 16.9164 13.4575Z" fill="#E96767" />
        </g>
        <defs>
            <clipPath id="clip0_5352_42016">
                <rect width="28" height="28" fill="white" />
            </clipPath>
        </defs>
    </svg>
)