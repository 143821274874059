import React, { useEffect, useRef, useState } from 'react'
import Slider from "react-slick";
import './HomeCarousel.scss'


export default function HomeCarousel() {

    const sliderRef = useRef(null);
    const sliderItemRefs = useRef(null);
    const [currentSlideWidth, setCurrentSlideWidth] = useState(400);

    useEffect(() => {
        let slides = document.querySelectorAll('.slick-slide.slick-current');
        if(slides){
            let slide = slides[0];
            let width = slide.offsetWidth;
            setCurrentSlideWidth(width + 70)
        }
    }, [])

    var settings = {
        centerMode: true,
        centerPadding: '20%',
        slidesToShow: 1,
        // infinite: false,
        speed: 1500,
        arrows: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: true,
                    centerMode: true,
                    centerPadding: '2%',
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: true,
                    centerMode: true,
                    centerPadding: '2%',
                    slidesToShow: 1
                }
            }
        ]
    };

    const items = [
        { img: '/slider/harmonics.svg', name: 'Rob Barlow', designation: 'CEO, 6Harmonics', message: <> 
            <p>“With MIR Team's help, we have recently leveraged Microsoft Azure Solutions to securely store and access data in the Cloud. The whole implementation was thoroughly professional and finished on time.</p> 
            <p>As a result of this, our teams across the globe are collaborating much more effectively than ever. In addition to that, we are enjoying a lot of cost savings and have achieved operational efficiencies, while delivering services to our Clients.”</p> 
            </> },
        { img: '/slider/pharmachem.png', name: 'Nav Aggarwal', designation: 'CEO, A&A Pharmachem', message: <>
            <p>“With the newly customized dashboards and reports, we can now see the real-time status of our accounts, sales, customer history, and monthly financial statements at a glance.</p>
            <p>The customization and configuration process was professional and successful thanks to the MIR team's extensive resources and expertise. Throughout the process, the team has been very supportive and has always prioritized our company's unique needs.”</p>
        </> },
        { img: '/slider/make-a-wish.png', name: 'Ivneet Chadha', designation: 'VP of IT & Data Management, Make A Wish Canada', message: <>
            <p>“Microsoft Azure Cloud had all the security, data privacy, scalability options that we need to modernize our current operations, ensuring our data and knowledge sharing is secured and within budget.</p>
            <p>MIR team has made our cloud migration journey easier with professional support and in-depth training sessions from the team even after the implementation.”</p>
        </> },
    ]

    return (
        <section className='tw-pt-[96px]'>
            <h2 style={{ display: 'none' }}>Listen to our client's digital transformation success stories and their testimonials</h2>
            <div className='text-center tw-mb-[64px]'>
                <h4 className='tw-uppercase mir-p-overline-lg mir-text-primary-500 tw-mb-[16px]'>See why our clients trust us</h4>
                <div className='mir-h1-semibold mir-text-neutral-700'>Client <span>Success Stories</span></div>
            </div>
            <div className='slider-wrapper'>
                <div className="arrow-wrapper d-none d-md-block">
                    <div style={{ width: `${currentSlideWidth}px` }} className='btn-wrapper'>
                        <button className='arrow-btn arrow-btn-prev'  aria-label="previous" onClick={() => { sliderRef.current.slickPrev() }}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" fill="#1D68F0"/>
                            </svg>
                        </button>
                        <button className='arrow-btn arrow-btn-next'  aria-label="next" onClick={() => { sliderRef.current.slickNext() }}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.11959e-07 9L12.17 9L6.58 14.59L8 16L16 8L8 -6.99382e-07L6.59 1.41L12.17 7L7.86805e-07 7L6.11959e-07 9Z" fill="#1D68F0"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <Slider {...settings} ref={sliderRef}>
                    {
                        items.map((item, i) => {
                            return <div ref={sliderItemRefs} className='customer-slide-item-wrapper'>
                                <div className='customer-slide-item'>
                                    <img src={item.img} alt="customer-success-logo-image" />
                                    <div className="message mir-paragraph-md-l3-l7" style={{ fontSize: '20px' }}>{item.message}</div>
                                    <div className="person">
                                        <h4 className='mir-heading-l1-l1'>{item.name}</h4>
                                        <h6 className='mir-paragraph-rg-l1-l7'>{item.designation}</h6>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </Slider>
            </div>
        </section>
    )
}
