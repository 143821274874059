import React, { useState } from 'react'
import './index.scss'

export default function Dynamics({ iframe, overline, icon, heading, desc, videoPlaceholder }) {
    const [showVideo, setShowVideo] = useState(false);

    return (
        <div className='cloud-solutions dynamics'>
            <div className="container">
                <img className='icon' src={icon} alt="dynamics-image" />
                <small className='mir-p-overline-lg'>{overline}</small>
                <h3 className='mir-h1-semibold'>{heading}</h3>
                <p className='mir-p-md-regular' style={{ marginTop: '2rem' }}>{desc}</p>
            </div>
            <div className="video">
                {
                    !showVideo && <>
                        <img src={videoPlaceholder} className='placeholder-image' alt="video-placeholder.png" />
                        <img src="/supply-chain/play-button.png" onClick={() => { setShowVideo(true) }} className='play-button' alt="play-button-icon" />
                    </>
                }
                { showVideo && iframe }
            </div>
        </div>
    )
}
