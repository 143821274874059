import React, { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { StickyBannerWrapper } from './StickyBanner.styled';
import './StickyBanner.scss';
import BookNowModal from '../CommonElements/BookNowModal';

const StickyBanner = forwardRef(({ handleShow }, ref) => {
   const [showMe, setShowed] = React.useState(true);
   const [showRegionPopup, setShowRegionPopup] = React.useState(false);
   const onClick = () => setShowed(false);
   const handleChildEvent = () => {
      // Do something in the child component
      if(handleShow) handleShow(); // Call the parent function
      else{
         setShowRegionPopup(true)
      }
   };
   useImperativeHandle(ref, () => ({
      // Expose parent function to parent component
      callParentFunction: handleChildEvent,
   }));
   
   return (
      <>
         <div style={{ display: showMe ? "" : "none" }} className='sticky-wrapper'>
            <div className='row'>
               <div className='col-lg-10 col-md-10 col-sm-10 col-xs-10 col-10 offset-lg-1 offset-1 offset-xs-1 offset-sm-1 offset-md-1'>
                  <div className='text-center mir-paragraph-rg-l4-l7 text-shades-100'>
                     Schedule a FREE technical consultation with our team.
                     <span onClick={handleChildEvent} className='mir-paragraph-rg-l2-l7 text-primary-300 schedule-button'>Schedule Now</span>
                  </div>
               </div>
               <div className='col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1 text-right'>
                  <svg style={{cursor: "pointer"}} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                     <path d="M16.0675 15.6832C16.1256 15.7412 16.1717 15.8102 16.2031 15.886C16.2345 15.9619 16.2507 16.0432 16.2507 16.1253C16.2507 16.2075 16.2345 16.2888 16.2031 16.3647C16.1717 16.4405 16.1256 16.5095 16.0675 16.5675C16.0095 16.6256 15.9405 16.6717 15.8647 16.7031C15.7888 16.7345 15.7075 16.7507 15.6253 16.7507C15.5432 16.7507 15.4619 16.7345 15.386 16.7031C15.3102 16.6717 15.2412 16.6256 15.1832 16.5675L10.0003 11.3839L4.81753 16.5675C4.70026 16.6848 4.5412 16.7507 4.37535 16.7507C4.2095 16.7507 4.05044 16.6848 3.93316 16.5675C3.81588 16.4503 3.75 16.2912 3.75 16.1253C3.75 15.9595 3.81588 15.8004 3.93316 15.6832L9.11675 10.5003L3.93316 5.31753C3.81588 5.20026 3.75 5.0412 3.75 4.87535C3.75 4.7095 3.81588 4.55044 3.93316 4.43316C4.05044 4.31588 4.2095 4.25 4.37535 4.25C4.5412 4.25 4.70026 4.31588 4.81753 4.43316L10.0003 9.61675L15.1832 4.43316C15.3004 4.31588 15.4595 4.25 15.6253 4.25C15.7912 4.25 15.9503 4.31588 16.0675 4.43316C16.1848 4.55044 16.2507 4.7095 16.2507 4.87535C16.2507 5.0412 16.1848 5.20026 16.0675 5.31753L10.8839 10.5003L16.0675 15.6832Z" fill="#FAFAFC" />
                  </svg>
               </div>

            </div>

         </div>
         <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />
      </>
   );
});

export default StickyBanner;