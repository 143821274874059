import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import './Header.scss'
import MobileNav from './MobileNav';
import headerContent from '../../lib/headerContent';
import MIRRoutes from '../../lib/routes';
import StickyBanner from "../StickyBanner/StickyBanner";

export default function Header() {

    const [activeLink, setActiveLink] = useState(null);
    const [showMobileNav, setShowMobileNav] = useState(false);

    const location = useLocation();

    useEffect(() => {
      setActiveLink(null);
      setShowMobileNav(false)

    }, [location]);

    return (
        <div className='tw-sticky tw-top-0 tw-z-[10000000]'>
            <div className='header-wrapper tw-relative'>
                <div className="container header-links">
                    <Link to='/'>
                        <img height="auto" width="auto" src="/header/logo.svg" alt="logo-image" className="logo tw-h-[32px] tw-object-contain" />
                    </Link>
                    <div className="links d-none d-lg-flex">
                        <div className={`link ${activeLink == 0 ? 'active' : ''}`} onMouseEnter={(ev) => { setActiveLink(activeLink == 0 ? null : 0) }}>
                            <div className='mir-paragraph-rg-l3-l7'>Cloud Solutions</div>
                        </div>
                        <div className={`link ${activeLink == 1 ? 'active' : ''}`} onMouseEnter={(ev) => { setActiveLink(activeLink == 1 ? null : 1) }}>
                            <div className='mir-paragraph-rg-l3-l7'>Business Solutions</div>
                        </div>
                        <div className={`link`}>
                            <Link to={MIRRoutes.StaffingSolutions} className='mir-paragraph-rg-l3-l7'>Staffing Solutions</Link>
                        </div>
                        <div className={`link ${activeLink == 2 ? 'active' : ''}`} onMouseEnter={(ev) => { setActiveLink(activeLink == 2 ? null : 2) }}>
                            <div className='mir-paragraph-rg-l3-l7'>About Us</div>
                        </div>
                        <div className='link'>
                            <Link to={MIRRoutes.Contact} className='mir-btn-sm-primary'>Contact Us</Link>
                        </div>
                    </div>
                    <div className='links d-flex d-lg-none'>
                        <a href='#' className='link' onClick={() => { setShowMobileNav(!showMobileNav) }}>
                            { !showMobileNav && <img src="/header/menu.svg" alt="hamburger-menu-icon" className='w-7 h-7' /> }
                            { showMobileNav && <img src="/header/close.svg" alt="hamburger-menu-icon" className='w-7 h-7' /> }
                        </a>
                    </div>
                </div>
            </div>
            <div onMouseLeave={(ev) => { setActiveLink(null) }} className='nav-options-wrapper tw-shadow-lg'>
                { activeLink == 0 ? <CloudSolutionsNav /> : null }
                { activeLink == 1 ? <BusinessSolutionsNav /> : null }
                { activeLink == 2 ? <AboutUsNav /> : null }
            </div>
            { showMobileNav && <MobileNav showMobileNav={showMobileNav} setShowMobileNav={setShowMobileNav} /> }
        </div>
    )
}

export const CloudSolutionsNav = () => {

    const [index, setIndex] = useState(0);

    return <div className='nav-wrapper'>
        <div className="right-white-bg"></div>
        <div className="container">
            <div className="row">
                <div className="col-md-3 left-side-wrapper">
                    <div className="left-side">
                        <p className="heading">CLOUD SOLUTIONS</p>
                        <div className="links">
                            <div onMouseEnter={() => { setIndex(0) }} className={`link ${index == 0 ? 'active' : ''}`}>
                                <p className='mir-paragraph-rg-l3-l7'>Dynamics 365</p>
                                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.354 8.85403L6.35403 13.854C6.30757 13.9005 6.25242 13.9373 6.19173 13.9625C6.13103 13.9876 6.06598 14.0006 6.00028 14.0006C5.93458 14.0006 5.86953 13.9876 5.80883 13.9625C5.74813 13.9373 5.69298 13.9005 5.64653 13.854C5.60007 13.8076 5.56322 13.7524 5.53808 13.6917C5.51294 13.631 5.5 13.566 5.5 13.5003C5.5 13.4346 5.51294 13.3695 5.53808 13.3088C5.56322 13.2481 5.60007 13.193 5.64653 13.1465L10.2934 8.50028L5.64653 3.85403C5.55271 3.76021 5.5 3.63296 5.5 3.50028C5.5 3.3676 5.55271 3.24035 5.64653 3.14653C5.74035 3.05271 5.8676 3 6.00028 3C6.13296 3 6.26021 3.05271 6.35403 3.14653L11.354 8.14653C11.4005 8.19296 11.4374 8.24811 11.4626 8.30881C11.4877 8.36951 11.5007 8.43457 11.5007 8.50028C11.5007 8.56599 11.4877 8.63105 11.4626 8.69175C11.4374 8.75245 11.4005 8.80759 11.354 8.85403Z" fill="#00319C"/>
                                </svg>
                            </div>
                            <div onMouseEnter={() => { setIndex(1) }} className={`link ${index == 1 ? 'active' : ''}`}>
                                <p className='mir-paragraph-rg-l3-l7'>Productivity</p>
                                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.354 8.85403L6.35403 13.854C6.30757 13.9005 6.25242 13.9373 6.19173 13.9625C6.13103 13.9876 6.06598 14.0006 6.00028 14.0006C5.93458 14.0006 5.86953 13.9876 5.80883 13.9625C5.74813 13.9373 5.69298 13.9005 5.64653 13.854C5.60007 13.8076 5.56322 13.7524 5.53808 13.6917C5.51294 13.631 5.5 13.566 5.5 13.5003C5.5 13.4346 5.51294 13.3695 5.53808 13.3088C5.56322 13.2481 5.60007 13.193 5.64653 13.1465L10.2934 8.50028L5.64653 3.85403C5.55271 3.76021 5.5 3.63296 5.5 3.50028C5.5 3.3676 5.55271 3.24035 5.64653 3.14653C5.74035 3.05271 5.8676 3 6.00028 3C6.13296 3 6.26021 3.05271 6.35403 3.14653L11.354 8.14653C11.4005 8.19296 11.4374 8.24811 11.4626 8.30881C11.4877 8.36951 11.5007 8.43457 11.5007 8.50028C11.5007 8.56599 11.4877 8.63105 11.4626 8.69175C11.4374 8.75245 11.4005 8.80759 11.354 8.85403Z" fill="#00319C"/>
                                </svg>
                            </div>
                            <div onMouseEnter={() => { setIndex(2) }} className={`link ${index == 2 ? 'active' : ''}`}>
                                <p className='mir-paragraph-rg-l3-l7'>MIR Products</p>
                                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.354 8.85403L6.35403 13.854C6.30757 13.9005 6.25242 13.9373 6.19173 13.9625C6.13103 13.9876 6.06598 14.0006 6.00028 14.0006C5.93458 14.0006 5.86953 13.9876 5.80883 13.9625C5.74813 13.9373 5.69298 13.9005 5.64653 13.854C5.60007 13.8076 5.56322 13.7524 5.53808 13.6917C5.51294 13.631 5.5 13.566 5.5 13.5003C5.5 13.4346 5.51294 13.3695 5.53808 13.3088C5.56322 13.2481 5.60007 13.193 5.64653 13.1465L10.2934 8.50028L5.64653 3.85403C5.55271 3.76021 5.5 3.63296 5.5 3.50028C5.5 3.3676 5.55271 3.24035 5.64653 3.14653C5.74035 3.05271 5.8676 3 6.00028 3C6.13296 3 6.26021 3.05271 6.35403 3.14653L11.354 8.14653C11.4005 8.19296 11.4374 8.24811 11.4626 8.30881C11.4877 8.36951 11.5007 8.43457 11.5007 8.50028C11.5007 8.56599 11.4877 8.63105 11.4626 8.69175C11.4374 8.75245 11.4005 8.80759 11.354 8.85403Z" fill="#00319C"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-9 right-side-wrapper">
                    <div className="right-side w-100">
                        <p className="heading">{headerContent.cloudSolutionLinks[index].title}</p>
                        <div className="row w-100 mt-4">
                            {
                                headerContent.cloudSolutionLinks[index].items.map((item, index) => (
                                    <div className="col-md-6" key={`service-item-key-${index}`}>
                                        <Link to={item.href ? item.href : '/'}>
                                            <div className="service-item">
                                                <img src={item.img} />
                                                <div className="content">
                                                    <h5 className='mir-text-neutral-800 mir-paragraph-rg-l2-l7'>{item.heading}</h5>
                                                    <p className='mir-paragraph-sm-l3-l7'>{item.subtitle}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export const BusinessSolutionsNav = () => {

    const [index, setIndex] = useState(0);

    return <div className='business-nav-wrapper'>
        <div className="container">
            <div className="w-100">
                <p className="heading">{headerContent.businessSolutionLinks[index].title}</p>
                <div className="row w-100 mt-4">
                    {
                        headerContent.businessSolutionLinks[index].items.map((item, index) => (
                            <div className="col-md-4" key={`business-item-key-${index}`}>
                                <Link to={item.href ? item.href : '/'}>
                                    <div className="service-item">
                                        <img className='header-img-sp' src={item.img} />
                                        <div className="content">
                                            <h5 className='mir-text-neutral-800 mir-paragraph-rg-l2-l7'>{item.heading}</h5>
                                            <p className='mir-paragraph-sm-l3-l7'>{item.subtitle}</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    </div>
}

export const AboutUsNav = () => {

    return (<>
        <div className='nav-wrapper'>
            <div className="right-white-bg"></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-3 left-side-wrapper">
                        <div className="left-side">
                            <div className="about-wrapper">
                                <p className='mir-heading-l3-l3'>Company</p>
                                <p className='mir-paragraph-rg-l4-l7'>Unlock your business potential with
                                    Microsoft-Certified Excellence</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9 right-side-wrapper">
                        <div className="right-side w-100">
                            <p className="heading">ABOUT US</p>
                            <div className="row w-100 mt-4">
                                <div className="col-md-4">
                                    <div className="about-item">
                                        <Link to={MIRRoutes.MIRTeam}>
                                            <div className='content-wrapper'>
                                                <img src="/header/about/users.svg"/>
                                                <div className="content">
                                                    <h5 className='mir-paragraph-rg-l2-l7'>MIR Team</h5>
                                                    <p className='mir-p-sm-regular m-0'>Get to know more about us</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="about-item">
                                        <Link to={MIRRoutes.CaseStudies}>
                                            <div className='content-wrapper'>
                                                <img src="/header/about/bookmark.svg"/>
                                                <div className="content">
                                                    <h5 className='mir-text-neutral-800 mir-paragraph-rg-l2-l7'>Case
                                                        Studies</h5>
                                                    <p className='mir-p-sm-regular m-0'>Explore our real-world
                                                        results</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="about-item">
                                        <Link to={'/blogs'}>
                                            <div className='content-wrapper'>
                                                <img src="/header/about/read.svg"/>
                                                <div className="content">
                                                    <h5 className='mir-text-neutral-800 mir-paragraph-rg-l2-l7'>Blogs</h5>
                                                    <p className='mir-p-sm-regular m-0'>Get the latest from MIR</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="about-item">
                                        <Link to={MIRRoutes.MIRCulture}>
                                            <div className='content-wrapper'>
                                                <img src="/header/about/Culture.svg"/>
                                                <div className="content">
                                                    <h5 className='mir-text-neutral-800 mir-paragraph-rg-l2-l7'>MIR
                                                        Culture</h5>
                                                    <p className='mir-p-sm-regular m-0'>Explore what makes us special</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <StickyBanner/>
    </>)
}