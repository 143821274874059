import React from 'react'
import StickyBanner from '../StickyBanner/StickyBanner'
import Footer from '../Footer/Footer'
import { ContactForm } from '../CloudSolutions/Elements/Sections/ContactSection'
import { Helmet } from 'react-helmet'

export default function ContactUs() {
  return (
    <main>

        <Helmet>
            <meta charSet="utf-8" />
            <title>Contact Us | Microsoft Dynamics 365 & Power Platform Experts | MIR Digital Solutions</title>
            <meta name="description" content="Give us a call to get started. Our Dynamics 365 ERP, CRM, Business Central, Supply Chain & Power Platform expert team is ready to help you succeed!" />
            <link rel="canonical" href={`${window.location.origin}/contact-us`} />
        </Helmet>

        <StickyBanner />

        <section className=' overflow-hidden max-w-full tw-pt-[160px] tw-relative tw-bg-cover' style={{ backgroundImage: 'url(/contact/bg-desk.png)', backgroundPosition: 'center bottom' }}>
            <img src="/contact/right-planet.png" alt="right-planet" className='tw-w-[35%] md:tw-w-fit md:tw-h-fit tw-right-0 tw-absolute tw-top-0' />
            <img src="/contact/left-planet.png" alt="right-planet" className='tw-hidden md:tw-block tw-w-fit tw-h-fit tw-left-0 tw-absolute tw-top-0' />
            <img src="/contact/rocket.png" alt="right-planet" className='tw-w-fit tw-h-fit tw-left-0 tw-absolute tw-bottom-[96px] md:tw-bottom-[150px]' />
            <div className="container mx-auto">
                <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-x-[128px] tw-relative tw-z-10">
                    <div>
                        <h4 className='tw-hidden'>MIR Digital Solutions Contact US</h4>
                        <div className='mir-p-overline-lg mir-text-primary-500 tw-mb-[24px]'>CONTACT US</div>
                        <div className='mir-h1-semibold tw-mb-[32px] mir-text-neutral-100 tw-max-w-full md:tw-max-w-[449px]'>Unlock Your Business Potential with Certified Excellence Solutions</div>
                        <h1 className='tw-hidden'>Unlock your business potential with Microsoft certified excellent solutions and implementation services</h1>
                        <p className='mir-p-md-regular mir-text-neutral-200 tw-mb-[96px]'>We serve clients across North America and the Middle East. For additional information about MIR Digital Solutions or how we can assist you in achieving business transformation, please connect with us using the form or contact our headquarters directly.</p>
                    </div>
                    <div className='tw-bg-white tw-rounded-[16px] tw-p-[36px] tw-relative tw-z-20'>
                        <ContactForm />
                    </div>
                </div>
            </div>
        </section>

        <section className='tw-py-[120px] tw-bg-[#03071C] tw-relative tw-z-0 -tw-mt-[96px] md:-tw-mt-[150px] overflow-hidden max-w-full'>
            <img src="/contact/right-blue-globe.png" alt="right-blue-globe" className='tw-hidden md:tw-block tw-absolute tw-right-0 tw-bottom-0' />
            <div className="container mx-auto">
                <div className='tw-max-w-full md:tw-max-w-[408px]'>
                    <h1 className='tw-mt-[96px] md:tw-mt-0 tw-max-w-full md:tw-max-w-[447px] mir-h3-semibold mir-text-neutral-100 tw-mb-[96px]'>Looking for us? You can always find us at</h1>
                    <div className='tw-flex tw-items-start tw-justify-start tw-mb-[52px]'>
                        <img src="/contact/business.svg" alt="business-icon" className='tw-w-fit tw-h-fit tw-mr-[16px] tw-mt-[4px]' />
                        <div>
                            <h4 className='tw-hidden'>MIR Digital Solutions Business Hours</h4>
                            <div className='mir-p-rg-regular mir-text-neutral-100 tw-mb-[12px] mir-h6-bold'>Business Hours</div>
                            <p className='tw-mb-[16px] mir-text-neutral-100 mir-p-rg-regular'>Monday - Friday from 9AM - 5PM</p>
                            <div className='tw-flex tw-items-center tw-justify-start'>
                                <a href="https://ca.linkedin.com/company/mirdigitalsolutions" target='_blank' className='tw-mr-[12px]'><img src="/contact/linkedin.svg" alt="linkedin-icon" /></a>
                                <a href="https://www.instagram.com/mirdigitalsolutions/" target='_blank' className='tw-mr-[12px]'><img src="/contact/instagram.svg" alt="instagram-icon" /></a>
                                <a href="https://www.facebook.com/MIR.Digital.Solutions" target='_blank' className='tw-mr-[12px]'><img src="/contact/facebook.svg" alt="facebook-icon" /></a>
                            </div>
                        </div>
                    </div>
                    <div className='tw-flex tw-items-start tw-justify-start tw-mb-[52px]'>
                        <img src="/contact/business.svg" alt="business-icon" className='tw-w-fit tw-h-fit tw-mr-[16px] tw-mt-[4px]' />
                        <div>
                            <h4 className='tw-hidden'>MIR Digital Solutions Canada Office</h4>
                            <div className='mir-p-rg-regular mir-text-neutral-100 tw-mb-[12px] mir-h6-bold'>Canada Office</div>
                            <p className='tw-mb-[12px] mir-text-neutral-100 mir-p-rg-regular'>7880 Keele Street, Unit 203, Vaughan, ON L4K 4G7, Canada</p>
                            <p className='tw-mb-[12px] mir-text-neutral-100 mir-p-rg-regular'>Call us at <b><a href="tel:1-866-437-3812">1-866-437-3812</a></b></p>
                            <p className='tw-mb-[12px] mir-text-neutral-100 mir-p-rg-regular'>Email us at <b><a href="mailto:sales@mirdigitalsolutions.com">sales@mirdigitalsolutions.com</a></b></p>
                        </div>
                    </div>
                    <div className='tw-flex tw-items-start tw-justify-start tw-mb-[52px]'>
                        <img src="/contact/business.svg" alt="business-icon" className='tw-w-fit tw-h-fit tw-mr-[16px] tw-mt-[4px]' />
                        <div>
                            <h4 className='tw-hidden'>MIR Digital Solutions US Office</h4>
                            <div className='mir-p-rg-regular mir-text-neutral-100 tw-mb-[12px] mir-h6-bold'>US Office</div>
                            <p className='tw-mb-[12px] mir-text-neutral-100 mir-p-rg-regular'>17250 Dallas Parkway, Dallas, TX, 75248, USA</p>
                            <p className='tw-mb-[12px] mir-text-neutral-100 mir-p-rg-regular'>Call us at <b><a href="tel:1-866-437-3812">1-866-437-3812</a></b></p>
                            <p className='tw-mb-[12px] mir-text-neutral-100 mir-p-rg-regular'>Email us at <b><a href="mailto:sales@mirdigitalsolutions.com">sales@mirdigitalsolutions.com</a></b></p>
                        </div>
                    </div>
                    <div className='tw-flex tw-items-start tw-justify-start tw-mb-[52px]'>
                        <img src="/contact/business.svg" alt="business-icon" className='tw-w-fit tw-h-fit tw-mr-[16px] tw-mt-[4px]' />
                        <div>
                            <h4 className='tw-hidden'>MIR Digital Solutions UAE Office</h4>
                            <div className='mir-p-rg-regular mir-text-neutral-100 tw-mb-[12px] mir-h6-bold'>UAE Office</div>
                            <p className='tw-mb-[12px] mir-text-neutral-100 mir-p-rg-regular'>102 Naser Ahmed Saeed Alawadhi Building, Dubai, United Arab Emirates</p>
                            <p className='tw-mb-[12px] mir-text-neutral-100 mir-p-rg-regular'>Call us at <b><a href="tel:971-52-877-5716">971-52-877-5716</a></b></p>
                            <p className='tw-mb-[12px] mir-text-neutral-100 mir-p-rg-regular'>Email us at <b><a href="mailto:sales-mea@mirdigitalsolutions.com">sales-mea@mirdigitalsolutions.com</a></b></p>
                        </div>
                    </div>
                </div>
            </div>
            <img src="/contact/right-blue-globe-mobile.png" alt="right-blue-globe" className='tw-block md:tw-hidden tw-w-full' />
        </section>

        <Footer />

    </main>
  )
}
