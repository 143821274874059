import React, { useEffect, useState } from 'react';
import './MobileNav.scss'
import headerContent from '../../../lib/headerContent';
import { Link, useLocation } from 'react-router-dom';
import MIRRoutes from '../../../lib/routes';

export default function MobileNav({showMobileNav, setShowMobileNav}) {

    const [activePage, setActivePage] = useState(null);
    const [viewingSubPages, setViewingSubPages] = useState(false);

    // useEffect(() => {
    //     console.log(activePage)
    // }, [activePage])

    const onBackClick = () => {
        setActivePage(null)
    }

    const location = useLocation();

    useEffect(() => {
        setActivePage(null);
    }, [location]);

    return (
        <>
            <div className='mobile-nav-wrapper'>
                <div className='header-wrapper'>
                    <div className="container header-links">
                        { !activePage && <img height="auto" width="auto" src="/header/logo.svg" alt="logo-image" className="logo" />}
                        { activePage && <a 
                        onClick={onBackClick} className='back-btn-wrapper mir-paragraph-md-l3-l7'>
                            <img src="/header/back.svg" alt="back-icon" />
                            <span className='ml-2'>Back</span>
                        </a> }
                        <div className='links d-flex d-lg-none'>
                            <a href="#" className='link' onClick={() => { setShowMobileNav(!showMobileNav) }}>
                                { !showMobileNav && <img src="/header/menu.svg" alt="hamburger-menu-icon" /> }
                                { showMobileNav && <img src="/header/close.svg" alt="hamburger-menu-icon" /> }
                            </a>
                        </div>
                    </div>
                </div>
                {
                    !activePage ? <div className="links-wrapper container">
                        <div className="links">
                            <div className="link" onClick={() => { setActivePage(1) }}>
                                <a href="#" className='mir-paragraph-md-l3-l7'>Cloud Solutions</a>
                                <img src="/header/about/caret-right.svg" className='w-3 h-3 object-contain' />
                            </div>
                            <div className="link" onClick={() => { setActivePage(2) }}>
                                <a href="#" className='mir-paragraph-md-l3-l7'>Business Solutions</a>
                                <img src="/header/about/caret-right.svg" className='w-3 h-3 object-contain' />
                            </div>
                            <Link className="link" to={MIRRoutes.StaffingSolutions}>
                                <a href="#" className='mir-paragraph-md-l3-l7'>Staffing Solutions</a>
                                <img src="/header/about/caret-right.svg" className='w-3 h-3 object-contain' />
                            </Link>
                            <div className="link" onClick={() => { setActivePage(3) }}>
                                <a href="#" className='mir-paragraph-md-l3-l7'>About Us</a>
                                <img src="/header/about/caret-right.svg" className='w-3 h-3 object-contain' />
                            </div>
                        </div>
                        <div className='contact-btn'>
                            <a className='mir-btn-lg-primary w-100'>Contact Us</a>
                        </div>
                    </div> : <div>
                        { (activePage == 1) && <CloudSolutionsMobileNav /> }
                        { (activePage == 2) && <BusinessSolutionsMobileNav /> }
                        { (activePage == 3) && <AboutUsMobileNav /> }
                    </div>
                }
            </div>
        </>
    )
}

const CloudSolutionsMobileNav = () => {

    const items = headerContent.cloudSolutionLinks;
    const [subItems, setSubItems] = useState(null);

    return <>
        <div className="heading">
            <div className="container">
                <p className='mir-paragraph-rg-l2-l7'>{subItems ? subItems.title : 'Cloud Solutions'}</p>
            </div>
        </div>
        <div className='sub-links-wrapper container'>
            {
                subItems ? <div className="links">
                    {
                        subItems.items.map((item, index) => {
                            return <Link to={item.href ? item.href : '/'}>
                                <div className="link">
                                    <div className="image-title">
                                        <img src={item.img} alt="sub-item-image" />
                                        <div className="content">
                                            <a href="#" className='mir-paragraph-md-l2-l7'>{item.heading}</a>
                                            <p style={{ color: "#9CA3AF" }} className="mir-paragraph-rg-l4-l7">{item.subtitle}</p>
                                        </div>
                                    </div>
                                    <img src="/header/about/caret-right.svg" className='w-3 h-3 object-contain' />
                                </div>
                            </Link>
                        })
                    }
                </div> : <div className="links">
                    {
                        items.map((item, index) => {
                            return <div className="link" onClick={() => { setSubItems(item) }}>
                                <a href="#" className='mir-paragraph-md-l3-l7'>{item.title}</a>
                                <img src="/header/about/caret-right.svg" className='w-3 h-3 object-contain' />
                            </div>
                        })
                    }
                </div>
            }
        </div>
    </>
}

const BusinessSolutionsMobileNav = () => {

    const items = headerContent.businessSolutionLinks;

    return <>
        <div className="heading">
            <div className="container">
                <p className='mir-paragraph-rg-l2-l7'>Business Solutions</p>
            </div>
        </div>
        <div className='sub-links-wrapper container'>
            <div className="links">
                {
                    items[0].items.map((item, index) => {
                        return <Link to={item.href ? item.href : '/'}>
                            <div className="link">
                                <div className="image-title">
                                    <img src={item.img} alt="sub-item-image" />
                                    <div className="content">
                                        <a href="#" className='mir-paragraph-md-l2-l7'>{item.heading}</a>
                                        <p style={{ color: "#9CA3AF" }} className="mir-paragraph-rg-l4-l7">{item.subtitle}</p>
                                    </div>
                                </div>
                                <img src="/header/about/caret-right.svg" className='w-3 h-3 object-contain' />
                            </div>
                        </Link>
                    })
                }
            </div>
        </div>
    </>
}

const AboutUsMobileNav = () => {

    return <>
        <div className="heading">
            <div className="container">
                <p className='mir-paragraph-rg-l2-l7'>About Us</p>
            </div>
        </div>
        <div className='sub-links-wrapper container'>
            <div className="links">
                <Link to={MIRRoutes.MIRTeam}>
                    <div className="link">
                        <div className="image-title" style={{ alignItems: 'center' }}>
                            <img src="/header/about/users.svg" alt="sub-item-image" />
                            <div className="content">
                                <a href="#" className='mir-paragraph-md-l2-l7'>MIR Team</a>
                                <p className='mir-text-neutral-600 mir-p-sm-regular m-0'>Get to know more about us</p>
                            </div>
                        </div>
                        <img src="/header/about/caret-right.svg" className='w-3 h-3 object-contain' />
                    </div>
                </Link>
                <Link to={MIRRoutes.CaseStudies}>
                    <div className="link">
                        <div className="image-title" style={{ alignItems: 'center' }}>
                            <img src="/header/about/bookmark.svg" alt="sub-item-image" />
                            <div className="content">
                                <a href="#" className='mir-paragraph-md-l2-l7'>Case Studies</a>
                                <p className='mir-text-neutral-600 mir-p-sm-regular m-0'>Explore our real-world results</p>
                            </div>
                        </div>
                        <img src="/header/about/caret-right.svg" className='w-3 h-3 object-contain' />
                    </div>
                </Link>
                <Link to={'/blogs'}>
                    <div className="link">
                        <div className="image-title" style={{ alignItems: 'center' }}>
                            <img src="/header/about/read.svg" alt="sub-item-image" />
                            <div className="content">
                                <a href="#" className='mir-paragraph-md-l2-l7'>Blogs</a>
                                <p className='mir-text-neutral-600 mir-p-sm-regular m-0'>Get the latest from MIR</p>
                            </div>
                        </div>
                        <img src="/header/about/caret-right.svg" className='w-3 h-3 object-contain' />
                    </div>
                </Link>
            </div>
        </div>
    </>
}