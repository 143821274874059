const CaseStudiesContent = [
    {
        id: 15,
        formId: 'pushform_realtime_reporting',
        metaSlug: 'real-time-reporting',
        metaTitle: 'Streamlining Azure Cost Management: Real-Time Reporting for a Leading Canadian Law Firm',
        metaDesc: "Streamlining Azure Cost Management: Real-Time Reporting for a Leading Canadian Law Firm",
        date: "29 October 2024",
        title: "Streamlining Azure Cost Management: Real-Time Reporting for a Leading Canadian Law Firm",
        image: '/case-studies/images/16.webp',
        downloadLink: '/case-studies/docs/16.pdf',
        body: <>
            A leading Canadian law firm transformed its Azure cost management by replacing its manual report generation with a real-time Power BI dashboard. The IT team faced challenges extracting and renaming data monthly, which delayed decision-making and increased the risk of errors. MIR implemented a new solution that features automated data extraction and a user-friendly dashboard displaying comprehensive metrics, including monthly costs, budget comparisons, and forecasts. <br /><br />
            This streamlined approach eliminated manual processes, saving significant time while enhancing accuracy. With immediate insights, senior leadership can now make informed decisions, leading to improved efficiency and effectiveness in managing Azure costs during budgeting discussions.<br /><br />
            <br /><br />
        </>
    },
    {
        id: 15,
        formId: 'pushform_legacy_erp',
        metaSlug: 'financial-operations',
        metaTitle: 'MIR transforms Legacy ERP to Cloud-based Dynamics 365 Finance & Operations for an Accounting firm',
        metaDesc: "MIR transforms Legacy ERP to Cloud-based Dynamics 365 Finance & Operations for an Accounting firm",
        date: "15 October 2024",
        title: "MIR transforms Legacy ERP to Cloud-based Dynamics 365 Finance & Operations for an Accounting firm",
        image: '/case-studies/images/15.png',
        downloadLink: '/case-studies/docs/15.pdf',
        body: <>
            A leading professional services firm in the United Arab Emirates faced inefficiencies, manual processes, and limited financial visibility due to its outdated system. To support their growing business, they partnered with MIR and implemented Microsoft Dynamics 365 Finance. This cloud-based solution streamlined their operations, enhanced decision-making, and improved accuracy. <br /><br />
            The results were amazing...!!<br /><br />
            A 50% faster financial close time, 80% automation of key processes, 99.9% accuracy in reporting, and a 20% reduction in maintenance costs, are now reinvested in innovation.
            <br /><br />
        </>
    },
    {
        id: 14,
        formId: 'pushform_asygen',
        metaSlug: 'easy-generator',
        metaTitle: 'Easy Generator adapts to meet the demands of their expanding operations in the Middle East',
        metaDesc: "Easy Generator adapts to meet the demands of their expanding operations in the Middle East",
        date: "15 September 2024",
        title: "Easy Generator adapts to meet the demands of their expanding operations in the Middle East",
        image: '/case-studies/images/14.png',
        downloadLink: '/case-studies/docs/14.pdf',
        body: <>
            Easy Generator, a leader in e-learning authoring tools, faced significant challenges during its Middle East expansion, including limited flexibility, data silos, and escalating costs from its legacy ERP system. To overcome these obstacles, the company partnered with MIR Digital Solutions to implement Microsoft Dynamics 365 Business Central.<br /><br />
            The new cloud-based ERP system enhanced Easy Generator’s scalability, data visibility, and cost efficiency. By integrating their business processes and eliminating data silos, the company now operates on a seamless, agile platform, positioning itself for continued growth and innovation.
            <br /><br />
        </>
    },
    {
        id: 13,
        formId: 'pushform_agmd',
        metaSlug: 'agmd',
        metaTitle: 'MIR Successfully Improves AGMD Group Inc\'s Productivity and Security with Microsoft 365 License Migration',
        metaDesc: "MIR Successfully Improves AGMD Group Inc's Productivity and Security with Microsoft 365 License Migration",
        date: "15 September 2024",
        title: "MIR Successfully Improves AGMD Group Inc's Productivity and Security with Microsoft 365 License Migration",
        image: '/case-studies/images/13.png',
        downloadLink: '/case-studies/docs/13.pdf',
        body: <>
            AGMD Group Inc, a leader in Personal Protective Equipment and medical solutions, faced multiple challenges, including inefficient software license management, weak email security, disconnected Office applications, and the absence of a document management system. These issues strained resources, hindered productivity, and exposed the company to cyber risks. To address these concerns, AGMD partnered with MIR Digital Solutions for a comprehensive M365 License Migration. <br /><br />
            The implementation, executed in a matter of days with zero downtime, significantly streamlined AGMD’s operations. The smooth transition enabled the company to enhance its security and productivity while reducing administrative burdens, positioning AGMD Group Inc for future growth and continued success.
            <br /><br />
        </>
    },
    {
        id: 12,
        formId: 'pushform_self_serve_portal',
        metaSlug: 'self-serve-portal',
        metaTitle: 'Boosting Internal Efficiency: Construction and Mining Leader Integrates Self-Serve Portal With Microsoft Dynamics 365',
        metaDesc: "Boosting Internal Efficiency: Construction and Mining Leader Integrates Self-Serve Portal With Microsoft Dynamics 365",
        date: "30 June 2024",
        title: "Boosting Internal Efficiency: Construction and Mining Leader Integrates Self-Serve Portal With Microsoft Dynamics 365",
        image: '/case-studies/images/12.svg',
        downloadLink: '/case-studies/docs/12.pdf',
        body: <>
            Our client, a leading company in the construction industry, faced significant challenges with manual, email-based communication for employee hiring, onboarding, and HR tasks, leading to errors, inefficiencies, and delays. Coordinating these processes via email was complex, and tracking document flow and approvals was difficult without a centralized system. <br /><br />
            Through MIR's expertise in Dynamics 365 customer service, the client transformed their HR operations, achieving greater accuracy and efficiency. This collaboration highlights the value of a reliable Dynamics 365 implementation partner for digital transformation and operational excellence.
            <br /><br />
        </>
    },
    {
        id: 11,
        formId: 'pushform_power_bi_law_firm',
        metaSlug: 'power-bi-paw-firm',
        metaTitle: 'MIR Enhances Leading Canadian Law Firm’s Critical Conflict Analysis & Client Intake Power BI reporting',
        metaDesc: "MIR Enhances Leading Canadian Law Firm’s Critical Conflict Analysis & Client Intake Power BI reporting",
        date: "30 June 2024",
        title: "MIR Enhances Leading Canadian Law Firm’s Critical Conflict Analysis & Client Intake Power BI reporting",
        image: '/case-studies/images/11.svg',
        downloadLink: '/case-studies/docs/11.pdf',
        body: <>
            To uphold high ethical standards and streamline the client intake process, a leading law firm recognized the need to improve their decision-making and reporting mechanisms.<br /><br />
            To address these challenges and achieve the firm's goals, MIR, an expert in PowerBI technical implementation in Canada, undertook a comprehensive update of the PowerBI report with the following objectives:
            <ul>
                <li>Design and usability improvements </li>
                <li>Active vs. inactive resource visualization  </li>
                <li>Metrics validation </li>
                <li>Interactive date range slider </li>
            </ul>
            <br /><br />
            By leveraging the top PowerBI solutions, MIR ensured the law firm's decision-making and reporting mechanisms were robust, efficient, and compliant with high ethical standards. This collaboration underscores the importance of having a trusted PowerBI technical implementation partner in Canada for legal industry advancements.<br /><br />
            <h3 className="mir-p-md-semibold">Want to learn more about the transformative journey? Fill the form and download the case study to unveil the complete success story. </h3> <br /><br />
        </>
    },
    {
        id: 10,
        formId: 'pushform_casestudy_woodgreen',
        metaSlug: 'woodgreen-tax-clinic-services-with-microsoft-power-platform',
        metaTitle: 'MIR and Compugen’s partnership transforms WoodGreen Tax Clinic services with Microsoft Power Platform, reducing wait times significantly',
        metaDesc: "Experience the transformative journey of WoodGreen's Tax Clinic Services with MIR Digital Solutions and Compugen, powered by Microsoft Power Platform.",
        date: "15 December 2023",
        title: "MIR and Compugen’s partnership transforms WoodGreen Tax Clinic services with Microsoft Power Platform, reducing wait times significantly",
        image: '/case-studies/images/10.webp',
        downloadLink: '/case-studies/docs/10.pdf',
        body: <>
            Discover how MIR Digital Solutions, in collaboration with Compugen, revolutionized WoodGreen's Tax Clinic Services using Microsoft Power Platform.<br /><br />
            By addressing challenges like a tedious appointment booking process and inconsistent eligibility checks, we crafted a visionary solution. <br /><br />
            Our cutting-edge Tax Clinic portal, powered by Microsoft Power Platform, resulted in higher efficiency, improved user experience, and a significant reduction in wait times. <br /><br />
            <h3 className="mir-p-md-semibold">Want to learn more about the transformative journey? Fill the form and download the case study to unveil the complete success story. </h3> <br /><br />
        </>
    },
    {
        id: 9,
        metaSlug: 'saving-in-microsoft-d365-licensing-costs',
        formId: 'pushform_casestudy_d365',
        metaTitle: 'MIR finds nearly a 50% saving in Microsoft D365 Licensing Costs for a Top-tier Organization',
        metaDesc: "Explore how MIR addressed client challenges by optimizing Microsoft Dynamics 365 licensing, & achieveing significant cost savings.",
        date: "07 December 2023",
        title: "MIR finds nearly a 50% saving in Microsoft D365 Licensing Costs for a Top-tier Organization",
        image: '/case-studies/images/9.webp',
        downloadLink: '/case-studies/docs/9.pdf',
        body: <>
            This case study outlines the client's challenges, the strategic approach taken by MIR Digital Solutions, and the impactful results achieved through optimizing Microsoft Dynamics 365 licensing.<br /><br />
            The content focuses on transparency, cost savings of more than 50%, and future cost controls, positioning MIR as a reliable partner for businesses seeking to optimize their software licensing.  <br /><br />
            <h3 className="mir-p-md-semibold">To learn more, please fill out the form and download the case study!</h3> <br /><br />
        </>
    },
    {
        id: 8,
        metaSlug: 'law-firm-azure-interview-assessment-tool',
        formId: 'pushform_casestudy_interview',
        metaTitle: 'Top Law Firm Cuts Down 300+ Hours of Manual Work with Microsoft Azure-based RecruIt Application Solution ',
        metaDesc: "Discover how MIR's automation solutions expedited interview scheduling and assessment for a top-ranked law firm in Canada.",
        date: "01 August 2023",
        title: "MIR Cuts 300+ Hours of Manual Work for Top Law Firm with Microsoft Azure-based RecruIt Application Solution",
        image: '/case-studies/images/8.webp',
        downloadLink: '/case-studies/docs/8.pdf',
        body: <>
            Want to expedite your interview scheduling, assessment and co-ordination process? Look no further.  <br /><br />
            Our esteemed client, a leading law firm in Canada ranked among the top 5, encountered challenges in efficiently recruiting new university graduates. This was primarily attributed to reliance on external tools and outdated software, necessitating exhaustive efforts from supervisors during the interview events. <br /><br />
            As a trusted partner, MIR helped implement end-to-end process automation, unlocking the full potential for scheduling, interviewing, and assessing candidates  <br /> <br />
            <b>Download the case study now to learn more about this transformative project</b><br /><br />
            Start streamlining your candidate-to-hire process today!
        </>
    },
    {
        id: 1,
        date: '22 jul 2023',
        title: 'Construction and mining giant drives operational efficiency with Dynamics 365 Inventory and Warehouse Management',
        metaSlug: 'construction-mining-inventory-warehouse-management',
        formId: 'pushform_casestudy_inventory',
        metaTitle: 'Construction & Mining Giant Drives  Efficiency with Dynamics 365 Inventory & Warehouse Management',
        metaDesc: 'Discover how a Dynamics 365 solution empowered a construction & mining leader to conquer inventory management challenges and streamline processes.',
        image: '/case-studies/images/1.webp',
        downloadLink: '/case-studies/docs/1.pdf',
        body: <>
            <p>Download our case study to discover how our solution helped this construction and mining leader overcome their inventory management challenges, achieve transparent visibility, and streamline their processes.</p><br />
            <b>Fill up the form now and gain valuable insights into our successful implementation.</b> <br /><br />
            <p>Start optimizing your inventory processes today!</p>
        </>
    },
    {
        id: 2,
        date: '29 mar 2023',
        title: 'A&A Pharmachem Inc drives operational efficiencies with D365 Business Central and Power BI',
        metaSlug: 'aanda-pharmachem-business-central-power-bi',
        formId: 'pushform_casestudy_pharmachem',
        metaTitle: 'A&A Pharmachem Inc Drives Operational Efficiencies with Dynamics 365 Business Central & Power BI',
        metaDesc: 'Discover how a nutraceutical company achieved improved inventory management, financial reporting, & sales visibility by migrating their ERP system to the cloud.',
        image: '/case-studies/images/2.webp',
        downloadLink: '/case-studies/docs/2.pdf',
        body: <>
            Looking to streamline your business functions, increase productivity, and respond to financial and supply chain issues faster? <br /><br />
            Our client, a reputable nutraceutical and sports nutrition company, faced similar challenges and overcame them by migrating their legacy ERP system to the cloud with Microsoft Business Central. With our help as their trusted support partner, we implemented key Business Central functionalities and processes, customized PowerBI dashboards, and migrated legacy data. <br /><br />
            The result? Improved inventory management, financial reporting, and sales visibility.<br /><br />
            <b>Fill up our webform and download our case study to learn more about their success story and how you can achieve similar benefits for your own business.</b>
        </>
    },
    {
        id: 3,
        metaSlug: 'make-a-wish-canada-azure-migration',
        formId: 'pushform_casestudy_makeawish',
        metaTitle: 'Make A Wish Upscales Organization Operations & Reduce Ongoing Costs with Azure Public Migration',
        metaDesc: "Discover how Make A Wish Canada modernized their infrastructure and scaled their operations with MIR's help. Learn about their transformative project today!",
        date: "21 Sep 2022",
        title: "Make A Wish upscales organization operations and reduce ongoing costs with Azure Public Migration",
        image: '/case-studies/images/3.webp',
        downloadLink: '/case-studies/docs/3.pdf',
        body: <>
            Want to know how Make A Wish Canada successfully modernized their infrastructure and scaled their operations without compromising security? As part of their digital transformation strategy, they engaged MIR to assist in moving their workloads from on-premises to the streamlined infrastructure of Microsoft Azure public cloud. <br /><br />
            Challenges with increased operational costs, system instability, and limited scalability were addressed through a comprehensive Lift and Shift migration strategy. <b>Learn more about this transformative project by filling up the webform and downloading our insightful case study.</b> <br /><br />
            Don't miss out on the chance to explore the benefits of cloud migration and modernization!
        </>
    },
    {
        id: 4,
        metaSlug: 'national-telecom-giant-automated-infrastructure-upgrade',
        formId: 'pushform_casestudy_telecom',
        metaTitle: 'National Telecom Giant Achieves 65% Time Savings Through Automated Infrastructure Upgrade',
        metaDesc: "Simplify and accelerate software upgrades with our automated solution. Discover how a Telecom company achieved a 65%-time savings. Download the case study now.",
        date: "5 jul 2022",
        title: "National telecom giant achieves 65% time savings through automated infrastructure upgrade",
        image: '/case-studies/images/4.webp',
        downloadLink: '/case-studies/docs/4.pdf',
        body: <>
            Curious to know how our client, a leading Telecom company, achieved a 65%-time savings through our automated infrastructure upgrade solution? <br /><br />
            Manual and complex processes were causing delays and impacting performance, which prompted our client to engage MIR for a simplified and efficient solution. With features such as an easy-to-use GUI, automated task workflows, rules-based troubleshooting, and real-time alerts, our solution transformed the upgrade process.<br /><br />
            <b>Discover the full details of this successful project by filling up the webform and downloading our insightful case study.</b> See how our solution improved efficiency and accelerated software upgrades for our client. <br /><br />
            Don't miss out on learning about the transformative power of automation in the Telecom industry!
        </>
    },
    {
        id: 5,
        metaSlug: 'dynamics-365-procurement-automation',
        formId: 'pushform_casestudy_automation',
        metaTitle: 'Construction & Mining Giant Achieves Procurement Automation & Vendor Management Excellence',
        metaDesc: "Unlock the potential of  your business with Microsoft Dynamics 365 Procurement - learn from our construction and mining client's success story!",
        date: "30 mar 2022",
        title: "Construction and mining giant achieves procurement automation and vendor management excellence with Microsoft Dynamics 365 F&O",
        image: '/case-studies/images/5.webp',
        downloadLink: '/case-studies/docs/5.pdf',
        body: <>
            Interested in learning how our client achieved a seamless, efficient procurement process by implementing Microsoft Dynamics D365 ecosystem? <br /><br />
            Through our expertise, we helped our client transform their legacy procurement methods into a streamlined, digitized system. <b>Take a deeper dive into this successful project by filling up the web form and downloading our comprehensive case study.</b> <br /><br />
            Discover how our solutions, such as Vendor Onboarding, Request for Quotation, and Vendor Collaboration, improved the procurement lifecycle management for our client. <br /><br />
            Don't miss out on the opportunity to explore the power of Microsoft Dynamics D365 in your organization!
        </>
    },
    {
        id: 6,
        metaSlug: '6-harmonics-agility-microsoft-365',
        formId: 'pushform_casestudy_6harmonics',
        metaTitle: '6Harmonics Drives Agility at Workplace with Cloud and Office 365 Migration',
        metaDesc: "Transform your organization with our expertise in the Microsoft 365 ecosystem. Our client was abl to enhance team collaboration, data security, & cost savings.",
        date: "2 mar 2022",
        title: "6Harmonics drives agility at workplace with Cloud and Office 365 Migration",
        image: '/case-studies/images/6.webp',
        downloadLink: '/case-studies/docs/6-1.pdf',
        body: <>
            Curious to know how our partnership with 6Harmonics resulted in improved team collaboration, enhanced data security, and significant cost savings? <br /><br />
            By leveraging our expertise in the Microsoft 365 ecosystem, we implemented a robust system that transformed their existing data into a more integrated and secure platform.<br /><br />
            <b>Find out more about this successful digital transformation journey by filling up the webform and downloading our insightful case study.</b> <br /><br />
            Don't miss out on discovering the potential of Microsoft Cloud Ecosystem for your organization!
        </>
    },
    {
        id: 7,
        metaSlug: 'workforce-managing-attendance-and-payroll-at-crew-time-tracker',
        formId: 'pushform_casestudy_payroll',
        metaTitle: 'Efficiently Manage Workforce Attendance & Payroll: The Success of Crew Time Tracker’s ERP integration',
        metaDesc: "Discover how our TimeSheet project revolutionized workforce management, saving our client $250,000 annually while enhancing productivity & employee satisfaction",
        date: "11 feb 2022",
        title: "Efficiently managing workforce attendance and payroll: The success of Crew Time Tracker’s seamless ERP integration",
        image: '/case-studies/images/7.webp',
        downloadLink: '/case-studies/docs/7.pdf',
        body: <>
            Interested in knowing more about how our solution helped our client overcome their workforce management challenges and saved them $250,000 every year?  <br /><br />
            Our TimeSheet project, powered by Microsoft Power Platform, not only reduced fraudulent reporting but also improved payroll processing times and increased employee satisfaction and productivity. <br /><br />
            <b>Download our case study by filling up the webform and learn more about our innovative solution.</b>
        </>
    },
]

export default CaseStudiesContent;